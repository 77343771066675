import { Action } from '@ngrx/store';
import { RealtimeQuestForm } from 'src/app/_interface/quest.types';
import * as fromProfile from './profile.reducer';

export const TRY_GET_USER_INFO = 'TRY_GET_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const REALTIME_QUEST_STARTED = 'REALTIME_QUEST_STARTED';
export const REALTIME_QUEST_FINISHED = 'REALTIME_QUEST_FINISHED';
export const FINISH_REALTIME_QUEST = 'FINISH_REALTIME_QUEST';
export const REALTIME_QUEST_FINISH_FAILED = 'REALTIME_QUEST_FINISH_FAILED';
export const REALTIME_QUEST_REPEATED = 'REALTIME_QUEST_REPEATED';
export const RESET_QUEST_SAVED_STATE = 'RESET_QUEST_SAVED_STATE';

export class UpdateUserInfo implements Action {
  readonly type = UPDATE_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}
export class TryGetUserInfo implements Action {
  readonly type = TRY_GET_USER_INFO;
}

export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}

export class ClearUserInfo implements Action {
  readonly type = CLEAR_USER_INFO;
}

export class RealtimeQuestStarted implements Action {
  readonly type = REALTIME_QUEST_STARTED;

  constructor(public questId: number, public userId: number) {
    console.log('ProfileActions::RealtimeQuestStarted', questId, userId);
  }
}

export class RealtimeQuestFinished implements Action {
  readonly type = REALTIME_QUEST_FINISHED;

  constructor(public questId: number) {
    console.log('ProfileActions::RealtimeQuestFinished', questId);
  }
}
export class RealtimeQuestRepeated implements Action {
  readonly type = REALTIME_QUEST_REPEATED;

  constructor(public questId: number, public userId: number) {
    console.log('ProfileActions::RealtimeQuestRepeated', questId, userId);
  }
}

export class ResetQuestSavedState implements Action {
  readonly type = RESET_QUEST_SAVED_STATE;
}

export class FinishRealtimeQuest implements Action {
  readonly type = FINISH_REALTIME_QUEST;

  constructor(public payload: RealtimeQuestForm) {}
}

export class RealtimeQuestFinishFailed implements Action {
  readonly type = REALTIME_QUEST_FINISH_FAILED;

  constructor(public reason: string) {}
}

export type ProfileActions =  
  TryGetUserInfo |
  GetUserInfo |
  ClearUserInfo |
  UpdateUserInfo |
  RealtimeQuestStarted |
  RealtimeQuestFinished |
  FinishRealtimeQuest |
  RealtimeQuestFinishFailed |
  RealtimeQuestRepeated |
  ResetQuestSavedState;
