// tslint:disable-next-line:max-line-length
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {TaskGroup, TaskPreviewPayload} from './dl-milestones.typings';
import * as Actions from './dl-milestones.actions';
import { QuestTask } from './quest.types';


export interface TaskGroupsState {
  milestones: TaskGroup[];
  selectedMilestoneForPreview: TaskPreviewPayload;
  newMilestoneForPreview: QuestTask;
  newMilestoneGroupIndex: number;
}

const initialState: TaskGroupsState = {
  milestones: [],
  selectedMilestoneForPreview: null,
  newMilestoneForPreview: null,
  newMilestoneGroupIndex: null
};

export function dlMilestonesReducer(state: TaskGroupsState = initialState, action: Actions.DlMilestoneActions): TaskGroupsState {
  switch (action.type) {
    case (Actions.SET_MILESTONES):
      return {
        ...state,
        milestones: action.payload
      };
    case (Actions.NEW_TASK_GROUP_ADDED):
      return {
        ...state,
        milestones: [...state.milestones, action.taskGroup]
      };
    case (Actions.TASK_GROUP_REMOVED):
      const groupsAfterRemoval = [...state.milestones];
      groupsAfterRemoval.splice(action.groupIndex, 1);
      return {
        ...state,
        milestones: [...groupsAfterRemoval]
      };
    case (Actions.ADD_MILESTONE):
      const taskGroups = [...state.milestones];
      if (taskGroups[action.groupIndex]) {
        taskGroups[action.groupIndex].questTasks.push(action.payload);
      } else {
        taskGroups[action.groupIndex] = {
          id: null,
          name: null,
          order: action.groupIndex,
          questTasks: [action.payload]
        };
      }

      return {
        ...state,
        milestones: taskGroups
      };
    case (Actions.ADD_MILESTONE_IMAGE_URL):
      const milestonesWithNewImageUrl = [...state.milestones];
      milestonesWithNewImageUrl[action.payload.groupIndex].questTasks[action.payload.taskIndex].imageUrl = action.payload.value;
      return {
        ...state,
        milestones: milestonesWithNewImageUrl
      };
    case (Actions.REMOVE_MILESTONE):
      const updatedGroups = [...state.milestones];
      const updatedGroup = updatedGroups[action.groupIndex];

      let groupIdToRemove = null;
      if (updatedGroup && updatedGroup.questTasks && updatedGroup.questTasks.length) {
        updatedGroup.questTasks.splice(action.taskIndex, 1);
        if (!updatedGroup.questTasks.length) {
          groupIdToRemove = updatedGroup.id;
        }
      }

      return {
        ...state,
        milestones: updatedGroups.filter(group => group.id !== groupIdToRemove)
      };
    case (Actions.MOVE_MILESTONE):
      const milestones = [...state.milestones];

      if (action.payload.previousGroupIndex === action.payload.currentGroupIndex) {
        moveItemInArray(milestones[action.payload.currentGroupIndex].questTasks, action.payload.previousIndex, action.payload.currentIndex);
      } else {
        transferArrayItem(
          milestones[action.payload.previousGroupIndex].questTasks,
          milestones[action.payload.currentGroupIndex].questTasks,
          action.payload.previousIndex,
          action.payload.currentIndex
        );
      }

      return {
        ...state,
        milestones: milestones
      };
    case (Actions.UPDATE_MILESTONE_TASK):
      const umtMilestones = [...state.milestones];
      umtMilestones[action.payload.groupIndex].questTasks[action.payload.taskIndex] = action.payload.task;
      return {
        ...state,
        milestones: [...umtMilestones]
      };
    case (Actions.TASK_POSITION_UPDATED):
      const tpuMilestones = [...state.milestones];
      const fromGroup = action.notification ? tpuMilestones[action.notification.previousGroupIndex] : null;
      let fromGroupIdToRemove;
      if (fromGroup && !fromGroup.questTasks.length) {
        fromGroupIdToRemove = fromGroup.id;
      }
      return {
        ...state,
        milestones: tpuMilestones.filter(group => group.id !== fromGroupIdToRemove)
      };
    case (Actions.UPDATE_MILESTONE_TASK_COMPLETION):
      const umtcMilestones = [...state.milestones];
      umtcMilestones[action.payload.groupIndex].questTasks[action.payload.taskIndex].isTaskCompleted = action.payload.value;
      return {
        ...state,
        milestones: [...umtcMilestones]
      };
    case (Actions.PATCH_GROUP_NAME):

      let milestonesWithNewGroupName: TaskGroup[];
      if (state.milestones.length > 0) {
        milestonesWithNewGroupName = [...state.milestones];
        milestonesWithNewGroupName[action.payload.index].name = action.payload.value;
      } else {
        milestonesWithNewGroupName = [{
          id: null,
          name: action.payload.value,
          order: 0,
          questTasks: []
        }];
      }
      return {
        ...state,
        milestones: [...milestonesWithNewGroupName]
      };
    case (Actions.PATCH_MILESTONE):
      const taskGroupsWithPatchUpdate = [...state.milestones];
      const taskGroupWithPatchUpdate = taskGroupsWithPatchUpdate[action.payload.groupIndex];
      if (taskGroupWithPatchUpdate) {
        taskGroupWithPatchUpdate.questTasks[action.payload.taskIndex] = new QuestTask({
          ...taskGroupWithPatchUpdate.questTasks[action.payload.taskIndex],
          ...action.payload.value
        });
      } else {
        taskGroupsWithPatchUpdate[action.payload.groupIndex] = {
          id: null,
          order: action.payload.groupIndex,
          name: null,
          questTasks: [new QuestTask({
            ...action.payload.value
          })]
        };
      }
      return {
        ...state,
        milestones: [...taskGroupsWithPatchUpdate]
      };
    case (Actions.SET_MILESTONE_FOR_PREVIEW):
      return {
        ...state,
        selectedMilestoneForPreview: action.payload
      };
    case (Actions.CLEAR_MILESTONE_FOR_PREVIEW):
      return {
        ...state,
        selectedMilestoneForPreview: null
      };
    case (Actions.DL_MILESTONES_CLEAR_DATA):
      return {
        milestones: [],
        selectedMilestoneForPreview: null,
        newMilestoneForPreview: null,
        newMilestoneGroupIndex: null
      };
    case (Actions.SET_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: action.payload,
        newMilestoneGroupIndex: action.groupIndex
      };
    case (Actions.PATCH_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: new QuestTask({
          ...state.newMilestoneForPreview,
          ...action.payload
        }),
      };
    case (Actions.REMOVE_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: null,
        newMilestoneGroupIndex: null
      };
    default:
      return state;
  }
}
