import {Action} from '@ngrx/store';
import { BrandConfig } from '../brand.types';
import { FundraisingLinkType } from '../fundraise-link.types';
import { QuestTeam } from '../team.types';
import { LoggedActivity } from '../quest.types';

export const SET_QUEST_ID = 'SET_QUEST_ID';
export const LOAD_BRANDS = 'LOAD_BRANDS';
export const BRANDS_LOADED = 'BRANDS_LOADED';
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const TEAMS_LOADED = 'TEAMS_LOADED';
export const CREATE_TEAM = 'CREATE_TEAM';
export const TEAM_CREATED = 'TEAM_CREATED';
export const JOIN_TEAM = 'JOIN_TEAM';
export const TEAM_JOINED = 'TEAM_JOINED';
export const START_FUNDRAISER = 'START_FUNDRAISER';
export const FUNDRAISER_STARTED = 'FUNDRAISER_STARTED';
export const START_QUEST = 'START_QUEST';
export const QUEST_STARTED = 'QUEST_STARTED';
export const OPEN_TEAM_MODAL = 'OPEN_TEAM_MODAL';
export const CLOSE_TEAM_MODAL = 'CLOSE_TEAM_MODAL';
export const QUEST_LOGGED_ACTIVITIES = 'QUEST_LOGGED_ACTIVITIES';
export const UPDATE_QUEST_LOGGED_ACTIVITY = 'UPDATE_QUEST_LOGGED_ACTIVITY';
export const QUEST_NEW_LOGGED_ACTIVITY = 'QUEST_NEW_LOGGED_ACTIVITY';

export class SetQuestId implements Action {
  readonly type = SET_QUEST_ID;

  constructor(public payload: number) {
    console.log('QuestActions::SetQuestId', payload);
  }
}

export class LoadBrands implements Action {
  readonly type = LOAD_BRANDS;

  constructor(public questId: number) {
    console.log('QuestActions::LoadBrands', {questId});
  }
}

export class BrandsLoaded implements Action {
  readonly type = BRANDS_LOADED;

  constructor(public brands: BrandConfig[]) {
    console.log('QuestActions::BrandsLoaded', brands);
  }
}

export class LoadTeams implements Action {
  readonly type = LOAD_TEAMS;

  constructor(public questId: number, public doerId: number) {
    console.log('QuestActions::LoadTeams', {questId, doerId});
  }
}

export class TeamsLoaded implements Action {
  readonly type = TEAMS_LOADED;

  constructor(public teams: QuestTeam[]) {
    console.log('QuestActions::TeamLoaded', teams);
  }
}

export class CreateTeam implements Action {
  readonly type = CREATE_TEAM;

  constructor(public questId: number, public teamName: string, public teamLogoUrl: string) {
    console.log('QuestActions::CreateTeam', {questId, teamName, teamLogoUrl});
  }
}

export class TeamCreated implements Action {
  readonly type = TEAM_CREATED;

  constructor(public team: QuestTeam) {
    console.log('QuestActions::TeamCreated', team);
  }
}

export class JoinTeam implements Action {
  readonly type = JOIN_TEAM;

  constructor(public questId: number, public teamId: number) {
    console.log('QuestActions::JoinTeam', {questId, teamId});
  }
}

export class TeamJoined implements Action {
  readonly type = TEAM_JOINED;

  constructor(public team: QuestTeam) {
    console.log('QuestActions::TeamJoined', team);
  }
}

export class StartFundraiser implements Action {
  readonly type = START_FUNDRAISER;

  constructor(public questId: number,
              public userId: number,
              public brandUserId: number,
              public targetAmount: number,
              public currency: string,
              public campaignName: string,
              public secondaryBrandUserId?: number) {
    console.log('QuestActions::StartFundraiser', {
      questId,
      userId,
      brandUserId,
      secondaryBrandUserId,
      targetAmount,
      currency,
      campaignName
    });
  }
}

export class FundraiserStarted implements Action {
  readonly type = FUNDRAISER_STARTED;

  constructor(public link: FundraisingLinkType) {
    console.log('QuestActions::FundraiserStarted', link);
  }
}

export class StartQuest implements Action {
  readonly type = START_QUEST;

  constructor(public questId: number, public referrerId: number, public questMode: string, public fundraiserTitle?: string) {
    console.log('QuestActions::StartQuest', {questId, referrerId ,questMode, fundraiserTitle});
  }
}

export class QuestStarted implements Action {
  readonly type = QUEST_STARTED;

  constructor(public success: boolean) {
    console.log('QuestActions::QuestStarted', {success});
  }
}

export class OpenTeamModal implements Action {
  readonly type = OPEN_TEAM_MODAL;

  constructor(public teamId: number) {
    console.log('QuestActions::OpenTeamModal', teamId);
  }
}

export class CloseTeamModal implements Action {
  readonly type = CLOSE_TEAM_MODAL;

  constructor() {
    console.log('QuestActions::CloseTeamModal');
  }
}

export class QuestLoggedActivities implements Action {
  readonly type = QUEST_LOGGED_ACTIVITIES;

  constructor(public payload: LoggedActivity[]) {
    console.log('QuestActions::QuestLoggedActivities');
  }
}

export class UpdateQuestLoggedActivity implements Action {
  readonly type = 'UPDATE_QUEST_LOGGED_ACTIVITY';

  constructor(public payload: LoggedActivity) {

  }
}

export class QuestNewLoggedActivity implements Action {
  readonly type = 'QUEST_NEW_LOGGED_ACTIVITY';

  constructor(public payload: LoggedActivity) {

  }
}

export type QuestActions = SetQuestId
  | LoadBrands
  | BrandsLoaded
  | LoadTeams
  | TeamsLoaded
  | CreateTeam
  | TeamCreated
  | JoinTeam
  | TeamJoined
  | StartFundraiser
  | FundraiserStarted
  | StartQuest
  | QuestStarted
  | OpenTeamModal
  | CloseTeamModal
  | QuestLoggedActivities
  | UpdateQuestLoggedActivity
  | QuestNewLoggedActivity;
