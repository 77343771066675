import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { SnotifyService } from 'ng-snotify';
import Quill from 'quill';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ValidationMessages, VALIDATION_MESSAGES } from 'src/app/app.constants';
import { getYoutubeId, youtubeUrlValidator } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { DlMilestoneManageModes, TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { ManageQuestPayload } from 'src/app/_interface/quest-service.types';
import { Quest, QuestInfo, QuestTask } from 'src/app/_interface/quest.types';
import { SurveyType } from 'src/app/_interface/survey.types';
import { CommonService } from 'src/app/_services/common.service';
import { ReferenceDataService } from 'src/app/_services/reference-data.service';
import { QUILL_CONFIG } from '../../../app.config';
import { QuestService } from '../../../_services/quest.service';
import { SnotifyConfigService } from '../../../_services/snotify-config.service';
import * as fromApp from '../../../_store/app.reducers';
import { Counter } from '../../../_tools/counter';
import { DlMilestonesService } from '../../main/dl-milestones/service/dl-milestones.service';
import { PaymentsService } from '../../main/payments/payments.service';
import { FundraiseSwitchModel } from '../../main/quest/manage/fundraise-switch/fundraise-switch.model';
import { QuestPreparedPhoto } from '../../main/quest/quest.type';
import { SurveyService } from '../../main/survey/survey.service';
import { AccountService } from '../account/account.service';
import * as fromProfile from '../profile/store/profile.reducer';
import { ImagePositionZoomComponent } from '../../../modules/shared-module/components/image-position-zoom/image-position-zoom.component';

if (typeof Quill !== 'undefined' && Quill) {
  Quill.register('modules/counter', Counter);
  const Link = Quill.import('formats/link');
  Link.sanitize = (url: string) => {
    let sanitizedUrl = url;
    if(url.startsWith("http://")){
      sanitizedUrl = url.replace("http://", "https://");
    }
    else if(!url.startsWith("https://")){
      sanitizedUrl = `https://${url}`;
    }
    return sanitizedUrl;
  }

}

declare var $: any;

@Component({
  selector: 'app-quest-manage',
  templateUrl: './quest-manage.component.html',
  styleUrls: ['./quest-manage.component.scss']
})
export class QuestManageComponent implements OnInit, OnDestroy {
  readonly payoutsValidationMassage = 'Please activate Account payouts';
  userInfoState: Observable<fromProfile.State>;
  dlMilestonesState: Observable<TaskGroupsState>;
  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  isLoading = false;
  editable = false;
  userInfo: fromProfile.State;
  eventsSubject: Subject<void> = new Subject<void>();
  ngxConfig = {...QUILL_CONFIG};
  questForm: UntypedFormGroup;
  formSubmitted = false;

  fundraisingConfig: FundraiseSwitchModel = {
    currency: 'usd',
    targetAmount: null,
    enabled: false
  };
  
  questEditInfo: QuestInfo;
  questTasks: any[] = [];
  questId: number;
  questAdmins: string[] = [];
  questInvites: string[] = [];

  metaImage = '../../../../assets/images/logo-black-white.png';
  hasValidStripeAccount = false;
  hideFundraisingSwitch = true;
  leaderboardUpdateProgress = false;
  hasEvent = false;
  isAdmin = false;
  isCreator = false;
  surveyType = SurveyType;
  singleSurveyPushedToApp = false;
  milestoneModes = DlMilestoneManageModes;
  manageStep = 1;
  questEditType: string = null;
  usersList = [];
  searchUserSubs = null;
  modalReference: any = null;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  themeColorOptions: any[] = [{
    value: '#000000',
    label: '#000000'
  },{
    value: '#E5EAED',
    label: '#E5EAED'
  }];

  defaultAccentColor: string = '008297';

  defaultBackgroundColor: string = '#E5EAED';

  @ViewChild('bgColorElem', { read: ElementRef }) bgColorElem: ElementRef;

  @ViewChild('accentColorElem', { read: ElementRef }) accentColorElem: ElementRef;

  coverImageStyle: any = {};

  @ViewChild('headerPhotoElem', {read: ElementRef, static: false}) headerPhotoElem: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private referenceDataService: ReferenceDataService,
    private meta: Meta,
    private titleService: Title,
    private paymentsService: PaymentsService,
    private surveyService: SurveyService,
    private dlMilestonesService: DlMilestonesService,
    private notifier: NotifierService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private accountService: AccountService,
    private renderer: Renderer2
  ) {
    this.userInfoState = this.store.select('userInfo');
    // this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    const paramsQuestId = this.route.snapshot.paramMap.get('questId');
    this.questId = paramsQuestId ? parseInt(paramsQuestId, 10) : null;
    
    const paramsEditType = this.route.snapshot.paramMap.get('editType');
    this.questEditType = paramsEditType ? paramsEditType : null;

    this.ngxConfig.counter = {container: '#counter', unit: 'character', maxLength: 5000};
    this.editable = !!this.questId;

    this.questForm = this.fb.group({
      'questName': [null, [Validators.required, Validators.maxLength(75), Validators.minLength(3)]],
      'questDescription': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(5000)]],
      'questVideoUrl': [null, [youtubeUrlValidator]],
      'pillar': [null], //, [Validators.required]
      'privacy': ['public', [Validators.required]],
      'copyAllowed': [false, []],
      'backBtnEnabled': [false, []],
      'editableMilestones': [{ value: false, disabled: false}, []],
      'milestoneControlsDisabled': [{ value: false, disabled: false}, []],
      'taskViewDisabled': [{ value: false, disabled: false}, []],
      'leaderboardEnabled': [false, []],
      'admins': [''],
      'adminsList': this.fb.array([]),
      'invites': [],
      'invitesList': this.fb.array([]),
      'multiTeamsAllowed': [{ value: false, disabled: true}, []],
      'mode': ['', [Validators.required]],
      'photo': '',
      'themeColor': '',
      'accentColor': '',
      'coverCenterX': null,
      'coverCenterY': null,
      'coverZoomValue': null
    });

    this.addingAdminFgrpToList();
    this.addingUserFgrpToInviteList();

    this.questForm.controls.mode.valueChanges.subscribe(res => {
      this.selectQuestMode(res);
    });

    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.userInfo = state;

      if (this.userInfo.allowCustomColor) {
        this.store.select('account').subscribe((res)=> {
          if (res.accentColor && res.accentColor !== '' && res.themeColor && res.themeColor !== '') {
            this.questForm.patchValue({
              'themeColor': res['themeColor'],
              'accentColor': (res['accentColor']).replace('#', '')
            });
          }

          this.accountService.checkEnteredCustomColor(this.questForm, this.bgColorElem, this.accentColorElem, this.renderer, false);
        });
      }

      if (!Boolean(this.questId)) {
        this.hideFundraisingSwitch = false;
        return false;
      } // check if quest editable

      this.questService.getQuestEditInfo(this.questId).subscribe(([questInfo, questInvites, questAdmins]) => {
        this.questEditInfo = questInfo;
        // this.questMode = questInfo.mode;

        this.questService.getQuestDetail(questInfo.id, questInfo.user.id).subscribe((questDetailInfo: Quest) => {
          this.hasEvent = questDetailInfo.hasEvent;
        });

        this.updateAdminsAndCreator();

        if (this.questEditInfo.fundraising) {
          // checking that doer is not fundraiser
          this.questService.getFundraisingLink(this.questEditInfo.id, this.questEditInfo.user.id).subscribe((res: any) => {
            if (res) {
              this.fundraisingConfig = {
                currency: res.currency,
                targetAmount: res.targetAmount,
                enabled: true
              };
            }
            this.hideFundraisingSwitch = this.questEditInfo.mode === 'diyMode' && this.fundraisingConfig.enabled;
          });
        } else {
          this.hideFundraisingSwitch = false;
        }

        this.dlMilestonesService.getQuestGroupTasks(this.questId, state.id).subscribe((taskGroups: TaskGroup[]) => {
          taskGroups.forEach(_group => {
            _group.questTasks.forEach(task => {
              task['activitiesIds'] = [];
              
              let videoData = null;
              if (task.embeddedVideo && task.embeddedVideo.videoUrl) {
                if (task.embeddedVideo.videoUrl.indexOf('youtube') > -1) {
                  videoData = this.getVideoForTask(task.embeddedVideo.videoUrl)
                // } else {
                //   this.getLinkForTask(url);    
                }
              }

              task['video'] = videoData;
              if (task['activities'] && task['activities'].length > 0) {
                task['activities'].forEach(_acti => {
                  task['activitiesIds'].push( _acti.id );
                });
              }
            });
          });

          this.questTasks = taskGroups;
        });
      
        questInvites.forEach((_user, ind) => {
          if (ind > 0) {
            this.addingUserFgrpToInviteList();
          }

          this.questInvites.push(_user.email);
          (this.questForm.controls.invitesList as UntypedFormArray).controls[ind].patchValue({
            email: _user.email
          });
        });

        questAdmins.forEach((_admin, ind) => {
          if (ind > 0) {
            this.addingAdminFgrpToList();
          }

          this.questAdmins.push(_admin.email);
          (this.questForm.controls.adminsList as UntypedFormArray).controls[ind].patchValue({
            email: _admin.email
          });
        });

        const questVideoId = this.prepareQuestVideoId(this.questEditInfo.questVideoUrl);
        this.questForm.patchValue({
          'questName': this.questEditInfo.title,
          'questDescription': this.questEditInfo.questFeed,
          'pillar': null,
          'questVideoUrl': questVideoId ? `https://www.youtube.com/embed/${questVideoId}` : null,
          'privacy': this.questEditInfo.privacyLevel.toLowerCase(),
          // 'copyAllowed': this.questEditInfo.copyAllowed,
          'backBtnEnabled': !this.questEditInfo.backBtnDisabled,
          'editableMilestones': this.questEditInfo.editableMilestones,
          'milestoneControlsDisabled': this.questEditInfo.milestoneControlsDisabled,
          'taskViewDisabled': this.questEditInfo.taskViewDisabled,
          'leaderboardEnabled': this.questEditInfo.leaderboardEnabled,
          'admins': this.questAdmins,
          'invites': this.questInvites,
          'multiTeamsAllowed': this.questEditInfo.multiTeamsEnabled,
          'mode': this.questEditInfo.mode,
          'photo': this.questEditInfo.photo,
          'themeColor': this.questEditInfo.themeColor,
          'accentColor': this.questEditInfo.accentColor,
          'coverZoomValue': this.questEditInfo.coverZoomValue,
          'coverCenterX': this.questEditInfo.coverCenterX,
          'coverCenterY': this.questEditInfo.coverCenterY
        });

        this.questEditInfo.mode === 'diyMode' ? this.questForm.controls.multiTeamsAllowed.enable() : this.questForm.controls.multiTeamsAllowed.disable()
        this.questEditInfo.mode === 'viewOnlyMode' ? this.questForm.controls.editableMilestones.disable() : this.questForm.controls.editableMilestones.enable();
        
        setTimeout(() => {
          this.accountService.checkEnteredCustomColor(this.questForm, this.bgColorElem, this.accentColorElem, this.renderer, false);
          this.eventsSubject.next();
        });

        if (questInfo.accentColor && questInfo.themeColor && 
          questInfo.accentColor !== '' && questInfo.themeColor !== '') {
          this.accountService.changeRootColor({
            accentColor: questInfo.accentColor,
            themeColor: questInfo.themeColor
          })
        } else {
          this.accountService.checkAccountColorAndApply();
        }

        this.coverImageStyle = {
          'background': `url('${this.questEditInfo.photo}') 0 0 / cover no-repeat`
        };

        let imgData: any = {
          'zoomValue': this.questEditInfo.coverZoomValue,
          'centerX': this.questEditInfo.coverCenterX,
          'centerY': this.questEditInfo.coverCenterY,
          'imageUrl': this.questEditInfo.photo
        };
        this.positionCoverImage(imgData);

        this.hasValidStripeAccount = !this.questEditInfo.backBtnDisabled;
        // dont run this if logged in user is an admin of this quest and not the creator
        if (this.questEditInfo.user.id === this.userInfo.id) {
          this.paymentsService.testStripeAccount(this.userInfo.id).subscribe(() => {
            this.hasValidStripeAccount = true;
            this.questForm.controls.backBtnEnabled.enable();
          }, () => {
            this.hasValidStripeAccount = false;
            this.questForm.controls.backBtnEnabled.disable();
          });
        }
      });

      // this.questService.checkIfUserQuestSaved(this.userInfo.id).subscribe((res: any) => {
      //   this.isQuestSaved = res.success.isQuestSaved === 'true';
      // });
    });

    this.questForm.get('accentColor').valueChanges.subscribe(accentColr => {
      if(accentColr && accentColr.length === 6 && this.accentColorElem && this.accentColorElem.nativeElement) {
        this.renderer.setStyle(this.accentColorElem.nativeElement, 'background', '#' + accentColr);
      }
      if ((accentColr && accentColr.length === 6)) {
        setTimeout(() => {
          this.accountService.checkEnteredCustomColor(this.questForm, this.bgColorElem, this.accentColorElem, this.renderer);
        });
        // this.checkCustomColors();
      }
    });

    this.questForm.get('themeColor').valueChanges.subscribe(themeColr => {
      if (themeColr && themeColr.length === 7 && this.bgColorElem && this.bgColorElem.nativeElement) {
        this.renderer.setStyle(this.bgColorElem.nativeElement, 'background', themeColr);
      }
      if ((this.questForm.value.accentColor && this.questForm.value.accentColor.length === 6)) {
        setTimeout(() => {
          this.accountService.checkEnteredCustomColor(this.questForm, this.bgColorElem, this.accentColorElem, this.renderer);
        });
        // this.checkCustomColors();
      }
    });

    if (!this.questId) {
      this.paymentsService.testStripeAccount(this.userInfo.id).subscribe(() => {
        this.hasValidStripeAccount = true;
        this.questForm.controls.backBtnEnabled.enable();
      }, () => {
        this.hasValidStripeAccount = false;
        this.questForm.controls.backBtnEnabled.disable();
      });
    }

    this.questForm.controls.questName.valueChanges.subscribe(res => {
      if (res !== '' && res.length > 75) {
        this.questForm.patchValue({
          'questName': res.substring(0, 75) 
        })
        return false;
      }
    });
  }

  getVideoForTask(videoUrl) {
    const youtubeId = getYoutubeId(videoUrl);
    if (youtubeId) {
      const videoData = this.questService.getVideoForTask(videoUrl);

      return videoData.payload;
    }

    return null;
  }

  addingAdminFgrpToList() {
    let list = this.questForm.get('adminsList') as UntypedFormArray;
    list.push(new UntypedFormGroup({
      email: new UntypedFormControl(''),
      role: new UntypedFormControl(''),
      showList: new UntypedFormControl(false)
    }));
  }

  addingUserFgrpToInviteList() {
    let list = this.questForm.get('invitesList') as UntypedFormArray;
    list.push(new UntypedFormGroup({
      email: new UntypedFormControl(''),
      showList: new UntypedFormControl(false)
    }));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    // if (this.questFormSubscription) {
    //   this.questFormSubscription.unsubscribe();
    // }
    // this.store.dispatch(new DlMilestonesClearData());
  }

  tasksToDeleteArr = [];
  isMilstoneFormPristine = true;
  milestoneUpdated(event) {
    if (event.type && event.type === 'removed-task') {
      this.tasksToDeleteArr = [...event.tasksToDelete];
    } else if (event.type && event.type === 'removed-group') {
      // this.tasksToDeleteArr = [...event.tasksToDelete];
    }
    
    this.isMilstoneFormPristine = event.milestoneForm.pristine;
    this.questTasks = event.milestoneForm.value.milestone;
  }

  checkIfTasksValid() {
    if (!this.questTasks || this.questTasks.length < 1) return false;

    let isValid = true;
    // check if each group has at least 1 task
    this.questTasks.forEach(group => {
      if (isValid && (
          !group['questTasks'] || (group['questTasks'] && group['questTasks'].length < 1))
      ) {
        // task valid
        isValid = false;
      }
    });

    return isValid;
  }

  onFundraisingChange(switchModel: FundraiseSwitchModel) {
    this.fundraisingConfig.enabled = switchModel.enabled;
    this.fundraisingConfig.targetAmount = switchModel.targetAmount;
    // this.infoIsChanged = true;

    if(this.questForm.value.mode === 'diyMode'){
      const multiTeamsControl = this.questForm.get('multiTeamsAllowed');
      if(switchModel.enabled){
        multiTeamsControl.setValue(true);
        multiTeamsControl.disable();
      }
      else {
        multiTeamsControl.enable();
      }
    }

    if (switchModel.enabled && !this.hasValidStripeAccount) {
      this.notifier.notify('info', this.payoutsValidationMassage);
    }

    this.markFormAsDirty();
  }

  onPrivacyChange() {
    this.questForm.get('privacy').markAsTouched();
  }

  selectQuestMode(mode: string) {
    // this.questMode = mode;
    const multiTeamsControl = this.questForm.get('multiTeamsAllowed');
    const editableMilestonesControl = this.questForm.get('editableMilestones');

    if(mode === 'diyMode'){
      multiTeamsControl.enable();
      if(this.fundraisingConfig.enabled){
        multiTeamsControl.setValue(true);
        multiTeamsControl.disable();
      }
    }
    else {
      multiTeamsControl.disable();
      multiTeamsControl.setValue(false);
    }
    mode === 'viewOnlyMode' ? editableMilestonesControl.disable() : editableMilestonesControl.enable();
  }

  buildCreateQuestPayload(questTasks: QuestTask[]): ManageQuestPayload {
    // start of payload creation of task group and questtasks
    let _tasksGroups = [];
    let _questTasks = [];
    questTasks.forEach((group, i) => {
      _tasksGroups.push({
        groupName: group['name'],
        groupOrder: (i + 1),
        groupOwnerId: this.userInfo.id, // user id
        id: null
      })

      group['questTasks'].forEach(async (task, j) => {
        _questTasks.push({
          ...task,
          "groupIndex": (i),
          "order": j,
          "loading": false,
          id: null
        })
      });
    });
    // end of payload creation of task group and questtasks
    const payload: ManageQuestPayload = {
      ...this.questForm.value,
      // mode: this.questMode,
      fundraising: this.fundraisingConfig && this.fundraisingConfig.enabled,
      fundraisingConfig: this.fundraisingConfig ? this.fundraisingConfig : null,
      questTasks: _questTasks,
      tasksGroups: _tasksGroups
      // questTasksGroupName: groupName
    };
    payload.editableMilestones = payload.editableMilestones
      && (this.questForm.value.mode === 'paceYourselfMode' || this.questForm.value.mode === 'diyMode');
    payload.backBtnDisabled = !this.questForm.value.backBtnEnabled;
    payload.multiTeamsAllowed = this.questForm.get('multiTeamsAllowed').value;

    payload.accentColor = (!payload.accentColor || payload.accentColor === '') ? '' : '#' + (payload.accentColor.replace('#', ''));

    return payload;
  }

  createQuest() {
    if (this.fundraisingConfig.enabled && !this.hasValidStripeAccount) {
      this.notifier.notify('info', 'Please activate Account payouts.');
      return this.questService.fundraisingSwitchReset;
    }

    this.formSubmitted = true;
    if (this.isLoading) {
      return false;
    }

    if (this.questForm.valid) {
      // console.log('551', this.buildCreateQuestPayload(this.questTasks));
      this.callCreateQuest();
    } else {
      this.notifier.notify('error', 'Please complete required fields.');
    }
  }

  callCreateQuest() {
    this.questService.createQuest(this.buildCreateQuestPayload(this.questTasks)).subscribe(res => {
      this.questService.doQuest(res.id).subscribe(() => {
        this.notifier.notify('success', 'Successfully created Quest.');
        this.isLoading = false;
        this.navigateToQuest(res.id);
      });
    }, err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  updateAdminsAndCreator() {
    this.isAdmin = this.questEditInfo && this.questEditInfo.admins.indexOf(this.userInfo.email) >= 0;
    this.isCreator = this.questEditInfo && this.questEditInfo.createdBy === this.userInfo.id;

    if (this.isAdmin || this.isCreator) {
      this.questForm.controls.mode.disable();
    }
  }

  confirmEditQuest() {
    this.formSubmitted = true;
    if (this.isLoading) {
      return false;
    }

    if (this.questForm.valid) {
      this.updateAdminsAndCreator();

      if (this.isAdmin || this.isCreator) {
        this.checkDeleteAndEditQuest('Y');
      } else {
        this.snotifyService.confirm(
          'Editing this Quest will create a new copy of it. Would you like to keep the original one active as well, or remove it?',
          'Create New Quest?',
          this.snotifyConfigService.getConfig('confirm', {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: 'Keep',
                bold: true,
                action: (toast) => {
                  this.checkDeleteAndEditQuest('Y');
                  this.snotifyService.remove(toast.id);
                }
              },
              {
                text: 'Remove',
                bold: true,
                action: (toast) => {
                  this.checkDeleteAndEditQuest('N');
                  this.snotifyService.remove(toast.id);
                }
              }
            ]
          })
        );
      }
    } else {
      this.notifier.notify('error', 'Please complete required fields.');
      const firstInvalid = $('.c-forms__input.ng-invalid').eq(0);

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }

  }

  validateInvites() {
    if (this.questForm.value.privacy === 'invite' && this.questForm.value.invites.length === 0) {
      this.notifier.notify('error', 'Please select members.');
      return false;
    }
    return true;
  }

  prepareQuestVideoId(questVideoUrl: string): string | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? `https://www.youtube.com/embed/${res}` : null;
  }

  buildEditQuestPayload(stayInOldQuest: 'Y' | 'N' | null): ManageQuestPayload {
    // start of payload creation of task group and questtasks
    let _tasksGroups = [];
    let _questTasks = [];
    
    this.questTasks.forEach((group, i) => {
      
      _tasksGroups.push({
        groupName: group['name'],
        groupOrder: (i + 1),
        groupOwnerId: this.userInfo.id, // user id
        id: group.id || group.existingId || null
      })

      group['questTasks'].forEach(async (task, j) => {
        _questTasks.push({
          ...task,
          "groupIndex": (i),
          "order": j,
          "loading": false,
          id: task.id || task.existingId || null
        })
      });
    });
    // end of payload creation of task group and questtasks
    const payload: ManageQuestPayload = {
      ...this.questForm.value,
      mode: this.questEditInfo.mode,
      questVideoUrl: this.questForm.value.questVideoUrl ? this.prepareQuestVideoId(this.questForm.value.questVideoUrl) : null,
      // mode: this.questMode,
      fundraising: this.fundraisingConfig && this.fundraisingConfig.enabled,
      fundraisingConfig: this.fundraisingConfig || null,
      questId: this.questId,
      stayInOldQuest: stayInOldQuest,
      backBtnDisabled: !this.questForm.value.backBtnEnabled,
      questTasks: _questTasks,
      tasksGroups: _tasksGroups
    };

    payload.editableMilestones = payload.editableMilestones
      && (this.questForm.value.mode === 'paceYourselfMode' || this.questForm.value.mode === 'diyMode');
    payload.backBtnDisabled = !this.questForm.value.backBtnEnabled;
    payload.multiTeamsAllowed = this.questForm.get('multiTeamsAllowed').value;

    payload.accentColor = (!payload.accentColor || payload.accentColor === '') ? '' : '#' + (payload.accentColor.replace('#', ''));

    return payload;
  }

  async checkDeleteAndEditQuest(stayInOldQuest: 'Y' | 'N' | null) {
    if (this.isLoading || !this.validateInvites()) {
      return;
    }
    
    this.isLoading = true;

    // check if delete tasks is ther, run the api 
    if (await this.tasksToDeleteArr.length > 0) {
      await this.tasksToDeleteArr.forEach(async (deleteId, i) => {
        let res = await this.questService.removeMilestone({
            id: deleteId
          });

          await res.subscribe(() => {
          })
      });

      this.editQuest(stayInOldQuest);

      return  ;
    }
    // end of delete api

    this.editQuest(stayInOldQuest);
  }

  private editQuest(stayInOldQuest: 'Y' | 'N' | null) {
    // if (this.coverPictureFile) {
    //   const formData = new FormData();
    //   formData.append("file", this.coverPictureFile);
    //   this.commonService.uploadImageAndGetUrl(formData).subscribe(res => {
    //     this.questForm.patchValue({
    //       photo: res
    //     });

    //     this.callEditQuest(stayInOldQuest);
    //   });
    // } else {
      this.callEditQuest(stayInOldQuest);
    // }
  }

  callEditQuest(stayInOldQuest) {
    const payload = this.buildEditQuestPayload(stayInOldQuest);

    this.questService.editQuest(payload, this.userInfo.id).subscribe(data => {
      this.notifier.notify('success', 'Successfully edited Quest');
      const userName = this.referenceDataService.slugify(this.userInfo.userName).toLowerCase();
      const questTitle = this.referenceDataService.slugify(payload.questName).toLowerCase();
      this.router.navigate(['/', userName, questTitle, this.questEditInfo.id, this.userInfo.id]);
    });
  }

  goBackStep() {
    if (this.manageStep === 1) {
      this.navigateToQuest(this.questId);
    } else if (this.manageStep === 2) {
      this.manageStep = 1;
    }
  }

  navigateToQuest(questId: number) {
    if(questId){
      this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
        this.router.navigate(['/quest-detail', questId, state.id]);
      });
    }
    else {
      this.router.navigate([".."])
    }
  }

  goToNextPage() {
    if (this.manageStep === 1 && this.questId === null) {
      this.navTo('settings');
    } else if (this.manageStep === 1 && this.questId !== null && this.questEditType === 'detail') {
      this.navTo('tasks');
    } else if (this.manageStep === 2 && this.questId !== null && this.questEditType === 'detail') {
      this.confirmEditQuest();
    } else if (this.manageStep === 1 && this.questId !== null && this.questEditType === 'settings') {
      this.confirmEditQuest();
    } else if (this.questId === null && this.manageStep === 2) {
      this.createQuest();
    }
  }

  navTo(tab?) {
    if (tab) {
      if (tab === 'settings' && this.isContentValid()) {
        this.manageStep = 2;

        return;
      }

      if (tab === 'tasks' && this.isContentValid()) {
        this.manageStep = 2;

        return;
      }

      if (tab === 'details') {
        this.manageStep = 1;

        return;
      }
    }

    if(this.isContentValid()) {
      // this.tabs.selected = tab;
      this.manageStep = 2;
    }
    else {
      this.formSubmitted = true;
    }
  }

  isContentValid(){
    return this.questForm.controls.questName.valid && this.questForm.controls.questDescription.valid 
      && (this.manageStep === 1 && this.questId === null ? (this.questTasks && this.checkIfTasksValid()) : (true));
  }

  // setMetaTags() {
  //   this.meta.addTag({name: 'og:image', content: this.metaImage});

  //   if (this.questId) {
  //     this.titleService.setTitle('DIEMlife | Edit Quest');
  //     this.meta.addTag({name: 'og:title', content: 'DIEMlife | Edit Quest'});
  //     this.meta.addTag({name: 'og:description', content: 'Edit Quests'});

  //     return;
  //   }

  //   this.titleService.setTitle('DIEMlife | Create Quest');
  //   this.meta.addTag({name: 'og:title', content: 'DIEMlife | Create Quest'});
  //   this.meta.addTag({name: 'og:description', content: 'Create Quests'});
  // }

  updateLeaderboardMembers() {
    if (this.leaderboardUpdateProgress) {
      return;
    }
    this.leaderboardUpdateProgress = true;
    this.questService.updateLeaderBoardMembers(this.questId).subscribe(updatedCount => {
      this.leaderboardUpdateProgress = false;
      if (updatedCount) {
        this.notifier.notify('success', `Successfully imported leaderboard members`);
      } else {
        this.notifier.notify('info', `No new members .`);
      }
    }, () => {
      this.leaderboardUpdateProgress = false;
        this.notifier.notify('warning', `Error updating members`);
    });
  }

  // for test surveys with backend
  pushSurvey(questId: number, type?: SurveyType) {
    if (!this.singleSurveyPushedToApp) {
      this.surveyService.pushSurvey(questId, type).subscribe(() => {
        this.singleSurveyPushedToApp = true;
      },
      () => {
        this.notifier.notify('error', `Issue completing request`)
      });
    }
  }

  removeYoutubeQuest() {
    this.questForm.patchValue({
      questVideoUrl: null
    })
  }

  searchUsers(evnt, fieldForm) {
    const searchText = (evnt.target.value).trim();

    if (this.searchUserSubs) this.searchUserSubs.unsubscribe();

    if (searchText == '') {
      fieldForm.patchValue({
        showList: false
      });

      return;
    }

    this.searchUserSubs = this.questService.searchUsers(searchText).subscribe(res => {
      this.usersList = res;
      fieldForm.patchValue({
        showList: true
      });
    }, () => {
      this.usersList = [];
    });
  }

  selectedUser(userItem, selectedFor, fieldForm, index) {
    if (selectedFor === 'admin') {
      this.questAdmins[index] = userItem.email;
      
      this.usersList = [];
      fieldForm.patchValue({
        showList: false,
        email: userItem.email
      });
    } else if (selectedFor === 'invite') {
      this.questInvites[index] = userItem.email;

      this.questForm.patchValue({
        invites: this.questInvites
      });

      this.usersList = [];
      fieldForm.patchValue({
        showList: false,
        email: userItem.email
      });
    }
  }

  removeUserObject: any = null;
  removeUser(removeFor, index, deleteInvite?) {
    this.removeUserObject = {
      removeFor: removeFor,
      index: index
    };
    this.modalReference = this.modalService.open(deleteInvite,
      {
        windowClass: 'confirm-modal'
      });

    this.markFormAsDirty();
  }

  markFormAsDirty() {
    // editing a blank and removing to the quest name as it will make the pristine value as false
    this.questForm.markAsDirty();
  }

  closeModal(response: string) {
    if (response === 'yes') {
      if (this.removeUserObject.removeFor === 'admin') {
        this.questAdmins.splice(this.removeUserObject.index, 1);
        (this.questForm.controls.adminsList as UntypedFormArray).removeAt(this.removeUserObject.index);
      } else if (this.removeUserObject.removeFor === 'invite') {
        this.questInvites.splice(this.removeUserObject.index, 1);
        (this.questForm.controls.invitesList as UntypedFormArray).removeAt(this.removeUserObject.index);
      }

      this.removeUserObject = null;
    }

    if (this.modalReference)
      this.modalReference.close();
    this.modalReference = null;
  }

  getAdminListControl() {
    return (this.questForm.get('adminsList') as UntypedFormArray);
  }

  getLastAdminListControl() {
    return ((this.questForm.get('adminsList') as UntypedFormArray).controls[(this.questForm.get('adminsList') as UntypedFormArray).length - 1]) as FormGroup;
  }

  getInviteListControl() {
    return (this.questForm.get('invitesList') as UntypedFormArray);
  }

  getLastInviteListControl() {
    return ((this.questForm.get('invitesList') as UntypedFormArray).controls[(this.questForm.get('invitesList') as UntypedFormArray).length - 1]) as FormGroup;
  }

  getControlOf(control) {
    return (control.controls as FormGroup);
  }
  
  openDialog(openFor: string) {
    if (openFor === 'questCover') {
      this.modalReference = this.modalService.open(ImagePositionZoomComponent, {
        windowClass: 'no-overflow'
      });
      this.modalReference.componentInstance.title = this.questId ? 'Edit cover photo' : 'Add cover photo';
      this.modalReference.componentInstance.acceptFor = 'questCover';
      this.modalReference.componentInstance.existingImages = this.questForm.value.photo && this.questForm.value.photo !== '' ? this.questForm.value.photo : '';
      this.modalReference.componentInstance.imageProperties = {
        centerX: this.questForm.value.coverCenterX,
        centerY: this.questForm.value.coverCenterY,
        zoom: this.questForm.value.coverZoomValue
      };

      this.modalReference.componentInstance.imageUploadedData.subscribe(imageData => {
        this.questForm.patchValue({
          photo: imageData.imageUrl,
          coverCenterX: parseFloat(imageData.centerX),
          coverCenterY: parseFloat(imageData.centerY),
          coverZoomValue: parseFloat(imageData.zoomValue)
        });

        this.positionCoverImage(imageData);

        this.markFormAsDirty();
      });

      return;
    }
  }

  positionCoverImage(imageInfo) {
    if (!imageInfo.centerX || !imageInfo.centerY || !imageInfo.zoomValue) return;

    // getting custom width and height of img
    const divTag = document.createElement('div');
    divTag.setAttribute('id', 'tobe-deleted-cont');
    const imgTag = document.createElement('img');
    imgTag.onload = (() => {
      imgTag.setAttribute('id', 'tobe-deleted-img');
      imgTag.setAttribute('style', 'width: 100%');
      divTag.appendChild(imgTag);
      this.renderer.appendChild(this.headerPhotoElem.nativeElement, divTag);
 
      const ht = (document.getElementById('tobe-deleted-img') as any).height;
      const wd = (document.getElementById('tobe-deleted-img') as any).width;
      
      (document.getElementById('tobe-deleted-cont') as any).remove();

      // calculating position of img
      const coverImagePositionX = Math.abs(( (imageInfo.centerX * wd)/100 ) - (this.headerPhotoElem.nativeElement.clientWidth / 2));
      const coverImagePositionY = Math.abs(( (imageInfo.centerY * ht)/100 ) - (this.headerPhotoElem.nativeElement.clientHeight / 2));

      this.coverImageStyle = {
        'background': `url('${imageInfo.imageUrl}') -${coverImagePositionX}px -${coverImagePositionY}px / cover no-repeat`,
        'width': `${imageInfo.zoomValue * 100}%`,
        'height': `${imageInfo.zoomValue * 100}%`
      };
    })
    imgTag.src = (imageInfo.imageUrl as any);
  }
}
