export interface SurveyQuestion {
  promptEvent: SurveyType;
  type: SurveyQuestionType;
  promptText: string;
  promptOptions?: string[];
}

export interface SurveyData {
  questions: SurveyQuestion[];
}

export interface SurveyAnswer extends SurveyQuestion {
  answer: string;
}

export interface SurveyAnswersPresetType {
  [key: string]: string[];
}

export interface SurveyStyleType {
  backgroundColor?: string;
  textColor?: string;
  activeColor?: string;
  backgroundImage?: string;
}

export interface SurveyStylesPresetType {
  [key: string]: SurveyStyleType;
}

export enum SurveyType {
  StartQuestImmediate = 1,
  CompleteQuest = 9007199254740992
}

export enum SurveyQuestListType {
  UncheckedStartedImmediateQuests = 'UncheckedStartedImmediateQuests',
  UncheckedCompletedQuests = 'UncheckedCompletedQuests'
}

export enum SurveyQuestListRelativeType {
  UncheckedStartedImmediateQuests = SurveyType.StartQuestImmediate,
  UncheckedCompletedQuests = SurveyType.CompleteQuest
}

export enum SurveyQuestionType {
  'FREE_FORM' = 'FREE_FORM',
  'YES_NO' = 'YES_NO',
  'TRUE_FALSE' = 'TRUE_FALSE',
  'SCALE_OF_ONE_TO_FIVE' = 'SCALE_OF_ONE_TO_FIVE',
  'SCALE_OF_ONE_TO_TEN' = 'SCALE_OF_ONE_TO_TEN',
  'SCALE_OF_ZERO_TO_SEVEN' = 'SCALE_OF_ZERO_TO_SEVEN',
  'AGREE_DISAGREE_TWO_OPTIONS' = 'AGREE_DISAGREE_TWO_OPTIONS',
  'AGREE_DISAGREE_THREE_OPTIONS' = 'AGREE_DISAGREE_THREE_OPTIONS',
  'AGREE_DISAGREE_FIVE_OPTIONS' = 'AGREE_DISAGREE_FIVE_OPTIONS',
  'LIKELY_UNLIKELY_TWO_OPTIONS' = 'LIKELY_UNLIKELY_TWO_OPTIONS',
  'LIKELY_UNLIKELY_THREE_OPTIONS' = 'LIKELY_UNLIKELY_THREE_OPTIONS',
  'LIKELY_UNLIKELY_FIVE_OPTIONS' = 'LIKELY_UNLIKELY_FIVE_OPTIONS',
  'COUNT' = 'COUNT',
  'INTEGER' = 'INTEGER',
  'FLOAT' = 'FLOAT',
  'PERCENT' = 'PERCENT',
  'LOCATION_TRACKING' = 'LOCATION_TRACKING'
}

export enum SurveyQuestionTypeCode {
  'FREE_FORM' = 0,
  'YES_NO' = 1,
  'TRUE_FALSE' = 2,
  'SCALE_OF_ONE_TO_FIVE' = 100,
  'SCALE_OF_ONE_TO_TEN' = 101,
  'SCALE_OF_ZERO_TO_SEVEN' = 120,
  'AGREE_DISAGREE_TWO_OPTIONS' = 200,
  'AGREE_DISAGREE_THREE_OPTIONS' = 201,
  'AGREE_DISAGREE_FIVE_OPTIONS' = 202,
  'LIKELY_UNLIKELY_TWO_OPTIONS' = 300,
  'LIKELY_UNLIKELY_THREE_OPTIONS' = 301,
  'LIKELY_UNLIKELY_FIVE_OPTIONS' = 302,
  'COUNT' = 400,
  'INTEGER' = 401,
  'FLOAT' = 402,
  'PERCENT' = 403,
  'LOCATION_TRACKING' = 500
}

export const SurveyAnswersPreset: SurveyAnswersPresetType = {
  'FREE_FORM': null,
  'YES_NO': ['No', 'Yes'],
  'TRUE_FALSE': ['False', 'True'],
  'SCALE_OF_ONE_TO_FIVE': ['1', '2', '3', '4', '5'],
  'SCALE_OF_ONE_TO_TEN': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  'SCALE_OF_ZERO_TO_SEVEN': ['0', '1', '2', '3', '4', '5', '6', '7'],
  'AGREE_DISAGREE_TWO_OPTIONS': ['Disagree', 'Agree'],
  'AGREE_DISAGREE_THREE_OPTIONS': ['Disagree', 'Neutral', 'Agree'],
  'AGREE_DISAGREE_FIVE_OPTIONS': ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
  'LIKELY_UNLIKELY_TWO_OPTIONS': ['Unlikely', 'Likely'],
  'LIKELY_UNLIKELY_THREE_OPTIONS': ['Unlikely', 'Somewhat Likely', 'Likely'],
  'LIKELY_UNLIKELY_FIVE_OPTIONS': ['Very Unlikely', 'Unlikely', 'Somewhat Likely', 'Likely', 'Very Likely'],
  'COUNT': null,
  'INTEGER': null,
  'FLOAT': null,
  'PERCENT': null,
  'LOCATION_TRACKING': null
};

export const SurveyStylesPreset: SurveyStylesPresetType = {
  'FREE_FORM': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://www.swimmingpool.com/sites/default/files/Archive/digital-wallpapers/1125x2436-iphonex-wallpapers.jpg'
  },
  'YES_NO': {
    backgroundColor: '#4579e6',
    textColor: '#ffffff',
    activeColor: '#fff100',
    backgroundImage: 'https://icdn3.digitaltrends.com/image/aaocmrd-1440x3040.jpg'
  },
  'TRUE_FALSE': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://i.pinimg.com/originals/c6/a2/89/c6a289648d689ebf779f5c11b2699ab5.jpg'
  },
  'SCALE_OF_ONE_TO_FIVE': {
    backgroundColor: '#ef6507',
    textColor: '#ffffff',
    activeColor: '#80390a',
    backgroundImage: 'https://www.setaswall.com/wp-content/uploads/2018/04/Reflection-Wallpaper-1080x2280-380x802.jpg'
  },
  'SCALE_OF_ONE_TO_TEN': {
    backgroundColor: '#a105e4',
    textColor: '#ffffff',
    activeColor: '#530077',
    backgroundImage: 'https://galshir.com/c/wallpapers/galshir-astronaut-wallpaper.jpg'
  },
  'SCALE_OF_ZERO_TO_SEVEN': {
    backgroundColor: '#ffffff',
    textColor: '#000000',
    activeColor: '#7103a0',
    backgroundImage: 'https://i.pinimg.com/originals/71/98/71/719871ee441eecd6f12c1b4e01358e42.jpg'
  },
  'AGREE_DISAGREE_TWO_OPTIONS': {
    backgroundColor: '#ffffff',
    textColor: '#1203a0',
    activeColor: '#a0033f',
    backgroundImage: 'https://galshir.com/c/wallpapers/galshir-koi-fish-wallpaper.jpg'
  },
  'AGREE_DISAGREE_THREE_OPTIONS': {
    backgroundColor: '#ceb202',
    textColor: '#ffffff',
    activeColor: '#ffdc00',
    backgroundImage: 'https://i.redd.it/h1xq4d8mq2m21.jpg'
  },
  'AGREE_DISAGREE_FIVE_OPTIONS': {
    backgroundColor: '#cc0060',
    textColor: '#ffffff',
    activeColor: '#ff0079',
    backgroundImage: 'https://media.idownloadblog.com/wp-content/uploads/2016/03/Black-Home-Screen-By-Jason-Zigrino.png'
  },
  'LIKELY_UNLIKELY_TWO_OPTIONS': {
    backgroundColor: '#000000',
    textColor: '#ffffff',
    activeColor: '#a70000',
    backgroundImage: 'https://images.unsplash.com/photo-1558981408-db0ecd8a1ee4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
  },
  'LIKELY_UNLIKELY_THREE_OPTIONS': {
    backgroundColor: '#054ae0',
    textColor: '#ffffff',
    activeColor: '#002982',
    backgroundImage: 'https://i.pinimg.com/736x/ce/c0/74/cec074ab85ddb1b716c8ea9ed2a79d4f.jpg'
  },
  'LIKELY_UNLIKELY_FIVE_OPTIONS': {
    backgroundColor: '#01a6d4',
    textColor: '#ffffff',
    activeColor: '#00c8ff',
    backgroundImage: 'https://i.redd.it/p15vr6tl23l21.jpg'
  },
  'COUNT': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://i.imgur.com/tVfG12D.jpg'
  },
  'INTEGER': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://www.androidworld.it/wp-content/uploads/2019/03/S10-Bender-androidworld-black.png'
  },
  'FLOAT': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://i.imgur.com/QiJl5h4.jpg'
  },
  'PERCENT': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://funmary.com/wp-content/uploads/2018/06/best-iphone-x-background-wallpaper-004.jpg'
  },
  'LOCATION_TRACKING': {
    backgroundColor: '#77f591',
    textColor: '#ffffff',
    activeColor: '#00774f',
    backgroundImage: 'https://cdn57.androidauthority.net/wp-content/uploads/2018/10/Asus-ROG-Phone-wallpaper-2.jpeg'
   }
};
