import { EventEmitter, Output, Input, SimpleChanges, OnChanges, Directive } from '@angular/core';
import { LeaderBoardPodiumPlaceMode, LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { LeaderboardUtils } from 'src/app/_interface/utils/leaderboard.utils';

@Directive()
export abstract class LeaderboardScoreComponent implements OnChanges {
  @Output() updateMember: EventEmitter<LeaderboardScore> = new EventEmitter<LeaderboardScore>();
  @Input() allowEdit: boolean;
  @Input() selectedAttributeId: string;
  @Input() questId: number;
  @Input() mode: LeaderBoardPodiumPlaceMode = LeaderBoardPodiumPlaceMode.PROFILE;
  @Input() teams: QuestTeam[];
  team: QuestTeam;
  readonly availableModes = LeaderBoardPodiumPlaceMode;

  protected _member: LeaderboardScore;

  scoreText: string;
  unitText: string;
  showScore: boolean;
  showUnit: boolean;
  showForm = false;

  protected init(): void {
    this.scoreText = LeaderboardUtils.getScoreText(this._member);
    this.showScore = !!this.scoreText;
    this.unitText = LeaderboardUtils.getUnitText(this._member);
    this.showUnit = !!this.unitText;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('member') ? changes.member.currentValue !== changes.member.previousValue : false) {
      this.team = this.teams.filter(t => t.creatorId === this._member.userId)[0];
    }
  }

  onChangeVisibilityEditForm(value: boolean) {
    this.showForm = value;
  }

  onUpdateMember(updatedMember: LeaderboardScore) {
    this.updateMember.emit(updatedMember);
  }

}
