import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../components/main/auth/auth.service';
import { DlLogInComponent } from '../components/main/dl-auth/dl-log-in/dl-log-in.component';
import * as fromAuth from './../components/main/auth/store/auth.reducer';
import * as fromApp from './../_store/app.reducers';

@Injectable()
export class AuthGuard  {
  authState: Observable<fromAuth.State>;

  constructor(
    private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private modalService: NgbModal,
    @Inject ('isServer') private isServer: boolean
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    return this.store.select('auth')
      .pipe(
        take(1),
        map((authState: fromAuth.State) => {
          if (authState.authenticated) {
            return authState.authenticated && this.authService.checkTokenValid(authState);
          } else {
            if (!this.isServer) {
              this.modalService.open(DlLogInComponent, {
                windowClass: "no-overflow"
              });
            }
            this.authService.redirectUrl = url;
          }
        })
      );
  }
  canBeActivated() {
    return this.store.select('auth')
    .pipe(
      take(1),
      map((authState: fromAuth.State) => {
        if (authState.authenticated) {
            return authState.authenticated && this.authService.checkTokenValid(authState);
          } else {
            return false;
          }
        })
      );
  }
}
