import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';

// import * as datepicker from '@chenfengyuan/datepicker/src/index';

declare var $: any;

@Directive({
  selector: '[appInputFlatpickr]'
})
export class InputFlatpickrDirective implements OnInit, OnDestroy {
  // flatpickrInit: any;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    // console.log(datepicker);
  }

  ngOnDestroy() {
    // this.flatpickrInit.destroy();
  }

}
