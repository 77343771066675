import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { QuestDoer } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import {QuestService} from '../../../_services/quest.service';

@Component({
  selector: 'app-map-popover',
  templateUrl: './map-popover.component.html',
  styleUrls: ['./map-popover.component.scss']
})
export class MapPopoverComponent implements OnInit, OnChanges {
  @Input() questId: number;
  @Input() userId: number;
  @Input() questTitle: string;
  // @Input() questData: Quest;
  @Input() locationName: string;
  @Input() showTeams: boolean;
  @Input() teams?: QuestTeam[] = [];
  //Need to figure out where on class Quest or QuestUserInfo to put a geopoint or some other location thing
  //Temporarily a string for location name
  hasPrev: boolean = false;
  hasNext: boolean = false;
  @Input() doers?: QuestDoer[] = [];
  toShow = [];
  numShown: number;
  doersStartingIndex: number;
  ranOnce: boolean = false;

  constructor(
    private questService: QuestService
    ) { }

  ngOnInit() {
    this.onInit();
  }

  ngOnChanges(): void {
    this.updateInput();
  }

  onInit() {
    if (!this.showTeams && this.doers) {
      this.numShown = this.doers.length < 8 ? this.doers.length : 8;
      this.toShow = [...this.doers.slice(0, this.numShown)];
      this.doersStartingIndex = 0;
      this.updatePrevNext();
    } else if (this.showTeams && this.teams) {
      this.numShown = this.teams.length < 8 ? this.teams.length : 8;
      this.toShow = [...this.teams.slice(0, this.numShown)];
      this.doersStartingIndex = 0;
      this.updatePrevNext();
    }
  }

  updateInput() {
    if (!this.showTeams) {
      this.doers = this.doers;
      if (this.teams && this.doers.length == 1) {
        let userTeam = this.teams.find((obj) => obj.teamId == this.doers[0].teamId);
        if (userTeam) {
          this.teams.push(userTeam);
        }
      }
    } else {
      this.teams = (this.doers as any[]);
    }
    this.onInit();
  }

  setInputs(questId: number, userId: number, title: string, location: string, showTeams: boolean, portraits: any[], teams?: any[]) {
    this.questId = questId;
    this.userId = userId;
    this.questTitle = title;
    this.locationName = location;
    this.showTeams = showTeams;
    if (!showTeams) {
      this.doers = portraits;
      if (teams && this.doers.length == 1) {
        let userTeam = teams.find((obj) => obj.teamId == this.doers[0].teamId);
        if (userTeam) {
          this.teams.push(userTeam);
        }
      }
    } else {
      this.teams = portraits;
    }
    this.onInit();
  }

  debug() {
    console.log("Debug");
    console.log("ShowTeams ", this.showTeams);
    console.log("Doers ", this.doers);
    console.log("Teams ", this.teams);
    console.log("ToShow ", this.toShow);
  }

  updatePrevNext() {
    if (this.numShown < 8 && !this.hasPrev && !this.hasNext) {
      this.hasPrev = false;
      this.hasNext = false;
      return
    }
    let portraits = this.showTeams ? this.teams : this.doers;
    this.hasPrev = this.doersStartingIndex <= 0 ? false : true;
    this.hasNext = this.doersStartingIndex >= portraits.length - this.numShown ? false : true;
  }

  onPrevClick() {
    let portraits = this.showTeams ? this.teams : this.doers;
    this.doersStartingIndex = this.doersStartingIndex < this.numShown ? 0 : this.doersStartingIndex - this.numShown;
    const endingIndex = this.doersStartingIndex + this.numShown > portraits.length ? portraits.length : this.doersStartingIndex + this.numShown;
    this.toShow = [...portraits.slice(this.doersStartingIndex, endingIndex)];
    this.updatePrevNext();
  }

  onNextClick() {
    let portraits = this.showTeams ? this.teams : this.doers;
    this.doersStartingIndex = this.doersStartingIndex + this.numShown;
    const endingIndex = this.doersStartingIndex + this.numShown > portraits.length ? portraits.length : this.doersStartingIndex + this.numShown;
    this.toShow = [...portraits.slice(this.doersStartingIndex, endingIndex)];
    this.updatePrevNext();
  }



}
