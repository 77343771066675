import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AddNewTaskGroup, AddQuestLink, ADD_NEW_TASK_GROUP, ADD_QUEST_LINK, CreateDefaultTaskGroup, CREATE_DEFAULT_TASK_GROUP, NewTaskGroupAdded, PatchGroupName, PatchMilestone, RemoveTaskGroup, REMOVE_TASK_GROUP, TaskGroupRemoved, TaskPositionUpdated, UpdateMilestoneGroupName, UpdateTaskPosition, UPDATE_MILESTONE_GROUP_NAME, UPDATE_TASK_POSITION } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { QuestTask } from 'src/app/_interface/quest.types';
import { QuestService } from '../../../../_services/quest.service';
import { AppState } from '../../../../_store/app.reducers';
import { DlMilestonesService } from '../service/dl-milestones.service';

@Injectable()
export class DlMilestonesEffects {

  constructor(private actions: Actions,
              private store: Store<AppState>,
              private questService: QuestService,
              private milestonesService: DlMilestonesService,
              private notifier: NotifierService) {
  }

  
  doLinkQuestsByTask = createEffect(() => this.actions.pipe(
    ofType<AddQuestLink>(ADD_QUEST_LINK),
    switchMap((action: AddQuestLink) => this.questService.linkQuest(
      action.taskId,
      action.linkedQuestId
    ).pipe(
      map(task => {
        this.notifier.notify('success', 'Quest successfully linked');
        return {
          index: action.taskIndex,
          groupIndex: action.groupIndex,
          task: task
        } as IndexedTask;
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error linking Quest!');
        return EMPTY;
      })
    )),
    switchMap((result: IndexedTask) => result
      ? [new PatchMilestone({taskIndex: result.index, groupIndex: result.groupIndex, value: result.task})]
      : [])
  ));

  
  doUpdateMilestoneGroupName = createEffect(() => this.actions.pipe(
    ofType<UpdateMilestoneGroupName>(UPDATE_MILESTONE_GROUP_NAME),
    switchMap((action: UpdateMilestoneGroupName) => this.milestonesService.updateMilestonesGroupName(
      action.groupId,
      action.groupName
    ).pipe(
      map((group: TaskGroup) => {
        this.notifier.notify('success', 'Task group renamed!');
        return {index: action.groupIndex, group: group} as IndexedGroup;
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error renaming group!');
        return EMPTY;
      })
    )),
    switchMap((result: IndexedGroup) => result
      ? [new PatchGroupName({index: result.index, value: result.group.name})]
      : [])
  ));

  
  doCreateDefaultTaskGroup = createEffect(() => this.actions.pipe(
    ofType<CreateDefaultTaskGroup>(CREATE_DEFAULT_TASK_GROUP),
    switchMap((action: CreateDefaultTaskGroup) => this.milestonesService.createDefaultGroupWithTasks(
      action.questId,
      action.groupName
    ).pipe(
      map((group: TaskGroup) => {
        this.notifier.notify('success', 'Task group created!');
        return {index: action.groupIndex, group: group} as IndexedGroup;
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error creating task group!');
        return EMPTY;
      })
    )),
    switchMap((result: IndexedGroup) => result
      ? [new PatchGroupName({index: result.index, value: result.group.name})]
      : [])
  ));

  
  doCreateNewTaskGroup = createEffect(() => this.actions.pipe(
    ofType<AddNewTaskGroup>(ADD_NEW_TASK_GROUP),
    switchMap((action: AddNewTaskGroup) => this.milestonesService.createNewTaskGroup(
      action.questId,
      action.groupName,
      action.groupOwnerId
    ).pipe(
      map((group: TaskGroup) => {
        this.notifier.notify('success', 'Task group added!');
        return new NewTaskGroupAdded(group);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error adding task group!');
        return EMPTY;
      })
    ))
  ));

  
  doRemoveTaskGroup = createEffect(() => this.actions.pipe(
    ofType<RemoveTaskGroup>(REMOVE_TASK_GROUP),
    withLatestFrom(this.store.select('dlMilestones')),
    switchMap(([action, state]) => this.milestonesService.removeTaskGroup(state.milestones[action.groupIndex].id).pipe(
      map((group: TaskGroup) => {
        this.notifier.notify('success', `Task group "${group.name}" removed`);
        return new TaskGroupRemoved(action.groupIndex);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error removing task group!');
        return EMPTY;
      })
    ))
  ));

  
  doMoveTask = createEffect(() => this.actions.pipe(
    ofType<UpdateTaskPosition>(UPDATE_TASK_POSITION),
    switchMap((action: UpdateTaskPosition) => this.milestonesService.moveTask(action.payload).pipe(
      map((task) => {
        return new TaskPositionUpdated(task, action.notification);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error moving task!');
        return EMPTY;
      })
    ))
  ));

}

interface IndexedTask {
  index: number;
  groupIndex: number;
  task: QuestTask;
}

interface IndexedGroup {
  index: number;
  group: TaskGroup;
}
