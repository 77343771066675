import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { QUILL_CONFIG } from 'src/app/app.config';
import { MILESTONE_TASK_MAX_LENGTH } from 'src/app/app.constants';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { AddMilestone, PatchMilestone, PatchNewMilestone, RemoveNewMilestone, RemoveTaskGroup, SetNewMilestone } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { AddLinkPreviewToMilestonePayload, AddMilestonePayload, AddVideoUrlToMilestonePayload, DlMilestoneManageModes, PatchMilestonePayload } from 'src/app/_interface/dl-milestones.typings';
import { MilestoneVideo, QuestTask } from 'src/app/_interface/quest.types';
import { AppState } from 'src/app/_store/app.reducers';
import { QuestService } from '../../../../_services/quest.service';
import { QuestPreparedPhoto } from '../../quest/quest.type';
import { DlMilestonesService } from '../service/dl-milestones.service';

interface CroppedImage {
  croppedImage: string | any;
  contentType: string;
  caption: string;
}

@Component({
  selector: 'app-dl-new-milestone',
  styleUrls: ['./dl-new-milestone.component.scss'],
  templateUrl: 'dl-new-milestone.component.html'
})

export class DlNewMilestoneComponent implements OnInit, OnDestroy, OnChanges {
  readonly MAX_LENGTH = MILESTONE_TASK_MAX_LENGTH;
  @Input() index: number;
  @Input() groupIndex: number;
  @Input() questId: number;
  @Input() isEditMode: boolean;
  @Input() mode: DlMilestoneManageModes;
  @Input() questOwnerId: number;
  @Input() allActivities: any;
  dlMilestonesState: Observable<TaskGroupsState>;
  dlMilestonesStateSubscription: Subscription;
  isLoading = false;
  newMilestoneInfo: QuestTask;
  submitted = false;
  eventsSubject: Subject<void> = new Subject<void>();

  dlMilestonesSubscription: Subscription;
  taskMode: 'NEW' | 'RELEASED';
  milestoneInfo: QuestTask;
  openedModal: string;
  @ViewChild('imageUploadBox', { read: ElementRef }) imageUploadBox: ElementRef;
  imagePreview: any = '';
  @ViewChild('imageUploadInput', { read: ElementRef }) imageUploadInput: ElementRef;
  ngxConfig = {...QUILL_CONFIG};
  filteredActivities = [];

  constructor(
    private store: Store<AppState>,
    private dlMilestonesService: DlMilestonesService,
    private questService: QuestService,
    private notifier: NotifierService,
    private renderer: Renderer2
  ) {}
  ngOnInit() {
    this.dlMilestonesState = this.store.select('dlMilestones');
    this.addNewMilestone();
    this.ngxConfig.counter = {container: '#counter', unit: 'character', maxLength: 50000};

    this.dlMilestonesStateSubscription = this.dlMilestonesState.subscribe((state: TaskGroupsState) => {
      // console.log('57---', state, state.newMilestoneGroupIndex, this.groupIndex);
      if (state.newMilestoneGroupIndex === this.groupIndex) {
        this.newMilestoneInfo = state.newMilestoneForPreview || null;
      } else {
        this.newMilestoneInfo = null;
      }
    });

    this.dlMilestonesSubscription = this.dlMilestonesState.subscribe((state: TaskGroupsState) => {
      // * detect witch mode should be used for this dialog
      if (state.milestones.length && state.milestones[this.groupIndex]) {
        // tslint:disable-next-line:max-line-length
        this.taskMode = (state.milestones[this.groupIndex].questTasks.length - 1) < this.index ? 'NEW' : 'RELEASED';
      } else {
        this.taskMode = 'NEW';
      }
      if (this.taskMode === 'RELEASED') {
        this.milestoneInfo = state.milestones[this.groupIndex].questTasks[this.index];
      } else {
        this.milestoneInfo = state.newMilestoneForPreview;
      }
    });

    setTimeout(() => {
      if (this.imageUploadBox && this.imageUploadBox.nativeElement) {
        // console.log('95--', this.imageUploadBox);
        this.renderer.listen(this.imageUploadBox.nativeElement, 'drop', (event) => {
          event.stopPropagation();
          event.preventDefault();

          const fileList = event.dataTransfer.files;
          this.readImageDataAndProceed(fileList[0]);
        });

        this.renderer.listen(this.imageUploadBox.nativeElement, 'dragover', (event) => {
          event.stopPropagation();
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
        });
      }  
    }, 1000);
    
  }
  ngOnDestroy() {
    if (this.dlMilestonesStateSubscription) {
      this.dlMilestonesStateSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.filteredActivities = [...this.allActivities];    
  }

  fileChangeEvent(event) {
  // let payload: any;
  // if (this.payloadForSelectedArea.id) {
  //   payload = {
  //     taskId: this.payloadForSelectedArea.id,
  //     taskImage: newImage.croppedImage,
  //     contentType: newImage.contentType
  //   };
  //   this.dlMilestonesService.addImageToMilestone(payload).subscribe((imageUrl: string) => {

  //     this.imageAdded.emit(imageUrl);
  //     this.finishingUpload();
  //   }, () => {
  //   this.notifier.notify('error', 'Issue completing request');
  //   });
  // } else {
    const file = event.target.files[0];
    if (file) this.readImageDataAndProceed(file);
    // this.finishingUpload();
  // }
 }

 readImageDataAndProceed(file) {
    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      let uploaded_image = event.target['result'];
      // console.log('uploaded_image-', reader.result);
      let contentType = file.type;
      let caption = '';

      // this.addPhotoToMilestone(this.convertToUrl({
      //   croppedImage: uploaded_image,
      //   caption: caption,
      //   contentType: contentType
      // }));
    });

    reader.readAsDataURL(file);
  }

  private convertToUrl(image: CroppedImage): string {
    const data = image.croppedImage;
    const DATA_URL_PREFIX = 'data:';
    const HTTP_URL_PREFIX = 'http:';
    const HTTPS_URL_PREFIX = 'https:';
    if (data) {
      if ([
        DATA_URL_PREFIX,
        HTTP_URL_PREFIX,
        HTTPS_URL_PREFIX
      ].filter(prefix => data.startsWith(prefix)).length) {
        this.imagePreview = data;
        return this.imagePreview;
      } else {
        this.imagePreview = data.indexOf(';base64') > -1 ? data : (DATA_URL_PREFIX + image.contentType + ';base64,' + data);
        return this.imagePreview;
      }
    } else {
      return null;
    }
  }

  openImageUpload() {
    if (this.imageUploadInput && this.imageUploadInput.nativeElement)
      this.imageUploadInput.nativeElement.click();
  }

  isTaskInvalid() {
    return !this.newMilestoneInfo.title || !this.newMilestoneInfo.activityType || (
      this.newMilestoneInfo.activityType && 
      !this.newMilestoneInfo.activityType.id);
  }

  submit() {
    if (this.isLoading) { return false; }

    this.submitted = true;

    if (this.isTaskInvalid()) {
      this.notifier.notify('warning', 'Fill in required fields');
      return false;
    }

    if (this.mode === DlMilestoneManageModes.QUEST_DETAILS || this.mode === DlMilestoneManageModes.QUEST_MANAGE) {
      this.isLoading = true;
      this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
        const payload = {
          ...state.newMilestoneForPreview,
          groupIndex: this.groupIndex,
          questOwnerId: this.questOwnerId
        } as AddMilestonePayload;
        this.dlMilestonesService.addMilestone(this.questId, payload).subscribe((milestone: QuestTask) => {
          this.store.dispatch(new RemoveNewMilestone());
          this.store.dispatch(new AddMilestone(milestone, this.groupIndex));
          this.isLoading = false;
        }, () => {
          this.notifier.notify('error', `Issue completing request`);
        });
      });
    } else if (this.mode === DlMilestoneManageModes.NEW_QUEST) {
      this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
        const milestone = new QuestTask(state.newMilestoneForPreview);
        this.store.dispatch(new RemoveNewMilestone());
        this.store.dispatch(new AddMilestone(milestone, this.groupIndex));
      });
    }
  }

  // openDialogOld() {
  //   this.store.dispatch(new SetMilestoneForPreview({index: this.index, groupIndex: this.groupIndex, isEditMode: true}));
  //   this.openDialog('milestone-details-dialog');
  // }

  addNewMilestone() {
    const newQuestTask = new QuestTask({
      task: null,
      title: null,
      imageUrl: null,
      video: null,
      embeddedVideo: null,
      linkUrl: null,
      linkPreview: null,
      linkedQuest: null,
      linkedQuestId: null,
      activityType: null
    });
    this.store.dispatch(new SetNewMilestone(newQuestTask, this.groupIndex));
    this.submitted = false;
  }
  removeNewMilestone() {
    this.store.dispatch(new RemoveNewMilestone());
  }

  removeGroup() {
    this.isLoading = true;
    this.store.dispatch(new RemoveTaskGroup(this.groupIndex));
  }

  setMilestoneTask(event) {
    this.store.dispatch(new PatchNewMilestone({
      title: event.target.value
    } as QuestTask, this.groupIndex));
  }

  setMilestoneDescription(event) {
    this.store.dispatch(new PatchNewMilestone({
      task: event.html
    } as QuestTask, this.groupIndex));
  }

  selectedActivities: Array<any> = [];
  selectedActivity(event) {
    const _act = this.filteredActivities.filter(act=> act.id === Number(event.target.value))
    // console.log('140--selectedActivity', event);
    // console.log('140-', event.target.value, _act);
    
    let activities = [];
    this.selectedActivities.push(..._act);
    this.selectedActivities.forEach(acti => {
      activities.push({
        id: acti.id,
        text: acti.activityName
      })
    });

    const removeIndex = this.filteredActivities.findIndex(act => act.id === _act[0].id);
    this.filteredActivities.splice(removeIndex, 1);
    // console.log('298-', this.filteredActivities, removeIndex, _act);
    
    this.store.dispatch(new PatchNewMilestone({
      activityType: activities
    } as QuestTask, this.groupIndex));
  }

  removeActivity(activity) {
    const removeIndex = this.selectedActivities.findIndex(act => act.id === activity.id)
    this.selectedActivities.splice(removeIndex, 1);
    
    // add the removed activity back to filtered activity
    const newActivitiyToAdd = this.allActivities.filter(act => act.id === activity.id);
    this.filteredActivities.push(...newActivitiyToAdd);
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  get maxLengthCount(): number {
    return MILESTONE_TASK_MAX_LENGTH - (this.newMilestoneInfo.title ? this.newMilestoneInfo.title.length : 0);
  }

  checkUrlAndAddToMilestone(event){
    if ((!event.target.value || event.target.value === null)) return; 
    const url = event.target.value;

    if (url.indexOf('youtube') > -1) {
      this.addVideoToMilestone(event)
    } else {
      this.addLinkToMilestone(event);    
    }
  }

  addLinkToMilestone(event) {
    if ((!event.target.value || event.target.value === null)) return; 

    const payload: AddLinkPreviewToMilestonePayload = {
      taskId: null,//TODO: to pass - this.id, passed null as it will always be null for new task
      taskLink: event.target.value
    };
    this.dlMilestonesService.addLinkToMilestone(payload).subscribe((newTask: QuestTask) => {
      const patchMilestonePayload: PatchMilestonePayload = {
        taskIndex: this.index,
        groupIndex: this.groupIndex,
        value: {
          linkUrl: newTask.linkUrl,
          linkPreview: newTask.linkPreview
        } as QuestTask
      };
      if (this.taskMode === 'RELEASED') {
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
      } else {
        this.store.dispatch(new PatchNewMilestone({
          linkUrl: event.target.value, //this.linkForm.value.url,
          linkPreview: newTask.linkPreview
        } as QuestTask, this.groupIndex));
      }
    });
  }

  addVideoToMilestone(event) {
    if ((!event.target.value || event.target.value === null)) return; 

    const youtubeId = getYoutubeId(event.target.value);
    if (youtubeId) {
      const videoPayload: AddVideoUrlToMilestonePayload = {
        url: `https://www.youtube.com/embed/${youtubeId}`,
        provider: 'YOUTUBE',
        thumbnails: {
          xs: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          sm: `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
          md: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
        }
      };
      const embeddedVideo: MilestoneVideo = {
        thumbnailUrl: videoPayload.thumbnails.sm,
        videoId: null,
        videoUrl: videoPayload.url
      };
      // if (this.taskMode === 'RELEASED' && this.id) {
      //   // * milestone and quest is already created
      //   this.store.select('dlMilestones').pipe(take(1)).subscribe((state: TaskGroupsState) => {
      //     const milestone: AddVideoToMilestonePayload = {
      //       id: state.milestones[this.groupIndex].questTasks[this.index].id,
      //       task: state.milestones[this.groupIndex].questTasks[this.index].task,
      //       video: videoPayload
      //     };
      //     this.dlMilestonesService.addVideoToMilestone(milestone).subscribe(() => {
      //       const patchMilestonePayload: PatchMilestonePayload = {
      //         taskIndex: this.index,
      //         groupIndex: this.groupIndex,
      //         value: {
      //           video: videoPayload,
      //           embeddedVideo: embeddedVideo
      //         } as QuestTask
      //       };
      //       this.store.dispatch(new PatchMilestone(patchMilestonePayload));
      //       this.close();
      //     });
      //   });
      // } else 
      if (this.taskMode === 'RELEASED') { // && !this.id
        // * new quest with already created milestone
        const patchMilestonePayload: PatchMilestonePayload = {
          taskIndex: this.index,
          groupIndex: this.groupIndex,
          value: {
            video: videoPayload,
            embeddedVideo: embeddedVideo
          } as QuestTask
        };
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
      } else {
        // * new quest with not created milestone
        this.store.dispatch(new PatchNewMilestone({
          video: videoPayload,
          embeddedVideo: embeddedVideo
        } as QuestTask, this.groupIndex));
      }
    }
  }

  // openImageDialog() {
  //   this.openDialog('cropper-milestone-dialog');
  // }

  // openDialog(dialogId: string) {
  //   this.openedModal = dialogId;
  //   setTimeout(() => {
  //     this.modalService.open(dialogId);
  //   });
  // }

  // closeDialog(dialogId: string) {
  //   this.modalService.close(dialogId);
  //   setTimeout(() => {
  //     this.openedModal = dialogId;
  //   });
  // }

  // addPhotoToMilestone(imageUrl: string) {
  //   if (this.taskMode === 'RELEASED') {
  //     this.store.dispatch(new AddMilestoneImageUrl({taskIndex: this.index, groupIndex: this.groupIndex, value: imageUrl}));
  //   } else {
  //     this.store.dispatch(new PatchNewMilestone({
  //       imageUrl: imageUrl
  //     } as QuestTask, this.groupIndex));
  //   }
    // this.closeDialog('cropper-milestone-dialog');
    // this.close();
  // }
}
