import { Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { PreparedQuestComment } from '../comment/comment.type';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { AnimateList, AnimateItems, AddCommentToList } from 'src/app/animations/comments.animations';
import { QuestService } from 'src/app/_services/quest.service';
import { AppState } from 'src/app/_store/app.reducers';
import { Store } from '@ngrx/store';
import { ToggleEditCommentState, ToggleEditComment, AddReply, RemoveComment } from '../store/quest-components.actions';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CommentService } from '../comment/comment.service';
import { Observable } from 'rxjs';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as moment from 'moment';
import { ValidationMessages, VALIDATION_MESSAGES, SHARE_DATE_FORMAT } from 'src/app/app.constants';
import * as fromAuth from '../../../auth/store/auth.reducer';

declare var $: any;

// ! Used to count all response hierarchies in comments
const updateReplyCount = (replies: PreparedQuestComment[], clb: (count: number) => void) => {
  if (replies.length) {
    clb(replies.length);
    replies.forEach(reply => {
      updateReplyCount(reply.replies, clb);
    });
  } else {
    clb(0);
  }
};

@Component({
  selector: 'app-newsfeed-post',
  templateUrl: './newsfeed-post.component.html',
  styleUrls: ['./newsfeed-post.component.scss'],
  animations: [AnimateList, AnimateItems, AddCommentToList],
})
export class NewsfeedPostComponent implements OnInit, OnChanges {
  @Output() closeAnotherReplyForms: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Input() webviewMode: boolean;
  @Input() comment: PreparedQuestComment;
  @Input() closeReplyForm: boolean;
  @Input() viewerId: number;
  @Input() questId: number;
  @Input() showRepliesList = false;
  @ViewChild('feedbackEl') feedbackEl: ElementRef;

  reply = false;
  profileState: Observable<fromProfile.State>;
  replyForm: UntypedFormGroup;
  isReplyFormOpened = false;
  isLoading: string;
  hasCurrentUserLiked = false;
  sharedDate: string;
  
  tributeOptions: any;
  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  commentsCount = 0;
  authState: Observable<fromAuth.State>;
  isUserAuthenticated = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private elRef: ElementRef,
    private store: Store<AppState>,
    private questService: QuestService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private commentService: CommentService,
    private questSevice: QuestService
  ) {
    this.profileState = this.store.select('userInfo');
    this.tributeOptions = this.commentService.getTributeOptions();
  }

  ngOnInit() {
    this.authState = this.store.select('auth');
    // this.hasCurrentUserLiked = (this.comment.likes || []).filter(like => like.liker === this.viewerId).length > 0;
    this.sharedDate = moment(this.comment.createdDate).format(SHARE_DATE_FORMAT);
    this.replyForm = this.fb.group({
      editor: []
    });

    this.authState.subscribe(res => {
      this.isUserAuthenticated = res.authenticated;
    })
  }
  ngOnChanges() {
    if (this.comment !== null) {
      this.updateRepliesCount();    
      // console.log("NewsfeedPost :: Comment", this.comment)
    }
    if (this.closeReplyForm !== null) {
      this.isReplyFormOpened = this.closeReplyForm;
    }
  }

  onNavigate(navigateData: WebviewNavigationDataType) {
    if (this.webviewMode) {
      this.navigateToEmit.emit(navigateData);
    } else if (navigateData.type === 'LinkInside') {
      this.router.navigate(navigateData.routerLink);
    } else {
      this.document.location.href = navigateData.url;
    }
  }

  prepareCommentCRLF(comment: string) {
    return comment.replace(/\n/gm, '<br>');
  }

  toggleReplyComment(focus: boolean) {
    this.showReplies();
    if (!this.isReplyFormOpened) {
      this.closeAnotherReplyForms.emit();
      setTimeout(() => {
        this.isReplyFormOpened = true;
        if (focus) {
          this.focusOnField();
        }
      }, 0);
    }
  }
  cancelReply() {
    this.replyForm.patchValue( {
      editor: ''
    });
    this.isReplyFormOpened = false;
  }
  focusOnField() {
    setTimeout(() => {
      const $el = $(this.elRef.nativeElement);
      $('html, body').animate({
          scrollTop: $(this.feedbackEl.nativeElement).offset().top - 100
      }, 350, () => {
        const $replyEl = $el.find('.js-reply-trigger');
        const $replyElValue = $replyEl.val();
        $replyEl.val('');
        $replyEl.focus().val($replyElValue);
      });
    }, 400);
  }
  replyToComment() {
    if (!this.isLoading) {
      const replyId = this.comment.id;
      this.isLoading = 'REPLY';

      this.questService
        .addNewQuestCommentOld(this.comment.questId, this.viewerId, this.replyForm.value.editor, replyId)
        .subscribe((res: PreparedQuestComment) => {
          this.isLoading = null;
          this.isReplyFormOpened = false;
          this.replyForm.get('editor').patchValue('');
          this.loadMoreReplies(1);
          this.store.dispatch(new AddReply(res, replyId));
          this.updateRepliesCount();
        });
    }
  }

  showReplies() {
    this.showRepliesList = true;
  }

  loadMoreReplies(count?: number) {
    this.comment.limit += count ? count : 3;
  }

  toggleLikeComment() {
    if (!this.isUserAuthenticated) return;

    this.isLoading = 'LIKE';
    this.questService.toggleQuestLikeOld(this.comment.id).subscribe((liked: boolean) => {
      this.hasCurrentUserLiked = liked;
      // this.comment.likes = liked
      //   ? [...(this.comment.likes || []), {
      //     id: null,
      //     liker: this.viewerId,
      //     createdOn: null
      //   }]
      //   : [...(this.comment.likes || [])].filter(like => like.liker !== this.viewerId);
      this.isLoading = null;
    });
  }

  toggleEditComment() {
    if (this.comment.editable) {
      this.commentService.triggerEditMode();
    } else {
      const payload: ToggleEditCommentState = {
        commentId: this.comment.id,
        isEditable: !this.comment.editable
      };
      this.store.dispatch(new ToggleEditComment(payload));
    }
  }

  onCloseAnotherReplyForms() {
    this.closeAnotherReplyForms.emit();
  }

  removeComment(): void {
    if (this.isLoading) { return; }
    this.isLoading = 'REMOVING';

    this.questSevice.removeQuestComment(this.comment.id)
    .subscribe(() => {
      this.store.dispatch(new RemoveComment(this.comment.id));
      this.isLoading = null;
    }, () => {
      this.isLoading = null;
    });
  }
  updateRepliesCount(): void {
    this.commentsCount = 0;
    updateReplyCount(this.comment.replies, (count) => {
      this.commentsCount += count;
    });
  }
}
