import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ReaquestHeadersService} from './requestHeaders.service';
import {map, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of as observableOf} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {QuestEvent} from '../modules/ticket-flow/ticket-flow.typings';
import {NewQuestGalleryPhoto, QuestPreparedPhoto} from '../components/main/quest/quest.type';
import {PreparedQuestComment} from '../components/main/quest/quest-comments/comment/comment.type';
import {Utils} from '../_tools/utils';
import { getYoutubeId } from '../directives/youtube-validation/youtube-validation.directive';
import { BrandConfig } from '../_interface/brand.types';
import { QuestTeam } from '../_interface/team.types';
import { Quest, QuestActivity, QuestDoer, QuestInfo, QuestLite, QuestMapRoute, QuestMapRoutePoint, QuestTask, QuestUserInfo, RealtimeQuestForm, StartQuestForm } from '../_interface/quest.types';
import { LeaderboardMemberStatus, LeaderboardScore } from '../_interface/leaderboard.types';
import { MapLocation } from '../_interface/map.types';
import { ExploreCardListType } from '../_interface/explore-page.types';
import { ManageQuestPayload, QuestServiceInterface } from '../_interface/quest-service.types';
import { FundraisingLinkType } from '../_interface/fundraise-link.types';
import { CommentMention, QuestComment } from '../_interface/comment.types';
import { AccountService } from '../components/pages/account/account.service';
import { Store } from '@ngrx/store';
import { AppState } from '../_store/app.reducers';
import { AccountColor } from '../components/pages/account/store/account.reducer';

@Injectable({
  providedIn: 'root',
})
export class QuestService implements QuestServiceInterface {
  questInfoChanged: any = new EventEmitter();
  private fundraising: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private accountService: AccountService,
    private store: Store<AppState>) {
  }

  public static isEditableByUser(quest: QuestInfo, user: QuestUserInfo): boolean {
    return !!quest && !!user && (
      user.id === quest.createdBy || (!!quest.admins && quest.admins.indexOf(user.email) >= 0)
    );
  }

  public static hasUserActivity(questActivity: QuestActivity, userId: number) {
    return !!questActivity && !!userId && questActivity.userId === userId;
  }

  callUpdateForQuest(args?: any): void {
    this.questInfoChanged.emit(args);
  }

  getUpdateForQuest() {
    return this.questInfoChanged;
  }

  addMilestone(questId: number, payload: { task: string; video?: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/add-milestone`,
      payload,
      {headers: headers}
    );
  }

  removeMilestone(payload: { id: number; }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/remove',
      payload,
      {headers: headers}
    );
  }

  editMilestone(payload: { id: number; task: string; video?: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/edit',
      payload,
      {headers: headers}
    );
  }

  getQuestForPost(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/getquestforpost/${questId}`,
      {headers: headers}
    );
  }

  getQuestTasksForUser(questId: number): Observable<QuestTask[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<QuestTask[]>(
      environment.target + environment.context + '/getquesttasksforuser',
      {questId: questId},
      {headers: headers}
    ).pipe(
      map((tasks: any[]) => tasks.map(task => new QuestTask(task)))
    );
  }

  getQuestMembers(questId: number, userId: number): Observable<QuestDoer[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-members/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((doers: QuestDoer[]): QuestDoer[] => {
        doers.forEach(doer => {
          if (doer.amountBacked && doer.amountBacked.length) {
            doer.amountBackedSum = doer.amountBacked.reduce((sum, delta) => sum + delta, 0);
          } else {
            doer.amountBackedSum = 0;
          }
          doer.memberStatusObj = {
            Creator: false,
            Admin: false,
            Backer: false,
            Donor: false,
            Supporter: false,
            Doer: false,
            Achiever: false,
            Interested: false
          };
          if (doer.memberStatus && doer.memberStatus.length) {
            doer.memberStatus.forEach(status => {
              if (doer.memberStatusObj.hasOwnProperty(status)) {
                doer.memberStatusObj[status] = true;
              }
            });
          }
        });
        return doers;
      })
    );
  }

  updateTeamInfo(payload: QuestTeam) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/teams/${payload.teamId}`,
      {
        questTeamId: payload.teamId,
        questTeamName: payload.teamName,
        questTeamLogoUrl: payload.teamLogoUrl,
        questTeamCoverUrl: payload.teamCoverUrl,
        questTeamAction: 'Update',
        coverCenterX: payload.coverCenterX,
        coverCenterY: payload.coverCenterY,
        coverZoomValue: payload.coverZoomValue
      },
      {headers: headers}
    );
  }

  getQuestTeams(questId: number): Observable<QuestTeam[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam[]>(
      environment.target + environment.context + `/quest-teams/${questId}`,
      {headers: headers}
    );
  }

  getParentQuestTeams(questId: number,  userId: number): Observable<Quest[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest[]>(
      environment.target + environment.context + `/quest-parent-teams/${questId}/${userId}`,
      {headers: headers}
    );
  }

  getQuestBrands(questId: number): Observable<BrandConfig[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<BrandConfig[]>(
      environment.target + environment.context + `/quests/${questId}/brand-config`,
      {headers: headers}
    );
  }

  findTeam(questId: number, teamName: string): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${questId}/for-name`,
      {
        headers: headers,
        params: {'team-name': teamName}
      }
    );
  }

  getTeam(teamId: number): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${teamId}/for-id`,
      {headers: headers}
    );
  }

  checkQuestTeamName(questId: number, teamName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/quest-teams/${questId}/check-name`,
      teamName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  checkFundraiserCampaignName(campaignName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/fundraising/link/check-name`,
      campaignName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestLeaderboard(questId: number, attributeId: string): Observable<LeaderboardScore[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<LeaderboardScore[]>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {headers: headers}
    );
  }

  updateLeaderBoardMembers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/members/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  updateLeaderBoardDoers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/doers/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  patchLeaderboardScore(
    questId: number,
    attributeId: string,
    payload: {
      memberId: number;
      value: number;
      status: LeaderboardMemberStatus;
    }
  ) {
    return this.http.post(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {...payload},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestsInvites(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/invites`,
      {headers: headers}
    );
  }

  getQuestsAdmins(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/admins`,
      {headers: headers}
    );
  }

  getQuestEditInfo(questId: number): any {
    return forkJoin([
      this.getQuestForPost(questId),
      this.getQuestsInvites(questId),
      this.getQuestsAdmins(questId)
    ]);
  }

  editQuest(payload: any, userId: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/editquest',
      payload,
      {headers: headers}
    ).pipe(
      switchMap((res) => {
        return this.generateQuestClassification(payload.questId).pipe(map(() => res));
      })
    );
  }

  checkIfUserQuestSaved(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/isuserquestsaved',
      {questId: questId},
      {headers: headers}
    );
  }

  cancelQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.put(
      environment.target + environment.context + `/quest/${questId}/cancel`,
      null,
      {headers: headers}
    );
  }

  getEventForQuest(questId: number, withStripeInfo: boolean = false): Observable<QuestEvent> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestEvent>(
      environment.target + environment.context + `/quests/${questId}/event/${withStripeInfo}`,
      {headers: headers}
    );
  }

  getOtherUsers(): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/get-other-users',
      {headers: headers}
    );
  }

  searchUsers(keyword: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.authContext + `/find/user/${keyword}`,
      {headers: headers}
    );
  }

  completeQuest(questId: number, completeMilestones: boolean, withGeoLocation?: boolean): Observable<void> {
    return new Observable<void>(observer => {
      const doComplete = (point: MapLocation): void => {
        const completeHandle = this.completeQuestInternal(questId, completeMilestones, point).subscribe(
          () => observer.next(null),
          (error) => observer.error(error),
          () => completeHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before completing Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doComplete(point),
          () => doComplete(null),
          () => geoHandle.unsubscribe());
      } else {
        doComplete(null);
      }
      return observer;
    });
  }

  private completeQuestInternal(questId: number, completeMilestones: boolean, point?: MapLocation): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<void>(
      environment.target + environment.context + '/completequest',
      {questId, completeMilestones, point},
      {headers: headers}
    );
  }

  createQuest(payload: ManageQuestPayload): Observable<ExploreCardListType> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<ExploreCardListType>(
      environment.target + environment.context + '/newquest',
      payload,
      {headers: headers}
    ).pipe(
      switchMap((res: ExploreCardListType) => {
        return this.generateQuestClassification(res.id).pipe(map(() => res));
      })
    );
  }

  private generateQuestClassification(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest-classification/${questId}`,
      null,
      {headers: headers}
    );
  }

  addQuestGalleryPhoto(payload: NewQuestGalleryPhoto) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/addphototoquest',
      payload,
      {headers: headers}
    );
  }

  getQuestInterests() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/profile-interests',
      {headers: headers}
    );
  }

  startQuest(startQuestForm: StartQuestForm): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/doquest',
      startQuestForm,
      {headers: headers}
    );
  }

  doQuest(questId: number, referrerId?: number, questMode?: string, withGeoLocation?: boolean): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const doStart = (point: MapLocation): void => {
        const startHandle = this.startQuest({questId, referrerId, questMode, point}).subscribe(
          (started) => observer.next(started),
          (error) => observer.error(error),
          () => startHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before starting Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doStart(point),
          () => doStart(null),
          () => geoHandle.unsubscribe());
      } else {
        doStart(null);
      }
      return observer;
    });
  }

  updateQuestTask(taskId: number): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/updatetask',
      {taskId: taskId},
      {headers: headers}
    );
  }

  getQuestDetail(questId, userId): Observable<Quest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest>(
      environment.target + environment.context + `/quest-page/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((questPage: Quest) => {
        questPage.questTasks = questPage.questTasks.map(task => new QuestTask(task));
        return questPage;
      })
    );
  }

  getQuestLite(questId: number): Observable<QuestLite> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestLite>(
      environment.target + environment.context + `/quests/${questId}/lite`,
      { headers: headers }
    );
  }

  linkQuest(taskId: number, linkedQuestId: number): Observable<QuestTask> {
    return this.http.post<QuestTask>(
      environment.target + environment.context + `/quest-milestones/add-quest-link`,
      {taskId: taskId, linkedQuestId: linkedQuestId},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  followQuest(questId: number, follow: boolean) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/follow/${questId}/${follow ? 'true' : 'false'}`,
      payload,
      {headers: headers}
    );
  }

  saveQuest(questId) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/savequest`,
      payload,
      {headers: headers}
    );
  }

  copyQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/copy`,
      null,
      {headers: headers}
    );
  }

  startFundraising(questId: number,
                   doerId: number,
                   brandUserId: number,
                   targetAmount: number,
                   currency: string,
                   campaignName: string,
                   coverImageUrl: string,
                   centerX: string,
                   centerY: string,
                   zoomValue: string,
                   secondaryBrandUserId?: number): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {questId, doerId, brandUserId, targetAmount, currency, campaignName, coverImageUrl, secondaryBrandUserId};
    payload['coverCenterX'] = parseFloat(centerX);
    payload['coverCenterY'] = parseFloat(centerY);
    payload['coverZoomValue'] = parseFloat(zoomValue);
    return this.http.post<FundraisingLinkType>(
      environment.target + environment.context + '/fundraising/start',
      payload,
      {headers: headers}
    );
  }

  getFundraisingInfo(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/fundraising/parties/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getFundraisingLink(questId: number, userId: number): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<FundraisingLinkType>(
      environment.target + environment.context + `/fundraising/link/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getQuestCompletionPercent(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + `/getquestcompletionpercent/${questId}/${userId}`,
      payload,
      {headers: headers}
    ).pipe(map((res: any) => {
      res.completionPercentage = Number(res.completionPercentage.replace('%', '').trim());

      return res;
    }));
  }

  getQuestComments(questId: number, userId: number, viewerId: number): Observable<PreparedQuestComment[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + '/getallcommentsforquest',
      payload,
      {headers: headers}
    ).pipe(
      // map((comments: QuestComment[]): QuestComment[] => {
      //   // ? reverse comments
      //   comments.forEach(comment => comment.replies.reverse());
      //   return comments;
      // }),
      map((comments: QuestComment[]): PreparedQuestComment[] => {
        const shouldBeDeleted = [];
        let deletedCount = 0;
        comments.forEach((comment: PreparedQuestComment, index: number) => {
          comment.liked = false;
          comment.editable = false;
          if (comment.likes) {
            // comment.liked = (comment.likes).filter(like => like.liker === viewerId).length > 0;
          }
          // comment.likesCount = comment.likes ? comment.likes.length : 0;
          if (comment.deleted !== 'N' && comment.deleted !== null) {
            shouldBeDeleted.push(index);
          }
          this.prepareComment(comment);
        });
        if (shouldBeDeleted.length > 0) {
          shouldBeDeleted.forEach(key => {
            comments.splice((key - deletedCount), 1);
            deletedCount++;
          });
        }
        return comments;
      })
    );
  }

  addNewQuestComment(questId: number, activityRecordValueId: number, comment: string, parentCommentId = null): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comments: comment,
      activityRecordValueId: activityRecordValueId,
      parentCommentsId: parentCommentId
    };
    return this.http.post(
      environment.target + environment.context + `/comments/${questId}`,
      payload,
      {headers: headers}
    )
    // .pipe(
    //   map((newComment: PreparedQuestComment): PreparedQuestComment => {
    //     return this.prepareComment(newComment);
    //   })
    // );
  }

  addNewQuestCommentOld(questId: number, viewerId: number, comment: string, inReplyToId?: number, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId,
      comments: comment,
      inReplyToId: inReplyToId,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + '/addcomment',
      payload,
      {headers: headers}
    ).pipe(
      map((newComment: PreparedQuestComment): PreparedQuestComment => {
        return this.prepareComment(newComment);
      })
    );
  }

  prepareComment(comment: PreparedQuestComment): PreparedQuestComment {
    comment.limit = 5;

    const formattedStringArray = [];
    let regexSegment = '';

    comment.mentions.forEach((mention, index) => {
      regexSegment += `(@${mention.userName})`;
      if (comment.mentions.length > index + 1) {
        regexSegment += '|';
      }
    });

    const regexp = new RegExp(`\\B${regexSegment}\\b`, 'gmi');
    const commentArray = comment.comment.split(regexp).filter(el => el !== undefined);

    commentArray.forEach((item) => {
      const formattedStringArrayItem: any = {};
      formattedStringArrayItem.text = item;
      if (item.match(regexp)) {
        formattedStringArrayItem.mention = [...comment.mentions].find(mention => '@' + mention.userName === item.match(regexp)[0]);
      }
      formattedStringArray.push(formattedStringArrayItem);
    });

    let str = '';
    let mentionCount = 0;
    formattedStringArray.forEach(item => {
      if (!item.mention) {
        str += item.text;
      } else {
        str += this.buildCommentMention(item.mention, mentionCount);
        mentionCount++;
      }
    });

    comment.formattedComment = str;
    comment.replies = [...comment.replies].map(reply => this.prepareComment(reply));
    return comment;
  }

  buildCommentMention(mention: CommentMention, index: number) {
    // tslint:disable-next-line:max-line-length
    return `<a class="c-comment__mention js-mention" href="javascript:void(0);" data-index="${index}">${mention.firstName} ${mention.lastName}</a>`;
  }

  editQuestComment(commentId: number, value: string, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comment: value,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}`,
      payload,
      {headers: headers}
    ).pipe(
      map((updatedComment: QuestComment): PreparedQuestComment => {
        return this.prepareComment(updatedComment);
      })
    );
  }

  editQuestCommentReply(commentId: number, comment: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comments: comment,
      activityRecordValueId: null,
      parentCommentsId: null
    };
    return this.http.post(
      environment.target + environment.context + `/comments/edit/${commentId}`,
      payload,
      {headers: headers}
    )
  }

  toggleQuestLike(questId: number, activityRecordValueId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/likes/${questId}/${activityRecordValueId}`,
      null,
      {headers: headers}
    );
  }

  toggleQuestLikeOld(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}/like`,
      null,
      {headers: headers}
    );
  }

  removeQuestComment(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/comment/${commentId}`,
      {headers: headers}
    );
  }

  addQuestCoverPhoto(questId: number, photo: File) {
    if (photo && questId) {
      const formData: FormData = new FormData();
      formData.append('questImage', photo, photo.name);
      formData.append('questId', questId.toString());
      const headers = this.reaquestHeadersService.getHeaders();
      return this.http.post(
        environment.target + environment.context + '/newquestimage',
        formData,
        {headers: headers}
      );
    }
    return observableOf([]);
  }

  addQuestCoverPhotoWithUrl(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/newquestimagewithurl',
      payload,
      {headers: headers}
    );
  }

  removeQuestPhoto(photoId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/quest/photo/${photoId}`,
      {headers: headers}
    );
  }

  prepareCoverPhoto(photo: any | null, category?: string | null): QuestPreparedPhoto {
    let url = '/assets/images/useful/categories/';

    if (photo) {
      return {
        jpg: photo,
        webp: null
      };
    } else {
      switch (category) {
        case 'MENTAL':
          url += 'mental';
          break;
        case 'OCCUPATIONAL':
          url += 'occupational';
          break;
        case 'ENVIRONMENTAL':
          url += 'environmental';
          break;
        case 'SOCIAL':
          url += 'social';
          break;
        case 'PHYSICAL':
          url += 'physical';
          break;
        case 'FINANCIAL':
          url += 'financial';
          break;
        default:
          url = null;
          break;
      }
    }

    return url === null ? null : {
      jpg: `${url}.jpg`,
      webp: `${url}.webp`
    };
  }

  getYoutubeId(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  fundraisingSwitchReset() {
    return this.fundraising.emit(false);
  }

  getFundraisingSwitchValue() {
    return this.fundraising;
  }

  addMapRoute(questId: number, payload: { gpxFile: any; name: string; description: string; }) {
    const headers = this.reaquestHeadersService.getHeaders();
    const data = new FormData();
    data.append('gpxFile', payload.gpxFile);
    data.append('name', payload.name);
    data.append('description', payload.description);
    console.log("Sending payload :: ", payload)
    console.log("To :: ", environment.target + environment.context + `/quest-maproute/${questId}/upload`)
    return this.http.post(
      environment.target + environment.context + `/quest-maproute/${questId}/upload`,
      data,
      {headers: headers, observe: 'response'}
    );
  }

  toggleMapRoutes(questId: number, toggle: boolean) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-maproute/${questId}/toggle/${toggle}`,
      {headers: headers, observe: 'response'}
    );
  }

  getMapRoutes(questId: number): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-maproute/${questId}/all`,
      {headers: headers}
    ).pipe(map((routes: any[]) => {
      let result: QuestMapRoute[] = [];
      routes.forEach(route => {
        let newRoute = {
          id: route.questMapRoute.id,
          questId: route.questMapRoute.questId,
          name: route.questMapRoute.name,
          description: route.questMapRoute.description,
          distance: route.questMapRoute.distance,
          points: (<Array<any>>route.questMapRoutePoints).map(point => {
            return {
              pointId: point.questMapRoutePointId,
              geoPoint: {
                latitude: point.point.coordinates[0],
                longitude: point.point.coordinates[1],
              } as MapLocation,
              segment: point.segment,
              altitude: point.altitude,
              sequence: point.sequence,
            } as QuestMapRoutePoint;
          })
        } as QuestMapRoute;
        result.push(newRoute);
      })
      return result;
    }));
  }

  renameQuest(questId: number, payload: { questName: string }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest/${questId}/rename`,
      payload,
      {headers: headers}
    );
  }

  
  finishRealtimeQuest(realtimeQuestForm: RealtimeQuestForm) : Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + `/quest/realtime/${realtimeQuestForm.questId}?format=base64`,
      realtimeQuestForm,
      {headers: headers}
    );
  }

  getActivities(page, size, search, pillar?) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/search/${pillar || null}/${page ? page : 1}/${size ? size : 25}/${search || null}`,
      {headers: headers}
    );
  }

  getGroupsForQuest(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/group/quest/${questId}`,
      {headers: headers}
    );
  }

  getActivitiesForQuest(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/quest/tasks/${questId}`,
      {headers: headers}
    );
  }

  getVideoForTask(videoUrl) {
    const youtubeId = getYoutubeId(videoUrl);
    let _embeddedVideo = {
      thumbnailUrl: null,
      videoId: null,
      videoUrl: null
    };
    let _videoPayload = null;

    if (youtubeId) {
      _videoPayload = {
        url: `https://www.youtube.com/embed/${youtubeId}`,
        provider: 'YOUTUBE',
        thumbnails: {
          xs: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          sm: `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
          md: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
        }
      };

      _embeddedVideo = {
        thumbnailUrl: _videoPayload.thumbnails.sm,
        videoId: null,
        videoUrl: _videoPayload.url
      };
    }

    return {
      embed: _embeddedVideo,
      payload: _videoPayload
    };
  }

  getAttributesOfActivity(activityId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/attributes/units/${activityId}`,
      {headers: headers}
    );
  }

  logActivity(questId, payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/logactivity/${questId}`,
      payload,
      {headers: headers}
    );
  }

  updateActivity(activityRecordValueId, payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/logactivity/edit/${activityRecordValueId}`,
      payload,
      {headers: headers}
    );
  }

  getLoggedActivities(questId, pageNumber, pageSize) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/logactivity/${questId}/${pageNumber ? pageNumber : 1}/${pageSize ? pageSize : 25}`,
      {headers: headers}
    );
  }

  deleteFeed(activityRecordValueId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/logactivity/delete/${activityRecordValueId}`,
      {headers: headers}
    );
  }

  getRepliesOnComment(commentId: number, pageNumber: number, pageSize: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/post/replies/${commentId}/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  getCommentsOnPost(postId: number, pageNumber: number, pageSize: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/comments/${postId}/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  deleteComment(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/comments/delete/${commentId}`,
      {headers: headers}
    );
  }

  checkQuestCustomColorAndApply(questData: Quest, currentUserId: number, creatorId: number) {
    if (questData.quest.accentColor && questData.quest.themeColor && 
      questData.quest.accentColor !== '' && questData.quest.themeColor !== '') {
      this.accountService.changeRootColor({
        accentColor: questData.quest.accentColor,
        themeColor: questData.quest.themeColor
      })
    } else {
      if (currentUserId === creatorId) {
        this.accountService.checkAccountColorAndApply();
      } else {
        this.accountService.getCustomColor(creatorId)
          .subscribe((res: AccountColor) => {
            if (res && res.accentColor && res.accentColor !== '' && res.themeColor && res.themeColor !== '') {
              this.accountService.changeRootColor(res);
            } else {
              this.accountService.resetAllRootColors();
            }
          })
      }
    }
  }

  getQuestImages(questId: number, doerId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-gallery/${questId}/${doerId}`,
      {headers: headers}
    );
  }
}
