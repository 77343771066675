import { Component, Input } from '@angular/core';
import { QuestTask } from 'src/app/_interface/quest.types';

@Component({
  selector: 'app-dl-new-preview-milestone',
  templateUrl: './dl-new-preview-milestone.component.html',
  styleUrls: ['./dl-new-preview-milestone.component.scss']
})
export class DlNewPreviewMilestoneComponent {
  @Input() milestone: QuestTask;
  @Input() index: number;
  @Input() groupIndex: number;
  showOptions = false;
  constructor() { }

  editTask() {
    this.showOptions = false;
  }

}
