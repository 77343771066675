import {Component, Input, OnInit} from '@angular/core';
import { Quest, QuestLite } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import {QuestService} from 'src/app/_services/quest.service';

@Component({
  selector: 'app-mega-quest-link',
  templateUrl: './mega-quest-link.component.html',
  styleUrls: ['./mega-quest-link.component.scss']
})
export class MegaQuestLinkComponent implements OnInit {

  @Input() megaQuests: QuestLite[];
  @Input() teamData: QuestTeam;
  @Input() quest: Quest;
  @Input() isParentQuest: boolean = false;

  mobile: Boolean = false;

  constructor(private questService: QuestService) {}

  ngOnInit() {
    if (window.screen.width < 750) {
      this.mobile = true;
    }
  }

}
