import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { LOG_ACTIVITY_POST_CHARACTER_LIMIT, QUILL_CONFIG } from 'src/app/app.config';
import { CommonService } from 'src/app/_services/common.service';
import { QuestService } from 'src/app/_services/quest.service';
import { CommentService } from '../../main/quest/quest-comments/comment/comment.service';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app.reducers';
import { QuestNewLoggedActivity, UpdateQuestLoggedActivity } from '../../../_interface/store/quest-detail.actions';
import { LoggedActivity, QuestGalleryImage } from '../../../_interface/quest.types';

@Component({
  selector: 'app-log-activity',
  templateUrl: './log-activity.component.html',
  styleUrls: ['./log-activity.component.scss'],
  providers: [CommentService]
})
export class LogActivityComponent implements OnInit {
  activityForm: UntypedFormGroup;
  eventsSubject: Subject<void> = new Subject<void>();
  allActivities: any = [];

  @Input('questId') questId: number;

  @Input('isEditActivity') isEditActivity: boolean = false;

  @Input('editActivityData') editActivityData: any;

  formSubmitted: boolean = false;

  availableAttributes: Array<any> = [];

  availableUnits: Array<any> = [];

  @ViewChild('imageInputElem', {read: ElementRef, static: true}) imageInputElem: ElementRef;

  selectedImage: File;

  @ViewChild('imageUploadBox', { read: ElementRef }) imageUploadBox: ElementRef;

  showTimeFields: boolean = false;

  showDistanceFields: boolean = false;

  minorValueOptions: string[] = ['.0', '.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9'];

  isLoading: boolean = false;

  inputValueType: string = 'text';

  timeChangeSub: any = null;

  LOG_ACTIVITY_POST_CHARACTER_LIMIT = LOG_ACTIVITY_POST_CHARACTER_LIMIT;

  logCommentLength: number = 0;

  isAttributeCompleted: boolean = false;

  ngxConfig = {...QUILL_CONFIG};

uploadImageSubmit: boolean = false;

doesImageExists: boolean = false;

constructor(private activeModal: NgbActiveModal,
  private formBuilder: UntypedFormBuilder,
  private questService: QuestService,
  private notifier: NotifierService,
  private renderer: Renderer2,
  private store: Store<AppState>) {
  this.activityForm = this.formBuilder.group({
    pillarId: [''],
    activityId: [null, Validators.required],
    attributeId: [''],
    attributeName: [''],
    unitId: [''],
    unitName: [''],
    attributeValue: [''],
    title: [''],
    comment: ['', [Validators.required, Validators.maxLength(this.LOG_ACTIVITY_POST_CHARACTER_LIMIT)]],
    location: [null],
    tags: [''], 
    images: [null],    
    time: formBuilder.group({
      hour: [null],
      minute: [null],
      second: [null]
    }), 
    distanceMajor: ['0.0'],
    distanceMinor: ['.0']
  });
 
} 

ngOnInit() {
  if (this.isEditActivity) {
    this.activityForm.removeControl('pillarId');
    this.activityForm.removeControl('activityId');
    this.activityForm.removeControl('attributeId');
    this.activityForm.removeControl('attributeName');
    this.activityForm.removeControl('unitId');
    this.activityForm.removeControl('unitName');
    this.activityForm.removeControl('attributeValue');
    this.activityForm.removeControl('time');
    this.activityForm.removeControl('distanceMajor');
    this.activityForm.removeControl('distanceMinor');

    this.activityForm.patchValue({
      title: this.editActivityData['title'],
      comment: this.editActivityData['comment'],
      images: this.editActivityData['images']
    });
  } else {
    this.activityForm.controls.unitId.valueChanges.subscribe(unitId => {
      const _unitObj  = this.availableUnits.filter(un => Number(un.id) === Number(unitId));
      this.activityForm.patchValue({
        unitName: _unitObj && _unitObj.length > 0 ? _unitObj[0]['unitNameSingular'] : ''
      });
    });

    this.activityForm.controls.attributeId.valueChanges.subscribe(attrId => {
      const _attrObj  = this.availableAttributes.filter(attr => Number(attr.id) === Number(attrId));
      this.activityForm.patchValue({
        attributeName: _attrObj && _attrObj.length > 0 ?_attrObj[0]['attributeName'] : ''
      });
    })

    this.getActivityOfQuest();
  }
}

textChanged($event) {
  this.logCommentLength = $event.editor.getLength();
  
  if ($event.editor.getLength() > LOG_ACTIVITY_POST_CHARACTER_LIMIT) {
    $event.editor.deleteText(LOG_ACTIVITY_POST_CHARACTER_LIMIT, $event.editor.getLength());
  }
}


closeModal(isSuccess: boolean = false): void {
  this.activeModal.close(isSuccess);
  this.activeModal.dismiss();
}

getActivityOfQuest() {
  this.questService.getActivitiesForQuest(this.questId).subscribe((res: any) => {
    this.allActivities = res;

    if (this.allActivities.length === 1) {
      this.selectedActivity(this.allActivities[0]);
      this.activityForm.patchValue({
        activityId: this.allActivities[0].id
      })
    }
  })
}

selectedActivity(activityEvent) {
  const selectedAct = activityEvent;
  this.activityForm.patchValue({
    pillarId: selectedAct ? selectedAct['pillarId'] : null
  });

  this.getAttributesOfActivity(activityEvent.id);
  this.closedDropdown();
}

getAttributesOfActivity(activityId) {
  this.isAttributeCompleted = false;

  this.activityForm.patchValue({
    attributeId: '',
    attributeName: '',
    unitId: '',
    unitName: '',
    attributeValue: '',
    time: {
      hour: null,
      minute: null,
      second: null
    },
    distanceMajor: '0.0',
    distanceMinor: '.0'
  });

  this.clearAllValidatorsFromTime();
  if (this.timeChangeSub) this.timeChangeSub.unsubscribe();

  this.activityForm.clearValidators();
  this.activityForm.setErrors(null); 
  
  this.activityForm.controls.activityId.setValidators([Validators.required])
  this.activityForm.controls.comment.setValidators([Validators.required])

  this.showDistanceFields = false;
  this.showTimeFields = false;
  this.availableAttributes = [];
  this.availableUnits = [];
  this.activityForm.controls.attributeId.clearValidators();
  this.activityForm.controls.attributeId.setErrors(null);

  this.questService.getAttributesOfActivity(activityId).subscribe(res => {
    this.activityForm.controls.attributeId.clearValidators();
    this.activityForm.controls.attributeId.setErrors(null);

    this.activityForm.controls.unitId.clearValidators();
    this.activityForm.controls.unitId.setErrors(null);

    if (res && res['attributes'] && res['attributes'].length > 0) {
      this.availableAttributes = res['attributes'];
      this.activityForm.controls.attributeId.setValidators([Validators.required]);

      // check if there is only 1 activity then make it preselected
      setTimeout(() => {
        if (this.availableAttributes.length === 1) {
          this.activityForm.patchValue({
            attributeId: this.availableAttributes[0]['id'],
            attributeName: this.availableAttributes[0]['attributeName']
          });

          this.eventsSubject.next();

          this.selectedAttribute(this.availableAttributes[0]);
        }
      }, 100);
    }
  })
}

selectedAttribute(attributeDet) {
  let attribute = attributeDet;
  this.availableUnits = attribute['units'];
  this.activityForm.controls.unitId.clearValidators();

  this.showDistanceFields = false;
  this.showTimeFields = false;
  if ( attribute['attributeName'].toLowerCase() === 'time' || attribute['attributeName'].toLowerCase() === 'duration') {
    this.showTimeFields = true;

    this.setTimeValidators();
  }
  else if (attribute['attributeName'].toLowerCase() === 'distance') {
    this.showDistanceFields = true;
    this.activityForm.controls.distanceMajor.setValidators([Validators.required]);
    this.activityForm.controls.unitId.setValidators([Validators.required]);
  }
  else if (attribute['attributeName'].toLowerCase() === 'complete' || attribute['attributeName'].toLowerCase() === 'completed') {
    // this.availableAttributes = [];
    const yesId = this.availableUnits.filter(unit => unit.abbreviation === 'yes');
    this.activityForm.controls.unitId.patchValue(yesId[0].id);
    this.activityForm.controls.unitName.patchValue(yesId[0].abbreviation);
    this.activityForm.controls.attributeValue.patchValue("1");
    this.isAttributeCompleted = true;
    this.availableUnits = [];
  } else {
    this.activityForm.controls.attributeValue.setValidators([Validators.required]);
  }

  this.inputValueType = ['count', 'reps', 'quantity'].indexOf( attribute['attributeName'].toLowerCase() ) > -1 ? 'number' : 'text';
  
  if (this.availableUnits.length > 0) {
    this.activityForm.controls.unitId.setValidators([Validators.required]);
  }
  
  if (this.availableUnits.length > 0) {
    // check if there is only 1 unit then make it preselected
    setTimeout(() => {
      if (this.availableUnits.length === 1) {
        this.activityForm.patchValue({
          unitId: this.availableUnits[0]['id'],
          unitName: this.availableUnits[0]['abbreviation'],
        });
        
        this.eventsSubject.next();
      }
    }, 100);
  }

  this.closedDropdown();
}

public get getTimeForm(): UntypedFormGroup {
  return this.activityForm.controls.time as UntypedFormGroup;
}


setTimeValidators(): void {
  const timeGroup = this.getTimeForm;
  this.setAllValidatorsToTime();

  // call only when they are set required
  this.timeChangeSub = timeGroup.valueChanges.subscribe(timeVal => {
    this.clearAllValidatorsFromTime();

    if (timeVal.hour > 0) {
      this.setHourValidation(timeGroup);
      if (timeVal.hour > 23) {
        timeGroup.controls.hour.setErrors({max: 23});
      }
    }

    if (timeVal.minute > 0) {
      this.setMinuteValidation(timeGroup);
      if (timeVal.minute > 59) {
        timeGroup.controls.minute.setErrors({max: 59});
      }
    }

    if (timeVal.second > 0) {
      this.setSecondValidation(timeGroup);
      if (timeVal.second > 59) {
        timeGroup.controls.second.setErrors({max: 59});
      }
    }
    
    if (!timeVal.hour && !timeVal.minute && !timeVal.second) {
      this.setAllValidatorsToTime()
    }
  });
}

setAllValidatorsToTime() {
  const timeGroup = this.getTimeForm;
  this.setHourValidation(timeGroup);
  this.setMinuteValidation(timeGroup);
  this.setSecondValidation(timeGroup);
}

setHourValidation(timeGroup) {
  timeGroup.controls.hour.setValidators([Validators.required, Validators.min(1), Validators.max(23)]);
}

setMinuteValidation(timeGroup) {
  timeGroup.controls.minute.setValidators([Validators.required, Validators.min(1), Validators.max(59)]);
}

setSecondValidation(timeGroup) {
  timeGroup.controls.second.setValidators([Validators.required, Validators.min(1), Validators.max(59)]);
}

clearAllValidatorsFromTime() {
  const timeGroup = this.getTimeForm;
  timeGroup.controls.hour.clearValidators();
  timeGroup.controls.hour.setErrors(null);
  timeGroup.controls.minute.clearValidators();
  timeGroup.controls.minute.setErrors(null);
  timeGroup.controls.second.clearValidators();
  timeGroup.controls.second.setErrors(null);
}

submitActivity(): void {
  if (this.activityForm.invalid) return;

  this.isLoading = true;

  if (this.doesImageExists) {
    // upload image and then run add activity
    this.uploadImageSubmit = true;
  } else {
    this.addActivity();
  }
  return;
}

imageUploaded(event) {
  this.uploadImageSubmit = false;
  this.addActivity(event.assetData);
}

addActivity(imageResp: QuestGalleryImage[] = []) {
  let payload = {
    ...this.activityForm.value
  }

  if (imageResp.length > 0) payload.images = imageResp;

  if (!this.isEditActivity) {
    let attributeValue = this.activityForm.value.attributeValue;
    if (this.showTimeFields) {
      const timeVal = this.activityForm.value.time;
      attributeValue = (timeVal.hour || 0)+ ':' + (timeVal.minute || 0) + ':' + (timeVal.second || 0);
    } else if (this.showDistanceFields) {
      attributeValue = this.activityForm.value.distanceMajor;// + '' + this.activityForm.value.distanceMinor;
    }

    payload.attributeValue = attributeValue;
  }

  this.isLoading = true;

  const apiToCall = this.isEditActivity ? this.questService.updateActivity(this.editActivityData['actvityRecordValueId'], payload) : this.questService.logActivity(this.questId, payload);
  apiToCall
    .pipe(
      finalize(() => (this.isLoading = false))
    )
    .subscribe((res: LoggedActivity) => {
      if (res && res.actvityRecordValueId) {
        this.notifier.notify('success', this.isEditActivity ? 'Activity updated successfully.' : 'Activity logged successfully.');
        this.isEditActivity ? this.store.dispatch(new UpdateQuestLoggedActivity(res)) : this.store.dispatch(new QuestNewLoggedActivity(res));

        this.closeModal(true);
      } else {
        this.notifier.notify('error', this.isEditActivity ? 'Activity updating failed.' : 'Activity logging failed.');
      }
    }, err => {
      this.notifier.notify('error', this.isEditActivity ? 'Activity updating failed.' : 'Activity logging failed.');
    });
  }

  fixTimeField(event, fieldName: string): void {
    let value = ('00' + (event.target.value).toString());
    value = value.substring(value.length - 2, value.length);
    const timeGroup = this.getTimeForm;
    timeGroup.get(fieldName).setValue(value);
    event.target.value = value;
  }

  showAbbreviation(): boolean {
    if (!this.editActivityData || (this.editActivityData && !this.editActivityData['attributeName'])) {
      return;
    }

    return this.editActivityData['attributeName'].toLowerCase() !== 'count';
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() { 
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }

  checkImageExist(event) {
    this.doesImageExists = event;
  }
}



