import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Sanitizer, SecurityContext, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { CommonService } from '../../../../_services/common.service';
import { AppState } from '../../../../_store/app.reducers';
import { ProfileService } from '../../../../components/pages/profile/profile.service';
import { UpdateUserInfo } from '../../../../components/pages/profile/store/profile.actions';
import * as fromProfile from '../../../../components/pages/profile/store/profile.reducer';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-position-zoom',
  templateUrl: './image-position-zoom.component.html',
  styleUrls: ['./image-position-zoom.component.scss']
})
export class ImagePositionZoomComponent implements OnInit {
  @Input('acceptFor') acceptFor: string;
  
  @Input('title') title: string;
  
  @Input('userEmail') userEmail: string = '';

  @Input('existingImages') existingImages: string = ''; //must be array of url

  @Input('imageProperties') imageProperties: {centerX: number, centerY: number, zoom: number};

  file: any = null;

  selectedImage: any;

  elbounds = {
    w: 300,// parseInt($bg.width()),
    h: 100 //parseInt($bg.height())
  };
  // 2350 is image width

  bounds = {w: 2350 - this.elbounds.w, h: 1750 - this.elbounds.h};
  
  origin = {x: 0, y: 0};
  
  start = {x: 0, y: 0};
  
  zoomValue = 1;
  
  bgWidth = 100;
  
  bgHeight = 100;
  
  mouseDown: boolean = false;
  
  fitToWindow: boolean = true;
  
  imgCustomWidth: number;
  
  imgCustomHeight: number;

  imageUploading: boolean = false;

  @ViewChild('imageDisplayElem', {read: ElementRef, static: false}) imageDisplayElem: ElementRef;

  // @ViewChild('dragMessageElem', {read: ElementRef, static: false}) dragMessageElem: ElementRef;

  disableSubmitBtn: boolean = false;

  showDragMessage: boolean = false;

  @Output('imageUploadedData') imageUploadedData: EventEmitter<any> = new EventEmitter();

  contentType: any;

  imageDataSaving: boolean = false;

  uploadedImageUrl: string;

  constructor(private commonService: CommonService,
    private modalService: NgbModal,
    private profileService: ProfileService,
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private notifier: NotifierService) { }

  ngOnInit() {
    if (this.existingImages && this.existingImages !== '') {
      this.disableSubmitBtn = true;
      this.file = this.existingImages;
      this.uploadedImageUrl = this.existingImages;

      setTimeout(() => {
        this.imageCallback(this.file);
      }, 50);
    }

    this.showDragMessage = true;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.file = null;
    // this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      let newItem:any = new File([item], item.name.replace(/['"]/g, ""), {type: item.type});
      newItem.progress = 0;
      this.file = newItem;
    }
    
    this.showDragMessage = true;
    this.uploadImageAndSubmit(false, true);

    //   return;

    // this.commonService.readImageAndReturnBase64(this.file, this.imageCallback.bind(this));
  }

  imageCallback(fileData) {
    const previewWidth = this.imageDisplayElem.nativeElement.clientWidth;
    this.elbounds.w = previewWidth;
    this.elbounds.h = this.imageDisplayElem.nativeElement.clientHeight;
    
    this.selectedImage = 'url(\'' + this.sanitizer.sanitize(SecurityContext.URL, fileData) + '\')';
    this.zoomValue = 1;

    let img = new Image();
    img.width = previewWidth;
    img.onload = (e:any) => {
      // console.log('70', e, e.target.width, (e.target.width / 4) - this.elbounds.w);
      // creating element in body
      const divTag = document.createElement('div');
      divTag.setAttribute('id', 'tobe-deleted-cont');
      divTag.setAttribute('style', 'width: ' +previewWidth+'px');
      const imgTag = document.createElement('img');
      imgTag.src = fileData;
      imgTag.setAttribute('id', 'tobe-deleted-img');
      divTag.appendChild(imgTag);
      document.getElementsByTagName('body')[0].appendChild(divTag);
      const ht = (document.getElementById('tobe-deleted-img') as any).height;
      // console.log('102-', ht, previewWidth, (document.getElementById('tobe-deleted-img') as any).width);
      
      this.imgCustomWidth = previewWidth;
      this.imgCustomHeight = ht;
      // setting bounds
      this.bounds.w = 0; //e.target.width - this.elbounds.w;
      // console.log('90', ht, this.elbounds.h);
      this.bounds.h = ht - this.elbounds.h;// e.target.height - this.elbounds.h;
      // console.log('93', this.bounds, this.elbounds);
      (document.getElementById('tobe-deleted-cont') as any).remove();

      if (this.existingImages && this.existingImages !== '') {
        this.readImageProperties();
      }
    }
    img.src = fileData;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
 
  newMouseDown(e) {
    this.disableSubmitBtn = false;
    this.showDragMessage = false;
    this.mouseDown = false;
    this.origin.x = e.touches && e.touches.length > 0 ? e.touches[0].clientX : e.clientX;
    this.origin.y = e.touches && e.touches.length > 0 ? e.touches[0].clientY : e.clientY;
    this.mouseDown = true;
  }

  newMouseUp(e) {
    this.mouseDown = false;
  }

  newMouseMove(e) {
    let inbounds = {x: false, y: false};
    let offset = {
      x: e.touches && e.touches.length > 0 ? (this.start.x - (this.origin.x - e.touches[0].clientX)) : (this.start.x - (this.origin.x - e.clientX)),
      y: e.touches && e.touches.length > 0 ? (this.start.y - (this.origin.y - e.touches[0].clientY)) : (this.start.y - (this.origin.y - e.clientY))
    };
    inbounds.x = offset.x < 0 && (offset.x * -1) < this.bounds.w;
    inbounds.y = offset.y < 0 && (offset.y * -1) < this.bounds.h;

    if (this.mouseDown && inbounds.x) {
      this.start.x = offset.x;
    }

    if (this.mouseDown && inbounds.y) {
      this.start.y = offset.y;
    }

    this.origin.x = e.touches && e.touches.length > 0 ? e.touches[0].clientX : e.clientX;
    this.origin.y = e.touches && e.touches.length > 0 ? e.touches[0].clientY : e.clientY;

    e.stopPropagation();
  }

  zoomIn() {
    this.disableSubmitBtn = false;
    this.zoomValue += 0.1;
    this.bgWidth = 100 * this.zoomValue;
    this.bgHeight = 100 * this.zoomValue;

    //setting bounds
    if (this.zoomValue === 1) {
      // reset here
      this.bounds.w = 0;
      this.bounds.h = this.imgCustomHeight - this.elbounds.h;
    } else {
      this.bounds.w = (this.imgCustomWidth * this.zoomValue) - this.elbounds.w;
      this.bounds.h = (this.imgCustomHeight * this.zoomValue) - this.elbounds.h;
    }

    // this.start.x = (this.start.x + 0.5) * this.zoomValue - 0.5;
    // this.start.y = (this.start.y + 0.5) * this.zoomValue - 0.5;
  }

  zoomOut() {
    if (this.zoomValue === 1) {
      return;
    }
    this.disableSubmitBtn = false;
    this.zoomValue -= 0.1;
    this.bgWidth = 100 * this.zoomValue;
    this.bgHeight = 100 * this.zoomValue;

    //setting bounds
    if (this.zoomValue === 1) {
      // reset here
      this.bounds.w = 0;
      this.bounds.h = this.imgCustomHeight - this.elbounds.h;
      this.start.x = 0;
      this.start.y = 0;
    } else {
      this.bounds.w = (this.imgCustomWidth * this.zoomValue) - this.elbounds.w;
      this.bounds.h = (this.imgCustomHeight * this.zoomValue) - this.elbounds.h;
    }

    // this.start.x = (this.start.x + 0.5) * this.zoomValue - 0.5;
    // this.start.y = (this.start.y + 0.5) * this.zoomValue - 0.5;
  }

  closeModal() {
    this.modalService.dismissAll();    
  }

  fitToWindowChange(ev) {
    if (this.fitToWindow) {
      // console.log('172');
      this.bounds.w = 0;
    }
  }

  uploadImageAndSubmit(runSaveImage = true, loadImgCallBack = false) {
    this.imageUploading = true;
    // 
    if (this.existingImages && this.existingImages !== '') {
      this.saveImage(this.existingImages)
      
      return;
    }

    // upload image and get url
    let formData = new FormData();
    formData.append("file", this.file);
    this.commonService
      .uploadImageAndGetUrl(formData)
      .subscribe(imageResp => {
        // if (imageResp.type == HttpEventType.UploadProgress) {
          // console.log('320', event);
        // }

        if (imageResp.type === HttpEventType.Response) {
          this.imageUploading = false;
          // getting the image url from the body as it returns image and video both.
          this.uploadedImageUrl = imageResp.body.imageURL;

          if (runSaveImage) {
            setTimeout(() => {
              this.saveImage(imageResp.body.imageURL);
            }, 50);
          }

          if (loadImgCallBack) {
            setTimeout(() => {
              this.imageCallback(imageResp.body.imageURL);
            }, 50);
          }
        }
      })
  }

  saveImage(imageUrl) {
    this.imageDataSaving = true;

    const previewWidth = this.imageDisplayElem.nativeElement.clientWidth;
    const previewHeight = this.imageDisplayElem.nativeElement.clientHeight;
    // get the center of image in % according to the custom img height and width
    const imgLeftPos = ((Math.abs(this.start.x) + (previewWidth/2)) / this.imgCustomWidth) * 100;
    const imgTopPos = ((Math.abs(this.start.y) + (previewHeight/2)) / this.imgCustomHeight) * 100;
    // console.log('221', imgLeftPos, imgTopPos, this.zoomValue);
    let payload;

    switch (this.acceptFor) {
      case 'profileCover': // update profile avatar
        payload = {
          email: this.userEmail,
          coverPic: imageUrl,
          contentType: this.contentType,
          centerX: imgLeftPos.toFixed(2),
          centerY: imgTopPos.toFixed(2),
          zoomValue: this.zoomValue.toFixed(2)
        };

        this.profileService.uploadProfilePictures(payload).subscribe(
          (res: { profilePictureURL: string, 
            coverPictureURL: string,
            centerX: number,
            centerY: number,
            zoomValue: number }) => {
              this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
                const userInfo: fromProfile.State = { ...state };
                userInfo.coverPictureURL = res.coverPictureURL;
                userInfo.centerX = res.centerX;
                userInfo.centerY = res.centerY;
                userInfo.zoomValue = res.zoomValue;

                this.store.dispatch(new UpdateUserInfo(userInfo));
              });

              this.imageDataSaving = false;
              this.uploadedImageUrl = '';
              this.modalService.dismissAll();
        }, () => {
          this.notifier.notify('error', 'Issue completing request');
        });

        break;

      case 'questCover':
      case 'teamCover':
      case 'questCoverImage':
        this.imageUploadedData.emit({
          imageUrl: imageUrl,
          centerX: imgLeftPos.toFixed(2),
          centerY: imgTopPos.toFixed(2),
          zoomValue: this.zoomValue.toFixed(2)
        });

        this.imageDataSaving = false;
        this.uploadedImageUrl = '';

        this.modalService.dismissAll();

        break;
      
      case 'profilePhoto':
        payload = {
          email: this.userEmail,
          profPic: imageUrl,
          // contentType: newImage.contentType
          centerX: imgLeftPos.toFixed(2),
          centerY: imgTopPos.toFixed(2),
          zoomValue: this.zoomValue.toFixed(2)
        };

        this.profileService.uploadProfilePictures(payload).subscribe((res: { 
          profilePictureURL: string, 
          coverPictureURL: string,
          profileZoomValue: number,
          profileCenterX: number,
          profileCenterY: number
        }) => {
          this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
            const userInfo: fromProfile.State = { ...state };
            userInfo.profilePictureURL = res.profilePictureURL;
            userInfo.profileCenterX = res.profileCenterX;
            userInfo.profileCenterY = res.profileCenterY;
            userInfo.profileZoomValue = res.profileZoomValue;

            this.store.dispatch(new UpdateUserInfo(userInfo));
          });
          
          this.imageDataSaving = false;
          this.uploadedImageUrl = '';
          this.modalService.dismissAll();
        }, () => {
          this.notifier.notify('error', 'Issue completing request');
        });
        break;
    }
  }

  readImageProperties() {
    if (!this.imageProperties.centerX || !this.imageProperties.centerY || !this.imageProperties.zoom) return;

    const previewWidth = this.imageDisplayElem.nativeElement.clientWidth;
    const previewHeight = this.imageDisplayElem.nativeElement.clientHeight;

    this.zoomValue = this.imageProperties && this.imageProperties.zoom ? this.imageProperties.zoom : 1;
    this.start.x = -(((this.imageProperties.centerX * this.imgCustomWidth) / 100) - (previewWidth / 2));
    this.start.y = -(((this.imageProperties.centerY * this.imgCustomHeight) / 100) - (previewHeight / 2));

    this.bgWidth = 100 * this.zoomValue;
    this.bgHeight = 100 * this.zoomValue;

    //setting bounds
    if (this.zoomValue === 1) {
      // reset here
      this.bounds.w = 0;
      this.bounds.h = this.imgCustomHeight - this.elbounds.h;
    } else {
      this.bounds.w = (this.imgCustomWidth * this.zoomValue) - this.elbounds.w;
      this.bounds.h = (this.imgCustomHeight * this.zoomValue) - this.elbounds.h;
    }
  }

  removeImage() {
    this.disableSubmitBtn = false;
    this.file = null;
    this.start =  {x:0, y:0};
    this.zoomValue = 1;
    this.bgWidth = 100 * this.zoomValue;
    this.bgHeight = 100 * this.zoomValue;
    this.existingImages = '';
  }
}
