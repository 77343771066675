import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReaquestHeadersService } from './requestHeaders.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  reader = new FileReader();
  readerListener: () => void;
  renderer: Renderer2;

  constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    private reaquestHeadersService: ReaquestHeadersService) {
      this.renderer = rendererFactory.createRenderer(null, null);
  }

  uploadImageAndGetUrl(formData: FormData): Observable<any> {
    return this.http.post(
      environment.target + environment.authContext + '/image/upload',
      formData,
      {
        reportProgress: true, observe: "events"
      }
    );
  }

  uploadMultipleImagesAndGetUrl(formData: FormData): Observable<any> {
    return this.http.post(
      environment.target + environment.authContext + '/images/upload',
      formData,
      {
        reportProgress: true, observe: "events"
      }
    );
  }

  readImageAndReturnBase64(file: File, callback: Function) {
    this.readerListener = this.renderer.listen(this.reader, "load", (event) => {
      this.unlistenRenderer();

      callback(event.target.result);
    });

    this.reader.readAsDataURL(file);
  }

  unlistenRenderer(): void {
    this.readerListener();
  }
}
