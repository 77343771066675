import { Location, ViewportScroller } from '@angular/common';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, map, take, tap } from 'rxjs/operators';
import { AuthService } from './components/main/auth/auth.service';
import * as authActions from './components/main/auth/store/auth.actions';
import { SelectFormMode, SetDefaultEmail } from './components/main/auth/store/auth.actions';
import * as fromAuth from './components/main/auth/store/auth.reducer';
import { FormMode } from './components/main/auth/store/auth.reducer';
import * as fromNotifications from './components/main/notifications/store/notifications.reducer';
import { AccountService } from './components/pages/account/account.service';
import { SetAccountColors } from './components/pages/account/store/account.actions';
import { TryGetUserInfo } from './components/pages/profile/store/profile.actions';
import { AccountColor } from './components/pages/account/store/account.reducer';
import { AuthGuardService } from './directives/auth-guard/auth-guard.service';
import { WindowRef } from './_global/window-ref.module';
import * as fromApp from './_store/app.reducers';
import tools from './_tools';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  authState: Observable<fromAuth.State>;
  notificationsInfoState: Observable<fromNotifications.State>;
  hideHeader = true;
  showNotifications = false;
  
  constructor(
    private winRef: WindowRef,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authGuardService: AuthGuardService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private modalConfig: NgbModalConfig,
    private accountService: AccountService,
    private viewport: ViewportScroller
    ) {
  }

  ngOnInit() {
    this.modalConfig.centered = true;
    this.modalConfig.size = 'lg';
    this.modalConfig.scrollable = true;

    this.authState = this.store.select('auth');
    this.authState.pipe(take(1)).subscribe(
      tools.debounce((authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.authService.checkTokenValid(authState);
        }
      }, 0)
    );

    this.store.select('userInfo').pipe(take(3)).subscribe(userInfo => {
      // get the user account colors
      this.authState.pipe(take(1)).subscribe(auth => {
        if (auth.authenticated) {
          this.getUserAccountColors(userInfo.id);
        }
      });
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // $('html').scrollTop(0);
        this.viewport.scrollToPosition([0, 0]);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        document.body.classList.remove('modal-open');
        this.hideHeader = this.route.snapshot.queryParams['_web-view'] === 'true' ? true : (
          this.route.snapshot.firstChild.data[0]
            ? this.route.snapshot.firstChild.data[0].hideHeader
            : false
        );

        setTimeout(() => {
          this.viewport.scrollToPosition([0, 0]);
          // $('html').scrollTop(0);
        }, 10);
      }
    });  

    this.route.queryParams.subscribe((params) => {
      if (params && params.token) {
        const now = new Date();
        const data = this.parseJwt(params.token);
        this.store.dispatch(new authActions.SetToken({token: params.token, tokenUpdateTime: now.getTime()}));
        this.store.dispatch(new authActions.SetExpires(data.exp));
        this.store.dispatch(new authActions.Signin());
        this.store.dispatch(new TryGetUserInfo());
        this.store.dispatch(new authActions.AuthSetLoadingState(false));
      }
    })

    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('active'),
        map(state => state.active),
        tap((isActive) => {
          this.showNotifications = isActive;
        })
      )
      .subscribe();
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  ngAfterViewChecked() {
    if (typeof $ !== 'undefined' && $(this.winRef.nativeWindow).width() < 768) {
      this.ngbTooltipConfig.triggers = 'click';
    }
  }

  setupFormOptions(event: { formMode: string, defaultEmail: string }) {
    this.store.dispatch(new SelectFormMode(FormMode[event ? event.formMode : 'logIn']));
    this.store.dispatch(new SetDefaultEmail(event ? event.defaultEmail : null));
  }

  onCloseModal() {
    this.authGuardService.update();
    this.store.dispatch(new SelectFormMode(null));
    this.store.dispatch(new SetDefaultEmail(null));
  }

  getUserAccountColors(userId: number): void {
    this.accountService.getCustomColor(userId)
      .subscribe((res: AccountColor) => {
        this.store.dispatch(new SetAccountColors(res));
        if (res.accentColor && res.themeColor && 
          res.accentColor !== '' && res.themeColor !== '') {
          this.accountService.changeRootColor(res);
        }
      })
  }
}
