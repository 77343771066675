import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { QuestGalleryImage } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';

@Component({
  selector: 'app-gallery-upload',
  templateUrl: './gallery-upload.component.html',
  styleUrls: ['./gallery-upload.component.scss']
})
export class GalleryUploadComponent implements OnInit {
  @Input() questId?: number;

  @Input() hideCaption?: boolean;

  @Input() selectedArea: string;

  @Output() imageAdded: EventEmitter<any> = new EventEmitter<any>();

  caption: string = '';

  isUploading = false;

  imagePreview: any = '';

  doesImageExists: boolean = false;

  uploadImageSubmit: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private questService: QuestService,
    private notifier: NotifierService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
  
  }

  checkImageExist(event) {
    this.doesImageExists = event;
  }

  closeDialog() {
    // if (window.confirm('Are you sure you want to close?')) {
      this.activeModal.close();
      this.modalService.dismissAll();
    // }
  }

  uploadImage() {
    this.isUploading = true;
    this.uploadImageSubmit = true;
  }

  imageUploaded(event) {
    this.uploadImageSubmit = false;
    this.isUploading = true;
    let payload = {
      questId: this.questId,
      questImages: event.assetData,
      caption: this.caption
    };

    this.questService.addQuestGalleryPhoto(payload).subscribe((newImg: QuestGalleryImage[]) => {
      this.isUploading = false;

      this.imageAdded.emit({
        croppedImage: newImg,
        selectedArea: 'questGalleryPhoto'
      });
      this.finishingUpload();
    }, () => {
      this.isUploading = false;
      this.notifier.notify('error', 'Issue completing request');
    });
  }

  private finishingUpload() {
    this.closeDialog();
    setTimeout(() => {
      this.isUploading = false;
      this.notifier.notify('success', 'Image updated');
    }, 300);
  }
}
