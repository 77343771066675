import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { QuestService } from '../../../_services/quest.service';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { AppState } from 'src/app/_store/app.reducers';
import { QUILL_CONFIG } from '../../../app.config';
import * as Constants from '../../../app.constants';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import { MembersService } from './members.service';
import { QuestDoer, QuestUserInfo } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { QuestLogic } from 'src/app/_interface/quest.logic';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() mode: string;
  @Input() firstName: string;
  @Input() doers: QuestDoer[];
  @Input() doersRequest: QuestDoer[];
  @Input() status: string;
  @Input() userId: number;  // currentUserId
  @Input() questId: number;  // currentUserId
  @Input() allowEditQuest: boolean;
  @Input() showBacking: boolean;
  @Input() isCurrentUserQuestDoer: boolean = false;
  @Input() questTitle: string;
  @Input() teams: QuestTeam[];
  @Input() fromProfilePage: boolean = false;
  
  doersRequestCutOff: QuestDoer[];

  authState: Observable<fromAuth.State>;
  authStateSubscription: Subscription;
  userInfoState: Observable<fromProfile.State>;
  userInfoStateSubscription: Subscription;
  viewListDoers: QuestDoer[] = [];
  viewListDoersCutoff: QuestDoer[] = [];
  fullDoers: QuestDoer[] = [];
  doersCount: number;
  savedCount?: number;
  currentUser: QuestUserInfo;
  viewLimit = 10;
  moreBtnIsVisible = false;
  limit = 15;
  quillConfig = {...QUILL_CONFIG};
  messages = Constants.VALIDATION_MESSAGES;
  messageLimit = 4000;
  dialogInProgress = false;
  canSendBulkMessage = false;
  isLoading = false;
  messageAllForm: UntypedFormGroup;
  messageAllFormSubmitted = false;
  @ViewChild('doerContainer', { read: ElementRef }) doerContainer: ElementRef;
  doerContainerSize = 0;
  maxCountToDisplay = 6;
  sendToOptions: Array<string> = [];

  @ViewChild('membersModal', { read: TemplateRef }) membersModal: TemplateRef<any>;

  membersModalDoers: any[];

  @ViewChild('messageAllModal', { read: TemplateRef }) messageAllModal: TemplateRef<any>;

  modalReference: any = null;

  @Input() isMegaQuest: boolean;

  @Input() moveToPeopleTab: boolean;

  @Output() emitToMoveToPeopleTab: EventEmitter<boolean> = new EventEmitter<false>();

  constructor(
    private questService: QuestService,
    private membersService: MembersService,
    private store: Store<AppState>,
    private notifier: NotifierService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) {
    this.authState = this.store.select('auth');
    this.userInfoState = this.store.select('userInfo');

    this.sendToOptions.push('All');
  }

  ngOnInit() {
    this.getGroupsForMsg();
  }

  ngOnChanges() {
    this.getMembers();

    this.messageAllForm = new UntypedFormGroup({
      'sendTo':  new UntypedFormControl('All'),
      'subject': new UntypedFormControl(null, []),
      'messageToAll': new UntypedFormControl(null, [
        Validators.required,
        Validators.max(this.messageLimit)
      ]),
      'brandedMessage': new UntypedFormControl({value: true, disabled: false}, [])
    });

    this.destroySubscriptions();

    this.userInfoStateSubscription = this.userInfoState.pipe(take(1)).subscribe((currentUser: fromProfile.State) => {
      this.currentUser = currentUser;
      this.canSendBulkMessage = this.mode === 'doers' && this.currentUser.isUserBrand === 'Y' && this.allowEditQuest;
    });
  }

  ngOnDestroy() {
    this.activeModal.close();
    this.destroySubscriptions();
  }
  private destroySubscriptions() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    if (this.userInfoStateSubscription) {
      this.userInfoStateSubscription.unsubscribe();
    }
  }

  private prepareListDoers(doers: QuestDoer[]): void {
    this.maxCountToDisplay = 6;
    if (this.doerContainer && this.doerContainer.nativeElement.offsetWidth && Number(this.doerContainer.nativeElement.offsetWidth)) {
      this.maxCountToDisplay = (Number(this.doerContainer.nativeElement.offsetWidth) / (60 + 17)) < 7 ? 6 : (Number(this.doerContainer.nativeElement.offsetWidth) / (60 + 17));
      this.doerContainerSize = Number(this.doerContainer.nativeElement.offsetWidth);
    }

    this.maxCountToDisplay = Math.floor(this.maxCountToDisplay);
    if (this.mode === 'doers') {
      this.viewListDoers = [...doers].filter((member: QuestDoer) => {
        if (member.memberStatus) {
          return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
        }
        return false;
      });
      this.doersCount = doers.length; //this.viewListDoers.filter(dr => !dr['acceptRequest']).length;
      this.savedCount = this.doersCount < this.doers.length ? this.doers.length - this.doersCount : 0;
      const end = this.viewListDoers.length > this.maxCountToDisplay ? this.maxCountToDisplay : this.viewListDoers.length;
      this.viewListDoersCutoff = this.viewListDoers.slice(0, end);
    } else {
      this.viewListDoers = doers;
      this.doersCount = doers.length; //doers.filter(dr => !dr['acceptRequest']).length;
      const end = this.viewListDoers.length > this.maxCountToDisplay ? this.maxCountToDisplay : this.viewListDoers.length;
      this.viewListDoersCutoff = this.viewListDoers.slice(0, end);
    }
  }
  private prepareDoers(doers: QuestDoer[]): QuestDoer[] {
    if (this.mode === 'doers') {
      doers.forEach((item: QuestDoer, index: number) => {
        item.memberStatus = item.memberStatus.sort((a, b) => {
          return Constants.MEMBER_STATUS_PRIORITY[a] - Constants.MEMBER_STATUS_PRIORITY[b];
        });
        doers[index].percentage = item.totalTasksCount ? item.completeTasksCount / item.totalTasksCount * 100 : 0;
      });
    }
    return doers;
  }

  onChangeViewLimit($event: { viewLimit: number, moreBtnIsVisible: boolean }) {
    this.viewLimit = $event.viewLimit;
    this.moreBtnIsVisible = $event.moreBtnIsVisible;
  }

  openMembersDialog(openModalFor) {
    if (this.moveToPeopleTab) {
      this.emitToMoveToPeopleTab.emit(true);
      return;
    }

    this.isLoading = true;
    this.membersModalDoers = (openModalFor === 'request') ? this.doersRequest : this.doers;
    setTimeout(() => {
      this.modalReference = this.modalService.open(this.membersModal, {
        windowClass: 'no-overflow'
      });
      this.isLoading = false;
    });
  }

  openMessageAllDialog() {
    this.isLoading = true;
    this.messageAllForm.patchValue({
      sendTo: 'All',
      subject: null,
      messageToAll: null,
      brandedMessage: true
    });
    setTimeout(() => {
      this.modalReference = this.modalService.open(this.messageAllModal);
      this.isLoading = false;
    });
  }

  closeDialog() {
    this.activeModal.close();
    if(this.modalReference) this.modalReference.close();
    this.modalReference = null;
  }

  loadMore() {
    this.limit += 15;
  }

  sendMessageForDoers() {
    this.messageAllFormSubmitted = true;

    if (this.dialogInProgress || this.messageAllForm.invalid) {
      return;
    }

    const message = this.messageAllForm.get('messageToAll').value.trim().replace(/<p><br><\/p>/g, '<br>').replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
    if (message.length > this.messageLimit) {
      this.notifier.notify('error', `Maximum message length is ${this.messageLimit}`);
      return
    }
    this.dialogInProgress = true;
    const subject = (this.messageAllForm.get('subject').value || '').trim() || null;
    const plain = !this.messageAllForm.get('brandedMessage').value;
    const sendTo = this.messageAllForm.get('sendTo').value;

    this.membersService.sendMessageAll(this.questId, message, subject, sendTo, plain).subscribe(() => {
      this.dialogInProgress = false;
      this.notifier.notify('success', 'Message sent');
      
      this.closeDialog();
    }, () => {
      this.dialogInProgress = false;
      this.notifier.notify('error', 'Oops, something went wrong, please try again');
    });
  }

  private getMembers() {
    // not calling the api if the quest is start DIEMlife
    if ((!environment.production && this.questId === 115) || (environment.production && this.questId === 222)) return false;
    

    if (!this.userId || !this.questId && this.doers) {
      this.authThenPrepareDoers();
    } else {
      this.doers.length = 0;
      this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
        this.doers = members;

        if (this.currentUser && this.currentUser.id) {
          this.isCurrentUserQuestDoer = QuestLogic.checkIfCurrentUserQuestDoer(this.doers, this.currentUser.id);
        }
        this.authThenPrepareDoers();
      });
    }
  }

  authThenPrepareDoers() {
    this.authStateSubscription = this.authState.pipe(
      map((state: fromAuth.State) => state.authenticated),
      distinctUntilChanged()
    ).subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.membersService.setDoersStatusRelativeToUser(this.doers).subscribe((doers: QuestDoer[]) => {
          this.fullDoers = this.prepareDoers([...doers]);
          this.prepareListDoers([...doers]);
        });
      } else {
        this.fullDoers = this.prepareDoers([...this.doers]);
        this.prepareListDoers([...this.doers]);
      }

      if (this.doersRequest && this.doersRequest.length > 0) {
        this.doersRequestCutOff = this.doersRequest.slice(0, 3);
      }
    });
  }

  makeInt(n) {
    return parseInt(n);
  }
  getDoers() {
    return this.viewListDoers;
  }

  getGroupsForMsg() {
    if (this.questId) {
      this.questService.getGroupsForQuest(this.questId).subscribe((res) => {
        if (res && res['length'] > 0) {
          (res as Array<any>).forEach(element => {
            this.sendToOptions.push(element.group);          
          });
        }
      })
    }
  }
}
