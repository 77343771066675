// tslint:disable-next-line:max-line-length

import { QuestTask } from "./quest.types";

export enum DlMilestoneManageModes {
  QUEST_MANAGE = 'QUEST_MANAGE',
  QUEST_DETAILS = 'QUEST_DETAILS',
  NEW_QUEST = 'NEW_QUEST'
}

export interface UpdateMilestonesGroupNamePayload {
  groupId: number;
  groupName: string;
}
export interface AddLinkPreviewToMilestonePayload {
  taskId: number;
  taskLink: string;
}

export interface AddImageToMilestonePayload {
  taskId: number;
  taskImage: string;
  contentType: string;
}

export interface MoveTaskPayload {
  taskId: number;
  taskOrder: number;
  groupId: number;
}

export interface TaskMovedNotification {
  previousGroupIndex: number;
  currentGroupIndex: number;
  previousIndex: number;
  currentIndex: number;
}

export interface AddVideoUrlToMilestonePayload {
  url: string;
  provider: 'YOUTUBE' | 'VIMEO';
  thumbnails: {
    xs: string;
    sm: string;
    md: string;
  };
}
export interface AddMilestonePayload {
  task: string;
  groupIndex: number;
  video: AddVideoUrlToMilestonePayload;
  linkUrl: string;
  linkedQuestId: number;
  imageUrl: string;
  questOwnerId: number;

  //
  title?: string;
  activities?: any[];
  activitiesIds?: number[];
  addedLink?: string;
  embeddedVideo?: any;
  linkPreview?: any;
  linkedQuest?: any;
  existingId?: string | number;
  required?: boolean; // value for making it task required for completion or not
}

export interface PatchMilestonePayload {
  taskIndex: number;
  groupIndex: number;
  value: Partial<QuestTask>;
}

export interface AddVideoToMilestonePayload {
  id: number;
  task: string;
  video: AddVideoUrlToMilestonePayload;
}

export interface TaskPreviewPayload {
  index: number;
  groupIndex: number;
  isEditMode: boolean;
}

export interface TaskGroup {
  id: number;
  name: string;
  order: number;
  questTasks: QuestTask[];
}
