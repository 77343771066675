export interface LeaderboardScore {
  memberId: number;
  userId?: number;
  avatarUrl?: string;
  place?: number;
  firstName: string;
  lastName: string;
  gender?: string;
  age?: number;
  city?: string;
  state?: string;
  country: string;
  score: number;
  unit: string;
  status: LeaderboardMemberStatus;
  userName?: string;
}

export interface LeaderboardAttribute {
  id?: string;
  name: string;
  unit?: string;
  asc?: boolean;
}

export enum LeaderboardMemberStatus {
  NoInfo = 'NoInfo',
  DidNotStart = 'DidNotStart',
  Started = 'Started',
  DidNotFinish = 'DidNotFinish',
  Finished = 'Finished'
}

export interface MemberStatusItem {
  value: LeaderboardMemberStatus;
  viewText: string;
}

export interface MemberStatusData {
  list: MemberStatusItem[];
  currentStatus: MemberStatusItem;
}

export enum LeaderBoardPodiumPlaceMode {
  TEAM = 'TEAM',
  PROFILE = 'PROFILE'
}
