import {Component, Input} from '@angular/core';
import { LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import {LeaderboardScoreComponent} from '../leaderboard-score.component';

@Component({
  selector: 'app-leaderboard-podium-place',
  templateUrl: './leaderboard-podium-place.component.html',
  styleUrls: ['./leaderboard-podium-place.component.scss']
})
export class LeaderboardPodiumPlaceComponent extends LeaderboardScoreComponent {

  @Input() place: number;

  @Input()
  set member(member: LeaderboardScore) {
    this._member = member;
    this.init();
  }

  get member(): LeaderboardScore {
    return this._member;
  }

  constructor() {
    super();
  }

  isMemberInfoPresent(): boolean {
    return !!this._member
      && !!(this._member.gender || this._member.age || this._member.city || this._member.state || this._member.country);
  }

}
