import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { AppState, authFormMode } from 'src/app/_store/app.reducers';
import { environment } from '../../../../../environments/environment';
import * as config from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import { NewAccountInfo, SignUpService } from '../../auth/sign-up/sign-up.service';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';
import { FormMode } from '../../auth/store/auth.reducer';

import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

interface NewAccountSavedInfo {
  firstName: string;
  lastName: string;
  email: string;
  // country: string;
  // zip: string;
  passwordGroup: {
    password1: string;
    password2: string;
  };
  receiveEmail: boolean;
  recaptcha: string;
}
@Component({
  selector: 'app-small-log-in',
  templateUrl: './small-log-in.component.html',
  styleUrls: ['./small-log-in.component.scss']
})
export class SmallLogInComponent implements OnInit {
  @ViewChild("stepsContainer", {read: ElementRef, static: true}) stepsContainer : ElementRef;
  @ViewChild("loginModalContent", {read: ElementRef, static: true}) loginModalContent : ElementRef;
  messages = Constants.VALIDATION_MESSAGES;
  authSubscribtion: Subscription;
  authState: Observable<fromAuth.State>;
  activeState: string = null;
  signinForm: UntypedFormGroup;
  signinFormSubmitted = false;

  stepNumber: number = 1;
  isUserRegistered: boolean = false; //todo: back to false;
  createUserAs: string = "";
  showPinVerification: boolean = false;
  // pincodeForm: UntypedFormGroup;
  disableNextButton: boolean = true;
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  userSignUpFormValue: any = null;
  brandSignUpFormValue: any = {};
  isUserValidated = false;
  userIsDisabled = false;
  autoCheckEmailForUser: boolean = false;
  passwordType = 'password';

  socialUser: SocialUser;
  userSocialLoginThrough = null;
  askForEmailSocial = false;

  pincodeFormSubmitted: boolean = false;

  constructor(
    private store: Store<AppState>,
    private renderer: Renderer2,
    private signUpService: SignUpService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private socialAuthSer: SocialAuthService,
    private notifier: NotifierService,
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef
    ) {
    this.authState = this.store.select('auth');
  }
  
  ngOnInit() {
    this.socialAuthSer.authState.subscribe(res => {
      if (res && res.provider === this.userSocialLoginThrough) {
        this.socialUser = res;
        this.proceedForSocialLogin();
      } 
    });

    // document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    //   // Handle successful response.
    //   console.log('77 app coponent apple sign-', event);
    // });

    // document.addEventListener('AppleIDSignInOnFailure', (event) => {
    //   // Handle error.
    //   console.log('82 app coponent apple sign fail-', event);
    // });

    this.stepNumber = 1;
    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.signinForm = this.fb.group({
      'email': new UntypedFormControl(null, [
        Validators.required,
        // Validators.email,
        Validators.pattern(emailReg)
      ]),
      'password': new UntypedFormControl(null, [
        // Validators.required,
        // Validators.minLength(config.password.minLength),
        // Validators.maxLength(config.password.maxLength)
      ])
    });

    // this.pincodeForm = this.fb.group({
    //   pincode: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/([0-9])/)])
    // });

    this.store
      .pipe(select(authFormMode))
      .subscribe(formModeResp => {
        if (formModeResp === 'logIn' || formModeResp === 'signUp') {
          this.stepNumber = 1;
          this.goBackStep();
          this.activeState = formModeResp ? fromAuth.FormMode[formModeResp] : 'logIn';
  
          this.checkIfEmailPassValid();
          this.setHeightOfStepContainer();
          setTimeout(() => {
            this.setHeightOfStepContainer();
          }, 500);
        }
      });

    this.authSubscribtion = this.authState.subscribe((state: fromAuth.State) => {
      if (state && (state.formMode === 'logIn' || state.formMode === 'signUp')) {
        if (!this.signinFormSubmitted) {
          if (state.email) this.signinForm.patchValue({email: state.email || null});
        }
      
        this.checkIfEmailPassValid();
        this.setHeightOfStepContainer();
        setTimeout(() => {
          this.setHeightOfStepContainer();
        }, 500);

        if (state.autoCheckEmail) {
          this.autoCheckEmailForUser = true;
          this.processFunctionOfStep();
          this.store.dispatch(new AuthActions.SetAutoCheckEmail(false));
        }

        if (state && state.askForEmailSocial === true) {
          // show the modal to enter the user email
          this.askForEmailSocial = true;
          this.notifier.notify('error', 'Email address required');
        }
      }
    });

    this.signinForm.valueChanges.subscribe(val => {
      this.disableNextButton = true;
      this.checkIfEmailPassValid();
    });

    // this.pincodeForm.valueChanges.subscribe(val => {
    //   this.disableNextButton = true;
    //   // todo change step check from 3 to 4
    //   if (this.pincodeForm.valid && ((this.stepNumber === 4 && this.createUserAs === 'USER') || (this.stepNumber === 6 && this.createUserAs === 'BRAND') || (this.stepNumber === 2 && this.isUserRegistered && !this.isUserValidated))) {
    //     this.disableNextButton = false;
    //   }
    // });
  }

  signInFacebook() {
    this.userSocialLoginThrough = 'FACEBOOK';
    this.socialAuthSer.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  proceedForSocialLogin() {
    if (this.userSocialLoginThrough === 'GOOGLE' || this.userSocialLoginThrough === 'FACEBOOK') {
      this.store.dispatch(new AuthActions.TrySigninSocial({
        id: this.socialUser.id || '',
        name: this.socialUser.name || '',
        email: this.socialUser.email || '',
        firstName: this.socialUser.firstName || '',
        lastName: this.socialUser.lastName || '',
        imageUrl: this.socialUser.photoUrl || '',
        provider: this.userSocialLoginThrough
      }));
    }
  }

  ngOnDestroy() {
    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
    this.socialUser = null;
    this.userSocialLoginThrough = null;
    this.askForEmailSocial = false;
  }

  checkIfEmailPassValid() {
    if ((this.stepNumber === 1 && this.signinForm.controls.email.valid) || (this.stepNumber === 2 && this.signinForm.controls.password.valid)) {
      this.disableNextButton = false;
    }
  }

  processFunctionOfStep() {
    if (this.stepNumber === 1 && this.askForEmailSocial) {
      this.socialUser.email = this.signinForm.value.email;
      this.proceedForSocialLogin();
    }
    else if (this.stepNumber === 1 && !this.showPasswordRecoverCont) {
      // check if email exists or not
      this.showSignUpForm = false;
      this.createUserAs = '';
      this.checkIfEmailExists();
    } 
    else if (this.stepNumber === 1 && this.showPasswordRecoverCont) {
      this.recoverPassword()
    }
    // user is registered. and now password is entered
    else if (this.stepNumber === 2 && this.isUserRegistered && this.isUserValidated) {
      // go for sign in
      this.signInUser();
    } else if (this.stepNumber === 2 && this.isUserRegistered && !this.isUserValidated) {
      // this.sendVerificationPin();
      // this.veriyPinForSignUp();
    } else if (this.stepNumber === 2 && !this.isUserRegistered) {
      // this.signUpAsUser({type: 'user'}); // todo: remove this line when choice is there and uncomment below line
      this.moveToNextStep();
    } else if (this.stepNumber === 3 && (!this.isUserRegistered || (this.isUserRegistered && !this.isUserValidated)) && this.createUserAs === "USER") {
      this.signUpAsUser({type: 'user'});
    } else if (this.stepNumber === 3 && !this.isUserRegistered && this.createUserAs === "BRAND") {
      this.signUpAsUser({type: 'brand'});
      // this.moveToNextStep();
      // this.signUpAsBrand();
    } else if (this.stepNumber === 4 && !this.isUserRegistered && this.createUserAs === "BRAND") {
      this.createBrand();
      // this.moveToNextStep();
    } else if (this.stepNumber === 5 && !this.isUserRegistered && this.createUserAs === "BRAND") {
      // save brand data and show pin verification screen
      // this.moveToNextStep();
      this.saveBrandAddressData();
    } 
  }

  createBrand() {
    let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO'));
    let payload = {
      name: this.brandSignUpFormValue.formValues.businessName,
      user_id: newAccountInfo['userId'],
      url: this.brandSignUpFormValue.formValues.website,
      type: this.brandSignUpFormValue.formValues.orgType,
      role: "SUPERADMIN"
    };

    this.isLoading = true;
    this.signUpService.createBrand(payload).subscribe((value) => {
      newAccountInfo = {...newAccountInfo, brand: value};
      sessionStorage.setItem('NEW_ACCOUNT_CREATED_INFO', JSON.stringify(newAccountInfo));
      this.isLoading = false;

      this.moveToNextStep();
    }, err => {
      this.notifier.notify('error', 'Oops, something went wrong, please try again');
      this.isLoading = false;
    });
  }

  saveBrandAddressData() {
    this.isLoading = true;
    let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO'));
    let payload = {
      user_id: newAccountInfo['userId'],
      companyId: newAccountInfo['brand']['id'],
      addressLine1: this.brandSignUpFormValue.formValues.businessAddLine1,
      addressLine2: this.brandSignUpFormValue.formValues.businessAddLine2,
      city: this.brandSignUpFormValue.formValues.city,
      state: this.brandSignUpFormValue.formValues.state,
      zip: this.brandSignUpFormValue.formValues.zipcode,
      country: this.brandSignUpFormValue.formValues.country,
      phone: this.brandSignUpFormValue.formValues.phone
    };
    this.signUpService.updateAddressForBrand(payload).subscribe((value) => {
      // this.isLoading = false;
      this.sendAccountVerificationEmail(newAccountInfo.userId)

      // this.moveToNextStep();
      this.isLoading = false;
      // } else {
      //   this.sendVerificationPin();
      // }
    });
  }

  checkIfEmailExists() {
    if (!this.signinForm.valid) return false;
    this.isUserValidated = false;
    this.userIsDisabled = false;

    this.isUserValidated = false;
    this.isLoading = true;

    this.signUpService.checkEmail(this.signinForm.value.email.trim()).subscribe(res => {
      if (res && res['exists'] === true) {
        this.isUserRegistered = true;
        if (res['active'] === true) {
          if (res['validated'] === true) {
            // email is validated
            // email exists show password
            this.isUserValidated = true;
            this.signinForm.controls.password.setValidators([Validators.required,
              Validators.minLength(config.password.minLength),
              Validators.maxLength(config.password.maxLength)]);
              this.moveToNextStep();
          } else {
            // email is not valid
            this.isUserValidated = false;
            // show verification pin modal.
            this.moveToNextStep();
            this.sendAccountVerificationEmail(res['userId']);
            
            this.userSignUpFormValue = {
              formValues: {
                email: this.signinForm.value.email
              }
            };
            // this.sendVerificationPin();
            this.disableNextButton = true;
          }
        } else {
          // user is disabled
          this.userIsDisabled = true;
          this.setHeightOfStepContainer();
        }
      } else {
        // email does not exists, show create which type user 
        this.isUserRegistered = false;
        this.createUserAs = '';
        this.moveToNextStep();
      }
      this.isLoading = false;
    });
  }

  showSignUpForm: boolean = false;
  moveToNextStep() {
    this.showSignUpForm = false;

    this.renderer.setStyle(this.stepsContainer.nativeElement, 'width', (1 * 100) + '%');
    if (!this.isUserRegistered && (this.createUserAs === 'USER' || (this.createUserAs === 'BRAND' && this.stepNumber < 6))) {
      this.showSignUpForm = true;
    }

    this.stepNumber += 1;

    this.disableNextButton = true;
    if (this.stepNumber === 2 && this.createUserAs !== '') {
      this.disableNextButton = false;
    }
        
    if (this.isUserRegistered) this.checkIfEmailPassValid();
    
    this.setHeightOfStepContainer();
    setTimeout(() => {
      this.setHeightOfStepContainer();
    }, 500);

    this.cdr.detectChanges();
    if (this.autoCheckEmailForUser && this.stepNumber === 2 && !this.isUserRegistered) {
      this.createUserAs = 'USER';
  
      this.processFunctionOfStep()
    }
  }

  signInUser() {
    if (this.signinForm.invalid) return false;

    this.signinFormSubmitted = true;
    const email = this.signinForm.value.email.trim();
    this.isLoading = true;
    const password = this.signinForm.value.password;
    if (this.signinForm.valid) {
      this.store.dispatch(new AuthActions.TrySignin({
        email: email,
        password: password
      }));
      this.isLoading = false;
    }
  }

  cancelStep() {
    if (this.isUserRegistered || (!this.isUserRegistered && this.stepNumber === 3)) {
      // this.modalService.close('auth-modal');
      this.activeModal.close()
    }
  }

  goBackStep(showPasswordRecover?: boolean) {
    const email = this.signinForm.value.email;
    this.stepNumber = ((this.stepNumber - 1) === 0) ? 1 : (this.stepNumber - 1);
    if(this.stepNumber === 1) {
      this.signinForm.controls.password.clearValidators();
      this.signinForm.reset();
      this.showSignUpForm = false;
      this.createUserAs = '';
      this.showPasswordRecoverCont = showPasswordRecover ? showPasswordRecover : false;
      this.signinForm.controls.email.setValue(email);
    }
    // this.renderer.setStyle(this.stepsContainer.nativeElement, 'margin-left', '-' + ((this.stepNumber - 1) * 100) + '%');
    this.setHeightOfStepContainer();
    setTimeout(() => {
      this.setHeightOfStepContainer();
    }, 500);
    this.checkIfEmailPassValid();
  }

  setHeightOfStepContainer() {
    setTimeout(() => {
      if (this.loginModalContent.nativeElement.getElementsByClassName("steps-container") && this.loginModalContent.nativeElement.getElementsByClassName("steps-container")[0] &&
      this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content") &&
        this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content")[0]) {
          this.renderer.setStyle(this.loginModalContent.nativeElement.getElementsByClassName("steps-container")[0], 'height', 
            (this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content")[0].clientHeight + this.loginModalContent.nativeElement.querySelectorAll('.section-title')[0].clientHeight + 40) + 'px'
          )
      }
    }, 100);
  }

  createNewAccountFor(type) {
    this.createUserAs = type;
    this.disableNextButton = false;
    if (this.createUserAs === 'USER' || this.createUserAs === 'BRAND') {
      this.showSignUpForm = true;

      this.processFunctionOfStep();
    }
  }

  userSignUpFormUpdate( event ) {
    this.formSubmitted = false;
    this.disableNextButton = true;
    this.userSignUpFormValue = event;
    if (event.isFormValid && event.isFormValid === true) {
      this.showPinVerification = true;
      this.disableNextButton = false;
    }
  }

  brandSignUpFormUpdate( event ) {
    this.disableNextButton = true;
    let brandDetailsVal = this.brandSignUpFormValue.formValues;

    this.brandSignUpFormValue = {
      formValues: {...brandDetailsVal, ...event.formValues}
    };
    sessionStorage.setItem('NEW_BRAND_INFO', JSON.stringify(this.brandSignUpFormValue));
    
    this.brandSignUpFormValue[event.type] = event.isFormValid;
    if (event.type === 'basic' && event.isFormValid && event.isFormValid === true) {
      this.disableNextButton = false;
    } else if (event.type === 'advanced' && event.isFormValid && event.isFormValid === true) {
      this.showPinVerification = true;
      this.disableNextButton = false;
    }
  }

  signUpAsUser (typeObj) {
    this.formSubmitted = true;

    if (!this.userSignUpFormValue.isFormValid || this.isLoading) {
      return false;
    }

    if (!this.userSignUpFormValue.formValues.recaptcha) {
      return false;
    }

    this.isLoading = true;
    const payload: NewAccountInfo = {
      ...this.userSignUpFormValue.formValues,
      ...typeObj,
      withPin: true
    };

    sessionStorage.setItem('NEW_ACCOUNT_INFO', JSON.stringify(this.userSignUpFormValue.formValues));
    
    this.signUpService.createAccount(payload)
      .pipe(finalize(() => {
        this.formSubmitted = false;
      }))
      .subscribe((value: any) => {
        sessionStorage.setItem('NEW_ACCOUNT_CREATED_INFO', JSON.stringify(value));
        if (typeObj && typeObj.type === 'user') {
          this.sendAccountVerificationEmail(value.userId);

          return;
        }

        if (typeObj && typeObj.type === 'brand') {
          this.isLoading = false;
          
          this.moveToNextStep();
        }
      });
  }

  signUpAsBrand() {
    this.signUpAsUser({type: 'brand'});
  }
  
  showPasswordRecoverCont = false;
  showPasswordRecoveryForm() {
    this.showPasswordRecoverCont = true;
    this.stepNumber = 1;
    this.goBackStep(true);
  }

  recoverPassword() {
    const email = this.signinForm.value.email;
    if (email === '' || email === null || !email) {
      this.notifier.notify('error', 'Enter your email');

      return false;
    }

    this.isUserValidated = false;
    this.userIsDisabled = false;
    this.isLoading = true;

    this.signUpService.checkEmail(this.signinForm.value.email).subscribe(res => {
      if (res && res['exists'] === true) {
        if (res['active'] === true) {
          this.store.dispatch(new AuthActions.SelectFormMode(FormMode.recoveryPassword));

          this.store.dispatch(new AuthActions.TryRecoveryPass({
            email: email
          }));
        } else {
          // user is disabled
          this.userIsDisabled = true;
          this.setHeightOfStepContainer();
        }
      } else {
        this.notifier.notify('error', 'Account not found');
      }

      this.isLoading = false;
    });    
  }

  sendAccountVerificationEmail(userId?: number) {
    if (!userId) {
      let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO')); 
      userId = newAccountInfo.userId;
    }

    this.signUpService.sendVerificationEmail(userId)
      .subscribe(res => {
        if ((this.createUserAs === 'USER' && this.stepNumber === 3) || (this.createUserAs === 'BRAND' && this.stepNumber === 5)) {
          this.moveToNextStep();
        }
        
        this.notifier.notify('success', 'We have sent you an email to activate your account and set a password.')

        // this.activeModal.close();
      }, err => {

      })
  }


  googleLogin() {
    window.location.href = environment.root + '/api/v2/login/oauth2/google?redirect_uri=' + environment.root + this.router.url; //http://localhost:4200
    return;
  }

  appleLogin() {
    window.location.href = environment.root + "/api/v2/login/apple?redirect_uri=" + environment.root + this.router.url;
  }

  showHidePassword() {
    this.passwordType = (this.passwordType === 'password') ? 'text' : 'password';
  }

}
