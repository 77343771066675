import {Component, Input, OnChanges, Output, EventEmitter, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {QuestService} from '../../../../_services/quest.service';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import {QuestDropdownContext} from '../quest-dropdown/quest-dropdown.component';
import { QuestPreparedPhoto } from '../quest.type';
import { AppState } from 'src/app/_store/app.reducers';
import { Quest, QuestInfo, QuestUserInfo } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';

@Component({
  selector: 'app-quest-header',
  templateUrl: './quest-header.component.html',
  styleUrls: ['./quest-header.component.scss']
})
export class QuestHeaderComponent implements OnChanges {
  @Input() questData: Quest;
  @Input() teamData?: QuestTeam;
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() showStartTrigger?: string;
  @Input() isCurrentUserAdminOrOwner: boolean;
  @Input() allowEditCoverImage: boolean;
  @Input() isQuestInMultiTeamMode: boolean;
  @Output() changeCoverImage: EventEmitter<void> = new EventEmitter<void>();

  @Input('isViewShort') isViewShort: boolean = false;

  profileState: Observable<fromProfile.State>;
  questInfo: QuestInfo;
  questUserInfo: QuestUserInfo;
  photo: QuestPreparedPhoto;
  dropdownContext: QuestDropdownContext;

  questImageStyle: any = {};

  @ViewChild('headerPhotoElem', {read: ElementRef, static: true}) headerPhotoElem: ElementRef;

  photoToBeUsed: string;

  allowEdit: boolean = false;

  currentUser: any;

  constructor(
    private questService: QuestService,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {
    this.profileState = this.store.select('userInfo');
  }

  ngOnChanges() {
    this.questInfo = this.questData.quest;
    this.questUserInfo = this.questData.user;
    this.photo = this.questService.prepareCoverPhoto(this.questInfo.photo, this.questInfo.pillar);
    this.profileState.pipe(take(1)).subscribe((currentUser: fromProfile.State) => {
      this.currentUser = currentUser;
      this.dropdownContext = {
        id: this.questInfo.id,
        title: this.questInfo.title,
        mode: this.questData.quest.mode,
        shareUrl: this.questData.questShareUrl,
        copyable: false, //this.questInfo.copyAllowed,
        followed: this.questData.userHasFollowed,
        saved: this.questData.userHasSaved,
        active: QuestService.hasUserActivity(this.questData.activityInfo, currentUser.id),
        editable: QuestService.isEditableByUser(this.questInfo, currentUser),
        privacyLevel: this.questData.quest.privacyLevel
      };
    });

    let positionData: any = this.questInfo;
    if (this.fundraisingLink && this.fundraisingLink.coverImageUrl && ((this.teamData && !this.teamData.defaultTeam) || (this.fundraisingLink.creator.id !== this.fundraisingLink.doer.id))) {
      this.photoToBeUsed = this.fundraisingLink.coverImageUrl;
      positionData = this.fundraisingLink;
      
      this.allowEdit = this.currentUser && (this.fundraisingLink.doer.id === this.currentUser.id);
    }
    else if (this.teamData && this.teamData.teamCoverUrl && !this.teamData.defaultTeam) {
      positionData = this.teamData;
      this.photoToBeUsed = this.teamData.teamCoverUrl;
    }
    else {
      this.photoToBeUsed = this.photo && this.photo.jpg ? this.photo.jpg : '';
    }

    if (this.photoToBeUsed && this.photoToBeUsed !== '') {
      this.questImageStyle = {
        'background': `url('${this.photoToBeUsed}') 0 0 / cover no-repeat`,
        width: '100%',
        height: '100%'
      }
      // console.log('90', this.questImageStyle)
      this.positionCoverImage(positionData);
    }
  }

  onChangeCoverImage() {
    this.changeCoverImage.emit();
  }

  positionCoverImage(positionData) {
    if (!positionData.coverCenterX || !positionData.coverCenterY || !positionData.coverZoomValue) return;

    // getting custom width and height of img
    const divTag = document.createElement('div');
    divTag.setAttribute('id', 'tobe-deleted-cont');
    const imgTag = document.createElement('img');
    imgTag.onload = (() => {
      imgTag.setAttribute('id', 'tobe-deleted-img');
      imgTag.setAttribute('style', 'width: 100%');
      divTag.appendChild(imgTag);
      this.renderer.appendChild(this.headerPhotoElem.nativeElement, divTag);
 
      const ht = (document.getElementById('tobe-deleted-img') as any).height;
      const wd = (document.getElementById('tobe-deleted-img') as any).width;
      
      (document.getElementById('tobe-deleted-cont') as any).remove();

      // calculating position of img
      const coverImagePositionX = Math.abs(( (positionData.coverCenterX * wd)/100 ) - (this.headerPhotoElem.nativeElement.clientWidth / 2));
      const coverImagePositionY = Math.abs(( (positionData.coverCenterY * ht)/100 ) - (this.headerPhotoElem.nativeElement.clientHeight / 2));
      this.questImageStyle = {
        'background': `url('${this.photoToBeUsed}') -${coverImagePositionX}px -${coverImagePositionY}px / cover no-repeat`,
        'width': `${positionData.coverZoomValue * 100}%`,
        'height': `${positionData.coverZoomValue * 100}%`
      };
    })
    imgTag.src = (this.photoToBeUsed as any);
  }
}
