import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { PAGE_SIZE_TABLE } from 'src/app/app.constants';
import { QuestDoer } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { PeopleLeaderboardService } from './people-leaderboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MobileSideNavService } from '../../../modules/shared-module/components/mobile-side-nav/mobile-side-nav.service';
import { environment } from '../../../../environments/environment';

interface MemberWithScore {
  userName: string;
  imageURL?: string;
  activityName?: string;
  score?: number | string;
  userFirstName: string;
  userLastName: string;
}

@Component({
  selector: 'app-people-leaderboard',
  templateUrl: './people-leaderboard.component.html',
  styleUrls: ['./people-leaderboard.component.scss']
})
export class PeopleLeaderboardComponent implements OnInit, OnChanges {
  @Input('teams') teams: any[];

  @Input('showTeams') showTeams: boolean;

  @Input('isMegaQuest') isMegaQuest: boolean;

  @Input('showParentTeams') showParentTeams: boolean;

  @Input('parentTeams') parentTeams: any[];

  @Input('allMembers') allMembers: any[];

  @Input('selectedTab') selectedTab: string;

  @Input('isTeamPage') isTeamPage: boolean;

  showAmountBackedColumnMembers: boolean;

  showAmountBackedColumnTeams: boolean;

  showAmountBackedColumnParentTeams: boolean;

  questId: number;

  userId: number;

  membersWithScores: MemberWithScore[];

  pageSize: number = PAGE_SIZE_TABLE;

  pageNumber: number = 1;

  isFilterActivityOpen: boolean = false;

  getMemberScorePayload: {
    "activityIds": number[];
    "userFirstName": string;
    "sortedByUserName": boolean;
    "sortedByactivityName": boolean;
    "byTime": boolean,
    "byCount": boolean,
    "byActivity": boolean,
    "byDistance":  boolean,
    "byVolume": boolean,
    "byReps": boolean,
    "byComplete": boolean
  } = {
    "activityIds": [],
    "userFirstName": null,
    "sortedByUserName": false,
    "sortedByactivityName": false,
    "byTime":false,
    "byCount":false,
    "byActivity":false,
    "byDistance":false,
    "byVolume":false,
    "byReps":false,
    "byComplete":false
  };

  allActivityList: any[];

  filteredActivityList: any[];

  @ViewChild('activityOptionsBox', {read: ElementRef, static: false}) activityOptionsBox: ElementRef;

  hasActivitySortingApplied: boolean = false;
  
  hasNameSortingApplied: boolean = false;

  membersScoreApiLoaded: boolean = false;

  pageTabs: any = {
    selected: 'teams',
    items: [
      {
        title: 'Teams',
        value: 'teams',
        selected: true,
        show: true
      },{
        title: 'Members',
        value: 'members',
        selected: false,
        show: true
      }
    ]
  };

  searchForm: FormGroup;

  isSearchOpened: boolean = false;

  @ViewChild('searchField', {read: ElementRef, static: false}) searchField: ElementRef;

  @ViewChild('searchIcon', {read: ElementRef, static: false}) searchIcon: ElementRef;

  filteredTeams: any[];

  filteredMembers: MemberWithScore[];

  @ViewChild('peopleTabsElem', {read: ElementRef, static: true}) peopleTabsElem: ElementRef;

  filterActivityForm: FormGroup;

  @ViewChild('filterActivityTemp', {read: ElementRef, static: false}) filterActivityTemp: ElementRef;

  teamMembersArr: any[] = [];

  constructor(private leaderboardService: PeopleLeaderboardService,
    private route: ActivatedRoute,
    private questService: QuestService,
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private mobileSideNavService: MobileSideNavService) { }
  
  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.activityOptionsBox && !this.activityOptionsBox.nativeElement.contains(event.target)) {
      this.isFilterActivityOpen = false;
    }

    if (this.isSearchOpened && 
      !this.searchField.nativeElement.contains(event.target) && 
      !this.searchIcon.nativeElement.contains(event.target) && 
      (this.searchForm.value.search === '' || this.searchForm.value.search === null)) {
      this.isSearchOpened = false;
    }
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);
      this.getActivityOfQuest();
      
      if (this.isTeamPage) {
        this.getScoresWithTeamMembers();
      }else {
        this.getScoresWithMembers();
      }
    });

    this.searchForm = this.formBuilder.group({
      search: ['']
    });

    this.filterActivityForm = this.formBuilder.group({
      activity: [],
      filterKey: ['']
    })

    this.searchForm.controls.search.valueChanges.subscribe(res => {
      if (res && res.trim() !== '') {
        const _teams = [...this.teams];
        this.filteredTeams = _teams.filter(team => {
          let teamMember = [];
          if (team.allMembers && team.allMembers.length > 0) {
            teamMember = team.allMembers.filter(mem => mem.userFirstName.toLowerCase().indexOf(res.toLowerCase()) > -1 || mem.userLastName.toLowerCase().indexOf(res.toLowerCase()) > -1);
          }

          if (teamMember.length > 0) {
            team.allMembers = [...teamMember];
            team.showMembersAccordion = true;

            return team;
          }

          return team.teamName.toLowerCase().indexOf(res.toLowerCase()) > -1;
        });

        this.filteredMembers = this.membersWithScores.filter(member => member.userFirstName.toLowerCase().indexOf(res.toLowerCase()) > -1 || member.userLastName.toLowerCase().indexOf(res.toLowerCase()) > -1);
      } else {
        this.filteredMembers = this.membersWithScores;
        this.filteredTeams = this.teams;
        this.teamMembersArr.forEach((mem, i) => {
          this.filteredTeams[i].allMembers = mem;
        })
      }
    });

    this.filterActivityForm.controls.filterKey.valueChanges.subscribe(res => {
      if (res && res.trim() !== '') {
        this.filteredActivityList = this.allActivityList.filter(activity => activity.name.toLowerCase().indexOf(res.toLowerCase()) > -1);
      } else {
        this.filteredActivityList = this.allActivityList;
      }
    });
  }

  ngOnChanges() {
    this.pageTabs.selected = this.selectedTab;
    if (this.selectedTab === 'members') {
      this.pageTabs.items.forEach(tab => {
        (tab.value === 'members') ? (tab.selected = true) : (tab.selected = false);
      });
    }

    if (this.allMembers.length > 0) {
      this.showAmountBackedColumnMembers = this.allMembers.filter(member => member.amountBacked && member.amountBacked > 0).length > 0;
    }

    if (this.teams.length > 0) {
      this.filteredTeams = this.teams;

      this.showAmountBackedColumnTeams = this.teams.filter(team => team.amountBacked && team.amountBacked > 0).length > 0;
    }

    if (this.parentTeams.length > 0) {
      this.showAmountBackedColumnParentTeams = this.parentTeams.filter(team => team.amountBacked && team.amountBacked > 0).length > 0;
    }

    if (!this.showTeams) {
      this.pageTabs.items.filter(tab => tab.value === 'teams')[0].show = false;
      this.pageTabs.items.filter(tab => tab.value === 'members')[0].selected = true;
      this.pageTabs.selected = 'members';
    }
  }

  getActivityOfQuest() {
    this.questService.getActivitiesForQuest(this.questId).subscribe((res: any) => {
      this.allActivityList = res;
      this.filteredActivityList = res;
    })
  }

  getScoresWithMembers() {
    this.leaderboardService.getScoresWithMembers(this.questId, this.userId, this.pageNumber, this.pageSize, this.getMemberScorePayload).subscribe((res: MemberWithScore[]) => {
      this.membersWithScores = res;
      this.membersScoreApiLoaded = true;
      this.filteredMembers = this.membersWithScores;
    });
  }

  getScoresWithTeamMembers() {
    this.leaderboardService.getScoresWithTeamMembers(this.questId, this.userId).subscribe((res: MemberWithScore[]) => {
      this.membersWithScores = res;
      this.membersScoreApiLoaded = true;
      this.filteredMembers = this.membersWithScores;
    });
  }

  sortBy(sortingBy: string): void {
    // this.resetScorePayload();
    this.pageNumber = 1;
    if (sortingBy === 'name') {
      this.hasActivitySortingApplied = false;
      this.hasNameSortingApplied = true;
      this.getMemberScorePayload.sortedByactivityName = false;
      this.getMemberScorePayload.sortedByUserName = !this.getMemberScorePayload.sortedByUserName;
    } else if (sortingBy === 'activity') {
      this.hasActivitySortingApplied = true;
      this.hasNameSortingApplied = false;
      this.getMemberScorePayload.sortedByUserName = false;
      this.getMemberScorePayload.sortedByactivityName = !this.getMemberScorePayload.sortedByactivityName;
    }

    this.getScoresWithMembers();
  }

  resetScorePayload(): void {
    this.pageNumber = 1;

    this.getMemberScorePayload = {
      "activityIds": [],
      "userFirstName": null,
      "sortedByUserName": false,
      "sortedByactivityName": false,
      "byTime":false,
      "byCount":false,
      "byActivity":false,
      "byDistance":false,
      "byVolume":false,
      "byReps":false,
      "byComplete":false
    };
  }

  filterBy(filterOf: string, filterItem: number | string) {
    // this.resetScorePayload();

    if (filterOf === 'activity') {
      // let existingArr: number[] = this.getMemberScorePayload.activityIds;
      // const existingIndex = existingArr.findIndex(itm => itm === filterItem);
      // if (existingIndex > -1) {
      //   existingArr.splice(existingIndex, 1);
      // } else {
      //   existingArr.push(filterItem as number);
      // }
      if (this.getMemberScorePayload.activityIds.indexOf(filterItem as number) > -1) {
        this.getMemberScorePayload.activityIds.splice(this.getMemberScorePayload.activityIds.indexOf(filterItem as number), 1);
      } else {
        this.getMemberScorePayload.activityIds.push(filterItem as number); // = [filterItem as number];
      }
      // this.isFilterActivityOpen = false;
    }

    this.getScoresWithMembers();
  }

  /* isActivitySelected(activityId: number) {
    return (this.getMemberScorePayload.activityIds as []).findIndex(act => act === activityId) > -1;
  } */

  clearFilters() {
    this.filterActivityForm.reset();
    this.resetScorePayload();
    this.getScoresWithMembers();
  }

  resetFilter(event) {
    if (event) {
      this.clearFilters();
      this.mobileSideNavService.closeSideNav();
    }
  }

  applyFilter($event) {
    if ($event) {
      this.mobileSideNavService.closeSideNav();
    }
  }

  getScoresFormatted(memberActivity: any) {
    let value = memberActivity && memberActivity.value ? memberActivity.value : null;
    if (value && value.indexOf(':') > -1) {
      let _timeVal: any[] = value.split(':');
      _timeVal = _timeVal.map(strNum => Number(strNum));
      let finalValue = '';
      
      _timeVal.length === 3 ? 
        (finalValue = (_timeVal[0] > 0 ? (_timeVal[0] + 'h ') : '')  + (_timeVal[1] > 0 ? (_timeVal[1] + 'm ') : '') + (_timeVal[2] > 0 ? (_timeVal[2] + 's') : '')) 
        : 
        (_timeVal.length === 2 ? (finalValue = (_timeVal[0] > 0 ? (_timeVal[0] + 'h ') : '')  + (_timeVal[1] > 0 ? (_timeVal[1] + 'm ') : '')) : null);

      return finalValue;
    }

    if (value && value !== '') {
      const unitDisplay = ["cnt"].indexOf(memberActivity.unit) > -1 ? '' : (memberActivity.unit || '');
      return value + ' ' + unitDisplay;
    }

    return '';
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openModal(elemt) {
    this.ngbModal.open(elemt, {
      windowClass: 'leaderboard-info-modal'
    });
  }

  toggleTeamMembers(teamItem, index) {
    if (teamItem.showMembersAccordion) {
      teamItem.showMembersAccordion = false;
      
      return;
    }

    if (teamItem.allMembers && teamItem.allMembers.length > 0 && teamItem.allMembersLoaded) {
      teamItem.showMembersAccordion = true;
      
      return;
    }

    this.loadAndDisplayMembers(teamItem, index);
  }

  loadAndDisplayMembers(teamItem, ind) {
    const pageSize = 20;
    const pageNumber = teamItem.pageLoadedNumber ? (teamItem.pageLoadedNumber + 1) : 1;

    // not calling the api if the quest is start DIEMlife
    if ((!environment.production && this.questId === 115) || (environment.production && this.questId === 222)) return false;

    const defaultPayload = {
      "activityIds": [],
      "userFirstName": null,
      "sortedByUserName": false,
      "sortedByactivityName": false,
      "byTime":false,
      "byCount":false,
      "byActivity":false,
      "byDistance":false,
      "byVolume":false,
      "byReps":false,
      "byComplete":false
    };

    this.leaderboardService.getScoresWithTeamMembers(teamItem.questId, 
      teamItem.creatorId).subscribe((res: MemberWithScore[]) => {

      teamItem.showMembersAccordion = true;
      teamItem.allMembersLoaded = res.length < pageSize;
      teamItem.pageLoadedNumber = pageNumber;
      const oldAllMembers = teamItem.allMembers && teamItem.allMembers.length > 0 ? teamItem.allMembers : [];
      teamItem.allMembers = [...oldAllMembers, ...res];

      const allTeamMembers = this.teamMembersArr[ind] && this.teamMembersArr[ind].length > 0 ? this.teamMembersArr[ind] : [];
      this.teamMembersArr[ind] = [...allTeamMembers, ...res];
    });
  }

  changeTabs(item: any) {
    this.pageTabs.items.forEach(tab => {
      tab.selected = false;
      if (tab.value === item.value) {
        tab.selected = true;
      }
    })
    this.pageTabs.selected = item.value;
    
    this.searchForm.reset();
    this.isSearchOpened = false;

    window.scrollTo(0, this.peopleTabsElem.nativeElement.offsetTop - 100);
  }

  onSearchFocusIn() {
    this.isSearchOpened = true;
    this.searchForm.reset();
    this.searchField.nativeElement.focus();
  }

  onSearchClear() {
    this.searchForm.reset();
    this.isSearchOpened = false;
    this.filteredTeams = this.teams;
    this.filteredMembers = this.membersWithScores;
  }

  toggleFilterActivity() {
    if (window.innerWidth > 570) {
      this.isFilterActivityOpen = !this.isFilterActivityOpen;

      return;
    }

    // for mobile
    this.mobileSideNavService.openSideNav(this.filterActivityTemp, 'filter');
  }
}
