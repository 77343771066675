export enum StripeErrorCodesEnum {
  card_declined = "Card declined",
  balance_insufficient = "Card declined",
  incorrect_cvc = "Incorrect CVC",
  invalid_cvc = "Incorrect CVC",
  bank_account_unverified = "Verify your bank account",
  bank_account_exists = "This account already exists",
  country_unsupported = "Country not supported",
  coupon_expired = "Coupon expired",
  expired_card = "Card expired",
  incorrect_zip = "Verify details",
  invalid_expiry_month = "Verify details",
  invalid_expiry_year = "Verify details",
  invalid_number = "Verify details",
  order_creation_failed = "Order failed",
  postal_code_invalid = "Verify details",
  sku_inactive = "Product is inactive"
}
