import * as TicketFlowActions from './ticket-flow.actions';
import { Participant, QuestEvent, QuestTicketVariantPrapared, SkusItem, TeamInformation } from '../../../ticket-flow.typings';
import { QuestTeam } from 'src/app/_interface/team.types';
import { Breakdown } from '../ticket-flow.service';

export interface TicketSteps {
  parcipiantsInfo: boolean;
  review: boolean;
  payment: boolean;
  success: boolean;
}

export interface State {
  selectedPaymentDetail: any;
  paymentDetail: any;
  paymentTypeConfirm: boolean;
  paymentSubmitted: boolean;

  steps: TicketSteps;
  eventDetails: QuestEvent;
  isTicketPaid: boolean;
  teamList: QuestTeam[];
  ticketCountArr: number[];
  ticketSkuMapper: SkusItem[];
  participantInfo: Participant[];
  ticketVariants: QuestTicketVariantPrapared[];
  totalTickets: number;
  participantValidArr: boolean[];
  selectedTeam: TeamInformation;
  formSubmitted: boolean;
  buyerChange: boolean;
  signUp: boolean;
  priceBreakdown: Breakdown;
  buyerInfoValidate: boolean;
  buyerInfoSubmit: boolean;
}

export const initialState: State = {
  steps: {
    parcipiantsInfo: false,
    payment: false,
    review: false,
    success: false
  },
  participantInfo: [],
  selectedPaymentDetail: null,
  paymentDetail: null,
  paymentTypeConfirm: false,
  paymentSubmitted: false,
  formSubmitted: false,
  buyerChange: false,
  signUp: false,

  eventDetails: null,
  isTicketPaid: false,
  teamList: null,
  ticketCountArr: [],
  ticketSkuMapper: [],
  ticketVariants: [],
  totalTickets: 0,
  participantValidArr: [],
  selectedTeam: null,
  priceBreakdown: {
    "netTotal": 0,
    "netTip": 0,
    "brutTip": 0,
    "discount": 0,
    "platformFee": 0,
    "tax": 0,
    "stripeFee": 0,
    "brutTotal": 0,
    "absorbFee": false
  },
  buyerInfoValidate: false,
  buyerInfoSubmit: false
};

export function TicketFlowReducer(state = initialState, action: TicketFlowActions.Actions) {
  switch (action.type) {
    case (TicketFlowActions.TICKET_FLOW_UPDATE_STEPS):
      return {
        ...state,
        steps: action.payload
      };
    case (TicketFlowActions.TICKET_UPDATE_PARTICIPANT):
      const newTicketInfo = [...state.participantInfo];
      newTicketInfo[action.payload.index] = action.payload.value;
      return {
        ...state,
        participantInfo: newTicketInfo
      };
    case (TicketFlowActions.TICKET_UPDATE_ALL_PARTICIPANT):
      return {
        ...state,
        participantInfo: action.payload
      };
    case (TicketFlowActions.TICKET_RESET_INFO):
      return {
        ...initialState
      };
    case (TicketFlowActions.TICKET_PAYMENT_INFO):
      return {
        ...state,
        paymentTypeConfirm: action.payload.paymentTypeConfirm,
        paymentDetail: action.payload.paymentDetail,
        selectedPaymentDetail: action.payload.selectedPaymentDetail
      };
    case (TicketFlowActions.TICKET_PAYMENT_SUBMITTED):
      return {
        ...state,
        paymentSubmitted: action.payload
      }
    case (TicketFlowActions.TICKET_FORM_SUBMIT):
      return {
        ...state,
        formSubmitted: action.payload
      }
    case (TicketFlowActions.TICKET_BUYER_CHANGE):
      return {
        ...state,
        buyerChange: action.payload
      }
    case (TicketFlowActions.TICKET_SIGNUP_ALL_USERS): 
      return {
        ...state,
        signUp: action.payload
      };
    case (TicketFlowActions.TICKET_EVENT_DETAILS):
      return {
        ...state,
        eventDetails: action.payload
      }
    case (TicketFlowActions.TICKET_IS_TICKET_PAID):
      return {
        ...state,
        isTicketPaid: action.payload
      }
    case (TicketFlowActions.TICKET_TEAMS_LIST):
      return {
        ...state,
        teamList: action.payload
      }
    case (TicketFlowActions.TICKET_TOTAL_COUNT_ARR):
      return {
        ...state,
        ticketCountArr: action.payload
      }
    case (TicketFlowActions.TICKET_SKU_MAPPER):
      return {
        ...state,
        ticketSkuMapper: action.payload
      }
    case (TicketFlowActions.TICKET_VARIANTS_TICKETS):
      return {
        ...state,
        ticketVariants: action.payload
      }
    case (TicketFlowActions.TICKET_TOTAL_COUNT_NUM):
      return {
        ...state,
        totalTickets: action.payload
      }
    case (TicketFlowActions.TICKET_UPDATE_PARTICIPANT_VALID):
      let existingArr = [...state.participantValidArr];
      existingArr[action.payload.index] = action.payload.value;
      return {
        ...state,
        participantValidArr: existingArr
      }
    case (TicketFlowActions.TICKET_UPDATE_SELECTED_TEAM):
      return {
        ...state,
        selectedTeam: action.payload
      }
    case (TicketFlowActions.TICKET_UPDATE_PRICE_BREAKDOWN):
      return {
        ...state,
        priceBreakdown: action.payload
      }
    case (TicketFlowActions.TICKET_BUYER_INFO_VALIDATE):
      return {
        ...state,
        buyerInfoValidate: action.payload
      }
    case (TicketFlowActions.TICKET_BUYER_INFO_SUBMIT):
      return {
        ...state,
        buyerInfoSubmit: action.payload
      }
    default:
      return state;
  }
}

export const buyerChanged = (state: State) => state.buyerChange;
export const signUpTicketChange = (state: State) => state.signUp;
export const eventDetailsChange = (state: State) => state.eventDetails;
export const teamListChange = (state: State) => state.teamList;
export const ticketVariantsChange = (state: State) => state.ticketVariants;
export const participantInfoChange = (state: State) => state.participantInfo;
export const participantValidArrChange = (state: State) => state.participantValidArr;
export const selectedTeamChange = (state: State) => state.selectedTeam;
