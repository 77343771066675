import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LeaderBoardPodiumPlaceMode, LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { LEADERBOARD_TEAM_PREFIX } from '../../../../app.constants';

@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss']
})
export class LeaderboardListComponent implements OnChanges {
  @Output() updateMember: EventEmitter<LeaderboardScore> = new EventEmitter<LeaderboardScore>();
  @Input() allowEdit: boolean;
  @Input() selectedAttributeId: string;
  @Input() questId: number;

  @Input()
  teams: QuestTeam[];

  @Input()
  members: LeaderboardScore[];

  podiumMode: LeaderBoardPodiumPlaceMode = LeaderBoardPodiumPlaceMode.PROFILE;

  constructor() {
  }

  onUpdateMember(updatedMember: LeaderboardScore) {
    this.updateMember.emit(updatedMember);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedAttributeId')) {
      this.checkPodiumModeState();
    }
  }

  private checkPodiumModeState(): void {
    if ((this.selectedAttributeId || '').startsWith(LEADERBOARD_TEAM_PREFIX)) {
      this.podiumMode = LeaderBoardPodiumPlaceMode.TEAM;
    } else {
      this.podiumMode = LeaderBoardPodiumPlaceMode.PROFILE;
    }
  }

}
