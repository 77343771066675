import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AddCommentToList, AnimateItems, AnimateList } from 'src/app/animations/comments.animations';
import { COMMENT_CHARACTER_LIMIT } from 'src/app/app.config';
import { ValidationMessages, VALIDATION_MESSAGES } from 'src/app/app.constants';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { QuestService } from '../../../../../_services/quest.service';
import * as fromApp from '../../../../../_store/app.reducers';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as CommentsActions from '../store/quest-components.actions';
import { CommentService } from './comment.service';
import { PreparedQuestComment } from './comment.type';

declare var $: any;

@Component({
  selector: 'app-comment',
  animations: [AnimateList, AnimateItems, AddCommentToList],
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Output() closeAnotherReplyForms: EventEmitter<void> = new EventEmitter<void>();
  @Output() increaseRepliesLimit: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateRepliesCount: EventEmitter<void> = new EventEmitter<void>();

  // @Input('webviewMode') webviewMode: boolean;
  @Input('comment') comment: PreparedQuestComment;
  
  @Input('reply') reply = false;
  
  @Input('postId') postId: number = null;
  
  @Input() questId: number;

  @Input('closeReplyForm') closeReplyForm: boolean;

  @Output() update: EventEmitter<{type: string; of?: number}> = new EventEmitter<{type: string; of?: number}>(); 

  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  profileState: Observable<fromProfile.State>;
  viwerId: number;
  form: UntypedFormGroup;
  replyForm: UntypedFormGroup;
  message = '';
  isLoading: string;
  isReplyFormOpened = false;
  isLikeLoading = false;
  hasCurrentUserLiked = false;
  tributeOptions: any;
  jqueryLinks: any;
  commentsViewLimit = 2;
  showRepliesList = false;
  addCommentToListState: string;

  public commentLength = COMMENT_CHARACTER_LIMIT;

  modalReference: any;

  showOptions: boolean = false;

  get limit() {
    return this.reply ? this.comment.limit : this.comment.replies.length;
  }
  constructor(
    @Inject(DOCUMENT) private document: any,
    private elRef: ElementRef,
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private commentService: CommentService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private notifier: NotifierService
  ) {
    this.profileState = this.store.select('userInfo');
    this.tributeOptions = this.commentService.getTributeOptions();
  }

  ngOnInit() {
    this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.viwerId = state.id;
      // this.hasCurrentUserLiked = (this.comment.likes || []).filter(like => like.liker === this.viwerId).length > 0;
    });
    this.form = this.fb.group({
      editor: [this.comment.comment, [Validators.required]]
    });
    this.replyForm = this.fb.group({
      editor: ['', [Validators.required, Validators.maxLength(this.commentLength)]]
    });
    this.addCommentToListState = 'in';
  }

  ngOnChanges() {
    if (this.closeReplyForm !== null) {
      this.isReplyFormOpened = this.closeReplyForm;
    }
  }

  prepareCommentCRLF(comment: string) {
    return comment.replace(/\n/gm, '<br>');
  }

  ngAfterViewInit(): void {
    // if (this.webviewMode) {
    //   setTimeout(() => {
    //     this.initLinksClicker();
    //   }, 0);
    // }
  }

  ngOnDestroy() {
    if (this.jqueryLinks && this.jqueryLinks.length > 0) {
      this.jqueryLinks.off('click.questComments');
    }
  }

  editingComment: boolean = false;
  editComment() {
    this.editingComment = true;  
    this.showOptions = false;
  }

  updateCommentReply() {
  if (!this.isLoading || this.form.valid) {
      this.isLoading = 'EDIT';
      this.questService.editQuestCommentReply(this.comment.commentId, this.form.value.editor)
        .subscribe((newComment: PreparedQuestComment) => {
          this.isLoading = null;
          this.store.dispatch(new CommentsActions.UpdateComment(newComment));
          this.comment = newComment;
          this.toggleEditComment();
        });
    }
  }

  toggleEditComment() {
    this.editingComment = false;

    const payload: CommentsActions.ToggleEditCommentState = {
      commentId: this.comment.id,
      isEditable: !this.comment.editable
    };
    this.store.dispatch(new CommentsActions.ToggleEditComment(payload));
  }

  toggleLikeComment() {
    this.isLikeLoading = true;
    // this.questService.toggleQuestLike(this.comment.id).subscribe((liked: boolean) => {
    //   this.hasCurrentUserLiked = liked;
    //   this.comment.likes = liked
    //     ? [...(this.comment.likes || []), {
    //       id: null,
    //       liker: this.viwerId,
    //       createdOn: null
    //     }]
    //     : [...(this.comment.likes || [])].filter(like => like.liker !== this.viwerId);
    //   this.isLikeLoading = false;
    // });
  }

  showReplyForm: boolean = false;
  toggleReplyComment() {
    // this.showReplies();
    this.showReplyForm = true;

    // if (!this.isReplyFormOpened) {
    //   this.closeAnotherReplyForms.emit();
    //   setTimeout(() => {
    //     if (!this.replyForm.get('editor').value) {
    //       this.replyForm.get('editor').patchValue('@' + this.comment.userName + ' ');
    //     }
    //     this.isReplyFormOpened = true;
    //     this.focusOnField();
    //   }, 0);
    // } else {
    //   this.focusOnField();
    // }
  }

  cancelReply() {
    // this.isReplyFormOpened = false;
    this.replyForm.patchValue({
      editor: ''
    });
    this.form.patchValue({
      editor: ''
    });
    this.toggleEditComment();
  }
  focusOnField() {
    setTimeout(() => {
      const $el = $(this.elRef.nativeElement);
      $('html, body').animate({
          scrollTop: $el.find('.c-dl-ns-post__feedback').offset().top - 100
      }, 350, () => {
        const $replyEl = $el.find('.js-reply-trigger');
        const $replyElValue = $replyEl.val();
        $replyEl.val('');
        $replyEl.focus().val($replyElValue);
      });
    }, 350);
  }

  onCloseAnotherReplyForms() {
    this.closeAnotherReplyForms.emit();
  }

  replyToComment() {
    if (!this.isLoading) {
      const replyId = this.comment.commentId ;// this.reply ? && this.postId ? this.postId : this.comment.id;
      this.isLoading = 'REPLY';
      this.questService
        .addNewQuestComment(this.questId, this.postId, this.replyForm.value.editor, replyId)
        .subscribe((replyData: any) => {
          this.isLoading = null;
          this.replyForm.get('editor').patchValue('');

          this.comment.replies.push(replyData);
          // this.showReplies();
          // this.loggedItem.userComments = replyData;
          // this.isReplyFormOpened = false;
          // this.loadMoreReplies(1);
          // this.store.dispatch(new AddReply(res, replyId));
          // this.updateRepliesCount();
        }, () => {
          this.isLoading = null;
        });

      // tslint:disable-next-line:max-line-length
      // this.questService.addNewQuestComment(this.comment.questId, this.viwerId, this.replyForm.value.editor, replyId).subscribe((res: PreparedQuestComment) => {
      //   this.isLoading = null;
      //   this.isReplyFormOpened = false;
      //   this.replyForm.get('editor').patchValue('');
      //   if (this.parentCommentId) {
      //     this.increaseRepliesLimit.emit();
      //   } else {
      //     this.loadMoreReplies(1);
      //   }
      //   this.store.dispatch(new CommentsActions.AddReply(res, replyId));
      //   this.emitRepliesCount();
      // });
    }
  }
  onNavigate(navigateData: WebviewNavigationDataType) {
    // if (this.webviewMode) {
    //   this.navigateToEmit.emit(navigateData);
    // } else 
    if (navigateData.type === 'LinkInside') {
      this.router.navigate(navigateData.routerLink);
    } else {
      this.document.location.href = navigateData.url;
    }
  }

  initLinksClicker() {
    const that = this;
    this.jqueryLinks = $(this.elRef.nativeElement).children('.c-comment__wrapper').find('a');
    this.jqueryLinks.off('click.questComments').on('click.questComments', function (event) {
      event.preventDefault();
      const href = $(this).attr('href');
      if (href && href.toLowerCase().indexOf('void(0)') === -1) {
        that.onNavigate({
          type: 'LinkOutside',
          url: href
        });
      }
    });
  }

  emitRepliesCount() {
    console.log('emitRepliesCount');
    this.updateRepliesCount.emit();
  }

  commentReplies: PreparedQuestComment[] = [];
  showReplies() {
    this.showRepliesList = true;

    // this.questService
    //   .getRepliesOnComment(this.comment.commentId)
    //   .subscribe((replies: PreparedQuestComment[]) => {
    //     const existingReplies = this.comment.replies.map(repl => repl.commentId);
    //     replies = replies.filter(com => existingReplies.indexOf(com.commentId) < 0);
        
    //     this.comment.replies.push(...replies);
    //   })
  }


  pageNumber: number = 1;
  pageSize: number = 10;
  loadMoreReplies() {
    // this.comment.limit += count ? count : 3;
    this.questService
      .getRepliesOnComment(this.comment.commentId, this.pageNumber, this.pageSize)
      .subscribe((replies: PreparedQuestComment[]) => {
        const existingReplies = this.comment.replies.map(repl => repl.commentId);
        replies = replies.filter(com => existingReplies.indexOf(com.commentId) < 0);
        replies = replies.reverse();

        this.pageNumber += 1;

        this.comment.replies.unshift(...replies);
      })
  }

  confirmDeleteComment(template) {
    this.modalReference = this.modalService.open(template, {
      size: 'sm',
      windowClass: 'confirm-modal'
    });
  }

  closeModal() {
    this.modalReference.dismiss();
    this.modalService.dismissAll();
  }

  deleteComment() {
    this.showOptions = false;
    this.questService
      .deleteComment(this.comment.commentId)
      .pipe(finalize(() => this.closeModal()))
      .subscribe((res: {"Success": boolean}) => {
        if (res && res.Success) {
          this.update.emit({
            type: 'removeComment',
            of: this.comment.commentId
          });
          this.notifier.notify('success', 'Comment deleted successfully.');
        } else {
          this.notifier.notify('error', 'Comment deletetion failed.');
        }
      }, err => {
        this.notifier.notify('error', 'Comment deletetion failed.');
      })
  }

  updateReply(event) {
    if (event.type === 'removeComment') {
      const index = this.comment.replies.findIndex(item => item.commentId === event.of);
      this.comment.replies.splice(index, 1);
      this.comment.totalReplies -= 1;
    }
  }
}
