import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {

  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor() { }

  ngOnInit() {
  }

  // setMetaTags() {
  //   this.titleService.setTitle('DIEMlife | Connections');
  //   this.meta.addTag({name: 'og:title', content: 'DIEMlife | Connections'});
  //   this.meta.addTag({name: 'og:image', content: this.metaImage});
  //   this.meta.addTag({name: 'og:description', content: 'Manage and view your connections in DIEMlife'});
  // }
}
