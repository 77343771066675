import {
  BRANDS_LOADED,
  QUEST_LOGGED_ACTIVITIES,
  QUEST_NEW_LOGGED_ACTIVITY,
  QuestActions,
  SET_QUEST_ID,
  TEAM_CREATED,
  TEAM_JOINED,
  TEAMS_LOADED,
  UPDATE_QUEST_LOGGED_ACTIVITY
} from './quest-detail.actions';
import {QuestTeam} from '../team.types';
import {BrandConfig} from '../brand.types';
import { LoggedActivity } from '../quest.types';

export interface QuestDetailState {
  selectedQuestId: number;
  selectedQuestTeams: QuestTeam[];
  selectedQuestBrands: BrandConfig[];
  joinedTeam: QuestTeam;
  questActivitiesLogged: LoggedActivity[];
}

const initialState: QuestDetailState = {
  selectedQuestId: null,
  selectedQuestTeams: [],
  selectedQuestBrands: [],
  joinedTeam: null,
  questActivitiesLogged: []
};

export function QuestReducer(
  state = initialState,
  action: QuestActions
) {
  switch (action.type) {
    case SET_QUEST_ID:
      return {
        ...state,
        selectedQuestId: action.payload
      };
    case BRANDS_LOADED:
      return {
        ...state,
        selectedQuestBrands: action.brands
      };
    case TEAM_CREATED:
      return {
        ...state,
        joinedTeam: action.team,
        selectedQuestTeams: [...state.selectedQuestTeams, action.team]
      };
    case TEAM_JOINED:
      return {
        ...state,
        joinedTeam: action.team
      };
    case TEAMS_LOADED:
      return {
        ...state,
        selectedQuestTeams: action.teams
      };
    case QUEST_LOGGED_ACTIVITIES:
      return {
        ...state,
        questActivitiesLogged: action.payload
      }
    case QUEST_NEW_LOGGED_ACTIVITY:
      let allActivities = state.questActivitiesLogged;
      allActivities.unshift(action.payload);
      return {
        ...state,
        questActivitiesLogged: allActivities
      }
    case UPDATE_QUEST_LOGGED_ACTIVITY:
      let oldActivities = state.questActivitiesLogged;
      oldActivities.forEach((activity, i) => {
        if (activity.actvityRecordValueId === action.payload.actvityRecordValueId) {
          oldActivities[i] = action.payload;
        }
      });

      return {
        ...state,
        questActivitiesLogged: oldActivities
      }
    default:
      return state;
  }
}
