import {Directive, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AuthGuard} from 'src/app/_guards/auth-guard.service';
import {AuthGuardService} from './auth-guard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DlLogInComponent } from 'src/app/components/main/dl-auth/dl-log-in/dl-log-in.component';

@Directive({
  selector: '[appAuthGuard]'
})
export class AuthGuardDirective implements OnInit, OnDestroy {
  @Output() appAuthGuard = new EventEmitter();
  @Input('open-move-to') openMoveTo: any; 
  private clicks = new Subject();
  private subscription: Subscription;
  private authGuardSubscription: Subscription;
  private isActivated = false;

  constructor(
    private authGuard: AuthGuard,
    private modalService: NgbModal,
    private authGuardService: AuthGuardService,
    @Inject ('isServer') private isServer: boolean
  ) {
  }

  ngOnInit() {
    this.authGuardSubscription = this.authGuardService.getSubject().subscribe(() => {
      if (this.isActivated) {
        setTimeout(() => {
          this.authGuard.canBeActivated().pipe(take(1)).subscribe((canBeActivated: boolean) => {
            if (canBeActivated) {
              this.appAuthGuard.emit();
            }
            this.isActivated = false;
          });
        });
      }
    });
    this.subscription = this.clicks.subscribe(e => {
      this.authGuard.canBeActivated().pipe(take(1)).subscribe((canBeActivated: boolean) => {
        if (this.openMoveTo && this.openMoveTo !== '' && !canBeActivated) {
          localStorage.setItem('openMoveTo', JSON.stringify(this.openMoveTo));
        }

        if (canBeActivated) {
          this.appAuthGuard.emit(e);
        } else {
          if (!this.isServer) {
            this.modalService.open(DlLogInComponent, {
              windowClass: "no-overflow"
            });
          }
          this.isActivated = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.authGuardSubscription) {
      this.authGuardSubscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
