import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AnimateItems, AnimateItemsUp, AnimateList } from 'src/app/animations/comments.animations';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { QuestComment } from 'src/app/_interface/comment.types';
import { QuestService } from '../../../../_services/quest.service';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { PreparedQuestComment } from './comment/comment.type';
import * as CommentsActions from './store/quest-components.actions';
import * as fromQuestComponents from './store/quest-components.reducer';
import { QuestLoggedActivities } from '../../../../_interface/store/quest-detail.actions';
import * as fromQuest from 'src/app/_interface/store/quest-detail.reducers';

export interface Mention {
  key: string;
  profilePictureURL: string;
  value: string;
}
export interface MentionListItem {
  index: number;
  score: number;
  string: string;
  original: {
    key: string;
    profilePictureURL: string;
    value: string;
  };
}

@Component({
  selector: 'app-quest-comments',
  templateUrl: './quest-comments.component.html',
  styleUrls: ['./quest-comments.component.scss'],
  animations: [AnimateList, AnimateItems, AnimateItemsUp],
})
export class QuestCommentsComponent implements OnInit, OnDestroy {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Input('webviewMode') webviewMode: boolean;
  @Input('questUserId') questUserId?: number;
  @Input('questId') questId: number;
  @Input('postId') postId: number;
  userInfoState: Observable<fromProfile.State>;
  commentsState: Observable<fromQuestComponents.State>;
  commentsStateSubscription: Subscription;
  viewerId: number;
  isLoaded = false;
  comments: QuestComment[] = [];
  closeReplyForm: boolean = null;
  limit = 10;
  limitStep = 10;

  activityApiPage = 1;
  activityApiSize = 25;
  loggedActivities = [];
  loadMoreActivityApi = true;

  @Input() showLogActivityBtn: boolean;
  
  @Output() openActivityModal: EventEmitter<boolean> = new EventEmitter();

  questState: Observable<fromQuest.QuestDetailState>;

  @Input('isCurrentUserAdminOrOwner') isCurrentUserAdminOrOwner: boolean = false;
  
  constructor(
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef
  ) {
    // this.comments = [];
    this.userInfoState = this.store.select('userInfo');
    this.commentsState = this.store.select('questComponents');
    this.questState = this.store.select('quest');
  }

  loadMore() {
    this.limit += this.limitStep;
  }
  ngOnInit() {
    this.commentsStateSubscription = this.commentsState.subscribe((state: fromQuestComponents.State) => {
        this.comments = state.comments;
        if (this.postId && this.comments.length > 0) {
          let index;
          this.comments.forEach((comment, i) => {
            comment.openReply = true
            if (comment.id == this.postId){
              index = i;
            }
            else if(comment.replies.some(reply => reply.id == this.postId || reply.replies.some(reply => reply.id == this.postId))){
              index = i;
              comment.openReply = true
            }
          })
          let pageNum = Math.floor(index / 10);
          while (pageNum > 0) {
            pageNum -= 1;
            this.loadMore();
          }
          this.waitComments();
        }
    });
    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.viewerId = state.id;

      this.questService.getQuestComments(this.questId, this.questUserId, state.id).subscribe((res: PreparedQuestComment[]) => {
        this.store.dispatch(new CommentsActions.SetCommentsList(res));
        this.isLoaded = true;
      });

      this.loadActivities();
    });
  }

  loadActivities() {
    if (this.loadMoreActivityApi) {
      this.questService.getLoggedActivities(this.questId, this.activityApiPage, this.activityApiSize).subscribe((res: Array<any>) => {
        if (!res || res.length === 0) {
          this.loadMoreActivityApi = false;
        }

        this.isLoaded = true;
        this.loggedActivities = [...this.loggedActivities, ...res];
        
        this.store.dispatch(new QuestLoggedActivities(this.loggedActivities));
      }, err => {
        
      });
    }
  }

  async waitComments() {
    while(!document.getElementById(`${this.postId}`)) {
      await new Promise(r => setTimeout(r, 500));
    }
    document.getElementById(`${this.postId}`).scrollIntoView({behavior:"smooth", block:"center"});
  }

  ngOnDestroy() {
    if (this.commentsStateSubscription) {
      this.commentsStateSubscription.unsubscribe();
    }
    this.loggedActivities = [];
    this.store.dispatch(new CommentsActions.ClearCommentsList());
  }

  onNavigate(navigateData: WebviewNavigationDataType) {
    this.navigateToEmit.emit(navigateData);
  }

  onCloseAnotherReplyForms() {
    this.closeReplyForm = false;
    setTimeout(() => {
      this.closeReplyForm = null;
    }, 0);
  }

  loadMoreActivity(resetResults: boolean, showCentralSpinner: boolean, showBottomSpinner: boolean): void {
    this.activityApiPage += 1;
    this.loadActivities();
  }

  openLogActivityModal(): void {
    this.openActivityModal.emit(true);
  }

  updateComments(event: {type: string; of?: number}) {
    if (event.type === 'removeFeed') {
      const index = this.loggedActivities.findIndex(item => item.actvityRecordValueId === event.of);
      this.loggedActivities.splice(index, 1);
    }
  }
}
