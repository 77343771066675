import {Quest, QuestUserInfo} from './quest.types';
import {DiemlifeCommonsModelService} from './diemlife-commons-model.service';

export class MilestonesLogic {

  public static milestonesCheckingAllowed(questData: Quest, currentUser: QuestUserInfo) {
    const activityTracker = DiemlifeCommonsModelService.buildQuestActivityTracker(questData.viewerActivityInfo, currentUser);
    if (DiemlifeCommonsModelService.isUserQuestAdmin(questData, currentUser) && !activityTracker.isStatusComplete()) {
      return true;
    }
    if (activityTracker.hasUserActivity() && activityTracker.isStatusInProgress()) {
      if (activityTracker.isModePaceYourself()) {
        return Number(questData.user.id) === Number(currentUser.id);
      } else if (activityTracker.isModeTeam()) {
        return Number(questData.viewerTeamId) === Number(questData.doerTeamId);
      } else if (activityTracker.isModeSupportOnly()) {
        return false;
      }
    }
  }

}
