import { Action } from '@ngrx/store';
import { TicketSteps } from './ticket-flow.reducer';
import { Participant, QuestEvent, QuestTicketVariantPrapared, SkusItem, TeamInformation } from '../../../ticket-flow.typings';
import { QuestTeam } from 'src/app/_interface/team.types';
import { Breakdown } from '../ticket-flow.service';

export const TICKET_FLOW_UPDATE_STEPS = 'TICKET_FLOW_UPDATE_STEPS';
export const TICKET_UPDATE_PARTICIPANT = 'TICKET_UPDATE_PARTICIPANT';
export const TICKET_RESET_INFO = 'TICKET_RESET_INFO';
export const TICKET_PAYMENT_INFO = 'TICKET_PAYMENT_INFO';
export const TICKET_PAYMENT_SUBMITTED = 'TICKET_PAYMENT_SUBMITTED';
export const TICKET_FORM_SUBMIT = 'TICKET_FORM_SUBMIT';
export const TICKET_BUYER_CHANGE = 'TICKET_BUYER_CHANGE';
export const TICKET_SIGNUP_ALL_USERS = 'TICKET_SIGNUP_ALL_USERS';
export const TICKET_EVENT_DETAILS = 'TICKET_EVENT_DETAILS';
export const TICKET_IS_TICKET_PAID = 'TICKET_IS_TICKET_PAID';
export const TICKET_TEAMS_LIST = 'TICKET_TEAMS_LIST';
export const TICKET_TOTAL_COUNT_ARR = 'TICKET_TOTAL_COUNT_ARR';
export const TICKET_SKU_MAPPER = 'TICKET_SKU_MAPPER';
export const TICKET_VARIANTS_TICKETS = 'TICKET_VARIANTS_TICKETS';
export const TICKET_TOTAL_COUNT_NUM = 'TICKET_TOTAL_COUNT_NUM';
export const TICKET_UPDATE_ALL_PARTICIPANT = 'TICKET_UPDATE_ALL_PARTICIPANT';
export const TICKET_UPDATE_PARTICIPANT_VALID = 'TICKET_UPDATE_PARTICIPANT_VALID';
export const TICKET_UPDATE_SELECTED_TEAM = 'TICKET_UPDATE_SELECTED_TEAM';
export const TICKET_UPDATE_PRICE_BREAKDOWN = 'TICKET_UPDATE_PRICE_BREAKDOWN';
export const TICKET_BUYER_INFO_VALIDATE = 'TICKET_BUYER_INFO_VALIDATE';
export const TICKET_BUYER_INFO_SUBMIT = 'TICKET_BUYER_INFO_SUBMIT';

export class TicketFlowUpdateSteps implements Action {
  readonly type = TICKET_FLOW_UPDATE_STEPS;

  constructor(public payload: TicketSteps) {}
}
export class TicketUpdateParticipant implements Action {
  readonly type = TICKET_UPDATE_PARTICIPANT;

  constructor(public payload: {index: number; value: Participant}) {}
}

export class TicketUpdateAllParticipant implements Action {
  readonly type = TICKET_UPDATE_ALL_PARTICIPANT;

  constructor(public payload: Participant[]) {}
}

export class TicketResetInfo implements Action {
  readonly type = TICKET_RESET_INFO;
}

export class TicketPaymentInfo implements Action {
  readonly type = TICKET_PAYMENT_INFO;

  constructor(public payload: {paymentTypeConfirm: boolean; paymentDetail: any; selectedPaymentDetail: any}) {}
}

export class TicketPaymentSubmitted implements Action {
  readonly type = TICKET_PAYMENT_SUBMITTED;

  constructor(public payload: boolean) {}
}

export class TicketFormSubmit implements Action {
  readonly type = TICKET_FORM_SUBMIT;

  constructor(public payload: boolean) {}
}

export class TicketBuyerChange implements Action {
  readonly type = TICKET_BUYER_CHANGE;

  constructor(public payload: boolean) {}
}

export class TicketSignupAllUsers implements Action {
  readonly type = TICKET_SIGNUP_ALL_USERS;

  constructor(public payload: boolean) {}
}

export class UpdateEventDetailsTicket implements Action {
  readonly type = TICKET_EVENT_DETAILS;

  constructor(public payload: QuestEvent) {}
}

export class TicketIsTicketPaid implements Action {
  readonly type = TICKET_IS_TICKET_PAID;

  constructor(public payload: boolean) {}
}

export class TicketTeamsList implements Action {
  readonly type = TICKET_TEAMS_LIST;

  constructor(public payload: QuestTeam[]) {}
}

export class TicketTotalCountArr implements Action {
  readonly type = TICKET_TOTAL_COUNT_ARR;

  constructor(public payload: number[]) {}
}

export class TicketSkuMapper implements Action {
  readonly type = TICKET_SKU_MAPPER;

  constructor(public payload: SkusItem[]) {}
}

export class TicketVariantsTickets implements Action {
  readonly type = TICKET_VARIANTS_TICKETS;

  constructor(public payload: QuestTicketVariantPrapared[]) {}
}

export class TicketTotalCountNum implements Action {
  readonly type = TICKET_TOTAL_COUNT_NUM;

  constructor(public payload: number) {}
}

export class TicketUpdateParticipantValid implements Action {
  readonly type = TICKET_UPDATE_PARTICIPANT_VALID;

  constructor(public payload: {value:boolean, index: number}) {}
}

export class TicketUpdateSelectedTeam implements Action {
  readonly type = TICKET_UPDATE_SELECTED_TEAM;

  constructor(public payload: TeamInformation) {}
}

export class TicketPriceBreakdownUpdate implements Action {
  readonly type = TICKET_UPDATE_PRICE_BREAKDOWN;

  constructor(public payload: Breakdown) {}
}

export class TicketBuyerInfoValidate implements Action {
  readonly type = TICKET_BUYER_INFO_VALIDATE;

  constructor(public payload: boolean) {}
}

export class TicketBuyerInfoSubmit implements Action {
  readonly type = TICKET_BUYER_INFO_SUBMIT;

  constructor(public payload: boolean) {}
}


export type Actions =  TicketFlowUpdateSteps | TicketUpdateParticipant 
   | TicketUpdateAllParticipant 
   | TicketResetInfo 
   | TicketPaymentInfo 
   | TicketPaymentSubmitted
   | TicketFormSubmit
   | TicketBuyerChange
   | TicketSignupAllUsers 
   | UpdateEventDetailsTicket 
   | TicketIsTicketPaid 
   | TicketTeamsList 
   | TicketTotalCountArr 
   | TicketSkuMapper
   | TicketVariantsTickets 
   | TicketTotalCountNum 
   | TicketUpdateParticipantValid 
   | TicketUpdateSelectedTeam 
   | TicketPriceBreakdownUpdate 
   | TicketBuyerInfoValidate 
   | TicketBuyerInfoSubmit;
