import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Constants from '../../../../app.constants';
import * as config from '../../../../app.config';
import { Observable } from 'rxjs';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../_store/app.reducers';
import { take } from 'rxjs/operators';
import { AccountService } from '../../../pages/account/account.service';
import { TryLogout, TrySignin } from '../store/auth.actions';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  deleteUserForm: FormGroup;

  deleteFormSubmitted: boolean = false;

  messages = Constants.VALIDATION_MESSAGES;

  userInfoState: Observable<fromProfile.State>;

  isUserLoggedIn: boolean = false;

  loggedUserData: any;

  clickedOnDeleteBtn: boolean = false;

  constructor(private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private notifier: NotifierService) { 
    this.userInfoState = this.store.select('userInfo');

    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.deleteUserForm = this.fb.group({
      'email': new FormControl(null, [
        Validators.required,
        Validators.pattern(emailReg)
      ]),
      'password': new FormControl(null, [Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength)])
    });
  }

  ngOnInit() {
    this.userInfoState
      .subscribe(currentUser => {
        if (currentUser && currentUser.id) {

          this.loggedUserData = currentUser;
          this.isUserLoggedIn = true;
          console.log('60', this.isUserLoggedIn, this.loggedUserData);
          if (this.clickedOnDeleteBtn) {
            this.deleteAccount();
          }
        } else {
          console.log('65', this.isUserLoggedIn);
          this.isUserLoggedIn = false;
        }
      });
  }

  loginAndDeleteAccount() {
    if (this.deleteUserForm.invalid) return;

    this.clickedOnDeleteBtn = true;
    this.store.dispatch(new TrySignin({
      email: this.deleteUserForm.value.email,
      password: this.deleteUserForm.value.password
    }));
  }

  deleteAccount() {
    this.accountService.deleteUserAccount(this.loggedUserData.id)
      .subscribe(res => {
        this.store.dispatch(new TryLogout());
        this.notifier.notify('success', 'Your account is disabled, and will be deleted in 90 days.');
      })
  }

}
