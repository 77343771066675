import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { getYoutubeId, youtubeUrlValidator } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { SelectedSearchResult } from 'src/app/modules/shared-module/components/dl-search/dl-search.component';
import { AddMilestoneImageUrl, AddQuestLink, PatchMilestone, PatchNewMilestone } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { AddLinkPreviewToMilestonePayload, AddVideoToMilestonePayload, AddVideoUrlToMilestonePayload, PatchMilestonePayload } from 'src/app/_interface/dl-milestones.typings';
import { MilestoneVideo, QuestLite, QuestTask, SEOSlugs, UserLite } from 'src/app/_interface/quest.types';
import { SearchResult } from 'src/app/_interface/search.types';
import { AppState } from 'src/app/_store/app.reducers';
import { DlMilestonesService } from '../service/dl-milestones.service';

declare var $: any;


@Component({
  selector: 'app-dl-milestone-preview',
  templateUrl: 'dl-milestone-preview.component.html'
})

export class DlMilestonePreviewComponent implements OnInit, OnDestroy {
  @ViewChild('previewDropdown', {read: NgbDropdown, static: false}) dropdown: NgbDropdown;
  @Input() id: number;
  @Input() index: number;
  @Input() groupIndex: number;
  @Input() allowed: string = "all";
  linkForm: UntypedFormGroup;
  videoForm: UntypedFormGroup;
  openedModal: string;
  mode: 'NEW' | 'RELEASED';
  dlMilestonesState: Observable<TaskGroupsState>;
  dlMilestonesSubscription: Subscription;
  milestoneInfo: QuestTask;

  constructor(
    private fb: UntypedFormBuilder,
    private dlMilestonesService: DlMilestonesService,
    private store: Store<AppState>,
    private notifier: NotifierService
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    this.linkForm = this.fb.group({
      url: [null, [Validators.required]]
    });
    this.videoForm = this.fb.group({
      videoUrl: [null, [Validators.required, youtubeUrlValidator]]
    });
    this.dlMilestonesSubscription = this.dlMilestonesState.subscribe((state: TaskGroupsState) => {
      // * detect witch mode should be used for this dialog
      if (state.milestones.length && state.milestones[this.groupIndex]) {
        // tslint:disable-next-line:max-line-length
        this.mode = (state.milestones[this.groupIndex].questTasks.length - 1) < this.index ? 'NEW' : 'RELEASED';
      } else {
        this.mode = 'NEW';
      }
      if (this.mode === 'RELEASED') {
        this.milestoneInfo = state.milestones[this.groupIndex].questTasks[this.index];
      } else {
        this.milestoneInfo = state.newMilestoneForPreview;
      }
    });
  }

  ngOnDestroy() {
    if (this.dlMilestonesSubscription) {
      this.dlMilestonesSubscription.unsubscribe();
    }
  }

  openMenu() {
    $(document).trigger('click');
    setTimeout(() => {
      if (this.dropdown) this.dropdown.open();
    });
  }

  close() {
    if (this.dropdown) this.dropdown.close();
  }

  addLinkToMilestone() {

    const payload: AddLinkPreviewToMilestonePayload = {
      taskId: this.id,
      taskLink: this.linkForm.value.url
    };
    this.dlMilestonesService.addLinkToMilestone(payload).subscribe((newTask: QuestTask) => {
      const patchMilestonePayload: PatchMilestonePayload = {
        taskIndex: this.index,
        groupIndex: this.groupIndex,
        value: {
          linkUrl: newTask.linkUrl,
          linkPreview: newTask.linkPreview
        } as QuestTask
      };
      if (this.mode === 'RELEASED') {
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
      } else {
        this.store.dispatch(new PatchNewMilestone({
          linkUrl: this.linkForm.value.url,
          linkPreview: newTask.linkPreview
        } as QuestTask, this.groupIndex));
      }

      this.close();
    });

  }

  addVideoToMilestone() {
    const youtubeId = getYoutubeId(this.videoForm.value.videoUrl);
    if (youtubeId) {
      const videoPayload: AddVideoUrlToMilestonePayload = {
        url: `https://www.youtube.com/embed/${youtubeId}`,
        provider: 'YOUTUBE',
        thumbnails: {
          xs: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          sm: `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
          md: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
        }
      };
      const embeddedVideo: MilestoneVideo = {
        thumbnailUrl: videoPayload.thumbnails.sm,
        videoId: null,
        videoUrl: videoPayload.url
      };
      if (this.mode === 'RELEASED' && this.id) {
        // * milestone and quest is already created
        this.store.select('dlMilestones').pipe(take(1)).subscribe((state: TaskGroupsState) => {
          const milestone: AddVideoToMilestonePayload = {
            id: state.milestones[this.groupIndex].questTasks[this.index].id,
            task: state.milestones[this.groupIndex].questTasks[this.index].task,
            video: videoPayload
          };
          this.dlMilestonesService.addVideoToMilestone(milestone).subscribe(() => {
            const patchMilestonePayload: PatchMilestonePayload = {
              taskIndex: this.index,
              groupIndex: this.groupIndex,
              value: {
                video: videoPayload,
                embeddedVideo: embeddedVideo
              } as QuestTask
            };
            this.store.dispatch(new PatchMilestone(patchMilestonePayload));
            this.close();
          });
        });
      } else if (this.mode === 'RELEASED' && !this.id) {
        // * new quest with already created milestone
        const patchMilestonePayload: PatchMilestonePayload = {
          taskIndex: this.index,
          groupIndex: this.groupIndex,
          value: {
            video: videoPayload,
            embeddedVideo: embeddedVideo
          } as QuestTask
        };
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
        this.close();
      } else {
        // * new quest with not created milestone
        this.store.dispatch(new PatchNewMilestone({
          video: videoPayload,
          embeddedVideo: embeddedVideo
        } as QuestTask, this.groupIndex));
        this.close();
      }
    }
  }

  onLinkedQuestSelected(searchResult: SelectedSearchResult) {
    this.closeDialog('link-quest-dialog');
    if (this.mode === 'RELEASED' && this.id) {
      this.store.dispatch(new AddQuestLink(this.index, this.groupIndex, this.id, searchResult.data.questId));
    } else if (this.mode === 'RELEASED' && !this.id) {
      this.store.dispatch(new PatchMilestone({
        taskIndex: this.index,
        groupIndex: this.groupIndex,
        value: {
          linkedQuestId: searchResult.data.questId,
          linkedQuest: DlMilestonePreviewComponent.convertToQuestLite(searchResult.data)
        } as QuestTask
      }));
    } else {
      this.store.dispatch(new PatchNewMilestone({
        linkedQuestId: searchResult.data.questId,
        linkedQuest: DlMilestonePreviewComponent.convertToQuestLite(searchResult.data)
      } as QuestTask, this.groupIndex));
    }
  }

  addPhotoToMilestone(imageUrl: string) {
    if (this.mode === 'RELEASED') {
      this.store.dispatch(new AddMilestoneImageUrl({taskIndex: this.index, groupIndex: this.groupIndex, value: imageUrl}));
    } else {
      this.store.dispatch(new PatchNewMilestone({
        imageUrl: imageUrl
      } as QuestTask, this.groupIndex));
    }
    this.closeDialog('cropper-milestone-dialog');
    this.close();
  }

  openImageDialog() {
    this.openDialog('cropper-milestone-dialog');
  }

  openLinkQuestDialog() {
    this.openDialog('link-quest-dialog');
  }

  private static convertToQuestLite(searchResult: SearchResult): QuestLite {
    return {
      id: searchResult.questId,
      title: searchResult.questName,
      photo: searchResult.questImageURL,
      questFeed: searchResult.description,
      shortDescription: searchResult.description,
      pillar: searchResult.pillar,
      seoSlugs: {
        shortUrl: searchResult.path
      } as SEOSlugs,
      user: {
        id: searchResult.userId,
        profilePictureURL: searchResult.profilePictureURL
      } as UserLite
    } as QuestLite;
  }

  private openDialog(dialogId: string) {
    this.openedModal = dialogId;
    setTimeout(() => {
      // this.modalService.open(dialogId);
    });
  }

  private closeDialog(dialogId: string) {
    // this.modalService.close(dialogId);
    setTimeout(() => {
      this.openedModal = dialogId;
    });
  }

  removePreview() {
    if (this.mode === 'RELEASED' && this.milestoneInfo.id) {
      // * milestone and quest is already created
      this.dlMilestonesService.removePreview(this.milestoneInfo.id).subscribe(() => {
        this.removePreviewAction();
      }, () => {
        this.notifier.notify('error', 'Oops, something went wrong, please try again');
      });
    } else if (this.mode === 'RELEASED' && !this.milestoneInfo.id) {
      // * new quest with already created milestone
      this.removePreviewAction();
    } else {
      // * new quest with not created milestone
      this.store.dispatch(new PatchNewMilestone({
        imageUrl: null,
        linkUrl: null,
        linkPreview: null,
        linkedQuestId: null,
        linkedQuest: null,
        video: null,
        embeddedVideo: null
      } as QuestTask, this.groupIndex));
    }
  }
  private removePreviewAction() {
    this.notifier.notify('success', 'Image removed');
    this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
      this.store.dispatch(new PatchMilestone({
        taskIndex: this.index,
        groupIndex: this.groupIndex,
        value: {
          ...this.milestoneInfo,
          imageUrl: null,
          linkUrl: null,
          linkPreview: null,
          linkedQuestId: null,
          linkedQuest: null,
          embeddedVideo: null,
          video: null
        }
      }));
    });
  }
}
