import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {environment} from '../../../../../environments/environment';
import {ReferredQuest} from '../../../../modules/ticket-flow/ticket-flow.typings';
import {Observable} from 'rxjs';
import {CarouselQuest} from './landing-carousel.component';

@Injectable({
  providedIn: 'root',
})
export class LandingCarouselService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
  ) {}

  getReferredQuests(questId: number): Observable<ReferredQuest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<ReferredQuest>(
      environment.target + environment.context + `/quests/${questId}/referred`,
      { headers: headers }
    );
  }

  getRecommendedQuest(questId: number): Observable<CarouselQuest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<CarouselQuest>(
      environment.target + environment.context + `/quests/${questId}/recommendation`,
      { headers: headers }
    );
  }
}
