import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotifierModule } from 'angular-notifier';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AutosizeModule } from 'ngx-autosize';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxTributeModule } from 'ngx-tribute';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as config from './app.config';
import { AuthService } from './components/main/auth/auth.service';
import { PasswordResetComponent } from './components/main/auth/password-reset/password-reset.component';
import { AuthEffects } from './components/main/auth/store/auth.effects';
import { ContactUsComponent } from './components/main/contact-us/contact-us.component';
import { AdvanceDetailsComponent } from './components/main/dl-auth/brand-signup/advance-details/advance-details.component';
import { BasicDetailsComponent } from './components/main/dl-auth/brand-signup/basic-details/basic-details.component';
import { DlAuthComponent } from './components/main/dl-auth/dl-auth.component';
import { DlForgotPasswordComponent } from './components/main/dl-auth/dl-forgot-password/dl-forgot-password.component';
import { DlLogInComponent } from './components/main/dl-auth/dl-log-in/dl-log-in.component';
import { DlPasswordResetComponent } from './components/main/dl-auth/dl-password-reset/dl-password-reset.component';
import { UserSignupComponent } from './components/main/dl-auth/user-signup/user-signup.component';
import { DlMilestoneDialogComponent } from './components/main/dl-milestones/dl-milestone-dialog/dl-milestone-dialog.component';
import { DlMilestonePreviewComponent } from './components/main/dl-milestones/dl-milestone-preview/dl-milestone-preview.component';
import { DlMilestoneComponent } from './components/main/dl-milestones/dl-milestone/dl-milestone.component';
import { DlMilestonesComponent } from './components/main/dl-milestones/dl-milestones.component';
import { DlNewMilestoneComponent } from './components/main/dl-milestones/dl-new-milestone/dl-new-milestone.component';
import { DlMilestonesEffects } from './components/main/dl-milestones/store/dl-milestones.effects';
import { FundsModalComponent } from './components/main/funds-modal/funds-modal.component';
import { HeaderComponent } from './components/main/header/header.component';
// tslint:disable-next-line:max-line-length
import { LeaderboardAttributeChoiceComponent } from './components/main/leaderboard/leaderboard-attribute-choice/leaderboard-attribute-choice.component';
import { LeaderboardListItemComponent } from './components/main/leaderboard/leaderboard-list-item/leaderboard-list-item.component';
import { LeaderboardListComponent } from './components/main/leaderboard/leaderboard-list/leaderboard-list.component';
import { LeaderboardMainComponent } from './components/main/leaderboard/leaderboard-main/leaderboard-main.component';
import { LeaderboardPodiumPlaceComponent } from './components/main/leaderboard/leaderboard-podium-place/leaderboard-podium-place.component';
import { LeaderboardPodiumComponent } from './components/main/leaderboard/leaderboard-podium/leaderboard-podium.component';
import { LeaderboardScoreEditorComponent } from './components/main/leaderboard/leaderboard-score-editor/leaderboard-score-editor.component';
import { MembersDirective } from './components/main/members/members.directive';
import { MembersService } from './components/main/members/members.service';
import { NotificationsComponent } from './components/main/notifications/notifications.component';
import { NotificationsService } from './components/main/notifications/notifications.service';
import { VerifyBankDialogComponent } from './components/main/payments/verify-bank-dialog/verify-bank-dialog.component';
import { ProfilePrivateComponent } from './components/main/profile/profile-private/profile-private.component';
import { ProfilePublicComponent } from './components/main/profile/profile-public/profile-public.component';
import { MegaQuestLinkComponent } from './components/main/quest/linking/mega-quest-link.component';
// tslint:disable-next-line:max-line-length
import { FundraiseSwitchComponent } from './components/main/quest/manage/fundraise-switch/fundraise-switch.component';
import { QuestCarouselComponent } from './components/main/quest/quest-carousel/quest-carousel.component';
import { CommentDropdownComponent } from './components/main/quest/quest-comments/comment/comment-dropdown/comment-dropdown.component';
import { CommentComponent } from './components/main/quest/quest-comments/comment/comment.component';
import { CommentService } from './components/main/quest/quest-comments/comment/comment.service';
import { NewsfeedCtrlComponent } from './components/main/quest/quest-comments/newsfeed-ctrl/newsfeed-ctrl.component';
import { NewsfeedPostComponent } from './components/main/quest/quest-comments/newsfeed-post/newsfeed-post.component';
import { QuestCommentsComponent } from './components/main/quest/quest-comments/quest-comments.component';
import { QuestDescriptionComponent } from './components/main/quest/quest-description/quest-description.component';
import { QuestDonateComponent } from './components/main/quest/quest-donate/quest-donate.component';
import { QuestHeaderToolsComponent } from './components/main/quest/quest-header-tools/quest-header-tools.component';
// import { QuestHeaderComponent } from './components/main/quest/quest-header/quest-header.component';
import { QuestInfoComponent } from './components/main/quest/quest-info/quest-info.component';
import { ShareWidjetService } from './components/main/share-widjet/share-widjet.service';
import { SkipNavComponent } from './components/main/skip-nav/skip-nav.component';
import { IndividualFormComponent } from './components/main/team/individual-form/individual-form.component';
import { MultiTeamQuestStarterComponent } from './components/main/team/multi-team-quest-starter/multi-team-quest-starter.component';
import { ParentQuestTeamComponent } from './components/main/team/parent-quest-team/parent-quest-team.component';
import { TeamFormComponent } from './components/main/team/team-form/team-form.component';
import { TeamListItemComponent } from './components/main/team/team-list-item/team-list-item.component';
import { TeamListComponent } from './components/main/team/team-list/team-list.component';
import { TeamQuestStarterComponent } from './components/main/team/team-quest-starter/team-quest-starter.component';
// tslint:disable-next-line:max-line-length
import { FriendsComponent } from './components/pages/friends/friends.component';
import { FriendsService } from './components/pages/friends/friends.service';
import { ProfileService } from './components/pages/profile/profile.service';
import { ProfileEffects } from './components/pages/profile/store/profile.effects';
import { RecentTransactionsEffects } from './components/pages/profile/store/recent-transactions.effects';
import { UserFavoritesEffects } from './components/pages/profile/store/user-favorites.effects';
import { QuestDetailCommentsComponent } from './components/pages/quest-detail-comments/quest-detail-comments.component';
import { QuestDetailComponent } from './components/pages/quest-detail/quest-detail.component';
import { QuestTeamModalEffects } from './components/pages/quest-detail/store/quest-team-modal.effects';
import { QuestManageComponent } from './components/pages/quest-manage/quest-manage.component';
import { ProfileAvatarService } from './components/separate/profile-avatar/profile-avatar.service';
import { CachedSrcDirective } from './directives/cached-src-directive/cached-src.directive';
import { InputFocusTriggerDirective } from './directives/focus-trigger/input-focus-trigger.directive';
import { MentionDirective } from './directives/mention/mention.directive';
import { RouterLinkDirective } from './directives/routerLink/router-link.directive';
import { ToggleDirective } from './directives/toggle/toggle.directive';
import { HTTPListener, HTTPStatus } from './interceptors/loader.interceptor';
// modules
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from './_global/window-ref.module';
import { AuthGuard } from './_guards/auth-guard.service';
import { GrdFilterPipe } from './_pipes/grd-filter.pipe';
import { HtmlToPlaintextPipe } from './_pipes/html-to-plain-text.pipe';
import { OptiImagePipe } from './_pipes/opti-image.pipe';
import { ReversePipe } from './_pipes/reverse.pipe';
import { DlMapMilestonesComponent } from './components/main/dl-milestones/dl-map-milestones/dl-map-milestones.component';
import { DlNewPreviewMilestoneComponent } from './components/main/dl-milestones/dl-new-preview-milestone/dl-new-preview-milestone.component';
import { MilestoneTaskPreviewComponent } from './components/main/dl-milestones/milestones-list/milestone-task-preview/milestone-task-preview.component';
import { MilestoneTaskComponent } from './components/main/dl-milestones/milestones-list/milestone-task/milestone-task.component';
import { MilestonesListComponent } from './components/main/dl-milestones/milestones-list/milestones-list.component';
import { PeopleLeaderboardComponent } from './components/main/people-leaderboard/people-leaderboard.component';
import { QuestCommentItemComponent } from './components/main/quest/quest-comments/quest-comment-item/quest-comment-item.component';
import { LogActivityComponent } from './components/separate/log-activity/log-activity.component';
import { SharedModule } from './modules/shared-module/shared.module';

// import { SafePipe } from './_pipes/safe-pipe';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { KeysPipe } from './_pipes/keys.pipe';
import { UdpCurrencyMaskV2Pipe } from './_pipes/udp-currency-mask-v2.pipe';
import { UdpCurrencyMaskPipe } from './_pipes/udp-currency-mask.pipe';
import { UrlifyPipe } from './_pipes/urlify.pipe';
import { CommonService } from './_services/common.service';
import { LocalStorageService } from './_services/localstorage.service';
import { metaReducers, reducers } from './_store/app.reducers';
import { DeleteUserComponent } from './components/main/auth/delete-user/delete-user.component';
import { SmallLogInComponent } from './components/main/dl-auth/small-log-in/small-log-in.component';
import { AccountEffects } from './components/pages/account/store/account.effects';
import { PasswordResetFailComponent } from './components/main/auth/password-reset-fail/password-reset-fail.component';

@NgModule({
  declarations: [
    AppComponent,
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
    ReversePipe,
    HtmlToPlaintextPipe,
    GrdFilterPipe,
    UrlifyPipe,
    HeaderComponent,
    FriendsComponent,
    NotificationsComponent,
    QuestDetailComponent,
    // QuestHeaderComponent,
    MegaQuestLinkComponent,
    QuestInfoComponent,
    QuestDonateComponent,
    QuestDescriptionComponent,
    ToggleDirective,
    QuestManageComponent,
    QuestCommentsComponent,
    CommentComponent,
    ProfilePrivateComponent,
    ProfilePublicComponent,
    CommentDropdownComponent,
    QuestCarouselComponent,
    // PhotoSwipeComponent,
    VerifyBankDialogComponent,
    FundraiseSwitchComponent,
    InputFocusTriggerDirective,
    MembersDirective,
    PasswordResetComponent,
    FundsModalComponent,
    SkipNavComponent,
    QuestHeaderToolsComponent,
    RouterLinkDirective,
    TeamListItemComponent,
    TeamListComponent,
    TeamFormComponent,
    TeamQuestStarterComponent,
    MultiTeamQuestStarterComponent,
    IndividualFormComponent,
    LeaderboardMainComponent,
    LeaderboardPodiumComponent,
    LeaderboardAttributeChoiceComponent,
    LeaderboardListComponent,
    LeaderboardListItemComponent,
    LeaderboardPodiumPlaceComponent,
    MentionDirective,
    LeaderboardScoreEditorComponent,
    CachedSrcDirective,
    DlAuthComponent,
    DlPasswordResetComponent,
    QuestDetailCommentsComponent,
    DlLogInComponent,
    MilestonesListComponent,
    DlForgotPasswordComponent,
    DlMilestonesComponent,
    DlMilestoneComponent,
    DlNewMilestoneComponent,
    DlMilestonePreviewComponent,
    DlMilestoneDialogComponent,
    OptiImagePipe,
    NewsfeedCtrlComponent,
    NewsfeedPostComponent,
    ContactUsComponent,
    UserSignupComponent,
    BasicDetailsComponent,
    AdvanceDetailsComponent,
    ParentQuestTeamComponent,
    DlNewPreviewMilestoneComponent,
    DlMapMilestonesComponent,
    MilestoneTaskComponent,
    MilestoneTaskPreviewComponent,
    PeopleLeaderboardComponent,
    LogActivityComponent,
    QuestCommentItemComponent,
    SmallLogInComponent,
    DeleteUserComponent,
    PasswordResetFailComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    StoreModule.forRoot(reducers, {metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    NgbModule,
    EffectsModule.forRoot([
      AuthEffects,
      AccountEffects,
      ProfileEffects,
      RecentTransactionsEffects,
      UserFavoritesEffects,
      QuestTeamModalEffects,
      DlMilestonesEffects
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedModule,
    AppRoutingModule,
    SnotifyModule,
    NgSelectModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'bottom',
        },
      },
      theme: 'material',
      behaviour: {
        autoHide: 3000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4,
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease',
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50,
        },
        shift: {
          speed: 300,
          easing: 'ease',
        },
        overlap: 150,
      }
    }),
    QuillModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxCaptchaModule,
    // ImageCropperModule,
    // TagInputModule,
    AutosizeModule,
    ClipboardModule,
    NgxTributeModule,
    InfiniteScrollModule,  
    SocialLoginModule,
    NgScrollbarModule,
    // PerfectScrollbarModule,
    // DropzoneModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbAppId)
          }
        ]
      } as SocialAuthServiceConfig
    },
    LocalStorageService,
    AuthGuard,
    AuthService,
    WindowRef,
    ProfileService,
    ProfileAvatarService,
    ShareWidjetService,
    HTTPStatus,
    HTTPListener,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    SnotifyService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: config.reCaptchaToken} as RecaptchaSettings,
    },
    CommonService,
    // QuestCarouselService,
    NotificationsService,
    FriendsService,
    MembersService,
    CommentService,
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
    NgbActiveModal,
    KeysPipe,
    {
      provide: 'isServer',
      useValue: false
    }
    // QuestCommentItemService
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: NoCacheHeadersInterceptor,
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
