import { Component, OnInit, Input } from '@angular/core';
import { CarouselQuest } from '../landing-carousel/landing-carousel.component';
import { QuestPreparedPhoto } from '../../quest/quest.type';
import { QuestService } from 'src/app/_services/quest.service';

@Component({
  selector: 'app-landing-carousel-item',
  templateUrl: './landing-carousel-item.component.html',
  styleUrls: ['./landing-carousel-item.component.scss']
})
export class LandingCarouselItemComponent implements OnInit {
  @Input() quest: CarouselQuest;
  photo: any = {};
  constructor(private questService: QuestService) { }

  testImage(URL) {
    const tester = new Image();
    tester.onload = () => {
      this.imageFound();
    };
    tester.onerror = () => {
      this.imageNotFound();
    };
    tester.src = URL;
  }
  imageFound() {
    this.photo = this.questService.prepareCoverPhoto(this.quest.photo, this.quest.pillar);
  }
  imageNotFound() {
    this.photo = this.questService.prepareCoverPhoto(null, this.quest.pillar);
  }
  ngOnInit() {
    this.testImage(this.quest.photo);
  }

}
