import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { SnotifyService } from 'ng-snotify';
import { Observable } from 'rxjs';
import { QuestGalleryImage } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { SnotifyConfigService } from 'src/app/_services/snotify-config.service';
import { AppState } from 'src/app/_store/app.reducers';
import { GalleryFullscreenComponent } from 'src/app/modules/shared-module/components/gallery-fullscreen/gallery-fullscreen.component';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';

const slidesToShow = 1.2;

@Component({
  selector: 'app-quest-carousel',
  templateUrl: './quest-carousel.component.html',
  styleUrls: ['./quest-carousel.component.scss']
})
export class QuestCarouselComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() images: QuestGalleryImage[];
  @ViewChild('toolsContainer', { static: true }) toolsContainer: ElementRef;
  userInfoState: Observable<fromProfile.State>;
  isLoaded = false;
  slideConfig: any;
  altImageText: string;
  photoDimensions = '2048x2048';

  @Input('isCurrentUserAdminOrOwner') isCurrentUserAdminOrOwner: boolean = false;

  constructor(
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private questService: QuestService,
    private cd: ChangeDetectorRef,
    private notifier: NotifierService,
    private modalService: NgbModal
  ) {
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.slideConfig = {
      slidesToShow: slidesToShow,
      infinite: false,
      dots: false,
      arrows: true,
      prevArrow: '<button class="c-gallery__tools__arrow-left h-btn-reset" type="button" aria-label="Previous"><svg class="icon icon-arrow_left"><use xlink:href="#icon-arrow_left"></use></svg></button>',
      nextArrow: '<button class="c-gallery__tools__arrow-right h-btn-reset" type="button" aria-label="Next"><svg class="icon icon-arrow_right"><use xlink:href="#icon-arrow_right"></use></svg></button>',
      appendArrows: this.toolsContainer.nativeElement,
      centerMode: false,
      swipeToSlide: true,
      draggable: false,
      touchThreshold: 10,
      customPaging : function(slider, index) {
        return `<span>${slider.$slides.length} ${((slider.$slides.length === 1) ? 'photo' : 'photos')}</span>`;
      }
    };
  }
  ngAfterViewChecked() {
    // this.isLoaded = true;
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.images.map((img, i) => {
      img.isVideo = img.questVideoUrl && img.questVideoUrl !== '' && img.questVideoUrl !== 'null';

      if (i === this.images.length - 1) {
        this.isLoaded = true;
      }
    });
  }
  afterChange(e) {
  }
  openGallery() {
  }
  onInitCarousel(event: any) {

  }

  openGalleryFs(index) {
    const _mod = this.modalService.open(GalleryFullscreenComponent, {
      backdropClass: 'carousel-bg',
      windowClass: 'carousel-modal'
    });
    _mod.componentInstance.assets = this.images;
    _mod.componentInstance.focusedIndex = index;
  }
  
  getAltText(creatorName: string) {
    return this.altImageText = creatorName + 's photo';
  }
  confirmRemoveQuestPhoto(event, photoId, index) {
    event.stopPropagation();
    this.snotifyService.confirm(
      `Are you sure you would like to remove this Quest Photo?`,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        html: null,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.removePhoto(photoId, index);
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }
  removePhoto(photoId, index) {
    this.questService.removeQuestPhoto(photoId).subscribe(() => {

      this.images.splice(index, 1);

      this.notifier.notify('success', 'Image removed');
    }, () => {
      this.notifier.notify('error', 'Error removing image');
    });
  }

  getPhotoDimensions(slide: QuestGalleryImage, image) {
    this.photoDimensions = image.naturalWidth + 'x' + image.naturalHeight;
    slide.questImageDimensions = this.photoDimensions;
  }
}
