import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { QUILL_CONFIG } from 'src/app/app.config';
import { MILESTONE_TASK_MAX_LENGTH } from 'src/app/app.constants';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { QuestService } from 'src/app/_services/quest.service';
import { DlMilestonesService } from '../service/dl-milestones.service';

@Component({
  selector: 'app-milestones-list',
  templateUrl: './milestones-list.component.html',
  styleUrls: ['./milestones-list.component.scss']
})
export class MilestonesListComponent implements OnInit, OnChanges {
  @Input() existingTasks: any;
  @Input() questId: any;
  @Output() milestoneUpdate: EventEmitter<any> = new EventEmitter();
  tasksToDeleteArr = [];

  milestonesForm: UntypedFormGroup;
  ngxConfig = {...QUILL_CONFIG};
  eventsSubject: Subject<void> = new Subject<void>();
  @ViewChild('imageUploadBox', { read: ElementRef }) imageUploadBox: ElementRef;
  imagePreview: any = '';

  @ViewChild('imageUploadInput', { read: ElementRef }) imageUploadInput: ElementRef;

  editGrpName: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private questService: QuestService,
    private milestoneService: DlMilestonesService
  ) { 
    this.createFormForMilestones();
  }

  ngOnInit() {
  }

  ngOnChanges(event) {
    if (this.existingTasks && this.existingTasks.length > 0) {
      let milestoneForm = this.milestonesForm.controls.milestone;
      
      this.existingTasks.forEach((group, i) => {
        if (i > 0 && i >= milestoneForm['controls'].length) {
          this.addNewMilestone();
        }

        milestoneForm['controls'][i].patchValue({
          name: group.name,
          existingId: group.id || group.existingId || null
        });

        let taskArrForm = milestoneForm['controls'][i].controls.questTasks;
        
        group.questTasks.forEach((_task, j) => {
          if (j > 0 && j >= milestoneForm['controls'][i].get('questTasks').controls.length) {
            this.addTask( milestoneForm['controls'][i] );
          }
          
          taskArrForm['controls'][j].patchValue({
            task: _task.task,
            title: _task.title || '',
            activities: _task.activities,
            activitiesIds: _task.activitiesIds,
            addedLink: _task.linkUrl ? _task.linkUrl : (_task.embeddedVideo && _task.embeddedVideo.videoUrl ? _task.embeddedVideo.videoUrl : null),
            imageUrl: _task.imageUrl ? _task.imageUrl : null,
            embeddedVideo: _task.embeddedVideo ? _task.embeddedVideo : null,
            linkUrl: _task.linkUrl ? _task.linkUrl : null,
            linkPreview: _task.linkPreview ? _task.linkPreview : null,
            linkedQuest: null,
            linkedQuestId: null,
            edit: _task.edit ? true : false,
            existingId: _task.id || _task.existingId || null,
            video: _task.video || null
          });
        });
      });
    }
  }

  createFormForMilestones() {
    this.milestonesForm = this.fb.group({
      milestone: this.fb.array([
      ])
    });

    const mileForm = this.milestoneService.createMilestoneGroupForm(this.milestonesForm.value.milestone.length);
    (this.milestonesForm.get('milestone') as UntypedFormArray).push(mileForm);
    // this.cdr.detectChanges();
  }

  addNewMilestone() {
    const mileForm = this.milestoneService.createMilestoneGroupForm(this.milestonesForm.value.milestone.length);
    (this.milestonesForm.get('milestone') as UntypedFormArray).push(mileForm);
    // console.log('156--', this.milestonesForm.value);
  }

  getMilestones() {
    return this.milestonesForm.get('milestone') as UntypedFormArray;
  }

  getTasks(group: any) {
    return group.get('questTasks') as UntypedFormArray;
  }

  addTask(milestone) {
    const _task = this.milestoneService.createTaskForGroupForm();

    (milestone.get('questTasks') as UntypedFormArray).push(_task);
    // console.log('181--', this.milestonesForm.value);
  }

  deleteTask(milestone, indexToDelete) {
    // console.log('147--delete-', milestone);
    // console.log('147--delete-', milestone.controls.questTasks.controls[indexToDelete]);

    const taskToDelete = milestone.controls.questTasks.controls[indexToDelete].value;
    milestone.controls.questTasks.removeAt(indexToDelete);

    if ( taskToDelete.existingId && taskToDelete.existingId !== '') {
      this.tasksToDeleteArr.push(taskToDelete.existingId);
      // emit data
      this.milestoneUpdate.emit({
        type: 'removed-task',
        milestoneForm: this.milestonesForm,
        tasksToDelete: this.tasksToDeleteArr
      });
    }
  }

  taskUpdated(event?) {
    this.milestoneUpdate.emit({
      type: 'update',
      milestoneForm: this.milestonesForm
    })
  }

  public checkIfAllTasksAreValid(checkFor) {
    if (!checkFor.valid) return false;

    let _valid = true;
    checkFor.value.questTasks.forEach(task => {
      _valid = task.activities && task.activities.length > 0;
      if (!_valid) return false;
    });
    
    return _valid;
  }

  checkIfAllGroupsAreValid() {
    const milestoneForm = this.milestonesForm.controls.milestone;
    // check all tasks groups are valid
    let _valid = true;
    milestoneForm['controls'].forEach(async (group) => {
      if (group.invalid && _valid) {
        _valid = false;
        return false;
      };

      _valid = this.checkIfAllTasksAreValid(group);
      if (!_valid) return false;
    });

    return _valid;
  }
}
