import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';
import { QuestInfo } from 'src/app/_interface/quest.types';

@Component({
  selector: 'app-quest-description',
  templateUrl: './quest-description.component.html',
  styleUrls: ['./quest-description.component.scss']
})
export class QuestDescriptionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() questInfo: QuestInfo;
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() mode?: 'wrapped' | 'unwrapped' = 'wrapped';
  currentText: string;
  stringStyle = false;
  readText = 'Read more';
  showMore = false;
  @ViewChild('description', { read: ElementRef }) descriptionEl: ElementRef;
  @ViewChild('readBtn', { read: ElementRef }) readBtn: ElementRef;
  clickedAtVal: any = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.cdr.detectChanges();

    setTimeout(() => {
      if (this.questInfo.questFeed && this.questInfo.questFeed.length > 0) {
        this.stringStyle = false;
        this.currentText = this.questInfo.questFeed;
      } else {
        this.stringStyle = true;
        this.currentText = this.questInfo.shortDescription;
      }

      setTimeout(() => {
        this.renderer.setStyle(this.descriptionEl.nativeElement, 'max-height', '100%');
        // check for the height of the content
        this.checkForContentAndShowBtn();
      }, 100);
    }, 100);
  }

  ngOnDestroy() {
    this.questInfo = null;
  }

  checkForContentAndShowBtn() {
    // considering single line occupies around 17px height
    if (this.descriptionEl.nativeElement.clientHeight > (17 * 13)) {
      this.renderer.setStyle(this.descriptionEl.nativeElement, 'max-height', (17 * 13)+'px');
      this.renderer.setStyle(this.readBtn.nativeElement, 'display', 'block');
    } else {
      this.renderer.setStyle(this.readBtn.nativeElement, 'display', 'none');
    }
  }

  toggleTextView() {
    this.showMore = !this.showMore;
    this.readText = this.showMore ? 'Read less' : 'Read more';

    if (this.showMore) {
      this.clickedAtVal = this.descriptionEl.nativeElement.getBoundingClientRect().top;
      this.renderer.setStyle(this.descriptionEl.nativeElement, 'max-height', 'none');
    } else if (this.descriptionEl.nativeElement.clientHeight > (17 * 13)) {
      window.scroll(0, this.clickedAtVal + (17 * 13));
      this.renderer.setStyle(this.descriptionEl.nativeElement, 'max-height', (17 * 13)+'px');
    }
  }
}
