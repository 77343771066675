import { Component, Input, OnInit } from '@angular/core';
import { Quest } from 'src/app/_interface/quest.types';

@Component({
  selector: 'app-parent-quest-team',
  templateUrl: './parent-quest-team.component.html',
  styleUrls: ['./parent-quest-team.component.scss']
})
export class ParentQuestTeamComponent implements OnInit {
  @Input() quests: Quest[];

  constructor() { }

  ngOnInit() {
  }

}
