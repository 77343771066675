import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/_store/app.reducers';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as fromQuestComponents from '../../main/quest/quest-comments/store/quest-components.reducer';

export interface WebviewNavigationDataType {
  url?: string;
  routerLink?: any[];
  type: string;
}

@Component({
  selector: 'app-quest-detail-comments',
  templateUrl: './quest-detail-comments.component.html',
  styleUrls: ['./quest-detail-comments.component.scss']
})
export class QuestDetailCommentsComponent implements OnInit {
  questId: number;
  userId: number;
  postId: string;
  loaded = false;
  authState: Observable<fromAuth.State>;
  commentsState: Observable<fromQuestComponents.State>;
  commentsStateSubscription: Subscription;
  webviewMode: boolean;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.authState = this.store.select('auth');
    this.commentsState = this.store.select('questComponents');
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);
      this.postId = this.route.snapshot.queryParams['postId']
      this.loaded = true;
    });
  }

  navigateTo(navigateData: WebviewNavigationDataType) {
  }
}
