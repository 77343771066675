import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

import * as ProfileActions from './profile.actions';
import * as fromProfile from './profile.reducer';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../_store/app.reducers';
import { GetAccountColors } from '../../account/store/account.actions';


@Injectable()
export class ProfileEffects {

  
  profileGetUserInfo = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.TRY_GET_USER_INFO),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/getuserinfo',
          {
            headers: headers,
            withCredentials: true
          }
        );
      }),
      mergeMap((data: fromProfile.State) => {
        if (!data['brand']) {
          data['brand'] = null;
        } 

        this.store.dispatch(new GetAccountColors({userId: data.id}));

        return [
          {
            type: ProfileActions.GET_USER_INFO,
            payload: data
          }
        ];
      })
    ));

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private store: Store<fromApp.AppState>
  ) {}
}
