import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';
import { NotificationType } from 'src/app/_interface/notification.types';
import { SearchResponse, SearchResult } from 'src/app/_interface/search.types';
import { QuestService } from 'src/app/_services/quest.service';
import { SearchService } from 'src/app/_services/search.service';
import { MyQuestsSevice } from '../../pages/my-quests/my-quests.service';
import { SelectFormMode, TryLogout } from '../auth/store/auth.actions';
import * as fromAuth from '../auth/store/auth.reducer';
import { DlLogInComponent } from '../dl-auth/dl-log-in/dl-log-in.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { QuestPreparedPhoto } from '../quest/quest.type';
import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import { NotificationsService } from './../notifications/notifications.service';
// import * as NotificationsActions from './../notifications/store/notifications.actions';
import * as fromNotifications from './../notifications/store/notifications.reducer';
import * as SearchActions from './../search/store/search.actions';

@Component({
  selector: '[id="header"]',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationsInfo: fromNotifications.State = fromNotifications.initialState;
  private destroyed$: Subject<void> = new Subject();
  @ViewChild('dropdownElementRef', { read: ElementRef }) dropdownElementRef: ElementRef;
  @ViewChild('profileMenuElement', { read: ElementRef }) profileMenuElement: ElementRef;
  @ViewChild('notificationMenuElement', { read: ElementRef }) notificationMenuElement: ElementRef;
  notificationsCount = 0;
  authenticated = true;
  showSubMenuOpt = false;
  private searchChangesSubscription: Subscription;
  private searchResultsSubscription: Subscription;
  searchText = '';
  isLoading = false;
  isLoadingMore = false;
  isOpened = false;
  loadmoreEnabled = false;
  startIndex = 0;
  newContentLoaded = false;
  usersQuests: { [key: number]: ExploreCardListType } = {};
  limit = 15;
  results: SearchResult[] = [];
  searchForm: UntypedFormGroup;
  private cleared$: Subject<void> = new Subject();
  showProfileMenu = false;
  menuIsOpened = false;
  userInfo = null;
  showNotificationsMenu = false;

  loadStep = 10;
  pagination = {
    from: 0,
    to: this.loadStep
  };
  notifications: NotificationType[] = [];

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private myQuestsService: MyQuestsSevice,
    private questService: QuestService,
    @Inject ('isServer') private isServer: boolean
  ) { }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.dropdownElementRef && !this.dropdownElementRef.nativeElement.contains(event.target) && this.isOpened) {
      this.close();
    }

    if (this.profileMenuElement && !this.profileMenuElement.nativeElement.contains(event.target) && this.showProfileMenu) {
      this.showProfileMenu = false;
    }

    if (this.notificationMenuElement && !this.notificationMenuElement.nativeElement.contains(event.target) && this.showNotificationsMenu) {
      this.showNotificationsMenu = false;
    }
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['']
    });

    this.store
      .pipe(
        select('auth'),
        distinctUntilKeyChanged('authenticated'),
        map((state: fromAuth.State) => {
          return state.authenticated;
        }),
        takeUntil(this.destroyed$),
        tap(authenticated => {
          this.authenticated = authenticated;

          if (this.authenticated) {
            this.getUserQuests();
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.store
      .pipe(
        select('userInfo'),
        map((state: fromProfile.State) => {
          return state;
        }),
        takeUntil(this.destroyed$),
        tap(_state => {
          this.userInfo = _state;
          
          if ((this.userInfo && this.userInfo !== null && this.userInfo.id && this.userInfo.id !== null)) {
            this.notificationsService.getNotifications(this.userInfo.id, 0, this.loadStep).subscribe((res: NotificationType[]) => {
              this.notifications = res;
            });
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();
      
    this.store
      .pipe(
        select('notifications'),
        takeUntil(this.destroyed$),
        tap((res: fromNotifications.State) => {
          this.notificationsInfo = res;
        })
      )
      .subscribe();

    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('count'),
        takeUntil(this.destroyed$),
        map((state) => state.count),
        tap(count => {
          this.notificationsCount = count;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    // this.store.dispatch(new NotificationsActions.ResetNotifications());
  }

  showSearch(): void {
    this.store.dispatch(new SearchActions.ShowSearch());
  }

  hideSearch(): void {
    this.store.dispatch(new SearchActions.HideSearch());
  }

  toggleSearch(): void {
    if (this.notificationsInfo.active) {
      this.hideSearch();
    } else {
      this.showSearch();
    }
  }

  openModal(openOf: string, mode?: string) {
    if (this.isServer) return;
    
    this.menuIsOpened = false;
    if (openOf === 'notifications-modal') {
      this.modalService.open(NotificationsComponent, {
        scrollable: false
      });
      
      return;
    }

    this.modalService.open(DlLogInComponent, {
      scrollable: false,
      windowClass: "no-overflow"
    });
    setTimeout(() => {
      this.store.dispatch(new SelectFormMode(fromAuth.FormMode[mode || 'logIn']));
    }, 100);
  }

  onFocus(isMobile?: boolean): void {
    if (!this.searchChangesSubscription || this.searchChangesSubscription.closed) {
      this.searchChangesSubscription = this.subscribeForSearchChanges(isMobile);
    }
    if (!this.searchResultsSubscription || this.searchResultsSubscription.closed) {
      this.searchResultsSubscription = this.subscribeForSearchResultChanges();
    }
    
    if (!this.searchText) {
      this.isLoading = true;
      this.isLoadingMore = false;
      this.cdr.detectChanges();

      this.loadMore(true, true, false);
    }

    if (!isMobile) this.open();
  }

  close() {
    // if (this.isItemClicked) return;
    this.isOpened = false;
    this.clear();
  }

  private clear(): void {
    if (this.searchChangesSubscription) {
      this.searchChangesSubscription.unsubscribe();
    }
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe();
    }
    this.cleared$.next();
    this.cleared$.complete();
    this.searchForm.patchValue({
      search: ''
    }, {
      onlySelf: true,
      emitEvent: false
    });
    this.searchText = '';
    this.isLoading = false;
    this.isLoadingMore = false;
  }

  private subscribeForSearchChanges(isMobile?: boolean): Subscription {
    // * open search box if text in input is changed
    return this.searchForm.get('search').valueChanges
    .pipe(takeUntil(this.cleared$))
    .subscribe(() => {    
      // this.isItemClicked = false;
      if (!this.isOpened && !isMobile) {
        this.open();
      }
    });
  }

  private subscribeForSearchResultChanges(): Subscription {    
    return this.searchForm.get('search').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.cleared$),
      switchMap(val => {
        this.searchText = val;
        this.resetResults();
        this.isLoading = true;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
        return this.forceLoadMore();
      })
    ).subscribe((searchResponse: SearchResponse) => {
      this.updateResults(searchResponse);
    }, (err) => {
      console.log(err);
      this.isLoading = false;
      this.isLoadingMore = false;
      this.cdr.detectChanges();
    });
  }

  private updateResults(searchResponse: SearchResponse): void {
    if (searchResponse.data) {
      this.loadmoreEnabled = searchResponse.more;
      this.startIndex = searchResponse.start + searchResponse.limit;
      this.results = [...this.results, ...searchResponse.data];
      
      setTimeout(() => {
        this.newContentLoaded = true;
        this.cdr.detectChanges();
      });
    }
    this.isLoadingMore = false;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private getUserQuests(): void {
    forkJoin([
      this.myQuestsService.getAllActiveQuestsForUser(),
      this.myQuestsService.getQuestsCompletedForUser()
    ]).subscribe(([activeQuests, completedQuests]) => {
      [...activeQuests, ...completedQuests].forEach(quest => {
        if (!this.usersQuests.hasOwnProperty(quest.id)) {
          this.usersQuests[quest.id] = quest;
        }
      });
    });
  }

  private resetResults(): void {
    this.loadmoreEnabled = true;
    this.startIndex = 0;
    this.results = [];
    this.cdr.detectChanges();
  }
  private forceLoadMore(): Observable<SearchResponse> {
    return this.searchService.getSearchResults({
      q: this.searchText.toLocaleLowerCase(),
      _start: this.startIndex,
      _limit: this.limit,
      type: 'global'
    });
  }

  loadMore(resetResults: boolean, showCentralSpinner: boolean, showBottomSpinner: boolean): any {
    if (resetResults) {
      this.resetResults();
    }
    if (this.loadmoreEnabled) {
      if (showCentralSpinner) {
        this.isLoading = true;
      }
      if (showBottomSpinner) {
        this.isLoadingMore = true;
      }
      this.cdr.detectChanges();

      this.forceLoadMore().pipe(take(1)).subscribe((searchResponse: SearchResponse) => {
        this.updateResults(searchResponse);
      }, (err) => {
        console.log(err);
        this.isLoading = false;
        this.isLoadingMore = false;
        this.cdr.detectChanges();
      });
    }
  }

  open(): void {
    this.isOpened = true;
    this.cdr.detectChanges();
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  selectItem(index: number): void {
    const selected = this.results[index];
    let url = [];

    if (selected.type === 'quest') {
      // tslint:disable-next-line: max-line-length
      url = ['/quest-detail', selected.questId, (this.usersQuests.hasOwnProperty(selected.questId) ? this.usersQuests[selected.questId].seoSlugs.userId : selected.userId)];
    } else if (selected.type === 'user') {
      url = ['/profile', selected.userId];
    }
    this.router.navigate(url);
    this.menuIsOpened = false;
    this.openSearchMobile = false;

    this.close();
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  toggleNotificationsMenu() {
    this.showNotificationsMenu = !this.showNotificationsMenu;
  }

  logoutUser() {
    this.menuIsOpened = false;
    this.showProfileMenu = false;
    this.store.dispatch(new TryLogout());
  }

  goToThatUser(userId, item) {
    this.notificationsService.readNotification(item).subscribe((_val) => {
      this.router.navigate(['/profile/' + userId]);
      this.toggleNotificationsMenu();
    });
  }

  goToThatPage(item: NotificationType) {
    this.notificationsService.readNotification(item).subscribe((_val) => {
      const destinationURL = (route) => [route, item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
      ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
      : item.userId)]
      switch (item.type) {
        case 'FRIEND_REQUEST':
          this.router.navigate(['/connections/requests']);
          break;

        case 'PROFILE_BACKED':
          this.router.navigate(['/profile']);
          break;

        //Newsfeed
        case 'COMMENT':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
              queryParams: { postId: item.comment && item.comment.id }
            });
          break;

        case 'COMMENT_LIKED':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'COMMENT_MENTION':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'COMMENT_REPLIED':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'PHOTO_VIDEO_ADDED':
          this.router.navigate(destinationURL('/quest-detail'), {
            queryParams: { gallery: true }
          });
          break;
          
        case 'QUEST_ACHIEVED':
        case 'QUEST_SAVED':
        case 'QUEST_STARTED':
        case 'QUEST_BACKED':
        case 'EVENT_STARTED':
        case 'FUNDRAISER_STARTED':
        case 'MILESTONE_ACHIEVED':
        case 'FOLLOWED':
        default:
          this.router.navigate(['/quest-detail', item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)]);
          break;
      }
      this.toggleNotificationsMenu();
    });
  }

  openSearchMobile = false;
  toggleSearchForMobile() {
    this.openSearchMobile = !this.openSearchMobile;
    this.close();
  }

  clearSearchForMobile() {
    this.cleared$.next();
    this.cleared$.complete();
    this.searchForm.patchValue({
      search: ''
    }, {
      onlySelf: true,
      emitEvent: false
    });
    this.searchText = '';
    this.isLoading = false;
    this.isLoadingMore = false;
    this.results = [];
  }
}
