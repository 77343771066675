import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mention, MentionListItem } from '../quest-comments.component';
import { SearchService } from 'src/app/_services/search.service';
import { ProfileAvatarService } from 'src/app/components/separate/profile-avatar/profile-avatar.service';
import { SearchResponse } from 'src/app/_interface/search.types';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  closeEditModeEventsSubject: Subject<void> = new Subject<void>();
  closeEditModeEventsSubjectSubscription: Subscription;

  tributeOptions = {
    values: (text, cb) => {
      this.remoteSearch(text, (mentions: Mention[]) => cb(mentions));
    },
    menuItemTemplate: (mention: MentionListItem) => {
      let avatar;
      if (mention.original.profilePictureURL) {
        // tslint:disable-next-line:max-line-length
        avatar = `<div class="c-avatar__inner" style="background-image: ${mention.original.profilePictureURL ? ('url(' + mention.original.profilePictureURL + ')') : 'none'};"></div>`;
      } else {
        const userNameArray = mention.original.key.split(' ');
        const firstLetter = userNameArray[0].substr(0, 1);
        const secondLetter = userNameArray.length > 1 ? userNameArray[1].substr(0, 1) : '';
        // tslint:disable-next-line:max-line-length
        avatar = `<div class="c-avatar__inner" style="background-color: ${this.profileAvatarService.getColor()};"><span>${firstLetter + secondLetter}</span></div>`;
      }
      return `
        <div class="tribute-container__avatar c-avatar">
          ${avatar}
        </div>
        <div class="tribute-container__ttl">${mention.original.key}</div>
      `;
    }
  };

  constructor(
      private searchService: SearchService,
      private profileAvatarService: ProfileAvatarService
  ) {}
  remoteSearch(text: string, cb) {
    this.forceLoadMore(text).subscribe((mentions: Mention[]) => {
      cb(mentions);
    });
  }
  forceLoadMore(text: string): Observable<Mention[]> {
    return this.searchService.getSearchResults({
      q: text.toLocaleLowerCase(),
      _start: 0,
      _limit: 20,
      type: 'people'
    }).pipe(
      map((res: SearchResponse) => {
        return res.data.map((user) => {
          return {
            key: user.userFullName,
            value: user.userName,
            profilePictureURL: user.profilePictureURL
          };
        });
      })
    );
  }
  getTributeOptions() {
    return this.tributeOptions;
  }
  triggerEditMode() {
    this.closeEditModeEventsSubject.next();
  }
}
