import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import * as Constants from '../../../../../app.constants';

@Component({
  selector: 'app-brand-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit {
  @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() formSubmitted = false;
  businessForm: UntypedFormGroup;
  messages = Constants.VALIDATION_MESSAGES;

  organizationTypesArr = [{
    label: "Company",
    value: "company"
  }, {
    label: "Individual",
    value: "individual"
  }, {
    label: "Non-Profit",
    value: "non_profit"
  },{
    label: "Government Entity",
    value: "government_entity"
  }];
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
  ) {
    const websiteRegex = new RegExp('^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*', 'i');
    this.businessForm = this.fb.group({
      'businessName': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      'website': ['', [Validators.required, Validators.pattern(websiteRegex)]],
      'orgType': ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.businessForm.valueChanges.subscribe(value => {
      this.valueChange.emit({
        type: 'basic',
        isFormValid: this.businessForm.valid,
        formValues: this.businessForm.value
      })
    });
  }

  selectedOrgType() {

  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }
}
