import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { SnotifyService } from 'ng-snotify';
import { Observable, ObservableInput, ReplaySubject, Subscription, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { DiemlifeCommonsModelService, QuestActivityTracker } from 'src/app/_interface/diemlife-commons-model.service';
import { DlMilestonesClearData, SetMilestones } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { DlMilestoneManageModes, TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';
import { MilestonesLogic } from 'src/app/_interface/milestones.logic';
import { QuestLogic } from 'src/app/_interface/quest.logic';
import { EventStatus, Quest, QuestDoer, QuestGalleryImage, QuestLite, QuestTask, QuestUserInfo } from 'src/app/_interface/quest.types';
import { CloseTeamModal, JoinTeam, OpenTeamModal, TeamJoined } from 'src/app/_interface/store/quest-detail.actions';
import { QuestTeam } from 'src/app/_interface/team.types';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { environment } from 'src/environments/environment';
import { QuestLoggedActivities } from '../../../_interface/store/quest-detail.actions';
import { QuestService } from '../../../_services/quest.service';
import { SnotifyConfigService } from '../../../_services/snotify-config.service';
import { AppState, questDetailState } from '../../../_store/app.reducers';
import { ImagePositionZoomComponent } from '../../../modules/shared-module/components/image-position-zoom/image-position-zoom.component';
import { ImageUploadComponent } from '../../../modules/shared-module/components/image-upload/image-upload.component';
import { MobileSideNavService } from '../../../modules/shared-module/components/mobile-side-nav/mobile-side-nav.service';
import { DlLogInComponent } from '../../main/dl-auth/dl-log-in/dl-log-in.component';
import { DlMilestonesService } from '../../main/dl-milestones/service/dl-milestones.service';
import { SaveFundraiserPayload } from '../../main/funds-modal/funds-modal.component';
import { QuestDropdownContext } from '../../main/quest/quest-dropdown/quest-dropdown.component';
import { QuestDetailStatus, UploadTeamImageModalMode } from '../../main/quest/quest.type';
import { LogActivityComponent } from '../../separate/log-activity/log-activity.component';
import { MyQuestsSevice } from '../my-quests/my-quests.service';
import { ProfileService } from '../profile/profile.service';
import * as fromProfile from '../profile/store/profile.reducer';

@Component({
  selector: 'app-quest-detail',
  templateUrl: './quest-detail.component.html',
  styleUrls: ['./quest-detail.component.scss'],
})
export class QuestDetailComponent implements OnInit, OnDestroy {
  @ViewChild('teamNewName') teamNewName: ElementRef;
  userInfo: QuestUserInfo;
  currentUser: QuestUserInfo;
  status: QuestDetailStatus;
  questId: number;
  userId: number;
  userInfoState: Observable<fromProfile.State>;
  dlMilestoneState: Observable<TaskGroupsState>;
  milestoneSubscription: Subscription;
  private authSubscription: Subscription;
  private routeParamsSubscription: Subscription;
  private questSubscription: Subscription;
  private questTeamsSubscription: Subscription;
  private joinedTeamSubscription: Subscription;
  private questDetailSubscription: Subscription;

  private viewerActivityTracker: QuestActivityTracker;

  allowEditQuest = false;
  viewerActivityStatus: string;
  hasUrlUserQuestActivity = false;
  hasCurrentUserQuestActivity = false;
  isCurrentUsersRepresentation = false;
  isCurrentUserHasFundraisingLink = false;
  isCurrentUserAdminOrOwner = false;
  isCurrentUserQuestDoer = false;
  isDoerHasFundraisingLink = false;
  fundraiseModalMode: string;
  // start | edit
  fundraiseModalAction: string = 'start'
  fundraisingLink: FundraisingLinkType = null;
  fundraisingInited = false;
  editableQuest: boolean;
  teamsWithoutFundraising: boolean;

  fundraisingButtonPresent = false;
  fundraisingButtonForcedHidden = false;
  renameMode = false;
  isLoaded = false;
  isProgress = false;
  data: Quest;
  openedModal: string;
  hasEvent: boolean;
  tabs = {
    items: [
      {key: 'details', value: 'Details', available: true},
      {key: 'comments', value: 'FEED', available: true},
      {key: 'people', value: 'People', available: true},
    ],
    selected: 'details'
  };
  galleryPhotoIsLoading: boolean;
  youtubeUrl: SafeResourceUrl;
  viewStatus: number;
  members: QuestDoer[] = [];
  teams: QuestTeam[] = [];
  parentTeamQuests: any[] = [];
  team: QuestTeam = null;
  teamCreator: fromProfile.State = null;
  allowCheckMilestones = false;
  uploadTeamImageModalMode = UploadTeamImageModalMode;
  selectedUploadTeamImageModalMode: string = '';
  milestoneModes = DlMilestoneManageModes;
  isEventRegister = false;
  eventStatus: EventStatus;
  eventStatusDate: Date;
  megaQuests: QuestLite[] = [];
  //CTA = Start | Join | Support btn
  hideCTABtn = false;
  isFirstTimeLoaded: boolean = false;
  allTeams = []; //includes parent and child
  defaultTeamData: QuestTeam;
  dropdownContext: QuestDropdownContext;
  modalReference: any = null;

  @ViewChild('startQuestTeamModal', { read: TemplateRef }) startQuestTeamModal: TemplateRef<any>;

  @ViewChild('fundraiseModal', { read: TemplateRef }) fundraiseModalElem: TemplateRef<any>;

  @ViewChild('backFundraiserModal', { read: TemplateRef }) backFundraiserModal: TemplateRef<any>;

  @ViewChild('startQuestMultiTeamModal', { read: TemplateRef }) startQuestMultiTeamModal: TemplateRef<any>;

  showQuestOptions = false;

  @ViewChild('shareOptionsBox', { read: ElementRef }) shareOptionsBox: ElementRef;

  public environment = environment;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  openSideNavMobile: boolean = false;

  questImages: any[] = [];

  selectedPeopleTab = 'teams';

  isCurrentUserAdminOrOwnerOfNotDefaultTeam: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questService: QuestService,
    private profileService: ProfileService,
    private rootStore: Store<AppState>,
    private store: Store<AppState>,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private myQuestsSevice: MyQuestsSevice,
    private sanitizer: DomSanitizer,
    private milestonesService: DlMilestonesService,
    private notifier: NotifierService,
    private mobileSideNavService: MobileSideNavService,
    @Inject ('isServer') private isServer: boolean
  ) {
    this.userInfoState = this.store.select('userInfo');
    this.dlMilestoneState = this.store.select('dlMilestones');
    
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.destroyAllParams();
        this.ngOnInit()
      }
    });
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.shareOptionsBox && !this.shareOptionsBox.nativeElement.contains(event.target)) {
      this.showQuestOptions = false;
    }
  }

  ngOnInit() {
    this.store.dispatch(new QuestLoggedActivities([]));
    
    this.youtubeUrl = null;
    this.isLoaded = false;
    this.isFirstTimeLoaded = false;
    this.routeParamsSubscription = this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);

      // 
      this.tabs.items[2].available = ((!environment.production && this.questId !== 115) || (environment.production && this.questId !== 222));

      this.getMembers();

      this.loadPageData();

      this.getQuestImages();
    });

    this.userInfoState.pipe(takeUntil(this.destroyed$), take(1))
      .subscribe(currentUser => {
      this.currentUser = currentUser;
      this.authSubscription = this.store.pipe(
        select('auth'),
        map(state => state.authenticated),
        distinctUntilChanged(),
      ).subscribe(authenticated => {

        if (authenticated) {
          this.viewStatus = null;
        }

        this.userInfoState.pipe(take(1)).subscribe((newCurrentUser: fromProfile.State) => {
          const isDifferentUser = (previousUser: fromProfile.State, nextUser: fromProfile.State): boolean => {
            if (previousUser && nextUser) {
              return previousUser.id !== nextUser.id;
            } else {
              return !(!previousUser && !nextUser);
            }
          };

          let currentUserChanged = false;
          if (authenticated) {
            if (newCurrentUser && newCurrentUser.id && isDifferentUser(this.currentUser, newCurrentUser)) {
              this.currentUser = newCurrentUser;

              currentUserChanged = true;
            } else {
              return;
            }
          } else {
            if (this.currentUser && this.currentUser.id) {
              this.currentUser = null;

              currentUserChanged = true;
            }
          }

          // if (userChangeSubscription) {
          //   userChangeSubscription.unsubscribe();
          // }

          if (currentUserChanged) {
            console.log('Current user changed - reloading', this.currentUser);
            this.loadPageData();
          }
        });
      });
    });

    this.milestoneSubscription = this.dlMilestoneState.subscribe(() => {
      if (this.data && this.data.quest) {
        this.updateQuestCompletionPercent(this.data.quest.id, this.getQuestCompletionUser());
      }
    });

    this.joinedTeamSubscription = this.rootStore.pipe(
      select(questDetailState),
      map(state => state.joinedTeam)
    ).subscribe(team => {
      if (team) {
        this.isProgress = false;
        this.rootStore.dispatch(new TeamJoined(null));
        setTimeout(() => {
          this.router.navigate(['my-quests']);
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.destroyAllParams();
  }

  destroyAllParams() {
    // making all the variables as null
    this.questId = null;
    this.allowEditQuest = false;
    this.data = null;
    this.teams = [];
    this.allTeams = [];
    this.defaultTeamData = null;
    this.fundraisingLink = null;
    this.isDoerHasFundraisingLink = null;
    this.fundraisingButtonPresent = null;
    this.fundraisingButtonForcedHidden = null;
    this.isCurrentUserHasFundraisingLink = null;
    this.isCurrentUserAdminOrOwner = null;
    this.fundraisingInited = null;
    this.youtubeUrl = null;
    this.editableQuest = null;
    this.tabs.selected = 'details';

    this.activeModal.close();
    this.closeModal();



    if (this.questSubscription) {
      this.questSubscription.unsubscribe();
    }
    if (this.questTeamsSubscription) {
      this.questTeamsSubscription.unsubscribe();
    }
    if (this.joinedTeamSubscription) {
      this.joinedTeamSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.questDetailSubscription) {
      this.questDetailSubscription.unsubscribe();
    }
    if (this.milestoneSubscription) {
      this.milestoneSubscription.unsubscribe();
    }

    this.store.dispatch(new DlMilestonesClearData());
  }

  ngAfterViewInit() {
    this.questSubscription = this.questService.getUpdateForQuest().subscribe((status: string) => {
      switch (status) {
        case 'gallery':
          this.updateGallery();
          break;
        case 'saved':
          this.getMembers();
          break;
        case 'doQuest':
          this.doQuest();
          break;
        default:
          break;
      }
    });

    if (this.route.snapshot.queryParams['gallery']) {
      this.waitGallery();
    }

  }

  async waitGallery() {
    while(!document.getElementById("galleryKey")) {
      await new Promise(r => setTimeout(r, 500));
    }
    document.getElementById("galleryKey").scrollIntoView({behavior:"smooth", block:"start"});
  }


  loadPageData(): void {
    this.isLoaded = false;
    if (!this.isFirstTimeLoaded) this.data = null;
    this.teams.length = 0;
    this.team = null;
    this.teamCreator = null;
    this.youtubeUrl = null;

    this.isDoerHasFundraisingLink = false;
    this.isCurrentUserHasFundraisingLink = false;
    this.isCurrentUserAdminOrOwner = false;

    this.fundraisingButtonPresent = false;
    this.fundraisingButtonForcedHidden = false;
    this.fundraisingInited = false;


    this.questService.getQuestDetail(this.questId, this.userId).pipe(take(1)).subscribe((questData) => {
      this.data = questData;

      if (this.data.megaQuests || this.data.megaQuests.length || this.data.isTeamPage) {
        this.getMegaQuests();
      }

      if (!this.data.quest.fundraising) {
        this.teamsWithoutFundraising = this.data.quest['multiTeamsEnabled'] && this.data.quest.mode == 'diyMode';
      }

      this.questService.checkQuestCustomColorAndApply(this.data, this.currentUser.id, this.userId);

      this.milestonesService.getQuestGroupTasks(this.questId, this.userId).subscribe((taskGroups: TaskGroup[]) => {
        this.store.dispatch(new SetMilestones(taskGroups));
      });

      const sources: [ObservableInput<QuestTeam[]>, ObservableInput<FundraisingLinkType>, ObservableInput<FundraisingLinkType>, ObservableInput<Quest[]>] = [

        this.isQuestInTeamMode() || this.isMegaQuest()
          ? this.questService.getQuestTeams(this.questId).pipe(take(1))
          : of([]).pipe(),

        this.data.quest.fundraising || this.data.isQuestFundraiser || this.isMegaQuest()
          ? this.questService.getFundraisingLink(this.questId, this.userId).pipe(take(1))
          : of(null).pipe(),

        (this.data.quest.fundraising || this.data.isQuestFundraiser) && this.currentUser && this.currentUser.id
          ? this.questService.getFundraisingLink(this.questId, this.currentUser.id).pipe(take(1))
          : of(null).pipe(),

        this.isMegaQuest()
          ? this.questService.getParentQuestTeams(this.questId, this.userId).pipe(take(1))
          : of([]).pipe()

      ];

      forkJoin(sources).subscribe(([questTeams, doersLink, currentUsersLink, parentTeamQuests]) => {
        this.teams = [];
        this.teams.push(...questTeams);
        // remove default team from the teams list
        this.teams = this.teams.filter(team => !team.defaultTeam);
        this.allTeams.push(...questTeams);
        
        this.team = questTeams.filter(team => team.teamId === this.data.doerTeamId).pop();
        this.isCurrentUserAdminOrOwnerOfNotDefaultTeam = this.team && !this.team.defaultTeam;

        if (this.isMegaQuest()) {
          this.allTeams = [];
          let teams = [];
          let _parentTeamQuests: any = parentTeamQuests;

          _parentTeamQuests.forEach(team => {
            // check if team is quest
            if (team && team.questTitle && team.questFromEdgeDetail) {
              let _tm = team.questFromEdgeDetail;
              _tm.teamName = team.questTitle;
              teams.push(_tm);
            } else if (team && team.teamName){
              teams.push(team);
            }
          });

          const _questTeams = questTeams.filter(team => !team.defaultTeam);
          this.allTeams.push(...teams, ..._questTeams);
        }

        const defaultTeamData = questTeams.filter(team  => team.defaultTeam === true );
        if (this.isParentQuest() && defaultTeamData.length > 0 && this.allTeams.length !== 1) {
          this.defaultTeamData = defaultTeamData[0];
          this.team = this.defaultTeamData;

          // add default team to be used as general donation
          this.allTeams.push(this.defaultTeamData);
        } else if (this.data && this.data['questFromEdgeDetail'] && this.data['questFromEdgeDetail'] !== null && (!this.team || this.team == null)) {
          // this means that the data is questFromEdgeDetail is of Default Team
          this.team = this.data['questFromEdgeDetail'];
        }

        if (this.team) {
          this.profileService.getUserInfoById(this.team.creatorId).pipe(take(1)).subscribe((profile: fromProfile.State) => {
            this.teamCreator = profile;
          });
        }

        this.fundraisingLink = doersLink;
        this.isDoerHasFundraisingLink = !!doersLink;
        this.fundraisingButtonPresent = !!doersLink;
        this.fundraisingButtonForcedHidden = !!doersLink && !doersLink.displayBtn;
        this.isCurrentUserHasFundraisingLink = !!currentUsersLink && doersLink && currentUsersLink && doersLink.id === currentUsersLink.id;
        this.isCurrentUserAdminOrOwner = !!(this.data.quest && this.currentUser && ((this.data.quest.admins && this.data.quest.admins.indexOf(this.currentUser.email) >= 0) || this.data.quest.user.id === this.currentUser.id));
        this.fundraisingInited = true;
        
        this.hideCTABtn = !this.data.quest.backBtnDisabled || this.hasEvent || this.isQuestInMultiTeamMode() || this.fundraisingButtonPresent;

        if (parentTeamQuests && parentTeamQuests.length > 0) {
          this.parentTeamQuests = [];
          this.parentTeamQuests.push(...parentTeamQuests);
          this.parentTeamQuests = this.parentTeamQuests.filter(q => (q.questTitle.toLowerCase().indexOf('general fund') < 0 && q.questTitle.toLowerCase().indexOf('general donation') < 0));
        }

        this.getQuestInfo();
      });

      this.userInfoState.pipe(take(1)).subscribe((currentUser: fromProfile.State) => {
        this.dropdownContext = {
          id: this.data.quest.id,
          title: this.data.quest.title,
          mode: this.data.quest.mode,
          shareUrl: this.data.questShareUrl,
          copyable: false, //this.data.quest.copyAllowed,
          followed: this.data.userHasFollowed,
          saved: this.data.userHasSaved,
          active: QuestService.hasUserActivity(this.data.activityInfo, currentUser.id),
          editable: QuestService.isEditableByUser(this.data.quest, currentUser),
          privacyLevel: this.data.quest.privacyLevel,
          createdBy: this.data.quest.createdBy,
          hasEvent: this.data.hasEvent,
          isQuestFundraiser: this.data.isQuestFundraiser,
          isMegaQuest: this.data.isMegaQuest,
          questTasks: this.data.questTasks,
          geoTriggerEnabled: this.data.quest.geoTriggerEnabled
        };
      });
    });
  }

  getQuestInfo() {
    this.allowCheckMilestones = false;
    this.isLoaded = false;
    this.editableQuest = false;
    this.fundraiseModalMode = null;

    this.hasEvent = this.data.hasEvent;
    if (this.hasEvent && this.data.event.registerStatus && this.isEventDateInFuture()) {
      this.isEventRegister = this.data.event.registerStatus.status !== EventStatus.EVENT_ENDED;
      this.eventStatusDate = new Date(this.data.event.registerStatus.date);
      this.eventStatus = this.data.event.registerStatus.status;
    } else {
      this.eventStatus = (this.isEventDateInFuture() && this.isEventActive()) ? EventStatus.REGISTER_PROGRESS : EventStatus.EVENT_ENDED;
    }

    if (this.data.questVideoUrl) {
      this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.questVideoUrl);
    }

    this.status = QuestDetailStatus.Public;

    this.allowEditQuest = QuestService.isEditableByUser(this.data.quest, this.currentUser);
    this.viewerActivityTracker = DiemlifeCommonsModelService.buildQuestActivityTracker(this.data.viewerActivityInfo, this.currentUser);
    this.hasUrlUserQuestActivity = QuestService.hasUserActivity(this.data.activityInfo, this.userId);
    this.hasCurrentUserQuestActivity = this.viewerActivityTracker && this.viewerActivityTracker.hasUserActivity();
    this.isCurrentUsersRepresentation = this.currentUser && this.currentUser.id === this.userId;
    this.status = this.buildViewerDetailsStatus();
    if (this.data.viewerActivityInfo && this.data.viewerActivityInfo.status) {
      this.viewerActivityStatus = this.data.viewerActivityInfo.status;
    }

    this.getQuestCompletionPercent(this.questId, this.getQuestCompletionUser()).subscribe(() => {
      this.allowCheckMilestones = MilestonesLogic.milestonesCheckingAllowed(this.data, this.currentUser);
    });

    this.editableQuest = QuestService.isEditableByUser(this.data.quest, this.currentUser);

    this.isLoaded = true;
    this.isFirstTimeLoaded = true;

    
    let openMoveTo: string = localStorage.getItem('openMoveTo') || '';
    openMoveTo = openMoveTo === '' ? '' : JSON.parse(openMoveTo);
    const stateAuth = localStorage.getItem('stateAuth') ? JSON.parse(localStorage.getItem('stateAuth')) : null;
    if (localStorage.getItem('stateUserInfo') && stateAuth && stateAuth.token && stateAuth.token !== ''
      && localStorage.getItem('openMoveTo') && openMoveTo !== '') { 
      setTimeout(() => {
        this.activeModal.close();
        this.closeModal();

        if (['fundraise'].indexOf(openMoveTo) > -1) {
          this.openStartFundraiserModal(openMoveTo);
        } else if (['start-quest-in-multi-team-modal'].indexOf(openMoveTo) > -1) {
          this.openStartMultiTeamModel()
        }
        localStorage.removeItem('openMoveTo')
      }, 700);
    }
  }

  private getMembers() {
    // not calling the api if the quest is start DIEMlife
    this.isCurrentUserQuestDoer = this.questId === 222 ? true : false;
    if ((!environment.production && this.questId === 115) || (environment.production && this.questId === 222)) return false;

    this.members = [];
    this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
      this.members.push(...members);
      // if memberStatus/QuestMemberStatus is Backer or Doer
      if (this.currentUser && this.currentUser.id) {
        this.isCurrentUserQuestDoer = this.questId === 222 ? true : QuestLogic.checkIfCurrentUserQuestDoer(this.members, this.currentUser.id);
      }
    });
  }

  get isDefaultTeamOnlyPresent(): boolean {
    return this.teams.length === 1 && this.teams[0].creatorId === this.userId;
  }

  getQuestCompletionUser(): number {
    if (this.hasCurrentUserQuestActivity && this.isModePaceYourself()) {
      return this.currentUser.id;
    } else if (this.hasCurrentUserQuestActivity && this.isModeTeam() && this.team) {
      return this.team ? this.team.creatorId : this.userId;
    } else {
      if (this.hasUrlUserQuestActivity) {
        return this.userId;
      } else {
        return this.data.quest.createdBy;
      }
    }
  }

  getQuestCompletionPercent(questId: number, userId: number) {
    return new Observable((observer) => {
      this.questService.getQuestCompletionPercent(questId, userId).pipe(take(1)).subscribe((completion: any) => {
        this.data = {...this.data, ...completion};
        observer.next();
        observer.complete();
      }, () => {
        observer.complete();
      });
    });
  }

  private updateQuestCompletionPercent(questId: number, userId: number) {
    this.getQuestCompletionPercent(this.questId, userId).subscribe(() => {
    });
  }

  openModal(modalContent: any) {
    if (this.isServer) return;

    if (!this.currentUser || (this.currentUser && !this.currentUser.id)) {
      this.modalService.open(DlLogInComponent, {
        windowClass: "no-overflow"
      })
      return false;
    }

    this.modalReference = this.modalService.open(modalContent, {
      windowClass: 'no-overflow',
      // beforeDismiss: () => {
      //   if (window.confirm('Are you sure you want to close?')) {
      //     return true;
      //   }

      //   return false;
      // }
    });
  }

  closeModal() {
    if (this.modalReference) this.modalReference.close();
    this.modalReference = null;
  }

  doQuest(): void {
    if (this.isProgress || this.hasCurrentUserQuestActivity) {
      return;
    }
    this.isProgress = true;
    this.questService.doQuest(this.questId, this.userId, null, this.data.quest.geoTriggerEnabled).subscribe(started => {
      this.isProgress = false;
      this.afterDoQuest(started);
    }, () => {
      this.isProgress = false;
      this.notifier.notify('error', 'Error starting Quest.');
    });
  }

  afterDoQuest(started) {
    this.closeModal();
    if (started) {
      let notificationWording;
      if (this.isQuestInTeamMode()) {
        notificationWording = `Successfully joined "${this.data.quest.title}"!`;
      } else {
        notificationWording = `Successfully started "${this.data.quest.title}"!`;
      }
      this.notifier.notify('success', notificationWording);
      this.router.navigate(['/my-quests', 'active']);
    } else {
      this.notifier.notify('warning', 'Quest already active.');
    }
  }

  isQuestInTeamMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'diyMode';
  }

  isMegaQuest() {
    return this.data && this.data.isMegaQuest;
  }

  isParentQuest() {
    return this.data && this.data.isParentQuest;
  }

  isQuestInMultiTeamMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'diyMode' && this.data.quest['multiTeamsEnabled'] && !this.data.quest.fundraising;
  }

  isQuestInSupportMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'viewOnlyMode';
  }

  isRegisterButtonEnabled() {
    return this.data.isRegisterButtonEnabled;
  }

  isDoerPurchasedTickets() {
    return this.data.isDoerPurchasedTickets;
  }

  private getCurrentUserTeam(): QuestTeam {
    if (this.currentUser && this.teams && this.teams.length) {
      return this.teams.find(team => team.memberIds.includes(this.currentUser.id));
    } else {
      return null;
    }
  }

  confirmRepeatQuest() {
    this.snotifyService.confirm(
      `Want to do "${this.data.quest.title}" again?`,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.repeatQuest();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  repeatQuest() {
    if (this.data.viewerActivityInfo.repeatable && this.isStatusComplete() && !this.data.quest.fundraising) {
      this.isProgress = true;
      this.myQuestsSevice.repeatQuest(this.data.quest.id, this.data.quest.geoTriggerEnabled).subscribe(() => {
        this.isProgress = false;
        this.notifier.notify('success', `Successfully restarted Quest`);
        this.router.navigate(['/my-quests', 'active']);
      }, () => {
        this.isProgress = false;
        this.notifier.notify('error', 'Error restarting Quest.');
      });
    } else {
        this.notifier.notify('error', 'Error repeating Quest.');
    }
  }

  startFundraiser(payload: SaveFundraiserPayload) {
    this.questService.startFundraising(
      this.questId,
      this.isCurrentUserHasFundraisingLink ? this.currentUser.id : (this.isCurrentUserAdminOrOwner ? this.data.user.id : this.currentUser.id),
      payload.brandUserId,
      payload.targetAmount,
      'usd',
      payload.campaignName,
      payload.coverImageUrl,
      null,
      null,
      null,
      payload.secondaryBrandUserId
    ).subscribe((data) => {
      this.fundraisingLink = data;
      if (!this.hasCurrentUserQuestActivity && !this.isCurrentUserAdminOrOwner && !this.iAmCreator()) {
        this.doQuest();
      }

      this.questService.getFundraisingLink(this.questId, this.userId).subscribe(val => {
        this.fundraisingLink = val;
      });

      this.closeModal();
    }, (error) => {
      console.log('Error saving fundraiser', error);
      this.closeModal();
      this.notifier.notify('error', 'Error saving fundraiser.');
    });
  }

  backFundraiser() {
    this.closeModal();
    this.router.navigate(['/donate', this.fundraisingLink.quest.id, this.fundraisingLink.doer.id]);
  }

  isStatusInProgress(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isStatusInProgress();
  }

  isStatusComplete(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isStatusComplete();
  }

  isModeSupportOnly(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModeSupportOnly();
  }

  isModePaceYourself(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModePaceYourself();
  }

  isModeTeam(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModeTeam();
  }

  iAmCreator() {
    return this.currentUser && this.currentUser.id === this.data.quest.user.id;
  }

  iAmDoer() {
    return this.currentUser && this.currentUser.id === this.userId;
  }

  iAmAnonymous() {
    return !this.currentUser || this.currentUser.id === null;
  }

  isDoerIsSimpleDoer() {
    return this.data.quest.fundraising
      ? !this.iAmCreator() && !this.isDoerIsCreator() && !this.isDoerHasFundraisingLink
      : false;
  }

  isDoerIsCreator() {
    return this.userId === this.data.quest.user.id;
  }

  isCurrentUserIsTeamCreator() {
    return this.currentUser && this.team ? this.team.creatorId === this.currentUser.id : false;
  }

  isUserHasAccessThisParentQuestTeam() {
    return this.currentUser && this.currentUser.id && this.currentUser.id !== null && this.data  
      && ((this.data.user && this.currentUser.id === this.data.user.id) || (this.data.quest && this.data.quest.admins && this.data.quest.admins.indexOf(this.currentUser.email) > -1));
  }

  private isDoersTeam() {
    return this.isQuestInTeamMode()
      && this.data.activityInfo.mode === 'diyMode'
      && this.team
  }

  private isCreatorsTeam() {
    return this.isQuestInTeamMode() && this.isDoerIsCreator() && this.isDoerHasFundraisingLink;
  }

  addGalleryPhoto(res: { croppedImage: QuestGalleryImage[], selectedArea: string }) {
    if (res.selectedArea === 'questGalleryPhoto') {
      this.addImageToCarousel(res.croppedImage);
    }
  }

  renameTeam(){
    const newName = this.teamNewName.nativeElement.value
    if(this.team && this.team.teamName && this.team.teamName === newName) {
      this.renameMode = false;
      return
    }
    const payload = {...this.team, teamName: newName}
    this.questService.updateTeamInfo(payload).subscribe(
      () => {
        this.notifier.notify('success', 'Successfully renamed team.');
        if(this.fundraisingLink) this.fundraisingLink.campaignName = this.fundraisingLink.campaignName.replace(this.team.teamName, newName);
        this.team.teamName = newName;
      },
      error => {
        this.notifier.notify('error', 'Error renaming team.');
      }
    );
    this.renameMode = false;
  }

  addImageToCarousel(image: QuestGalleryImage[]) {
    const questImages: QuestGalleryImage[] = [...image, ...this.questImages];
    this.questImages = [];
    setTimeout(() => {
      this.questImages = questImages;
      this.galleryPhotoIsLoading = false;
    });
  }

  canCurrentUserEditMilestones() {
    return (this.allowEditQuest || (this.data.quest.editableMilestones && this.isStatusInProgress())) && !this.isModeSupportOnly();
  }

  updateMilestones(milestones: QuestTask[]) {
    const progressChanged = this.data.questTasks.length !== milestones.length;
    this.data.questTasks = milestones;
    if (progressChanged) {
      this.getQuestCompletionPercent(this.data.quest.id, this.getQuestCompletionUser());
    }
  }

  openStartFundraiserModal(mode: string) {
    if (mode === 'fundraise' && this.shouldDisableFundraisingButton()) return false;

    if ((this.teamsWithoutFundraising) || mode === 'fundraise' && this.isQuestInTeamMode() && this.isDoerHasFundraisingLink) {
      this.openModal(this.startQuestTeamModal);
      this.rootStore.dispatch(new OpenTeamModal(this.team ? this.team.teamId : null));
    } else {
      this.fundraiseModalMode = mode;
      this.fundraiseModalAction = 'start'
      this.openModal(mode === 'fundraise' ? this.fundraiseModalElem : this.backFundraiserModal);
    }
  }

  openStartMultiTeamModel() {
    if (!((this.status != 'active' && !this.isCurrentUsersRepresentation) && this.isQuestInMultiTeamMode() && !this.data.isTeamPage)) return false;

    this.openModal(this.startQuestMultiTeamModal);
    this.rootStore.dispatch(new OpenTeamModal(this.team ? this.team.teamId : null));
  }

  openEditFundraiserModal() {
    this.fundraiseModalMode = 'fundraise';
    this.fundraiseModalAction = 'edit'
    this.openModal(this.fundraiseModalElem);
  }

  doJoinTeam() {
    if (this.data.quest.multiTeamsEnabled) {
      this.openModal(this.startQuestMultiTeamModal);
      this.rootStore.dispatch(new OpenTeamModal(this.team ? this.team.teamId : null));
    } else {
      this.isProgress = true;
      this.rootStore.dispatch(new JoinTeam(this.questId, this.data.doerTeamId));
    }
  }

  closeTeamModal() {
    this.closeModal();
    this.rootStore.dispatch(new CloseTeamModal());
  }

  prepareQuestVideoId(questVideoUrl: string): SafeResourceUrl | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${res}`) : null;
  }

  firstAvatarClick() {
    if (this.data.quest.user.id === this.data.user.id) {
      this.router.navigate(['profile', this.data.user.userName]);
    } else {
      this.router.navigate(['quest-detail', this.data.quest.id, this.data.quest.user.id]);
    }
  }

  private buildViewerDetailsStatus(): QuestDetailStatus {
    const isQuestTeamOrSupport = this.isQuestInSupportMode() || this.isQuestInTeamMode();

    if (!this.isCurrentUsersRepresentation && !isQuestTeamOrSupport) {
      return QuestDetailStatus.Public;
    }
    if (this.data.userHasSaved && !this.hasCurrentUserQuestActivity) {
      return QuestDetailStatus.Saved;
    } else if (this.hasCurrentUserQuestActivity) {
      if (this.isStatusInProgress()) {
        return QuestDetailStatus.Active;
      } else if (this.isStatusComplete()) {
        return QuestDetailStatus.Completed;
      } else {
        return QuestDetailStatus.Public;
      }
    } else {
      return QuestDetailStatus.Public;
    }
  }

  shouldShowFundraisingButton() {
    let shouldShow = false;

    if (this.fundraisingButtonPresent && !this.fundraisingButtonForcedHidden) {
      shouldShow = true;
    }
    if (this.iAmAnonymous() && !this.fundraisingButtonForcedHidden) {
      shouldShow = true;
    }

    return shouldShow;
  }

  shouldDisableFundraisingButton(): boolean {
    return (this.isCurrentUserHasFundraisingLink || this.isCurrentUserAdminOrOwner)
      || (this.isCurrentUserInQuestTeam() && this.isDoersTeam() && this.isDoerHasFundraisingLink)
      || (this.isCurrentUserInQuestTeam() && this.isCreatorsTeam());
  }

  isCurrentUserInQuestTeam(): boolean {
    return this.isQuestInTeamMode() && !!this.getCurrentUserTeam() && this.hasCurrentUserQuestActivity;
  }

  openUploadTeamImageModal(mode: string) {
    // console.log('1022', mode);
    this.selectedUploadTeamImageModalMode = mode;

    this.modalReference = this.modalService.open(ImagePositionZoomComponent, {
      windowClass: 'no-overflow'
    });
    this.modalReference.componentInstance.acceptFor = mode;
    this.modalReference.componentInstance.title = mode === 'teamCover' ? 'Upload team cover' : (mode === 'questCoverImage' ? 'Upload cover image' : 'Upload Team logo');
    /**
     * Conditions followed below are:
     * 1. If it is a fundraising link, then it should follow either of below condition
     *    a. If team exists then should be NO default team
     *    b. Or if the Current user is not the creator
     * 2. If its a team, then it should be NOT default team
     * 3. Else is we use the cover photo of quest.
     */
    this.modalReference.componentInstance.existingImages = (this.fundraisingLink && this.fundraisingLink.coverImageUrl && ((this.team && !this.team.defaultTeam) || (this.fundraisingLink.creator.id !== this.fundraisingLink.doer.id)))
      ? this.fundraisingLink.coverImageUrl
      : (this.team && this.team.teamCoverUrl && !this.team.defaultTeam ? this.team.teamCoverUrl : this.data.quest.photo);

    this.modalReference.componentInstance.imageProperties = (this.fundraisingLink && this.fundraisingLink.coverImageUrl && ((this.team && !this.team.defaultTeam) || (this.fundraisingLink.creator.id !== this.fundraisingLink.doer.id)))
      ? ({
        centerX: this.fundraisingLink.coverCenterX,
        centerY: this.fundraisingLink.coverCenterY,
        zoom: this.fundraisingLink.coverZoomValue
      })
      : ((this.team && this.team.teamCoverUrl && !this.team.defaultTeam ? {
        centerX: this.team.coverCenterX,
        centerY: this.team.coverCenterY,
        zoom: this.team.coverZoomValue
      } : {
        centerX: this.data.quest.coverCenterX,
        centerY: this.data.quest.coverCenterY,
        zoom: this.data.quest.coverZoomValue
      }));

    this.modalReference.componentInstance.imageUploadedData.subscribe(res => {
      // console.log('1048', res);
      if (mode === 'questCoverImage') {
        if (this.isParentQuest()) {
          let payload = {
            questId: this.questId,
            photoUrl: res.imageUrl,
            coverCenterX: parseFloat(res.centerX),
            coverCenterY: parseFloat(res.centerY),
            coverZoomValue: parseFloat(res.zoomValue)
          }
          this.questService.addQuestCoverPhotoWithUrl(payload).subscribe(
            () => {
              this.loadPageData();
              this.closeUploadTeamImageModal();
            },
            error => {
              console.log('Issue completing request', error);
              this.notifier.notify('error', `Issue completing request`);
            }
          );
        } else {

          this.questService.startFundraising(
            this.questId,
            this.isCurrentUserIsTeamCreator() ? this.currentUser.id : this.userId,
            this.fundraisingLink.brand ? this.fundraisingLink.brand.id : null,
            this.fundraisingLink.targetAmount,
            'usd',
            this.fundraisingLink.campaignName,
            res.imageUrl,
            res.centerX,
            res.centerY,
            res.zoomValue,
            this.fundraisingLink.secondaryBrand ? this.fundraisingLink.secondaryBrand.id : null,
          ).subscribe(
            () => {
              this.loadPageData();
              this.closeUploadTeamImageModal();
            },
            error => {
              console.log('Issue completing request', error);
              this.notifier.notify('error', `Issue completing request`);
            }
          );
        }
      }
      else if (mode === 'teamCover') {
        const payload = {...this.team};
        payload.teamCoverUrl = res.imageUrl;
        payload.coverCenterX = parseFloat(res.centerX);
        payload.coverCenterY = parseFloat(res.centerY);
        payload.coverZoomValue = parseFloat(res.zoomValue);
        this.questService.updateTeamInfo(payload).subscribe(
          () => {
            this.loadPageData();
            this.closeUploadTeamImageModal();
          },
          error => {
            console.log('Issue completing request', error);
            this.notifier.notify('error', `Team  Cover Picture saving failed`);
          }
        );
      }
    });
  }

  openTeamLogoModal() {
    this.selectedUploadTeamImageModalMode = 'teamLogo';

    this.modalReference = this.modalService.open(ImageUploadComponent, {
      windowClass: 'no-overflow'
    });

    this.modalReference.componentInstance.acceptFor = 'teamLogo';
    this.modalReference.componentInstance.title = 'Upload Team logo';

    this.modalReference.componentInstance.imageUploadedData.subscribe(res => {
      const payload = {...this.team};
      payload.teamLogoUrl = res.imageUrl;
      this.questService.updateTeamInfo(payload).subscribe(
        () => {
          this.loadPageData();
          this.closeUploadTeamImageModal();
        },
        error => {
          console.log('Issue completing request', error);
          this.notifier.notify('error', `Issue completing request`);
        }
      );
    });
  }

  closeUploadTeamImageModal() {
    this.selectedUploadTeamImageModalMode = '';

    this.closeModal();
  }

  isEventActive(): boolean {
    return this.data.event && this.data.event.happening && !!this.data.event.happening.active;
  }

  isEventDateInFuture(): boolean {
    if (this.data.event && this.data.event.happening && this.data.event.happening.happeningDate == null) {
      return true;
    }
    return this.data.event && this.data.event.happening && this.data.event.happening.happeningDate > Date.now();
  }

  private updateGallery() {
    this.questService.getQuestDetail(this.questId, this.userId).subscribe((quest: Quest) => {
      this.data.questImages = quest.questImages;
    });
  }

  getMegaQuests() {
    let result = this.data.megaQuests && this.data.megaQuests.length ? [...this.data.megaQuests] : [];
    if (this.data.isTeamPage) {
      this.questService.getQuestLite(this.data.quest.id).subscribe(mainQuest => {
        if (mainQuest.id == this.questId && mainQuest.user.id == this.userId) {
          console.log("Can't link a quest to itself")
        } else {
          result.push(mainQuest);
          this.megaQuests = [...result];
          console.log("Got Linked Quests :: ", this.megaQuests);
        }
      });
    } else {
      this.megaQuests = [...result];
    }
  }

  openLogActivityModal() {
    if (this.isServer) return;
    
    if (!this.currentUser || this.currentUser === null) {
      this.modalReference = this.modalService.open(DlLogInComponent, {
        windowClass: "no-overflow"
      });

      return;
    }

    const _isCurrentUserQuestDoer = this.questId === 222 ? true : QuestLogic.checkIfCurrentUserQuestDoer(this.members, this.currentUser.id);
    if (!_isCurrentUserQuestDoer) {
      this.notifier.notify('warning', 'Please join the Quest before logging an activity.');

      return;
    }
    
    this.modalReference = this.modalService.open(LogActivityComponent, {
      size: 'lg',
      windowClass: 'log-activity-modal no-overflow'
    });
    this.modalReference.componentInstance.questId = this.questId;
  }

  openActivityModal() {
    this.openLogActivityModal();
  }

  showPeopleTab(show: boolean) {
    if (show) {
      this.tabs.selected = 'people';
      this.selectedPeopleTab = 'members';
    }
  }

  enableLogActivityForThisQuest(): boolean {
    return (environment.production &&
      [222, 1162, 1165, 1163, 1170, 1162, 1164, 1166, 1167, 1168, 1169, 1175, 1177, 1179, 1184, 1186, 1188, 1193].indexOf(this.questId) > -1) 
      || (!environment.production);
  }

  toggleOptionsMenu() {
    if (window.innerWidth > 570) {
      this.showQuestOptions = !this.showQuestOptions;

      return;
    }
    
    // for mobile
    this.openSideNavMobile = true;
  }

  sideNavOpenedMobile(event) {
    if (event) this.openSideNavMobile = false;
  }

  getQuestImages() {
    this.questService.getQuestImages(this.questId, this.userId).subscribe(images => {
      this.questImages = images as any;
    })
  }
}
