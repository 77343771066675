import {Component, Input, OnInit} from '@angular/core';
import { QuestTeam } from 'src/app/_interface/team.types';

@Component({
  selector: 'app-map-team-list-item',
  templateUrl: './team-list-item.component.html',
  styleUrls: ['./team-list-item.component.scss']
})
export class TeamListItemComponent implements OnInit {

  @Input() team: QuestTeam;
  @Input() showMembersCount: boolean;
  @Input() showBackedAmount: boolean;
  @Input() selectable: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
