import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignUpService } from '../sign-up/sign-up.service';
import { finalize } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.reducers';
import { SelectFormMode, TryRecoveryPass } from '../store/auth.actions';

@Component({
  selector: 'app-password-reset-fail',
  templateUrl: './password-reset-fail.component.html',
  styleUrls: ['./password-reset-fail.component.scss']
})
export class PasswordResetFailComponent implements OnInit {

  failMsg: string = '';

  userEmail: string = '';

  isLoading: boolean = false;

  constructor(private route: ActivatedRoute,
    private signUpService: SignUpService,
    private notifier: NotifierService,
    private store: Store<AppState>) {
    this.route.queryParams.subscribe((params) => {
      this.failMsg = params.msg || '';
      this.userEmail = params.email || '';
    });
  }

  ngOnInit() {
  }

  sendPasswordResetLink() {
    // const email = this.signinForm.value.email;
    if (this.userEmail === '' || this.userEmail === null || !this.userEmail) {
      // this.notifier.notify('error', 'Enter your email');

      return false;
    }

    // this.isUserValidated = false;
    // this.userIsDisabled = false;
    this.isLoading = true;

    this.signUpService.checkEmail(this.userEmail)
      .pipe(finalize(() => {
        this.isLoading = false;
    //     this.signinFormSubmitted = false
      }))
      .subscribe(res => {
        if (res && res['deleted'] === true) {
          this.notifier.notify('error', 'Your account was not found. Please contact us at diemlife@diemlife.com');

          return;
        }

        if (res && res['exists'] === true) {
          if (res['active'] === true) {
            this.store.dispatch(new TryRecoveryPass({
              email: this.userEmail
            }));
          }
        } else {
          this.notifier.notify('error', 'Account not found');
        }
      });
  }

}
