import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import * as Constants from './../../../app.constants';
import {Observable, of, timer} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {QuestService} from "../../../_services/quest.service";
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';

@Component({
  selector: 'app-funds-modal',
  templateUrl: './funds-modal.component.html',
  styleUrls: ['./funds-modal.component.scss']
})
export class FundsModalComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() fundSubmit: EventEmitter<SaveFundraiserPayload> = new EventEmitter<SaveFundraiserPayload>();
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() questId: number;
  @Input() doerId: number;
  @Input() mode: string;
  @Input() action?: String;
  @Input() disableEditCampName: boolean = false;

  messages = Constants.VALIDATION_MESSAGES;
  fundsForm: UntypedFormGroup;
  isLoaded = false;
  progress = false;
  uploadProgress: boolean = false;
  checkProgress: boolean = false;
  formSubmitted = false;
  title: String;
  submitText: String;

  uploadImageSubmit: boolean = false;

  doesImageExists: boolean = false;

  constructor(
    private questService: QuestService,
    private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (this.mode === 'fundraise') {
      const initialAmount = this.fundraisingLink ? this.fundraisingLink.targetAmount / 100 : 0;
      const campaignName = this.fundraisingLink && this.fundraisingLink.campaignName !== null ? this.fundraisingLink.campaignName : (this.fundraisingLink && this.fundraisingLink.quest.title ? this.fundraisingLink.quest.title : null);
      const coverImageUrl = this.fundraisingLink ? this.fundraisingLink.coverImageUrl : null;
      this.fundsForm = this.fb.group({
        'fundsFormAmount': [initialAmount, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)]],
        'fundsCampaignName': [campaignName, [Validators.maxLength(250)], this.validateCampaignNameAvailable.bind(this)],
        // 'fundsCampaignName': [campaignName, [Validators.required, Validators.maxLength(250)], this.validateCampaignNameAvailable.bind(this)],
        'fundsCoverImageUrl': [coverImageUrl, []],
      });
    }
    else {
      this.fundsForm = this.fb.group({});
    }
    this.isLoaded = true;
  }

  ngOnChanges() {
    this.setTitleAndSubmitText()
  }

  startFundraising() {
    this.formSubmitted = true;
    this.progress = true;
    if (this.doesImageExists) {
      this.uploadImageSubmit = true;
      this.uploadProgress = true;
    } else {
      this.runSubmitFundraise();
    }
  }

  imageUploaded(event) {
    this.uploadImageSubmit = false;
    this.fundsForm.patchValue({
      fundsCoverImageUrl: event.imageUrl
    });
    
    this.runSubmitFundraise();
  }

  runSubmitFundraise() {
    if (this.mode === 'fundraise') {
      this.fundSubmit.emit({
        targetAmount: Number(this.fundsForm.controls['fundsFormAmount'].value || 0) * 100,
        campaignName: this.fundsForm.controls['fundsCampaignName'].value,
        coverImageUrl: this.fundsForm.controls['fundsCoverImageUrl'].value,
        brandUserId: this.fundraisingLink.brand ? this.fundraisingLink.brand.id : null,
        secondaryBrandUserId: this.fundraisingLink.secondaryBrand ? this.fundraisingLink.secondaryBrand.id : null
      });
    } else {
      this.fundSubmit.emit(null);
    }
    this.progress = false;
  }

  closeThisDialog() {
    this.progress = false;
    this.close.emit();
  }

  validateCampaignNameAvailable(control: AbstractControl): Observable<ValidationErrors> {
    return timer(100).pipe(
      switchMap(() => {
        if (control.value && this.fundraisingLink && this.fundraisingLink.campaignName && control.value === this.fundraisingLink.campaignName) {
          return of(null);;
        }

        if (control.value) {
          this.checkProgress = true;
          return this.questService.checkFundraiserCampaignName(control.value).pipe(
            map(result => {
              this.checkProgress = false;
              return result ? {duplicateCampaignName: true} : null;
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  setTitleAndSubmitText(){
    if(this.mode === 'fundraise'){
      if(this.action === 'edit'){
        this.title = 'Edit Fundraiser';
        this.submitText = 'Save';
      }
      else{
        this.title = 'Start Fundraiser';
        this.submitText = 'Fundraise';
      }
    }
    else {
      this.title = 'Donate to this Quest';
      // this.title = 'Back this Quest';
      this.submitText = 'Donate';
      // this.submitText = 'Back Me';
    }
  }

  checkImageExist(event: boolean): void {
    this.doesImageExists = event;
  }

}

export interface SaveFundraiserPayload {
  targetAmount: number;
  campaignName: string;
  coverImageUrl: string;
  brandUserId?: number;
  secondaryBrandUserId?: number;
}
