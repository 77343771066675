import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {StartSoloQuestPayload} from '../team-quest-starter/team-quest-starter.component';
import {AppState, teamModalOpenState, teamModalProgressState} from '../../../../_store/app.reducers';
import { BrandConfig } from 'src/app/_interface/brand.types';

@Component({
  selector: 'app-individual-form',
  templateUrl: './individual-form.component.html',
  styleUrls: ['./individual-form.component.scss']
})
export class IndividualFormComponent implements OnInit {

  @Input() brands: BrandConfig[];
  @Input() secondaryBrands: BrandConfig[];
  @Input() fundraisingEnabled: boolean = false;
  @Input() multiSellerEnabled: boolean = false;
  @Output() onSoloFundraiser: EventEmitter<StartSoloQuestPayload> = new EventEmitter<StartSoloQuestPayload>();

  soloFormGroup: UntypedFormGroup;
  progress: boolean;

  soloModalOpenSubscription: Subscription;
  soloModalProgressSubscription: Subscription;

  formIsSubmitted = false;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.soloModalOpenSubscription = this.store.pipe(select(teamModalOpenState)).subscribe(state => {
      if (this.soloFormGroup && state.open) {
        this.soloFormGroup.reset();
      }
    });
    this.soloModalProgressSubscription = this.store.pipe(select(teamModalProgressState)).subscribe(state => {
      this.progress = state.questProgress || state.fundProgress;
    });

    this.soloFormGroup = new UntypedFormGroup({});
    if (this.fundraisingEnabled) {
      this.soloFormGroup.addControl('campaignName', new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(250)
      ]));
      this.soloFormGroup.addControl('targetAmount', new UntypedFormControl(null, [
        Validators.required
      ]));
    }
    if (this.multiSellerEnabled) {
      this.soloFormGroup.addControl('targetBrand', new UntypedFormControl(null, [
        Validators.required
      ]));
      this.soloFormGroup.addControl('targetSecondaryBrand', new UntypedFormControl(null, []));
    }
  }

  onSubmitSoloForm() {
    this.formIsSubmitted = true;
    if (this.fundraisingEnabled) {
      this.onSoloFundraiser.emit({
        campaignName: this.soloFormGroup.controls['campaignName'].value,
        targetBrandUserId: this.multiSellerEnabled
          ? Number(this.soloFormGroup.controls['targetBrand'].value)
          : null,
        targetAmount: Number(this.soloFormGroup.controls['targetAmount'].value || 0) * 100,
        targetSecondaryBrandUserId: this.multiSellerEnabled
        ? Number(this.soloFormGroup.controls['targetSecondaryBrand'].value)
        : null,
      });
    } else {
      this.onSoloFundraiser.emit({});
    }
  }

}
