import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MapboxService } from 'src/app/_interface/service/mapbox.service';
import { DigitkPipe } from 'src/app/_pipes/digit-k.pipe';
import { KeysPipe } from 'src/app/_pipes/keys.pipe';
import { SafePipe } from 'src/app/_pipes/safe-pipe';
import { TimeAgoPipe } from 'src/app/_pipes/time-ago.pipe';
import { QuestService } from 'src/app/_services/quest.service';
import { ReferenceDataService } from 'src/app/_services/reference-data.service';
import { ReaquestHeadersService } from 'src/app/_services/requestHeaders.service';
import { SearchService } from 'src/app/_services/search.service';
import { BirthdayControlComponent } from 'src/app/components/main/birthday-control/birthday-control.component';
import { BirthdayControlService } from 'src/app/components/main/birthday-control/birthday-control.service';
import { BrandListComponent } from 'src/app/components/main/brand/brand-list/brand-list.component';
import { CardComponent } from 'src/app/components/main/card/card.component';
import { DlMilestonesService } from 'src/app/components/main/dl-milestones/service/dl-milestones.service';
import { MapPopoverComponent } from 'src/app/components/main/map-popover/map-popover.component';
import { TeamListItemComponent } from 'src/app/components/main/map-popover/team-list-item/team-list-item.component';
import { MembersDialogComponent } from 'src/app/components/main/members-dialog/members-dialog.component';
import { MembersComponent } from 'src/app/components/main/members/members.component';
import { SortMembersPipe } from 'src/app/components/main/members/sort-members.pipe';
import { PayDialogComponent } from 'src/app/components/main/pay-dialog/pay-dialog.component';
import { PhoneControlComponent } from 'src/app/components/main/phone-control/phone-control.component';
import { QuestProgressComponent } from 'src/app/components/main/quest/quest-progress/quest-progress.component';
import { ShareEmailComponent } from 'src/app/components/main/share-widjet/share-email/share-email.component';
import { ShareWidjetComponent } from 'src/app/components/main/share-widjet/share-widjet.component';
import { AccountService } from 'src/app/components/pages/account/account.service';
import { CommonFooterComponent } from 'src/app/components/pages/common-footer/common-footer.component';
import { FriendsItemComponent } from 'src/app/components/pages/friends/friends-item/friends-item.component';
import { MyQuestsSevice } from 'src/app/components/pages/my-quests/my-quests.service';
import { NewLandingService } from 'src/app/components/pages/new-landing/new-landing.service';
import { ProfileAvatarComponent } from 'src/app/components/separate/profile-avatar/profile-avatar.component';
import { TeamAvatarComponent } from 'src/app/components/separate/team-avatar/team-avatar.component';
import { AuthGuardDirective } from 'src/app/directives/auth-guard/auth-guard.directive';
import { AuthGuardService } from 'src/app/directives/auth-guard/auth-guard.service';
import { BsSelectDirective } from 'src/app/directives/bs-select/bs-select.directive';
import { GetInViewDirective } from 'src/app/directives/get-in-view/get-in-view.directive';
import { InputFlatpickrDirective } from 'src/app/directives/input-flatpickr/input-flatpickr.directive';
import { LoadmoreTriggerDirective } from 'src/app/directives/loadmore-trigger/loadmore-trigger.directive';
import { SmartEditDirective } from 'src/app/directives/smart-edit/smart-edit.directive';
import { LandingCarouselItemComponent } from '../../components/main/landing/landing-carousel-item/landing-carousel-item.component';
import { LandingCarouselComponent } from '../../components/main/landing/landing-carousel/landing-carousel.component';
import { QuestMapComponent } from '../../components/main/quest/quest-map/quest-map.component';
import { DlSearchComponent } from './components/dl-search/dl-search.component';
import { MobileSideNavComponent } from './components/mobile-side-nav/mobile-side-nav.component';
import { MobileSideNavService } from './components/mobile-side-nav/mobile-side-nav.service';
import { UiControlComponent } from './components/ui/ui-control/ui-control.component';
import { UiSelectTeamService } from './elements/ui-select-team.service';
import { UiSelectTeamComponent } from './elements/ui-select-team/ui-select-team.component';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { GalleryUploadComponent } from 'src/app/components/main/gallery-upload/gallery-upload.component';
import { ImagePositionZoomComponent } from './components/image-position-zoom/image-position-zoom.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { FileDropDirective } from './directive/file-drop/file-drop.directive';
import { NgxGridModule } from '@egjs/ngx-grid';
import { NgMapsCoreModule } from '@ng-maps/core';
import { NgMapsDirectionModule } from '@ng-maps/direction';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-2';
import { QuestHeaderComponent } from '../../components/main/quest/quest-header/quest-header.component';
import { GalleryFullscreenComponent } from './components/gallery-fullscreen/gallery-fullscreen.component';

import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgMapsGoogleModule,
    NgMapsCoreModule,
    NgMapsDirectionModule,
    ReactiveFormsModule,
    NgxMaskDirective, NgxMaskPipe,
    NgbTooltipModule,
    QuillModule.forRoot(),
    // NgbModule,
    SlickCarouselModule,
    RouterModule,
    NgbDropdownModule,
    DragDropModule,
    // Ng2TelInputModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    NgSelectModule,
    NgScrollbarModule,
    // PerfectScrollbarModule,
    NgxGridModule,
    NgbProgressbarModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  declarations: [
    PayDialogComponent,
    BsSelectDirective,
    InputFlatpickrDirective,
    AuthGuardDirective,
    GetInViewDirective,
    ShareWidjetComponent,
    ShareEmailComponent,
    LandingCarouselComponent,
    LandingCarouselItemComponent,
    CardComponent,
    BirthdayControlComponent,
    PhoneControlComponent,
    SmartEditDirective,
    QuestMapComponent,
    UiControlComponent,
    UiSelectTeamComponent,
    DlSearchComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    TeamAvatarComponent,
    QuestProgressComponent,
    MapPopoverComponent,
    TeamListItemComponent,
    TimeAgoPipe,
    SafePipe,
    KeysPipe,
    DigitkPipe,
    CommonFooterComponent,
    MobileSideNavComponent,
    BrandListComponent,
    SortMembersPipe,
    MembersDialogComponent,
    MembersComponent,
    GalleryUploadComponent,
    ImagePositionZoomComponent,
    FileDropDirective,
    ImageUploadComponent,
    QuestHeaderComponent,
    GalleryFullscreenComponent,
    VideoPlayerComponent
  ],
  exports: [
    CommonModule,
    // NgxMaskModule,
    NgxMaskDirective, NgxMaskPipe,
    PayDialogComponent,
    BsSelectDirective,
    InputFlatpickrDirective,
    AuthGuardDirective,
    NgbTooltipModule,
    ShareWidjetComponent,
    ShareEmailComponent,
    LandingCarouselComponent,
    LandingCarouselItemComponent,
    SlickCarouselModule,
    // DropdownModule,
    NgbDropdownModule,
    CardComponent,
    DragDropModule,
    CardComponent,
    BirthdayControlComponent,
    // Ng2TelInputModule,
    NgxIntlTelInputModule,
    PhoneControlComponent,
    SmartEditDirective,
    UiControlComponent,
    UiSelectTeamComponent,
    QuestMapComponent,
    DlSearchComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    TeamAvatarComponent,
    QuestProgressComponent,
    MapPopoverComponent,
    TeamListItemComponent,
    TimeAgoPipe,
    SafePipe,
    KeysPipe,
    DigitkPipe,
    CommonFooterComponent,
    MobileSideNavComponent,
    BrandListComponent,
    SortMembersPipe,
    MembersDialogComponent,
    MembersComponent,
    GalleryUploadComponent,
    ImagePositionZoomComponent,
    ImageUploadComponent,
    NgxGridModule,
    QuestHeaderComponent,
  ],
  providers: [
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyBFBBWND3Iwi097HWKyKAR4Z4oZpdPzMxk'
      }
    },
    QuestService,
    ReaquestHeadersService,
    ReferenceDataService,
    AuthGuardService,
    SearchService,
    NewLandingService,
    BirthdayControlService,
    DlMilestonesService,
    UiSelectTeamService,
    MyQuestsSevice,
    AccountService,
    MapboxService,
    MobileSideNavService,
    KeysPipe,
    provideNgxMask(),
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
// ,
//   entryComponents: [
//     MapPopoverComponent,
//     ShareEmailComponent,
//     ImagePositionZoomComponent,
//     ImageUploadComponent
//   ]

export class SharedModule { }
