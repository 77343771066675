import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/_store/app.reducers';
import * as Constants from '../../../../app.constants';
import { AccountService } from '../../../pages/account/account.service';
import { PaymentsService } from '../payments.service';
import * as PaymentsActions from '../store/payments.actions';
import * as fromPayments from '../store/payments.reducer';

@Component({
  selector: 'app-verify-bank-dialog',
  templateUrl: './verify-bank-dialog.component.html',
  styleUrls: ['./verify-bank-dialog.component.scss']
})
export class VerifyBankDialogComponent implements OnInit {
  messages = Constants.VALIDATION_MESSAGES;
  paymentsState: Observable<fromPayments.State>;
  bankVerify: UntypedFormGroup;
  bankAccount: fromPayments.BankAccount;
  isLoading = false;
  submitted = false;
  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private paymentsService: PaymentsService,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private notifier: NotifierService,
    private modalService: NgbModal
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
  }

  ngOnInit() {
    this.bankVerify = this.fb.group({
      firstDebit: ['', [Validators.required]],
      secondDebit: ['', [Validators.required]]
    });
    this.paymentsState.subscribe((state: fromPayments.State) => {
      this.bankAccount = state.bankAccounts[0];
    });
  }
  verifyBankAccount = () => {
    this.submitted = true;
    if (this.bankVerify.invalid) {
      this.notifier.notify('error', 'Fill in required fields.');
      return false;
    }
    this.isLoading = true;
    this.paymentsService.verifyBankAccount(this.bankVerify.value).subscribe(res => {
      if (res === false) {
        this.notifier.notify('error', 'Issue verifying micro-deposits');
      } else {
        this.notifier.notify('success', 'Bank account verified');
      }

      this.activeModal.close();

      this.accountService.getBankAccount('payments').subscribe(bankInfoPayments => {
        this.paymentsState.pipe(take(1)).subscribe((state: fromPayments.State) => {
          this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
            bankAccounts: bankInfoPayments,
            creditCards: state.creditCards,
            hasBankAccountForPayments: !!bankInfoPayments.length,
            hasBankAccountForPayouts: state.hasBankAccountForPayouts
          }));
        });
      });
      this.isLoading = false;
    });
  }

  closeModal() {
    this.activeModal.close();
    this.modalService.dismissAll();
  }
}
