import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppState } from 'src/app/_store/app.reducers';
import * as config from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import { HTTPStatus } from '../../../../interceptors/loader.interceptor';
import { ReferenceDataService } from '../../../../_services/reference-data.service';
import { RegistrationValidator } from '../../auth/sign-up/sign-up.validator';
import { SetAutoCheckUserDetail } from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';

interface PincodeValidation {
  validation: boolean;
  formValue: string;
}

interface NewAccountSavedInfo {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zip: string;
  // passwordGroup: {
  //   password1: string;
  //   password2: string;
  // };
  receiveEmail: boolean;
  recaptcha: string;
}

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit, OnChanges {
  @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input("userEmail") userEmail: string;
  @Input("formSubmitted") formSubmitted: string;
  activityStatusSubscriber: Subscription;
  activityStatus = false;
  authState: Observable<fromAuth.State>;
  authSubscriber: Subscription;
  messages = Constants.VALIDATION_MESSAGES;
  signUpForm: UntypedFormGroup;
  // passwordGroup: UntypedFormGroup;
  submitted = false;
  pinCode: any = null;
  window: any = null;
  isLoading: any = false;
  disabledSignup = true;
  countries: {[key: string]: string } = null;
  eventsSubject: Subject<void> = new Subject<void>();
  reCaptchaToken: string = config.reCaptchaToken;
  pincodeValidation: PincodeValidation = {
    validation: false,
    formValue: ''
  };
  newAccountSavedInfo: NewAccountSavedInfo | null = null;
  gapiSetup = false;
  googleAuthInstance = null;
  googleUser = null;
  error = null; 
  authSubscribtion: Subscription;
  passwordType = 'password';

  constructor(
    private httpStatus: HTTPStatus,
    private referenceDataService: ReferenceDataService,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
    this.authState = this.store.select('auth');
    // this.passwordGroup = this.fb.group({
    //   'password1': [
    //     '',
    //     [
    //       Validators.required,
    //       Validators.minLength(config.password.minLength),
    //       Validators.maxLength(config.password.maxLength)
    //     ]
    //   ],
    //   'password2': ['', [Validators.required]]
    // }, {
    //   validator: RegistrationValidator.validate.bind(this)
    // });

    this.signUpForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'email': ['', [Validators.required]],
      // 'country': ['', [Validators.required]],
      // 'zip': ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/)]],
      // 'passwordGroup': this.passwordGroup,
      'receiveEmail': [true],
      'recaptcha': [null, Validators.required]
    });

    this.authSubscribtion = this.authState.subscribe((state: fromAuth.State) => {
      if (state.userDetailCheck) {
        const detail = state.userDetailCheck;
        if (detail.firstName && detail.lastName) {
          this.signUpForm.patchValue({
            'firstName': detail.firstName,
            'lastName': detail.lastName
          });
        }

        this.store.dispatch(new SetAutoCheckUserDetail(null));
      }
    });
  }

  ngOnInit() {
    this.referenceDataService.getSupportedCountries().subscribe((res: {[key: string]: string}) => {
      this.countries = res;
      this.eventsSubject.next();
    });

    // todo: below condition when user signups but does not enter pin
    this.newAccountSavedInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_INFO'));

    this.authSubscriber = this.authState.subscribe((res: fromAuth.State) => {
      this.pincodeValidation = res.pincodeValidation;
    });

    this.signUpForm.valueChanges.subscribe(value => {
      this.valueChange.emit({
        isFormValid: this.signUpForm.valid && this.signUpForm.value.recaptcha,
        formValues: this.signUpForm.value
      })
    });
  }

  ngOnChanges() {
    this.signUpForm.controls.email.setValue(this.userEmail);

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.authSubscriber) {
      this.authSubscriber.unsubscribe();
    }
    if (this.activityStatusSubscriber) {
      this.activityStatusSubscriber.unsubscribe();
    }
  }

  handleSuccess($event: string) {
    this.referenceDataService.verifyCapcha($event).subscribe((res: any) => {
      this.disabledSignup = res !== 'status = 200';
    });
  }

  showHidePassword() {
    this.passwordType = (this.passwordType === 'password') ? 'text' : 'password';
  }
}

