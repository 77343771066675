import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-milestone-task-preview',
  templateUrl: './milestone-task-preview.component.html',
  styleUrls: ['./milestone-task-preview.component.scss']
})
export class MilestoneTaskPreviewComponent implements OnInit, OnChanges {
  @Input() task: UntypedFormGroup;
  @Input() groupForm: UntypedFormGroup;

  milestone: any;

  expandPreview: boolean = false;

  @Output() removeTask: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.milestone = this.task.value;
    // console.log('22milestone-', this.task, this.milestone);
  }

  editTask() {
    this.task.patchValue({
      edit: true
    });
  }

  deleteTask() {
    this.removeTask.emit(true);
  }

  public get getTasksLength() {
    return this.groupForm.value.questTasks.length;
  }
}
