import { Action } from '@ngrx/store';
import { Steps, ChangeDisplay, FormValue } from './donate.reducer';

export const DONATE_SET_SUCCESS_INFO = 'DONATE_SET_SUCCESS_INFO';
export const SET_BRUT_TOTAL = 'SET_BRUT_TOTAL';
export const SET_BRAND_ID = 'SET_BRAND_ID';
export const SET_SECONDARY_BRAND_ID = 'SET_SECONDARY_BRAND_ID';
export const SET_AMOUNT = 'UPDATE_AMOUNT';
export const SET_MESSAGE = 'UPDATE_MESSAGE';
export const SET_TIP_PERCENT = 'SET_TIP_PERCENT';
export const SET_RECURRENT_CHECK = 'SET_RECURRENT_CHECK';
export const SET_CHANGE_DISPLAY = 'SET_CHANGE_DISPLAY';
export const SET_ANON_CHECK = 'SET_ANON_CHECK';
export const SET_MAIL_CHECK = 'SET_MAIL_CHECK';
export const SET_NAVIGATION_STEP = 'SET_NAVIGATION_STEP';
export const SET_OFFLINE_FLAG = 'SET_OFFLINE_FLAG';
export const SET_ADMIN_FLAG = 'SET_ADMIN_FLAG';
export const SET_TIPPING_FLAG = 'SET_TIPPING_FLAG';
export const SET_MONTHLY_FLAG = 'SET_MONTHLY_FLAG';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_ABSORB_FEES = 'SET_ABSORB_FEES';
export const SET_STRIPE_FEE = 'SET_STRIPE_FEE';
export const SET_PLATFORM_FEE = 'SET_PLATFORM_FEE';
export const SET_PAY_LATER = 'SET_PAY_LATER';
export const SET_TIP_AMOUNT = 'SET_TIP_AMOUNT';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';


export interface ToggleCategoryPayload {
  index: number;
  value: boolean;
}

export class SetBrutTotal implements Action {
  readonly type = SET_BRUT_TOTAL;
  constructor(public payload: number | string | null) {}
}
export class SetBrandId implements Action {
  readonly type = SET_BRAND_ID;
  constructor(public payload: number) {
    console.log('DonateActions::SetBrandId', payload);
  }
}
export class SetSecondaryBrandId implements Action {
  readonly type = SET_SECONDARY_BRAND_ID;
  constructor(public payload: number) {
    console.log('DonateActions::SetSecondaryBrandId', payload);
  }
}
export class SetAmount implements Action {
  readonly type = SET_AMOUNT;
  constructor(public payload: number | null) {}
}
export class SetMessage implements Action {
  readonly type = SET_MESSAGE;
  constructor(public payload: string) {
    console.log('DonateActions::SetMessage', payload);
  }
}
export class SetTipPercent implements Action {
  readonly type = SET_TIP_PERCENT;
  constructor(public payload: number) {
    console.log('DonateActions::SetTipPercent', payload);
  }
}
export class SetOfflineFlag implements Action {
  readonly type = SET_OFFLINE_FLAG;
  constructor(public payload: boolean) {
    console.log('DonateActions::SetOfflineFlag', payload);
  }
}
export class SetAdminFlag implements Action {
  readonly type = SET_ADMIN_FLAG;
  constructor(public payload: boolean) {}
}
export class SetRecurrentCheck implements Action {
  readonly type = SET_RECURRENT_CHECK;
  constructor(public payload: boolean) {}
}
export class SetChangeDisplay implements Action {
  readonly type = SET_CHANGE_DISPLAY;
  constructor(public payload: ChangeDisplay) {}
}
export class SetAnonCheck implements Action {
  readonly type = SET_ANON_CHECK;
  constructor(public payload: boolean) {}
}
export class SetMailCheck implements Action {
  readonly type = SET_MAIL_CHECK;
  constructor(public payload: boolean) {}
}
export class SetNavigationStep implements Action {
  readonly type = SET_NAVIGATION_STEP;
  constructor(public payload: Steps) {}
}
export class DonateSetSuccessInfo implements Action {
  readonly type = DONATE_SET_SUCCESS_INFO;
  constructor(public payload: any) {}
}
export class SetTippingFlag implements Action {
  readonly type = SET_TIPPING_FLAG;
  constructor(public payload: boolean) {}
}
export class SetMonthlyFlag implements Action {
  readonly type = SET_MONTHLY_FLAG;
  constructor(public payload: boolean) {}
}

export class SetAbsorbFees implements Action {
  readonly type = SET_ABSORB_FEES;
  constructor(public payload: boolean) {}
}

export class SetStripeFee implements Action {
  readonly type = SET_STRIPE_FEE;
  constructor(public payload: number) {}
}

export class SetPlatformFee implements Action {
  readonly type = SET_PLATFORM_FEE;
  constructor(public payload: number) {}
}

export class SetPayLater implements Action {
  readonly type = SET_PAY_LATER;
  constructor(public payload: boolean) {}
}

export class SetTipAmount implements Action {
  readonly type = SET_TIP_AMOUNT;
  constructor(public payload: number) {}
}

export class SetFormValues implements Action {
  readonly type = SET_FORM_VALUES;
  constructor(public payload: FormValue) {}
}

export class SetLoadingStatus implements Action {
  readonly type = SET_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export type DonateActions =   SetAmount |
                              SetMessage |
                              SetTipPercent |
                              SetRecurrentCheck |
                              SetAnonCheck |
                              SetMailCheck |
                              SetNavigationStep |
                              SetChangeDisplay |
                              DonateSetSuccessInfo |
                              SetBrutTotal |
                              SetBrandId |
                              SetSecondaryBrandId |
                              SetOfflineFlag |
                              SetAdminFlag |
                              SetTippingFlag |
                              SetMonthlyFlag | 
                              SetFormValues | 
                              SetTipAmount | 
                              SetPayLater |
                              SetPlatformFee |
                              SetStripeFee |
                              SetAbsorbFees |
                              SetLoadingStatus;
