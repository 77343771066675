import { HttpEventType } from '@angular/common/http';
// import { Masonry, MasonryGridItem } from 'ng-masonry-grid';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../_services/common.service';
import { NgxGridComponent } from '@egjs/ngx-grid';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnChanges {
  @Input('acceptCount') acceptCount: string = 'single';

  @Input('acceptFor') acceptFor: string;
  
  @Input('title') title: string;
  
  @Input('userEmail') userEmail: string = '';

  @Input('existingImages') existingImages: string[] = []; //must be array of url

  @Input('showSubmitButtons') showSubmitButtons: boolean = true;

  @Input('uploadImage') uploadImage: boolean = false;

  @Input('showImageInfo') showImageInfo: boolean = true;

  @Input('smallWindowAvailable') smallWindowAvailable: boolean = false;

  files: any[] = [];
  
  imgCustomWidth: number;
  
  imgCustomHeight: number;

  imageUploading: boolean = false;

  disableSubmitBtn: boolean = false;


  @Output('imageUploadedData') imageUploadedData: EventEmitter<any> = new EventEmitter();

  @Output('imageSelectedData') imageSelectedData: EventEmitter<any> = new EventEmitter();

  contentType: any;

  imageDataSaving: boolean = false;

  allFileData: any[] = [];

  // _masonry: Masonry;

  fileUploadProgress: number = 0;

  videoFilesUrl: string[] = [];

  @ViewChild("gridElem") gridElem: NgxGridComponent;

  constructor(private commonService: CommonService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.existingImages && this.existingImages.length > 0) {
      this.files = [...this.existingImages];

      this.files.forEach(f => {
        setTimeout(() => {
          this.imageCallback(0, false, f);
        }, 50);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.uploadImage) {
      this.uploadImageAndSubmit();
    }
  }

  // onNgMasonryInit($event: Masonry) {
    // this._masonry = $event;
  // }
  

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  removeImage(index: number, event: any) {
    // if (this._masonry) {
    //   this._masonry.removeItem(event.currentTarget.closest('.masonry-item-cl'))
    //     .subscribe(e => {
    //       this.files.splice(index, 1);
    //       this.allFileData.splice(index, 1);
    //       this.existingImages.splice(index, 1);

    //       this.imageSelectedData.emit(this.files.length > 0 ? true : false);
    //     });
    // }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    [...files].forEach((item, i) => {
      item.progress = 0;
      this.files.push(item);

      setTimeout(() => {
        const nameSplit = item.name.toLowerCase().split('.');
        if (['mov', 'mp4', 'wmv', 'avi'].indexOf(nameSplit[nameSplit.length - 1]) > -1) { 
          this.videoFilesUrl.push(URL.createObjectURL(item));
          this.imageCallback(item.size, true, this.videoFilesUrl[this.videoFilesUrl.length - 1]);
        } else {
          this.commonService.readImageAndReturnBase64(item, this.imageCallback.bind(this, item.size, false));
          this.videoFilesUrl.push('');
        }
      }, 200 * i);
    });
  }

  imageCallback(fileSize, isVideo, fileData) {
    const length = this.allFileData.length;
    this.allFileData.push({
      data: isVideo ? this.sanitizer.bypassSecurityTrustUrl(fileData) : fileData,
      size: fileSize,
      tillPreviousSize: length < 1 ? fileSize : (this.allFileData[length - 1].tillPreviousSize + fileSize),
      isVideo: isVideo
    });

    if (this.gridElem) {
      this.gridElem.updateItems();
    }

    this.imageSelectedData.emit(true);
  }

  renderComplete(ev) {
    // if (this.gridElem) {
      // this.gridElem.renderItems();
      // this.gridElem.updateItems();
    // }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
 
  closeModal() {
    this.modalService.dismissAll();
  }

  uploadImageAndSubmit() {
    this.imageUploading = true;
    this.uploadImage = false;
    let newFilesTobeUploaded = [...this.files];
    let uploadedImageUrls: string[] = [];

    // 
    if (this.acceptCount === 'single' && this.existingImages && this.existingImages.length > 0) {
      this.saveImage(this.existingImages[0])
      
      return;
    }

    if (this.acceptCount === 'multiple' && this.existingImages && this.existingImages.length > 0) {
      uploadedImageUrls = [...this.existingImages];
      // nothing changed
      if (this.existingImages.length === this.files.length) {
        this.saveImage(this.existingImages);

        return;
      }

      // image deleted
      let allFilesAlreadyUploaded = true;
      newFilesTobeUploaded = [];
      this.files.forEach((file, ind) => {
        if (typeof file !== 'string') {
          allFilesAlreadyUploaded = false;
          newFilesTobeUploaded.push(file);
        }
      })
      
      if (allFilesAlreadyUploaded) {
        this.saveImage(this.existingImages);

        return;
      }
    }

    // upload image and get url
    let formData = new FormData();
    newFilesTobeUploaded.forEach(f => {
      formData.append("file", f);
    });

    if (this.acceptCount === 'single') {
      this.commonService
        .uploadImageAndGetUrl(formData)
        .subscribe(imageResp => {
          if (imageResp.type == HttpEventType.UploadProgress) {
            this.fileUploadProgress = imageResp.loaded;
          }

          if (imageResp.type === HttpEventType.Response) {
            this.imageUploading = false;

            setTimeout(() => {
              this.saveImage(imageResp.body);
            }, 50);
          }
        });
    } else {
      this.commonService
        .uploadMultipleImagesAndGetUrl(formData)
        .subscribe(imageResp => {
          if (imageResp.type == HttpEventType.UploadProgress) {
            this.fileUploadProgress = imageResp.loaded;
          }

          if (imageResp.type === HttpEventType.Response) {
            this.imageUploading = false;

            setTimeout(() => {
              uploadedImageUrls.push(...imageResp.body);
              this.saveImage(uploadedImageUrls);
            }, 50);
          }
        });
    }

  }

  saveImage(imageData) {
    this.imageDataSaving = true;

    switch (this.acceptFor) {
      case 'questGallery':
      case 'logActivity':
        this.imageUploadedData.emit({
          assetData: imageData
        });
        break;

      case 'teamLogo':
      case 'taskImage':
      case 'questCoverImage':
      case 'teamCover':
      case 'fundraiseImage':
        this.imageUploadedData.emit({
          imageUrl: imageData.imageURL
        });

        break;
    }
  }

  clearAllImages() {
    this.disableSubmitBtn = false;
    this.files = [];
    this.existingImages = [];
  }

  getFileUploadProgress(index): number {
    let totalSizePrev = this.allFileData[index].tillPreviousSize;

    let value = parseFloat(((this.fileUploadProgress / totalSizePrev) * 100).toFixed(2));
    
    if (index > 0 && this.fileUploadProgress < this.allFileData[index - 1].tillPreviousSize) {
      value = 0;
    }

    return value > 100 ? 100 : value;
  }
}
