import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { QuestDetailState } from 'src/app/_interface/store/quest-detail.reducers';
import * as AppConfig from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import * as fromApp from '../../../../_store/app.reducers';
import { ShareWidjetService } from '../share-widjet.service';

@Component({
  selector: 'app-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss']
})
export class ShareEmailComponent implements OnInit {
  @Input() questId: number;
  questState: Observable<QuestDetailState>;
  shareForm: UntypedFormGroup;
  messages = Constants.VALIDATION_MESSAGES;
  formSubmitted = false;
  emailsLimit = 10;
  shareIsloading = false;
  constructor(
    private shareWidjetService: ShareWidjetService,
    private store: Store<fromApp.AppState>,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private notifier: NotifierService
  ) {
    this.questState = this.store.select('quest');
  }

  ngOnInit() {
    this.shareForm = this.fb.group({
      emails: this.fb.array([ this.fb.control('', [Validators.required, Validators.email]) ]),
      message: this.fb.control('', [
        Validators.required,
        Validators.minLength(AppConfig.shareMessage.minLength),
        Validators.maxLength(AppConfig.shareMessage.maxLength)
      ])
    });
  }

  get emails(): UntypedFormArray {
    return this.shareForm.get('emails') as UntypedFormArray;
  }
  get messageControl(): AbstractControl {
    return this.shareForm.get('message');
  }

  addEmail() {
    if (this.emails.length < this.emailsLimit) {
      this.emails.push(this.fb.control('', [Validators.email]));
    }
  }
  hasEmptyFields() {
    let isEmpty = false;
    for (const val of this.emails.value) {
      if (val.length === 0) {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  }
  autoControlFields() {
    if (!this.hasEmptyFields() && this.emails.valid) {
      this.addEmail();
    }
  }
  removeEmail(index: number) {
    this.emails.removeAt(index);
  }

  onSubmit() {
    if (this.shareIsloading) {
      return false;
    }
    this.formSubmitted = true;
    this.shareIsloading = true;
    if (this.shareForm.value.message.length === 0) {
      this.notifier.notify('error', `Add a message`);
      this.shareIsloading = false;
      return false;
    }
    const validEmails: string[] = this.emails.value.filter((val, index) => {
      return val.length > 4 && this.emails.controls[index].valid;
    });
    this.shareWidjetService.shareViaEmail(this.questId, validEmails, this.shareForm.value.message).subscribe(() => {
      this.shareIsloading = false;
      this.notifier.notify('success', `Quest shared`);
      this.activeModal.close();
    }, err => {
      this.shareIsloading = false;
      this.activeModal.close();
      this.notifier.notify('error', `Oops, something went wrong, please try again`);
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
