import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {HTTPStatus} from 'src/app/interceptors/loader.interceptor';
import {Observable} from 'rxjs';

export interface NewAccountInfo {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zip: number;
  password1: string;
  password2: string;
  withPin: boolean;
  receiveEmail: boolean;
  passwordGroup?: {
    password1: string,
    password2: string
  };
}

@Injectable({
  providedIn: 'root',
})
export class SignUpService {

  constructor(
    private http: HttpClient,
    private httpStatus: HTTPStatus,
    private reaquestHeadersService: ReaquestHeadersService
  ) { }

  createAccount (payload: NewAccountInfo) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/register',
      payload,
      { headers: headers }
    );
  }
  checkEmail (email: string): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/check-email',
      {email: email},
      { headers: headers }
    );
  }
  requestPin (email: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/accounts/pin/generate',
      {email: email},
      { headers: headers }
    );
  }
  validatePin (email: string, code: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/accounts/pin/verify',
      { email: email, code: code },
      { headers: headers }
    );
  }

  createBrand (payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/brand',
      payload,
      { headers: headers }
    );
  }

  updateAddressForBrand(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/brand/update-address',
      payload,
      { headers: headers }
    );
  }

  sendVerificationEmail(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get(
      environment.target + environment.context + `/sendVerificationEmail/${userId}`,
      { headers: headers }
    );
  }
}
