import {Component, Input} from '@angular/core';
import { LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import {LeaderboardScoreComponent} from '../leaderboard-score.component';

@Component({
  selector: 'app-leaderboard-list-item',
  templateUrl: './leaderboard-list-item.component.html',
  styleUrls: ['./leaderboard-list-item.component.scss']
})
export class LeaderboardListItemComponent extends LeaderboardScoreComponent {

  @Input()
  set member(member: LeaderboardScore) {
    this._member = member;
    this.init();
  }

  get member(): LeaderboardScore {
    return this._member;
  }

  constructor() {
    super();
  }

}
