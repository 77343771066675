import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-quest-progress',
  templateUrl: './quest-progress.component.html',
  styleUrls: ['./quest-progress.component.scss']
})
export class QuestProgressComponent implements OnChanges {
  @Input() value: number;
  @Input() isHuge?: boolean;
  @Input() simpleMode?: boolean;
  @Input() color?: string;
  circumference: number;
  dashoffset: number;
  constructor() { }

  ngOnChanges() {
    const RADIUS = this.isHuge ? 54 : 56;
    const prgss = this.value / 100;
    this.circumference = 2 * Math.PI * RADIUS;
    this.dashoffset = this.circumference * (1 - prgss);
  }
  removeClass() {
  }
}
