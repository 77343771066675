export interface ValidationMessages {
  required: string;
  invalid: string;
  invalidEmail: string;
  duplicateEmail: string;
  invalidZipcode: string;
  zipNumberOnly: string;
  disabledUser: string;
  password: {
    compareTo: string;
    minlength: (count) => {};
    maxlength: (count) => {};
  };
  minlength: (count, fieldName: string) => {};
  maxlength: (count, fieldName: string) => {};
}
export const VALIDATION_MESSAGES: ValidationMessages = {
  required: 'This field is required.',
  invalid: 'This field is invalid.',
  invalidEmail: 'Please enter a valid email address.',
  duplicateEmail: 'Email is already in use. Please login or try another.',
  invalidZipcode: 'Please enter a valid zipcode.',
  zipNumberOnly: 'Please enter numbers only.',
  disabledUser: 'Your account has been disabled.',
  password: {
    compareTo: 'Passwords do not match',
    minlength: (count) => {
      return `Password must be over ${count} characters`;
    },
    maxlength: (count) => {
      return `Password must not exceed ${count} characters`;
    }
  },
  minlength: (fieldName, count) => {
    return `${fieldName} must be over ${count} characters`;
  },
  maxlength: (fieldName, count) => {
    return `${fieldName} must not exceed ${count} characters`;
  }
};

export const MEMBER_STATUS_PRIORITY = {
  'Creator'   : 1,
  'Admin'     : 2,
  'Backer'    : 3,
  'Donor'     : 4,
  'Supporter' : 5,
  'Doer'      : 6,
  'Achiever'  : 7,
  'Interested': 8
};

export const MEDIA_BREAKPOINTS = {
  xl: 1420,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 600
};

export const DEFAULT_DIEMLIFE_TIP_PERCENT = 10;

export const MILESTONE_TASK_MAX_LENGTH = 140;

export const MILESTONE_TASK_DESC_MAX_LENGTH = 1001;

export const SHARE_DATE_FORMAT = 'MMMM D, YYYY | h:mm a';

export const LEADERBOARD_TEAM_PREFIX = 'team-';

export const PAGE_SIZE_TABLE = 25;

export const MAP_STYLE_GRAY = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];
