import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/_store/app.reducers';
import * as fromAuth from '../auth/store/auth.reducer';
import { ShareWidjetService } from './share-widjet.service';

@Component({
  selector: 'app-share-widjet',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './share-widjet.component.html',
  styleUrls: ['./share-widjet.component.scss']
})
export class ShareWidjetComponent implements AfterViewInit, OnDestroy {
  @Input() questId: number;
  @Input() questUserId?: number;
  @Input() shareUrl?: string;
  authState: Observable<fromAuth.State>;
  authStateSubscription: Subscription;
  emailControlisHidden = true;
  url: string;
  openedTab = 'copy';

  constructor(
    private shareWidjetService: ShareWidjetService,
    public location: Location,
    private store: Store<AppState>
  ) {
    this.authState = this.store.select('auth');
  }
  ngAfterViewInit() {
    this.shareWidjetService.getShareUrl(this.questId).subscribe((res: string) => {
      this.url = res;
    });
  }

  ngOnDestroy() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  collapseBody() {
    this.emailControlisHidden = !this.emailControlisHidden;
  }

  shareViaFacebook() {
    this.shareWidjetService.shareViaFacebook(this.url);
  }
  openTab(key: string) {
    this.openedTab = key;
  }

}
