import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {LandingCarouselService} from './landing-carousel.service';
import {ReferredQuest} from '../../../../modules/ticket-flow/ticket-flow.typings';

export interface CarouselQuest {
  pillar: string;
  id: number;
  photo: string;
  questFeed: string;
  views: number;
  title: string;
  user: {
    firstName: string;
    id: number;
    lastName: string;
    profilePictureURL: string;
  };
}

@Component({
  selector: 'app-landing-carousel',
  templateUrl: './landing-carousel.component.html',
  styleUrls: ['./landing-carousel.component.scss']
})
export class LandingCarouselComponent implements OnInit {
  @Input('type') type: string;
  @Input('questId') questId: number;
  @Output() hasDonate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() questDonate: EventEmitter<ReferredQuest> = new EventEmitter<ReferredQuest>();
  isLoading = false;
  questsLimit = 8;
  quests: CarouselQuest[] = [];
  referredQuest: ReferredQuest;
  slideConfig = {
    centerMode: true,
    variableWidth: true,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    infinite: true,
    focusOnSelect: true,
    customPaging: function (slider, i) {
      return '';
    },
    responsive: [
      {
        breakpoint: 769,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          centerPadding: 0
        }
      }
    ]
  };

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private landingService: LandingCarouselService
  ) {
  }

  getQuests() {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get(
      environment.target + environment.context + `/quests/recommended?limit=${this.questsLimit}`,
      {headers: headers}
    );
  }

  ngOnInit() {
    this.isLoading = true;

    if (this.type === 'donate' && this.questId) {
      this.landingService.getReferredQuests(this.questId).subscribe((quest: ReferredQuest) => {
        this.referredQuest = quest[0];
        if (this.referredQuest) {
          this.landingService.getRecommendedQuest(this.referredQuest.questId).subscribe((res: CarouselQuest) => {
            this.quests.push(res);
            this.slideConfig.dots = false;
            this.isLoading = false;
            this.hasDonate.emit(true);
            this.questDonate.emit(this.referredQuest);
          });
        }
      });
    } else {
      this.getQuests().subscribe((quests: CarouselQuest[]) => {
        this.quests = quests;
        this.isLoading = false;
        this.hasDonate.emit(false);
      });
    }
  }

}
