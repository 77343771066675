import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QuestService } from 'src/app/_services/quest.service';
import { AppState } from 'src/app/_store/app.reducers';
import { COMMENT_CHARACTER_LIMIT } from 'src/app/app.config';
import { SHARE_DATE_FORMAT } from 'src/app/app.constants';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { LogActivityComponent } from 'src/app/components/separate/log-activity/log-activity.component';
import { QuestComment } from '../../../../../_interface/comment.types';
import { LoggedActivity } from '../../../../../_interface/quest.types';
import { GalleryFullscreenComponent } from '../../../../../modules/shared-module/components/gallery-fullscreen/gallery-fullscreen.component';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as fromAuth from '../../../auth/store/auth.reducer';
import { CommentService } from '../comment/comment.service';

@Component({
  selector: 'app-quest-comment-item',
  templateUrl: './quest-comment-item.component.html',
  styleUrls: ['./quest-comment-item.component.scss']
})
export class QuestCommentItemComponent implements OnInit, OnChanges {
  @Input() loggedItem: LoggedActivity;

  @Input() viewerId: number;

  @Input() questId: number;

  // webviewMode = false;
  // @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  sharedDate: string;

  hasCurrentUserLiked: boolean = false;

  authState: Observable<fromAuth.State>;

  isLoading: string = null;

  profileState: Observable<fromProfile.State>;

  isUserAuthenticated: boolean = false;

  replyForm: UntypedFormGroup;

  tributeOptions: any;

  showRepliesList: boolean = false;

  showOptions: boolean = false;

  @Output() update: EventEmitter<{type: string; of?: number}> = new EventEmitter<{type: string; of?: number}>(); 

  modalReference: NgbModalRef = null;

  @ViewChild('feedOptionsBox', { read: ElementRef }) feedOptionsBox: ElementRef;

  public commentLength = COMMENT_CHARACTER_LIMIT;

  loggedInUserInfo: any;

  userComments: QuestComment[];

  @Input('isCurrentUserAdminOrOwner') isCurrentUserAdminOrOwner: boolean = false;

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.feedOptionsBox && !this.feedOptionsBox.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: Store<AppState>,
    private router: Router,
    private questService: QuestService,
    private notifier: NotifierService,
    private fb: UntypedFormBuilder,
    private commentService: CommentService,
    private modalService: NgbModal) { 
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.authState.subscribe(res => {
      this.isUserAuthenticated = res.authenticated;
    });
    this.profileState = this.store.select('userInfo');

    this.replyForm = this.fb.group({
      editor: ['', [Validators.required, Validators.maxLength(this.commentLength)]]
    });
   
    this.tributeOptions = this.commentService.getTributeOptions();

    this.profileState.subscribe(res => {
      this.loggedInUserInfo = res;
    })
  }

  ngOnChanges(): void {
    this.userComments = this.loggedItem.userComments.reverse();


    this.sharedDate = moment.utc(this.loggedItem.creationDateTime).local().format(SHARE_DATE_FORMAT);
    if (this.viewerId) {
      this.hasCurrentUserLiked = this.loggedItem.likes.count > 0 ? (this.loggedItem.likes.users.filter(like => like.userId === this.viewerId).length > 0) : false;
    }

    if (this.loggedItem && 
      this.loggedItem.attributeName && 
      (this.loggedItem.attributeName.toLowerCase() === 'duration' 
        || this.loggedItem.attributeName.toLowerCase() === 'time')) {
      let attVal: any = this.loggedItem.attributeValue;
      
      if (attVal.indexOf(':') > -1) {
        attVal = attVal.split(':');
        
        attVal.length === 3 ? (this.loggedItem.attributeValue = (attVal[0] > 0 ? (attVal[0] + 'h ') : '')  + (attVal[1] > 0 ? (attVal[1] + 'm ') : '') + (attVal[2] > 0 ? (attVal[2] + 's') : '')) 
          : 
          (attVal.length === 2 ? (this.loggedItem.attributeValue = (attVal[0] > 0 ? (attVal[0] + 'h ') : '')  + (attVal[1] > 0 ? (attVal[1] + 'm ') : '')) : null);
      }
    }

    if (this.loggedItem &&
      (this.loggedItem.unitNameSingular === 'ounce' || this.loggedItem.unitNamePlural === 'ounces')) {
      this.loggedItem.attributeName = 'Volume';
    }

    switch(this.loggedItem.pillarName) {
      case 'physical':
        this.loggedItem.activityClass = 'physical';
        return;
      case 'occupational':
        this.loggedItem.activityClass = 'occupational';
        return;
      case 'mental':
        this.loggedItem.activityClass = 'mental';
        return;
      case 'friends':
        this.loggedItem.activityClass = 'friends';
        return;
      case 'piggybank':
        this.loggedItem.activityClass = 'piggybank';
        return;
      default:
        this.loggedItem.activityClass = 'environmental';
        return;
    }
  }

  prepareCommentCRLF(comment: string) {
    return comment.replace(/\n/gm, '<br>');
  }

  onNavigate(navigateData: WebviewNavigationDataType) {
    // if (this.webviewMode) {
    //   this.navigateToEmit.emit(navigateData);
    // } else 
    if (navigateData.type === 'LinkInside') {
      this.router.navigate(navigateData.routerLink);
    } else {
      this.document.location.href = navigateData.url;
    }
  }

  toggleLikeComment() {
    if (!this.isUserAuthenticated) return;

    this.isLoading = 'LIKE';
    this.questService.toggleQuestLike(this.questId, this.loggedItem.actvityRecordValueId).subscribe((likedData: any) => {
      this.loggedItem.likes = likedData;
      this.hasCurrentUserLiked = this.loggedItem.likes.count > 0 ? (this.loggedItem.likes.users.filter(like => like.userId === this.viewerId).length > 0) : false;
      this.isLoading = null;
    }, () => {
      this.notifier.notify('error', `Something went wrong.`);
      this.isLoading = null;
    });
  }

  toggleReplyComment(focus: boolean) {
    this.showReplies();
    // if (!this.isReplyFormOpened) {
    //   this.closeAnotherReplyForms.emit();
    //   setTimeout(() => {
    //     this.isReplyFormOpened = true;
    //     if (focus) {
    //       this.focusOnField();
    //     }
    //   }, 0);
    // }
  }

  showReplies() {
    this.showRepliesList = true;
  }

  openGallery(focusIndex) {
    const _mod = this.modalService.open(GalleryFullscreenComponent, {
      backdropClass: 'carousel-bg',
      windowClass: 'carousel-modal'
    });
    // console.log('203', this.loggedItem.images, focusIndex);
    const assetToSend = [];
    this.loggedItem.images.forEach(asset => {
      assetToSend.push({
        questImageUrl: asset.imageURL,
        questVideoUrl: asset.videoURL
      });
    });
    _mod.componentInstance.assets = assetToSend; //this.loggedItem.images;
    _mod.componentInstance.focusedIndex = focusIndex;

    // this.questCommentItemService.initPhotoSwipeFromDOM('.gallery-grid-'+this.loggedItem.actvityRecordValueId, this.isCurrentUserAdminOrOwner);
  }

  replyToComment() {
    if (!this.isLoading) {
      // const replyId = this.comment.id;
      this.isLoading = 'REPLY';

      this.questService
        .addNewQuestComment(this.questId, this.loggedItem.actvityRecordValueId, this.replyForm.value.editor)
        .subscribe((replyData: any) => {
          this.isLoading = null;
          this.replyForm.get('editor').patchValue('');

          // this.loggedItem.userComments.push(replyData);
          this.userComments.push(replyData);
        }, () => {
          this.isLoading = null;
        });
    }
  }

  commentPageNumber: number = 1;
  commentPageSize: number = 10;
  loadMoreComments() {
    this.questService
      .getCommentsOnPost(this.loggedItem.actvityRecordValueId, this.commentPageNumber, this.commentPageSize)
      .subscribe((comments: any) => {
        // const existingComs = this.loggedItem.userComments.map(com => com.commentId);
        const existingComs = this.userComments.map(com => com.commentId);
        comments = comments.filter(com => existingComs.indexOf(com.commentId) < 0);
        comments = comments.reverse();

        this.commentPageNumber += 1;

        // this.loggedItem.userComments.unshift(...comments);
        this.userComments.unshift(...comments);
      })
  }

  cancelReply() {
    this.replyForm.patchValue({
      editor: ''
    });
  }

  showAbbreviation(): boolean {
    if (!this.loggedItem || (this.loggedItem && !this.loggedItem.attributeName)) {
      return;
    }

    return this.loggedItem.attributeName 
      && this.loggedItem.attributeName.toLowerCase() !== 'count' 
      && this.loggedItem.attributeName.toLowerCase() !== 'reps'
      && this.loggedItem.attributeName.toLowerCase() !== 'duration'
      && this.loggedItem.attributeName.toLowerCase() !== 'completed';
  }

  showAttributeName(): boolean {
    if (!this.loggedItem || (this.loggedItem && !this.loggedItem.attributeName)) {
      return;
    }

    return this.loggedItem.attributeName && this.loggedItem.attributeName.toLowerCase() !== 'completed';
  }

  showAttributeValue(): boolean {
    return this.loggedItem.attributeName && this.loggedItem.attributeName.toLowerCase() !== 'completed';
  }

  editFeed(): void {
    this.showOptions = false;
    this.modalReference = this.modalService.open(LogActivityComponent, {
      size: 'lg',
      windowClass: 'log-activity-modal no-overflow',
    });
      
    this.modalReference.result.then(res => {
      this.modalReference = null;
    });
  
    this.modalReference.componentInstance.questId = this.questId;
    this.modalReference.componentInstance.isEditActivity = true;;
    this.modalReference.componentInstance.editActivityData = this.loggedItem;
  }

  confirmDeleteFeed(template) {
    this.modalReference = this.modalService.open(template, {
      size: 'sm',
      windowClass: 'confirm-modal'
    });
  }

  closeModal() {
    this.modalReference.dismiss();
    this.modalService.dismissAll();
  }

  deleteFeed() {
    this.showOptions = false;
    this.questService
      .deleteFeed(this.loggedItem['actvityRecordValueId'])
      .pipe(finalize(() => this.closeModal()))
      .subscribe(res => {
        this.update.emit({
          type: 'removeComment',
          of: this.loggedItem['actvityRecordValueId']
        });

        this.notifier.notify('success', 'Activity deleted successfully.');
      }, err => {
        this.notifier.notify('error', 'Activity deletetion failed.');
      })
  }

  updatedComment(event) {
    if (event.type === 'removeComment') {
      // const index = this.loggedItem.userComments.findIndex(item => item.commentId === event.of);
      const index = this.userComments.findIndex(item => item.commentId === event.of);
      // this.loggedItem.userComments.splice(index, 1);
      this.userComments.splice(index, 1);
      this.loggedItem.totalComments -= 1;
    }
  }

  openCheerModal(modalTemplate) {
    if (this.loggedItem.likes.count > 0) {
      this.modalReference = this.modalService.open(modalTemplate, {
        size: 'sm',
        windowClass: 'cheer-modal'
      });
    }
  }
  
  returnStyle(image) {
    return {
      'background': `url('${image}') 50% 50% / cover no-repeat`
    };
  }

  isAssetVideo(asset) {
    return asset && asset.videoURL && asset.videoURL !== '' && asset.videoURL !== 'null';
  }

  returnCheersText() {
    let cheerText = '';

    // check if loggedin user exists, then move that to 0th index
    let loggedInUserIndex = this.loggedItem.likes.users.findIndex(user => user.userId === this.loggedInUserInfo.id);

    if (loggedInUserIndex > -1) {
      // remove from original list and move atop
      let currentUserInfo = this.loggedItem.likes.users.filter(user => user.userId === this.loggedInUserInfo.id)
      this.loggedItem.likes.users.splice(loggedInUserIndex, 1);
      this.loggedItem.likes.users.unshift(...currentUserInfo);
    }

    this.loggedItem.likes.users.forEach((user, uInd) => {
      let userName = user.brand && user.brand.name ? user.brand.name : (user.userFirstName + ' ' + user.userLastName);
      
      if (uInd === 0) {
        cheerText += user.userId === this.loggedInUserInfo.id ? 'You' : userName;
        if (this.loggedItem.likes.count > 2) {
          cheerText += ', '
        }
      }
      else if (uInd === 1) {
        if (this.loggedItem.likes.count === 2) {
          cheerText += ' and ';
        }
        cheerText += user.userId === this.loggedInUserInfo.id ? 'You' : userName;
      }
      else if (uInd === 2 && this.loggedItem.likes.count > 2) {
        cheerText += (' and ' + (this.loggedItem.likes.count - 2) + ' ' + ((this.loggedItem.likes.count - 2) > 1 ? 'others' : 'other'));
        return;
      }
    });

    return cheerText;
  }

  returnCheerTooltip() {
    let cheerTooltip = '';

    this.loggedItem.likes.users.forEach((user, uInd) => {
      let userName = user.brand && user.brand.name ? user.brand.name : (user.userFirstName + ' ' + user.userLastName);
      
      if (uInd < 15) {
        cheerTooltip += '<div>' + (user.userId === this.loggedInUserInfo.id ? 'You' : userName) + '</div>';
      }
      else if (uInd === 15) {
        cheerTooltip += '<div>' + (this.loggedItem.likes.count - 15) + ' ' + ((this.loggedItem.likes.count - 15) > 1 ? 'others' : 'other') + '</div>';
      }
      else {
        return;
      }
    });

    return cheerTooltip;
  }
}