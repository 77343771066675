import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as ColorContrastChecker from 'color-contrast-checker';
import { forkJoin, Observable } from 'rxjs';
import { QuestUserInfo } from 'src/app/_interface/quest.types';
import { environment } from '../../../../environments/environment';
import { ReaquestHeadersService } from '../../../_services/requestHeaders.service';
import { BankAccount, CreditCard } from '../../main/payments/store/payments.reducer';
import { AccountColor } from './store/account.reducer';
import * as fromApp from '../../../_store/app.reducers';
import { take, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private notifier: NotifierService,
    private store: Store<fromApp.AppState>,
    @Inject(DOCUMENT) private _doc: Document,
    @Inject('isServer') private isServer: boolean,
  ) { }
  getSupportedCountriesStripe () {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/supported-countries',
      {
        headers: headers
      }
    );
  }
  getUserInfo (): Observable<QuestUserInfo> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestUserInfo>(
      environment.target + environment.context + '/getuserinfo',
      {
        headers: headers,
        withCredentials: true
      }
    );
  }
  getUserInfoById (id) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      userId: id
    };
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyid',
      payload,
      { headers: headers }
    );
  }
  getBankAccount (purpose: string): Observable<BankAccount[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<BankAccount[]>(
      environment.target + environment.context + `/accounts/bank-accounts/list/${purpose}`,
      { headers: headers }
    );
  }

  getBankAccounts(): Observable<BankAccount[][]> {
    return forkJoin([this.getBankAccount('payments'), this.getBankAccount('payouts')]);
  }

  getCreditCards(): Observable<CreditCard[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<CreditCard[]>(
      environment.target + environment.context + '/accounts/listcreditcards',
      { headers: headers }
    );
  }
  getFullyPaymentsInfo(): Observable<[BankAccount[][], CreditCard[]]> {
    return forkJoin([this.getBankAccounts(), this.getCreditCards()]);
  }
  getAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getSupportedCountriesStripe()]);
  }
  getFullyAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getCreditCards()]);
  }
  editProfile (payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/editprofile',
      payload,
      { headers: headers }
    );
  }
  editPassword (password: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      password: password
    };
    return this.http.post(
      environment.target + environment.context + '/changepassword',
      payload,
      { headers: headers }
    );
  }

  saveCustomColor(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/user/addcustomcolor',
      payload,
      { headers: headers }
    );
  }

  getCustomColor(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/user/getcustomcolor/${userId}`,
      { headers: headers }
    );
  }

  // setColorInStorage(colorObj: AccountColor) {
  //   localStorage.setItem('custom-color', JSON.stringify(colorObj));
  // }

  // getColorFromStorage(): AccountColor {
  //   return JSON.parse(localStorage.getItem('custom-color'));
  // }

  checkAccountColorAndApply() {
    this.store.select('account')
      .subscribe(res => {
        if (res.accentColor && res.accentColor !== '' && res.themeColor && res.themeColor !== '') {
          this.changeRootColor(res);
        } else {
          this.resetAllRootColors();
        }
      });
  }

  resetAllRootColors() {
    if (this.isServer) {
      return;
    }
    // console.log('124 reset all colors');
    
    document.documentElement.style.removeProperty(`--accent-color`);
    document.documentElement.style.removeProperty(`--primary-bg`);
    document.documentElement.style.removeProperty(`--white-section-bg`);
    document.documentElement.style.removeProperty(`--bg-text-color`);
    document.documentElement.style.removeProperty(`--theme-text-light`);
    document.documentElement.style.removeProperty(`--theme-text-dark`);
    document.documentElement.style.removeProperty(`--btn-text-light`);
    document.documentElement.style.removeProperty(`--accent-bg-light-color`);
    document.documentElement.style.removeProperty(`--btn-text-color`);
    document.documentElement.style.removeProperty(`--quest-content-bg`);
    document.documentElement.style.removeProperty(`--quest-highlight-bg`);
    document.documentElement.style.removeProperty(`--modal-bg`);
    document.documentElement.style.removeProperty(`--feedback-bg`);
    document.documentElement.style.removeProperty(`--bg-text-color-light`);
    document.documentElement.style.removeProperty(`--transparent-white-bg`);
    document.documentElement.style.removeProperty(`--bg-white-ghost`);
    document.documentElement.style.removeProperty(`--comment-blue-bg`);
    document.documentElement.style.removeProperty(`--bg-milestone`);
    document.documentElement.style.removeProperty(`--white-bg-original`);
    document.documentElement.style.removeProperty(`--accent-dark-color`);
    document.documentElement.style.removeProperty(`--milestone-task-bg`);
    document.documentElement.style.removeProperty(`--flow-pages-bg`);
    document.documentElement.style.removeProperty(`--tooltip-bg`);
    document.documentElement.style.removeProperty(`--tooltip-text-color`);
    document.documentElement.style.removeProperty(`--flow-pages-section-bg`);
    document.documentElement.style.removeProperty(`--nav-flow-number-bg`);
    document.documentElement.style.removeProperty(`--nav-flow-number-color`);
    document.documentElement.style.removeProperty(`--close-btn-invert`);
    document.documentElement.style.removeProperty(`--payment-block-row`);
    document.documentElement.style.removeProperty(`--search-hover-bg`);
    document.documentElement.style.removeProperty(`--progress-bar-uncovered`);
    document.documentElement.style.removeProperty(`--read-more-btn-text`);
    document.documentElement.style.removeProperty(`--read-more-gradient`);
    document.documentElement.style.removeProperty(`--dots-bg`);
    document.documentElement.style.removeProperty(`--dots-color`);
    document.documentElement.style.removeProperty(`--total-amount-color`);
    document.documentElement.style.removeProperty(`--team-option-bg`);
    document.documentElement.style.removeProperty(`--team-option-bg-dark`);
    document.documentElement.style.removeProperty(`--checkbox-selection-color`);
    document.documentElement.style.removeProperty(`--comment-text-color`);
    document.documentElement.style.removeProperty(`--primary-bg-secondary`);
    document.documentElement.style.removeProperty(`--quest-detail-right`);
    document.documentElement.style.removeProperty(`--bg-text-color-secondary`);
    document.documentElement.style.removeProperty(`--table-member-bg`);
    document.documentElement.style.removeProperty(`--table-rank-bg`);
    document.documentElement.style.removeProperty(`--table-gutter-bg`);
    
    document.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#000000');
  }

  changeRootColor(colorObject: AccountColor) {
    if (this.isServer) {
      return;
    }
    this.resetAllRootColors();

    const themeColor = colorObject['themeColor']; //bg color
    const accentColor = colorObject['accentColor']; //changing the teal
    // checking color contrast
    const ccc = new ColorContrastChecker();

    // console.log('1101-', ccc.isLevelAA(themeColor, accentColor, 16), ccc.isLevelAA(accentColor, themeColor, 16));

    this._doc.documentElement.style.setProperty(`--accent-color`, accentColor);
    this._doc.documentElement.style.setProperty(`--total-amount-color`, accentColor);
    // only apply if themeColor is not default one
    if (themeColor !== '#E5EAED') {
      this._doc.documentElement.style.setProperty(`--primary-bg`, themeColor);
      this._doc.documentElement.style.setProperty('--white-section-bg', `${themeColor}`);
      this._doc.documentElement.style.setProperty('--progress-bar-uncovered', `${themeColor}`);
      this._doc.documentElement.style.setProperty(`--modal-bg`, themeColor);
      this._doc.documentElement.style.setProperty(`--flow-pages-section-bg`, themeColor);
      this._doc.documentElement.style.setProperty(`--read-more-gradient`, themeColor);
    }
    
    const textColorBlack = '#2e3638';
    const textColorWhite = '#ffffff';

    // checking below for theme's color ie btn and nav bar of quest
    // 16 is average font size used
    if (ccc.isLevelAA(accentColor, textColorBlack, 18)) {
      // black is valid
      console.log('changeRootColor 157');

      const lighterColor = this.LightenDarkenColor(accentColor.replace('#', ''), 30);
      this._doc.documentElement.style.setProperty(`--theme-text-light`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--theme-text-dark`, textColorWhite);
      this._doc.documentElement.style.setProperty('--btn-text-light', textColorBlack);

      const hexToRgbColr = this.hexToRgb(`#${lighterColor}`)
      this._doc.documentElement.style.setProperty('--accent-bg-light-color', `#${lighterColor}`); //`rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.15)`);

      const darkerThemeColor = this.LightenDarkenColor(accentColor.replace('#', ''), -40);
      this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkerThemeColor}`);

      this._doc.documentElement.style.setProperty('--nav-flow-number-bg', `#000000`);
      this._doc.documentElement.style.setProperty('--nav-flow-number-color', `#ffffff`);

      this._doc.documentElement.style.setProperty('--checkbox-selection-color', textColorBlack);
    }

    if (themeColor !== '#E5EAED' && ccc.isLevelAA(themeColor, textColorBlack, 18)) {
      // black is valid on bg 
      console.log('changeRootColor 169');
      // const darkestThemeColor = this.LightenDarkenColor(bgColor.replace('#', ''), -155);
      //`rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.35)`);
      // this._doc.documentElement.style.setProperty('--btn-text-light', `${textColorBlack}`);
      this._doc.documentElement.style.setProperty('--bg-text-color', textColorBlack);

      const darkWhiteColor = this.LightenDarkenColor(textColorWhite.replace('#', ''), -100);
      this._doc.documentElement.style.setProperty('--bg-text-color-light', `#${darkWhiteColor}`);
      this._doc.documentElement.style.setProperty('--bg-text-color-secondary', `#${darkWhiteColor}`);

      this._doc.documentElement.style.setProperty('--btn-text-color', textColorBlack);

      // this._doc.documentElement.style.setProperty('--primary-bg', 'rgba(255, 255, 255, 0.6)');
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(255, 255, 255, 0)`);
      this._doc.documentElement.style.setProperty(`--quest-highlight-bg`, 'rgba(255, 255, 255, 0.55)');
      this._doc.documentElement.style.setProperty(`--transparent-white-bg`, 'rgba(255, 255, 255, 0.55)');

      this._doc.documentElement.style.setProperty(`--bg-white-ghost`, 'rgba(255, 255, 255, 0.5)');

      this._doc.documentElement.style.setProperty('--comment-blue-bg', 'rgba(0, 0, 0)');
      this._doc.documentElement.style.setProperty('--comment-text-color', textColorWhite);
      this._doc.documentElement.style.setProperty('--bg-milestone', 'rgba(255, 255, 255, 0.55)');

      const darkThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 10);
      const darkerThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), -36);
      const darkestThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), -50);
      this._doc.documentElement.style.setProperty('--white-bg-original', `#${darkestThemeColor}`);
      // this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkestThemeColor}`);
      this._doc.documentElement.style.setProperty(`--feedback-bg`, `#${darkThemeColor}`);
      this._doc.documentElement.style.setProperty(`--flow-pages-bg`, `#${darkThemeColor}`);
      this._doc.documentElement.style.setProperty(`--search-hover-bg`, `#${darkThemeColor}`);

      this._doc.documentElement.style.setProperty('--milestone-task-bg', 'rgba(255, 255, 255, 0.55)');

      this._doc.documentElement.style.setProperty(`--tooltip-bg`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--tooltip-text-color`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--close-btn-invert-str`, `0`);

      this._doc.documentElement.style.setProperty(`--payment-block-row`, `rgba(255, 255, 255, 0.3)`);

      this._doc.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#000000');

      this._doc.documentElement.style.setProperty(`--read-more-btn-text`, textColorBlack);

      this._doc.documentElement.style.setProperty(`--dots-bg`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--dots-color`, `${textColorWhite}`);

      this._doc.documentElement.style.setProperty(`--team-option-bg`, `rgba(0, 0, 0, 0.3)`);
      this._doc.documentElement.style.setProperty(`--team-option-bg-dark`, `rgba(0, 0, 0, 0.1)`);

      this._doc.documentElement.style.setProperty('--primary-bg-secondary', `#${darkerThemeColor}`);
      this._doc.documentElement.style.setProperty('--quest-detail-right', `${themeColor}`);
    }
    
    if (ccc.isLevelAA(accentColor, textColorWhite, 20)) {
      // white is valid
      console.log('changeRootColor 183');
      const darkerColor = this.LightenDarkenColor(accentColor.replace('#', ''), -40);
      
      this._doc.documentElement.style.setProperty(`--theme-text-light`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--theme-text-dark`, textColorBlack);
      this._doc.documentElement.style.setProperty('--btn-text-light', textColorWhite);

      // const hexToRgbColr = this.hexToRgb(`#${darkerColor}`);
      this._doc.documentElement.style.setProperty('--accent-bg-light-color', `#${darkerColor}`); //`rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.45)`);

      const darkerThemeColor = this.LightenDarkenColor(accentColor.replace('#', ''), 40);
      this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkerThemeColor}`);

      this._doc.documentElement.style.setProperty('--nav-flow-number-bg', `#ffffff`);
      this._doc.documentElement.style.setProperty('--nav-flow-number-color', `#000000`);

      this._doc.documentElement.style.setProperty('--checkbox-selection-color', textColorWhite);
    }

    if (themeColor !== '#E5EAED' && ccc.isLevelAA(themeColor, textColorWhite, 20)) {
      // black is valid on bg 
      console.log('changeRootColor 194');
      // const darkestThemeColor = this.LightenDarkenColor(bgColor.replace('#', ''), -155);
      // this._doc.documentElement.style.setProperty('--btn-text-light', `${textColorBlack}`);
      this._doc.documentElement.style.setProperty('--bg-text-color', textColorWhite);

      const darkWhiteColor = this.LightenDarkenColor(textColorWhite.replace('#', ''), -40);
      this._doc.documentElement.style.setProperty('--bg-text-color-light', `#${darkWhiteColor}`);
      this._doc.documentElement.style.setProperty('--bg-text-color-secondary', `#${darkWhiteColor}`);
      
      this._doc.documentElement.style.setProperty('--btn-text-color', textColorWhite);

      // this._doc.documentElement.style.setProperty('--primary-bg', 'rgba(0, 0, 0, 0.6)');
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(0, 0, 0, 0)`);
      this._doc.documentElement.style.setProperty(`--quest-highlight-bg`, 'rgba(255, 255, 255, 0.15)');
      this._doc.documentElement.style.setProperty(`--transparent-white-bg`, 'rgba(255, 255, 255, 0.15)');

      this._doc.documentElement.style.setProperty(`--bg-white-ghost`, 'rgba(0, 0, 0, 0.5)');

      this._doc.documentElement.style.setProperty('--comment-blue-bg', 'rgba(255, 255, 255)');
      this._doc.documentElement.style.setProperty('--comment-text-color', textColorBlack);
      this._doc.documentElement.style.setProperty('--bg-milestone', 'rgba(255, 255, 255, 0.15)');

      const lightThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 36);
      this._doc.documentElement.style.setProperty('--white-bg-original', `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--feedback-bg`, `#${lightThemeColor}`);
      // this._doc.documentElement.style.setProperty('--accent-dark-color', `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--flow-pages-bg`, `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--search-hover-bg`, `#${lightThemeColor}`);

      this._doc.documentElement.style.setProperty('--milestone-task-bg', 'rgba(255, 255, 255, 0.15)');

      this._doc.documentElement.style.setProperty(`--tooltip-bg`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--tooltip-text-color`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--close-btn-invert-str`, `1`);

      this._doc.documentElement.style.setProperty(`--payment-block-row`, `rgba(0, 0, 0, 0.3)`);

      this._doc.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#a9a9a9');

      this._doc.documentElement.style.setProperty(`--read-more-btn-text`, textColorWhite);

      this._doc.documentElement.style.setProperty(`--dots-bg`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--dots-color`, `${textColorBlack}`);

      this._doc.documentElement.style.setProperty(`--team-option-bg`, `rgba(255, 255, 255, 0.3)`);
      this._doc.documentElement.style.setProperty(`--team-option-bg-dark`, `rgba(255, 255, 255, 0.1)`);
      
      document.documentElement.style.setProperty('--primary-bg-secondary', `#${lightThemeColor}`);
      document.documentElement.style.setProperty('--quest-detail-right', `${themeColor}`);
      document.documentElement.style.setProperty('--table-member-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 25)}`);
      document.documentElement.style.setProperty('--table-rank-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 45)}`);
      document.documentElement.style.setProperty('--table-gutter-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 45)}`);
    }
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  LightenDarkenColor(col,amt) {
    var usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;
    
    var g = (num & 0x0000FF) + amt;

    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    let color = (g | (b << 8) | (r << 16)).toString(16);
    color = ('000000' + color);
    color = color.substring(color.length - 6, color.length);
    return (usePound?"#":"") + color;
  }

  checkEnteredCustomColor(colorForm, bgColorElem, accentColorElem, rendererServ, showError = true) {
    if (this.isServer) {
      return;
    }
    let defaultAccentColor = '008297';
    let defaultBackgroundColor = '#E5EAED';
    if (colorForm.value.revertCustomColor) {
      colorForm.patchValue({
        accentColor: (defaultAccentColor).replace('#', ''),
        themeColor: defaultBackgroundColor
      }, {emitEvent: false});
    }

    const colors = colorForm.value;
    if (bgColorElem && bgColorElem.nativeElement) rendererServ.setStyle(bgColorElem.nativeElement, 'background', colors.themeColor);
    if (accentColorElem && accentColorElem.nativeElement) rendererServ.setStyle(accentColorElem.nativeElement, 'background', '#' + colors.accentColor);
    
    if (!showError || colorForm.invalid) {
      return false;
    }
    
    colors.accentColor = '#' + (colors.accentColor.replace('#', ''));
    if ((colors.accentColor && colors.accentColor !== '' && colors.accentColor.indexOf('#') < 0) 
      || (colors.themeColor && colors.themeColor !== '' && colors.themeColor.indexOf('#') < 0)) {
      this.notifier.notify('error', 'Color should be a HEX value with a hash(#).');

      return false;
    }

    if (colors.accentColor === colors.themeColor) {
      this.notifier.notify('error', 'Accent and theme colors cannot be same.');

      return false;
    }

    const ccc = new ColorContrastChecker();
    if (colors.accentColor && colors.themeColor && 
        !ccc.isLevelAA(colors.themeColor, colors.accentColor, 20) || 
        !ccc.isLevelAA(colors.accentColor, colors.themeColor, 20)) {
      this.notifier.notify('error', 'Accent and theme colors are not in contrast.');

      return false;
    }

    if (!colors.revertCustomColor && ((!colors.accentColor || colors.accentColor == '') || (!colors.themeColor || colors.themeColor == ''))) {
      this.notifier.notify('error', 'Accent and theme colors need to be filled.');

      return false;
    }

    // changing color
    // console.log('changing color of sample', rendererServ, this.bgColorElem, this.accentColorElem, colors.accentColor, colors.themeColor);
    
    const textColorBlack = '#2e3638';
    const textColorWhite = '#ffffff';
    this._doc.documentElement.style.setProperty(`--sample-primary-bg`, colors.themeColor);
    this._doc.documentElement.style.setProperty('--sample-white-section-bg', `${colors.themeColor}`);
    this._doc.documentElement.style.setProperty(`--sample-theme-bg-color`, colors.accentColor);

    if (ccc.isLevelAA(colors.themeColor, textColorBlack, 18)) {
      this._doc.documentElement.style.setProperty('--sample-bg-text-color', textColorBlack);
      this._doc.documentElement.style.setProperty(`--sample-quest-content-bg`, `rgba(255, 255, 255, 0)`);
    }

    if (ccc.isLevelAA(colors.themeColor, textColorWhite, 18)) {
      this._doc.documentElement.style.setProperty('--sample-bg-text-color', textColorWhite);
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(0, 0, 0, 0)`);
    }

    if (ccc.isLevelAA(colors.accentColor, textColorBlack, 18)) {
      const lighterColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), 30);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-color`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-light`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-dark`, textColorWhite);
      this._doc.documentElement.style.setProperty('--sample-btn-text-light', textColorWhite);
      
      const hexToRgbColr = this.hexToRgb(`#${lighterColor}`)
      this._doc.documentElement.style.setProperty('--sample-theme-bg-light-color', `rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.15)`);

      const darkerThemeColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), -40);
      this._doc.documentElement.style.setProperty('--sample-theme-dark-color', `#${darkerThemeColor}`);
    }

    if (ccc.isLevelAA(colors.accentColor, textColorWhite, 18)) {
      const darkerColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), -30);
      
      this._doc.documentElement.style.setProperty(`--sample-theme-text-color`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-light`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-dark`, textColorBlack);
      this._doc.documentElement.style.setProperty('--sample-btn-text-light', textColorBlack);

      const hexToRgbColr = this.hexToRgb(`#${darkerColor}`)
      this._doc.documentElement.style.setProperty('--sample-theme-bg-light-color', `rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.15)`);

      const darkerThemeColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), 40);
      this._doc.documentElement.style.setProperty('--sample-theme-dark-color', `#${darkerThemeColor}`);
    }

    return true;
  }

  deleteUserAccount(userId): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/deleteuser/${userId}`,
      {
        headers: headers
      }
    )
  }
}
