import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddCommentToList, AnimateItems, AnimateToggleList, FadeIn } from 'src/app/animations/comments.animations';
import { MILESTONE_TITLE_HINTS } from 'src/app/app.config';
import { AddNewTaskGroup, ClearMilestoneForPreview, CreateDefaultTaskGroup, MoveMilestone, NewTaskGroupAdded, NEW_TASK_GROUP_ADDED, PatchGroupName, TaskGroupRemoved, TASK_GROUP_REMOVED, UpdateMilestoneGroupName, UpdateTaskPosition } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { DlMilestoneManageModes, TaskMovedNotification } from 'src/app/_interface/dl-milestones.typings';
import { QuestMapRoute, QuestTask } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { AppState } from 'src/app/_store/app.reducers';
import { QuestService } from '../../../_services/quest.service';

@Component({
  selector: 'app-dl-milestones',
  templateUrl: './dl-milestones.component.html',
  styleUrls: ['./dl-milestones.component.scss'],
  animations: [AnimateToggleList, AnimateItems, AddCommentToList, FadeIn],
})
export class DlMilestonesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() canBeEdit: boolean;
  @Input() allowCheck: boolean;
  @Input() showControls: boolean;
  @Input() questId: number;
  @Input() userId: number;
  @Input() selectedValue: string;
  @Input() mode: DlMilestoneManageModes;
  @Input() directionsType?: string;
  @Input() taskViewDisabled: boolean;
  @Input() teams: QuestTeam[] = [];
  @Input() mapView: any;
  @Input() hasMapRoute: boolean;
  @Input() existingTasks: any[];
  mapRoutes: QuestMapRoute[];

  milestoneModes = DlMilestoneManageModes;
  dlMilestonesState: Observable<TaskGroupsState>;
  newTaskGroupSubscription: Subscription;
  removedTaskGroupSubscription: Subscription;
  isGroupInEditMode: boolean[] = [];
  isGroupExpanded: boolean[] = [true];
  isEditMap: boolean = false;
  showHints: boolean[] = [];
  titleHints = MILESTONE_TITLE_HINTS;
  groupsConnectedIds: string[] = [];
  groupElementIdPrefix = 'task_group-';
  newGroupElementIdPrefix = 'new_task_group-';
  MAX_FILE_SIZE = 30000000;
  isLoading: boolean = false;
  isToggling: boolean = false;
  milestonesForm: UntypedFormGroup;
  @Output() milestoneUpdated: EventEmitter<any> = new EventEmitter;

  constructor(private store: Store<AppState>,
    private questService: QuestService,
    private actionUpdates: ActionsSubject,
    private notifier: NotifierService,
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');

    this.newTaskGroupSubscription = actionUpdates.pipe(
      ofType<NewTaskGroupAdded>(NEW_TASK_GROUP_ADDED),
    ).subscribe(action => this.groupEditOn(action.taskGroup.order));
    this.removedTaskGroupSubscription = actionUpdates.pipe(
      ofType<TaskGroupRemoved>(TASK_GROUP_REMOVED),
    ).subscribe(action => {
      this.isGroupInEditMode.splice(action.groupIndex, 1);
      this.isGroupExpanded.splice(action.groupIndex, 1);
    });
  }

  ngOnInit() {
    this.isGroupInEditMode = [];
    this.groupsConnectedIds = [];
    if (this.mode === DlMilestoneManageModes.NEW_QUEST) {
      this.store.dispatch(new NewTaskGroupAdded({
        id: null,
        order: 0,
        name: 'Tasks',
        questTasks: []
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mode === DlMilestoneManageModes.NEW_QUEST || this.mode === DlMilestoneManageModes.QUEST_MANAGE) {
      this.isGroupInEditMode = [];
    }
    if (changes.hasMapRoute && this.hasMapRoute) {
      this.getRoutes();
    }
  }

  ngOnDestroy(): void {
    if (this.newTaskGroupSubscription) {
      this.newTaskGroupSubscription.unsubscribe();
    }
    if (this.removedTaskGroupSubscription) {
      this.removedTaskGroupSubscription.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<QuestTask[]>) {
    const previousGroupIndex = Number(event.previousContainer.id.replace(this.groupElementIdPrefix, ''));
    const currentGroupIndex = Number(event.container.id.replace(this.groupElementIdPrefix, ''));
    if (previousGroupIndex === currentGroupIndex && event.previousIndex === event.currentIndex) {
      return;
    }

    const notification: TaskMovedNotification = {
      currentGroupIndex: currentGroupIndex,
      previousGroupIndex: previousGroupIndex,
      currentIndex: event.currentIndex,
      previousIndex: event.previousIndex
    };

    this.store.dispatch(new MoveMilestone(notification));
    if (this.mode !== DlMilestoneManageModes.NEW_QUEST) {
      this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
        this.store.dispatch(new UpdateTaskPosition({
          taskId: event.item.data.id,
          taskOrder: event.currentIndex,
          groupId: state.milestones[currentGroupIndex].id
        }, notification));
      });
    }
  }

  changeGroupTitle(groupIndex: number, groupName: string) {
    if (this.mode === DlMilestoneManageModes.NEW_QUEST) {
      this.store.dispatch(new PatchGroupName({index: groupIndex, value: groupName}));
    } else {
      this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
        if (state.milestones[groupIndex].name !== groupName) {
          if (state.milestones[groupIndex].id) {
            this.store.dispatch(new UpdateMilestoneGroupName(groupIndex, state.milestones[groupIndex].id, groupName));
          } else {
            this.store.dispatch(new CreateDefaultTaskGroup(this.questId, groupName, groupIndex));
          }
        }
      });
    }
  }

  onCloseMilestoneDetailsDialog() {
    this.store.dispatch(new ClearMilestoneForPreview());
  }

  onFocusIn(index: number) {
    this.showHints[index] = true;
  }

  onFocusOut(index: number) {
    setTimeout(() => {
      this.showHints[index] = false;
    });
  }

  groupEditOn(index: number) {
    this.isGroupExpanded[index] = true;
    this.isGroupInEditMode[index] = true;
    this.groupsConnectedIds.push(this.groupElementIdPrefix + String(index));
    this.groupsConnectedIds.push(this.newGroupElementIdPrefix + String(index));
  }

  groupEditOff(index: number) {
    this.isGroupInEditMode[index] = false;
    DlMilestonesComponent.removeByValue(this.groupsConnectedIds, this.groupElementIdPrefix + String(index));
    DlMilestonesComponent.removeByValue(this.groupsConnectedIds, this.newGroupElementIdPrefix + String(index));
  }

  groupAdd() {
    this.store.dispatch(new AddNewTaskGroup(this.questId, null, this.userId));
  }

  goToLinkedQuest(milestoneId: number) {
    if (milestoneId) {
      console.log('DlMilestonesComponent :: Captured milestone selection on map', milestoneId);
    }
  }

  collapseOrExpandGroup(groupIndex: number) {
    this.isGroupExpanded[groupIndex] = !this.isGroupExpanded[groupIndex];
  }

  private static removeByValue(array: any[], value: any) {
    let i = 0;
    while (i < array.length) {
      if (array[i] === value) {
        array.splice(i, 1);
      } else {
        i++;
      }
    }
    return array;
  }

  isEditingMap(editing) {
    this.isEditMap = editing;
  }

  fileChangeEvent(event: any): void {
    this.isLoading = true;
    setTimeout(() => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (file.size > this.MAX_FILE_SIZE) {
          this.notifier.notify('warning', 'File is too large, please try a smaller file');
          return;
        }
        let payload = {
          'gpxFile' : file,
          'name' : file.name.substring(0, file.name.length - 4),
          'description' : 'desc'
        }
        this.questService.addMapRoute(this.questId, payload).subscribe((res) => {
          if (res.status == 200) {
            this.notifier.notify('success', 'Route successfully uploaded');
            this.getRoutes();
          } else {
            this.notifier.notify('error', 'Something went wrong, please try again later');
          }
          this.isLoading = false;
        }, (err) => {
          this.isLoading = false;
            this.notifier.notify('error', 'Error uploading file, please use a valid .gpx file');
        });
      }
    }, 1000);
  }

  private getRoutes() {
    this.questService.getMapRoutes(this.questId).subscribe(routes => {
      this.mapRoutes = [...routes];
    })
  }

  toggleRoutes() {
    this.isToggling = true;
    this.questService.toggleMapRoutes(this.questId, !this.hasMapRoute).subscribe(res => {
      if (res.status == 200) {
        let msg = this.hasMapRoute ? "Turned routes off" : "Turned routes on";
        this.notifier.notify('success', msg);
        this.hasMapRoute = !this.hasMapRoute;
        this.mapRoutes = [];
        if (this.hasMapRoute) {
          this.getRoutes();
        }
      } else {
        this.notifier.notify('error', "Something went wrong, please try again later");
      }
      this.isToggling = false;
    }, err => {
      this.isToggling = false;
      this.notifier.notify('error', "Something went wrong, please try again later");
    });
  }

  milestoneUpdate(event) {
    // console.log('dlmilestones - milestoneUpdate 285--', event);
    this.milestoneUpdated.emit({
      ...event
    });
  }
}
