import { QuestUserInfo } from '../../../../_interface/quest.types';
import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as ProfileActions from './profile.actions';

export interface State extends QuestUserInfo {
}

export const initialState: State = {
  active: false,
  country: null,
  coverPictureURL: null,
  createdOn: null,
  email: null,
  emailValidated: false,
  firstName: null,
  id: null,
  identifier: null,
  isUserBrand: null,
  lastLogin: null,
  lastName: null,
  missionStatement: null,
  name: null,
  profilePictureURL: null,
  receiveEmail: null,
  stripeEntityId: null,
  updatedOn: null,
  userName: null,
  userNonProfit: false,
  zip: null,
  absorbFees: false,
  realtimeQuestId: null,
  centerX: null,
  centerY: null,
  zoomValue: null
};

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateUserInfo';

export function ProfileReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  action: ProfileActions.ProfileActions
) {
  switch (action.type) {
    case (ProfileActions.GET_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.UPDATE_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.CLEAR_USER_INFO):
      localStorageService.clear(LOCAL_STATE_KEY);
      return {
        ...state,
        ...initialState
      };
      case (ProfileActions.REALTIME_QUEST_STARTED) :
        return {
          ...state,
          realtimeQuestId : (action as ProfileActions.RealtimeQuestStarted).questId,
          realtimeUserId : (action as ProfileActions.RealtimeQuestStarted).userId
        };
      case (ProfileActions.REALTIME_QUEST_REPEATED) :
        return {
          ...state,
          realtimeQuestId : (action as ProfileActions.RealtimeQuestRepeated).questId,
          realtimeUserId : (action as ProfileActions.RealtimeQuestRepeated).userId
        };
      case (ProfileActions.REALTIME_QUEST_FINISHED) :
        return {
          ...state,
          realtimeQuestId : null,
          realtimeUserId : null
        };
    default:
      return state;
  }
}
