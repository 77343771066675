import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { LeaderboardMemberStatus, LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import { QuestInfo } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { QuestService } from '../../../../_services/quest.service';

@Component({
  selector: 'app-leaderboard-main',
  templateUrl: './leaderboard-main.component.html',
  styleUrls: ['./leaderboard-main.component.scss']
})
export class LeaderboardMainComponent implements OnInit {
  selectedAttributeId: string;
  isLoaded = false;
  updateProgress = false;
  filter = true;

  @Input()
  teams: QuestTeam[];

  @Input()
  quest: QuestInfo;

  @Input()
  allowEdit: boolean;

  members: LeaderboardScore[];

  constructor(private questService: QuestService,
              private notifier: NotifierService) {
    this.members = [];
  }

  ngOnInit() {
  }

  updateFromDoers(): void {
    if (this.updateProgress) {
      return;
    }
    this.updateProgress = true;
    this.questService.updateLeaderBoardDoers(this.quest.id).subscribe(updatedCount => {
      this.updateProgress = false;
      this.loadScoresForAttribute(this.selectedAttributeId);
      this.notifier.notify('success', `Leaderboard updated`);
    });
  }

  onSelectedIdChanged(attributeId: string) {
    this.selectedAttributeId = attributeId;
    this.loadScoresForAttribute(attributeId);
  }

  onUpdateMember(updatedMember: LeaderboardScore) {
    this.members[[...this.members].map((member: LeaderboardScore) => member.memberId).indexOf(updatedMember.memberId)] = {...updatedMember};
    this.loadScoresForAttribute(this.selectedAttributeId);
  }

  private loadScoresForAttribute(attributeId: string): void {
    this.questService.getQuestLeaderboard(this.quest.id, attributeId).pipe(take(1)).subscribe(score => {
      const scores = this.filter ? score.filter(user => user.score > 0 && user.status !== LeaderboardMemberStatus.NoInfo) : score;
      this.members.length = 0;
      this.members.push(...scores);
      if (!this.isLoaded) {
        this.isLoaded = true;
      }
    });
  }

  toggleUsers(){
    this.filter = !this.filter;
    this.loadScoresForAttribute(this.selectedAttributeId);
  }

}
