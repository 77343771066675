import { NotificationType, ReadNotificationPayloadType } from './notification.types';
import { QuestDoer } from './quest.types';

export class QuestLogic {

  public static checkIfCurrentUserQuestDoer(members: QuestDoer[], currentUserId: number): boolean {
    // * check if current user is doer
    const filteredMembers = members.filter(member => member.userId === currentUserId);
    if (filteredMembers.length === 0) { return false; }
    return filteredMembers[0].memberStatus.indexOf('Doer') >= 0;
  }
}
