import {DecimalPipe} from '@angular/common';
import {LeaderboardMemberStatus, LeaderboardScore} from '../leaderboard.types';

export class LeaderboardUtils {

  private constructor() {
  }

  public static getScoreText(leaderboardScore: LeaderboardScore): string {
    if (leaderboardScore.status === LeaderboardMemberStatus.DidNotFinish) {
      return 'DNF';
    } else if (leaderboardScore.status === LeaderboardMemberStatus.DidNotStart) {
      return 'DNS';
    } else if (leaderboardScore.status === LeaderboardMemberStatus.NoInfo) {
      return '';
    } else {
      return LeaderboardUtils.formatScore(leaderboardScore);
    }
  }

  public static getUnitText(leaderboardScore: LeaderboardScore): string {
    if (leaderboardScore.status === LeaderboardMemberStatus.Started || leaderboardScore.status === LeaderboardMemberStatus.Finished) {
      return LeaderboardUtils.formatUnit(leaderboardScore);
    } else if (leaderboardScore.status === LeaderboardMemberStatus.DidNotFinish) {
      return `(${LeaderboardUtils.formatScore(leaderboardScore)} ${LeaderboardUtils.formatUnit(leaderboardScore)})`.trim();
    } else {
      return '';
    }
  }

  private static formatScore(leaderboardScore: LeaderboardScore): string {
    if (leaderboardScore.unit === 'time') {
      const hours = Math.floor(leaderboardScore.score / 3600);
      const minutes = Math.floor((leaderboardScore.score - hours * 3600) / 60);
      const seconds = Math.floor(leaderboardScore.score - hours * 3600 - minutes * 60);
      const pad = (num: number): string => num < 10 ? '0' + num : '' + num;
      return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
    } else {
      return new DecimalPipe('en-US').transform(leaderboardScore.score);
    }
  }

  private static formatUnit(leaderboardScore: LeaderboardScore): string {
    if (leaderboardScore.unit === 'time') {
      return '';
    } else {
      return leaderboardScore.unit;
    }
  }

}
