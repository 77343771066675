import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LogActivityComponent } from 'src/app/components/separate/log-activity/log-activity.component';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';
import { QuestLogic } from 'src/app/_interface/quest.logic';
import { Quest, QuestDoer, QuestUserInfo } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { AppState } from 'src/app/_store/app.reducers';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { DlLogInComponent } from '../../dl-auth/dl-log-in/dl-log-in.component';

@Component({
  selector: 'app-quest-donate',
  templateUrl: './quest-donate.component.html',
  styleUrls: ['./quest-donate.component.scss']
})
export class QuestDonateComponent implements OnInit {
  @Output() showStartFundraisingModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() showEditFundraisingModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() public questData: Quest;
  @Input() private userId: number;
  @Input() data: FundraisingLinkType;
  @Input() teams: QuestTeam[];
  @Input() doers: QuestDoer[]; //FYI this will be null bc I moved getMembers() into members.component.ts instead of quest-dtail.component.ts
  @Input() userNonProfit?: boolean;
  @Input() requiresBackingConfirmation?: boolean;
  @Input() isProgress?: boolean;
  @Input() includeFundraiseButton = false;
  @Input() disableFundraiseButton = false;
  @Input() enableFundraiseEditLink = false;
  @Input() enableBackingButton = false;
  @Input() enableJoinTeamButton: boolean;
  @Input() enableDetailedTotals: boolean;
  @Input() isMainQuest: boolean;
  @Input() isTeamPage: boolean;
  @Input() isMegaQuest: boolean = false;
  questId: number;
  isGeneralFundOrDefaultTeamPage: boolean = false;
  openedDialog: string;
  isLoaded = false;
  raisedByTeamMembers = 0;
  raisedByDirectDonations = 0;
  fundraisingLink: FundraisingLinkType;
  showTeamSelection = false;
  @Input() dropdownContext: any;
  @Input() doerInfo: any;
  @Input() showStartTrigger: any;
  @Input() showFundraisingData: boolean;

  userInfoState: Observable<fromProfile.State>;
  currentUser: QuestUserInfo;
  @Input('members') members: QuestDoer[] = [];

  modalReference: any = null;
  showShareOptions = false;
  @ViewChild('shareOptionsBox', { read: ElementRef }) shareOptionsBox: ElementRef;
  @ViewChild('donateTeamBox', { read: ElementRef }) donateTeamBox: ElementRef;

  @Input() showLogActivityBtn: boolean;

  constructor(private router: Router,
    private changeDet: ChangeDetectorRef,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private notifier: NotifierService) {
    this.userInfoState = this.store.select('userInfo');
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.showShareOptions && this.shareOptionsBox && !this.shareOptionsBox.nativeElement.contains(event.target)) {
      this.showShareOptions = false;
    }

    if (this.showTeamSelection && this.donateTeamBox && !this.donateTeamBox.nativeElement.contains(event.target)) {
      this.showTeamSelection = false;
    }
  }

  ngOnInit() {
    this.userInfoState.pipe(take(1)).subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.questId = this.questData.quest.id;
    this.raisedByTeamMembers = this.teams.map(team => !team.defaultTeam && team.amountBacked).reduce((acc, val) => acc + val, 0);
    this.raisedByDirectDonations = this.showFundraisingData ? (this.data.currentAmount - this.raisedByTeamMembers) : 0;
    this.isLoaded = true;

    // check if quest is general fund or general donation quest.
    // OR if the team size = 1 and it is default team  
    //  so instead of showing dropdown in donate button. display the cta btn instead
    if (this.teams && this.teams.length === 1 && this.teams[0].teamName && this.teams[0].teamName !== null
      && (this.teams[0].teamName.toLowerCase().indexOf('general fund') > -1 || this.teams[0].teamName.toLowerCase().indexOf('general donation') > -1 || 
      this.teams[0].defaultTeam) ) {
        this.isGeneralFundOrDefaultTeamPage = true;
      }
  }

  navigateToBacking() {
    this.router.navigate(['/donate', this.questId, this.userId]);
  }

  triggerConfirmation(mode: string) {
    this.showStartFundraisingModal.emit(mode);
  }

  triggerEdit() {
    this.showEditFundraisingModal.emit();
  }

  checkFundraisingEndDateIsInFuture(): boolean {
    if (this.questData && this.questData.activityInfo && this.questData.activityInfo.status === "COMPLETE") {
      return false;
    }

    if (this.data && !this.data.endDate) {
      return true;
    }
    return (this.data && this.data.endDate) > Date.now();
  }

  doNothing(): void {
  }

  getQuestId() {
    return this.questId;
  }

  getUserId() {
    return this.userId;
  }

  onTeamSelect(selectedId) {
    if (!selectedId || selectedId === false) {
      this.showTeamSelection = false;
      this.changeDet.detectChanges();

      return false;
    }

    this.showTeamSelection = false;
    let selectedTeam = this.teams.find((team) => team.teamId == selectedId);
    this.router.navigate(['/donate', selectedTeam.questId, selectedTeam.creatorId]);
  }

  logActivity(): void {
    //if user is not there then open login modal
    if (!this.currentUser || (this.currentUser && !this.currentUser.id)) {
      this.modalService.open(DlLogInComponent, {
        windowClass: "no-overflow"
      });
      return;
    }

    const _isCurrentUserQuestDoer = QuestLogic.checkIfCurrentUserQuestDoer(this.members, this.currentUser.id);
    if (!_isCurrentUserQuestDoer) {
      this.notifier.notify('warning', 'Please join the Quest before logging an activity.');

      return;
    }

    this.modalReference = this.modalService.open(LogActivityComponent, {
      size: 'sm',
      windowClass: 'log-activity-modal no-overflow'
    });
    this.modalReference.componentInstance.questId = this.questId;
  }
}
