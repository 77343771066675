// import { AgmInfoWindow, AgmMarker } from '@agm/core';
import {
  Component, Input,
  OnChanges,
  OnInit, SimpleChanges
} from '@angular/core';
import { take } from 'rxjs/operators';
import { MAP_STYLE_GRAY } from 'src/app/app.constants';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { LeaderboardScore } from 'src/app/_interface/leaderboard.types';
import { QuestDoer, QuestMapData, QuestMapRoute } from 'src/app/_interface/quest.types';
import { QuestTeam } from 'src/app/_interface/team.types';
import { QuestService } from '../../../../_services/quest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quest-map',
  templateUrl: './quest-map.component.html',
  styleUrls: ['./quest-map.component.scss']
})
export class QuestMapComponent implements OnInit, OnChanges {

  @Input() data: TaskGroupsState;
  @Input() directionsType?: string = "WALKING";
  @Input() questId: number;
  @Input() userId: number;
  @Input() teams: QuestTeam[] = [];
  @Input() mapView: any;
  @Input() mapRoutes: QuestMapRoute[] = [];

  doers: QuestDoer[] = [];
  viewListDoers: QuestDoer[] = [];
  leaderboardScores: LeaderboardScore[] = [];
  gotScores = false;

  routesDrawn = false;
  routeIds: any[] = [];
  routeDist: number;

  // @Output() private onMilestoneSelected: EventEmitter<number>;

  // @ViewChildren('markerElement') private markerElements: QueryList<ElementRef>;

  questMapData: QuestMapData[] = [];

  geoPointSet = new Set();

  // private map: Map;
  // private mapData: MilestonesMapData;
  // private mapLoaded: boolean;
  // private mapPopupOpenCount: number;

  mapMarkerData: any[] = [];

  mapMarkerDirections: any[] = [];

  mapMarkerDirectionsFinal: any[] = [];

  mapStyle = MAP_STYLE_GRAY;

  mapZoomLevel = 3;

  infoRef = null;//: AgmInfoWindow

  zoomCount = 0;

  constructor(
      private questService: QuestService
  ) {
    // this.mapData = new MilestonesMapData();
    // this.mapLoaded = false;
    // this.mapPopupOpenCount = 0;
    // this.onMilestoneSelected = new EventEmitter<number>();
  }

  ngOnInit() {
    // console.log('82 data', this.data, this.teams, this.mapRoutes, this.mapView);
    
    this.getMembers();

    if (this.teams && this.teams.length) {
      //If team quest, try to get team aggregate leaderboard attribute and if found, put teams on map as pins
      this.addTeamRacers();
    }

    if (this.mapRoutes && this.mapRoutes.length) {
      // this.drawRoutes();
      this.addLeaderboardRacers();
    }

    // this.map = new Map({
    //   accessToken: environment.mapBoxKey,
    //   antialias: true,
    //   container: 'milestones-map',
    //   style: 'mapbox://styles/mapbox/light-v10',
    //   zoom: 13,
    //   center: [-74.0060, 40.7128]//[this.mapData.center.longitude, this.mapData.center.latitude],
    // });
    // this.map.addControl(new NavigationControl({showCompass: false}));

    // this.mapData.map = new MilestonesMapView(this.map, this.mapboxService, this.directionsType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('200', changes, this.mapRoutes);
    
    // if (changes.mapView) {
      // this.markerElements ? this.reloadMilestones() : this.reloadMilestonesData();
    // }

    this.mapZoomLevel = this.mapView.geoZoom || 3;

    if (changes.mapRoutes && this.mapRoutes && this.mapRoutes.length) {
      // this.removeRoutes();
      // this.drawRoutes();
      this.addLeaderboardRacers();
      if (this.teams && this.teams.length) {
        //If team quest, try to get team aggregate leaderboard attribute and if found, put teams on map as pins
        this.addTeamRacers();
      }
    } else if (changes.mapRoutes && (!this.mapRoutes || !this.mapRoutes.length) && this.routeIds && this.routeIds.length) {
      this.mapRoutes = [];
      // this.removeRoutes();
    }
  }

  changeZoom(ev) {
    this.mapZoomLevel = ev;

    if (this.zoomCount < 5) {
      setTimeout(() => {
        this.mapZoomLevel = this.mapView.geoZoom || 3;
      }, 10);
    }
    this.zoomCount += 1;
  }

  private getMembers() {
    // not calling the api if the quest is start DIEMlife
    if ((!environment.production && this.questId === 115) || (environment.production && this.questId === 222)) return false;
    
    this.doers.length = 0;
    this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
      this.doers.push(...members);
      this.viewListDoers = [...this.doers].filter((member: QuestDoer) => {
        //If in the future want to limit the user pins further, use otherConditions
        // let otherConditions = member.completeTasksCount || member.amountBackedSum;
        if (member.memberStatus && member.geopoint && member.location) {
          return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
        }
        return false;
      });
      
      // console.log('545 get members', [...this.doers], [...this.viewListDoers]);
      
      // this.getQuestMapData();

      this.createMapMarkerData();
    });
  }

  createMapMarkerData(): void {
    this.mapMarkerData = [];
    this.mapMarkerDirections = [];
    this.mapMarkerDirectionsFinal = [];
    // console.log('creating map marker', [...this.viewListDoers], [...this.data.milestones], [...this.mapMarkerData], [...this.mapMarkerDirections]);
    
    // checking users and adding marker data in array
    if (this.viewListDoers.length) {
      let mapPointDirections = [];
      this.mapMarkerData = this.viewListDoers.map(user => {
        
        return new QuestMapData({
          id: user.userId,
          geoPoint: {
            latitude: user.geopoint.latitude,
            longitude: user.geopoint.longitude,
          },
          pinText: user.userFullName,
          location: user.location,
          type: 'user',
          iconUrl: {
            url: user && user.amountBackedSum ? (this.mapView.geoDonationIcon ? this.mapView.geoDonationIcon : this.mapView.geoPointIcon) : this.mapView.geoMemberRouteIcon,
            scaledSize: {
              width: 40,
              height: 40
            }
          },
          doers: this.getDoersForPin('user', user.userId),
          showTeams: this.getBooleanShowTeams('user', user.userId)
        })
      });

      // aggregate users in this.mapMarkerData
      let toAdd: QuestMapData[] = [];
      let toRemove: QuestMapData[] = [];
      this.mapMarkerData.forEach((pin) => {
        let setId = pin.geoPoint.latitude.toString() + ',' + pin.geoPoint.longitude.toString();
        // creating aggregate icon if multiple users from same geopoint
        if (this.geoPointSet.has(setId) && 
            !toAdd.find((milestone) => milestone.geoPoint.latitude == pin.geoPoint.latitude && milestone.geoPoint.longitude == pin.geoPoint.longitude)) {
          
          let duplicates = this.mapMarkerData.filter((milestone) => milestone.geoPoint.latitude == pin.geoPoint.latitude && milestone.geoPoint.longitude == pin.geoPoint.longitude);
          let newId = 0;
          toRemove.push(...duplicates);
          duplicates.forEach((duplicate) => {
            newId = newId + duplicate.id;
          });
          const _allIds = duplicates.map((duplicate) => duplicate.id)
          let aggregate = new QuestMapData({
            id: newId,
            geoPoint: {
              latitude: pin.geoPoint.latitude,
              longitude: pin.geoPoint.longitude,
            },
            pinText: (duplicates.length).toString() + " users",
            location: pin.location,
            ids: _allIds,
            type: 'aggregate',
            iconUrl: {
              url: this.mapView.geoTeamRouteIcon,
              scaledSize: {
                width: 40,
                height: 40
              }
            },
            doers: this.getDoersForPin('aggregate', newId, _allIds),
            showTeams: this.getBooleanShowTeams('aggregate', newId)
          });
          toAdd.push(aggregate);
          
          mapPointDirections.push({
            lat: pin.geoPoint.latitude,
            lng: pin.geoPoint.longitude,
          });
        } else {
          this.geoPointSet.add(setId);
          
          mapPointDirections.push({
            lat: pin.geoPoint.latitude,
            lng: pin.geoPoint.longitude,
          });
        }
      });

      toRemove.forEach(milestone => {
        let index = this.mapMarkerData.indexOf(milestone);
        if (index > -1) {
          this.mapMarkerData.splice(index, 1);
          mapPointDirections.splice(index, 1);
        }
      });

      this.mapMarkerData.push(...toAdd);

      if (!this.mapView.mapPinsDisabled) {
        this.mapMarkerDirections.push(mapPointDirections);
      }
    }

    // create points for milestone/tasks and add in marker array
    if (this.data.milestones && this.data.milestones.length > 0) {
      let res = 0;
      this.data.milestones.forEach((taskGroup: any) => {
        res = res + (taskGroup.questTasks.filter(qt => qt.geoPoint).length);
      });

      let rteIndex;
      let milestoneRouteArr = [];
      this.data.milestones.forEach((taskGroup: any) => {
        taskGroup.questTasks.forEach((task, index) => {
          if (task.geoPoint) {
            let _iconUrl = null;
            if (index == 0) {
              rteIndex = 'first';
              _iconUrl = this.mapView.geoStartIcon;
            } else if (index == (res - 1)) {
              rteIndex = 'last';
              _iconUrl = this.mapView.geoEndIcon;
            } else {
              rteIndex = 'none';
              _iconUrl = task.isTaskCompleted ? this.mapView.geoCompletedIcon : this.mapView.geoPointIcon;
            }
            
            this.mapMarkerData.push(new QuestMapData({
              id: task.id,
              geoPoint: task.geoPoint,
              pinText: task.task,
              isCompleted: task.isTaskCompleted,
              location: '',
              type: 'milestone',
              routeWaypoint: true,
              routeIndex: rteIndex,
              iconUrl: {
                url: _iconUrl,
                scaledSize: {
                  width: 40,
                  height: 40
                }
              },
              doers: this.getDoersForPin('milestone', task.id),
              showTeams: this.getBooleanShowTeams('milestone', task.id)
            }));

            if (rteIndex) {
              milestoneRouteArr.push({
                lat: task.geoPoint.latitude,
                lng: task.geoPoint.longitude
              });
            }
          }
        });
      });

      if (milestoneRouteArr.length > 0) {
        this.mapMarkerDirections.push(milestoneRouteArr);
      }
    }

    if (this.mapMarkerDirections.length > 0) {
      let count = 0;
      this.mapMarkerDirections.forEach((directions,i) => {
        this.mapMarkerDirectionsFinal[i] = [];
        directions.forEach((coords, j) => {
          count += 1;
          setTimeout(() => {
            this.mapMarkerDirectionsFinal[i].push(coords);
          }, 1000 * (count + 1));
        });
      })
    }
    // console.log('670 final', this.mapMarkerData);
  }

  private addLeaderboardRacers() {
    if (!this.mapRoutes || !this.mapRoutes.length) {
      console.log("Failed to add leaderboard racers, no routes found")
      return;
    }
    if (!this.gotScores) {
      this.loadScoresForAttribute('individual-total-miles');
      return;
    }
    if (!this.leaderboardScores || !this.leaderboardScores.length) {
      console.log("Failed to add leaderboard racers, no scores found");
      return;
    }
    let leaderDirectionsArr = [];
    this.leaderboardScores.filter((racer) => racer.score > 0).forEach((racer) => {
      let userPin = this.doers ? this.doers.find((obj) => obj.userId == racer.userId) : null;

      let racerPin = new QuestMapData({
        id: racer.userId,
        geoPoint: this.getRoutePointByDistance(this.mapRoutes[0], racer.score),
        pinText: racer.firstName + ' ' + racer.lastName + ': ' + (Math.round(racer.score * 100) / 100).toFixed(2) + (racer.score == 1 ? ' mile' : ' miles'),
        location: racer.city && racer.state ? racer.city + ", " + racer.state : racer.country ? racer.country : 'Racing on DIEMlife',
        type: 'racer',
        iconUrl: {
          url: userPin ? this.mapView.geoMemberRouteIcon : null,
          scaledSize: {
            width: 40,
            height: 40
          }
        },
        doers: this.getDoersForPin('racer', racer.userId),
        showTeams: this.getBooleanShowTeams('racer', racer.userId)
      });

      this.mapMarkerData.push(racerPin);
      if (racerPin.geoPoint) {
        leaderDirectionsArr.push({
          lat: racerPin.geoPoint.latitude,
          lng: racerPin.geoPoint.longitude
        });
      }
    });

    if (leaderDirectionsArr.length > 0) {
      this.mapMarkerDirectionsFinal.push([]);
      const thisIndex = this.mapMarkerDirectionsFinal.length - 1;
      leaderDirectionsArr.forEach((pointDirec, i) => {
        setTimeout(() => {
          this.mapMarkerDirectionsFinal[thisIndex].push(pointDirec);
        }, 1000 * (i + 1));
      });
    }
  }

  private addTeamRacers() {
    this.questService.getQuestLeaderboard(this.questId, 'team-total-miles').pipe(take(1)).subscribe(scores => {
      // console.log('in add team racers');
      
      if (!scores || !scores.length) {
        console.log("Failed to add team racers, no scores found");
        return;
      }
      
      let leaderDirectionsArr = [];
      scores.filter((racer) => racer.score > 0).forEach((racer) => {
        let team = this.teams.find((team) => team.creatorId == racer.userId);
        if (team) {
          let racerPin = new QuestMapData({
            id: team.teamId,
            geoPoint: this.mapRoutes ? this.getRoutePointByDistance(this.mapRoutes[0], racer.score) : null,
            pinText:  'Team ' + team.teamName + ': ' + (Math.round(racer.score * 100) / 100).toFixed(2) + (racer.score == 1 ? ' mile' : ' miles'),
            location: racer.city && racer.state ? racer.city + ", " + racer.state : 'Racing on DIEMlife',
            type: 'team',
            iconUrl: {
              url: this.mapView.geoTeamRouteIcon,
              scaledSize: {
                width: 40,
                height: 40
              }
            },
            doers: this.getDoersForPin('team', team.teamId),
            showTeams: this.getBooleanShowTeams('team', team.teamId)
          });

          // this.questMapData.push(racerPin);

          this.mapMarkerData.push(racerPin);
          if (racerPin.geoPoint) {
            leaderDirectionsArr.push({
              lat: racerPin.geoPoint.latitude,
              lng: racerPin.geoPoint.longitude
            });
          }
        }
      });

      if (leaderDirectionsArr.length > 0) {
        this.mapMarkerDirectionsFinal.push([]);
        const thisIndex = this.mapMarkerDirectionsFinal.length - 1;
        leaderDirectionsArr.forEach((pointDirec, i) => {
          setTimeout(() => {
            this.mapMarkerDirectionsFinal[thisIndex].push(pointDirec);
          }, 1000 * (i + 1));
        });
      }
    });
  }

  private getRoutePointByDistance(route: QuestMapRoute, distInMiles: number) {
    if (!route || !route.points || !route.points.length) {
      console.log("Invalid route or route has no points")
      return null;
    }
    
    let totalDist = route.distance;
    let avgDistBetweenPoints = totalDist / route.points.length;
    let n = Math.round(distInMiles / avgDistBetweenPoints);
    //Calculation for tracks that stops users with scores > total distance at the end of track
    if (n >= route.points.length) {
      n = route.points.length - 1;
    }
    // Calculation for loops to make scores > total distance of the route continue for another lap
    // while (n >= route.points.length) {
    //   n = n - (route.points.length);
    // }
    return route.points[n].geoPoint;
  }

  private loadScoresForAttribute(attributeId: string): void {
    this.questService.getQuestLeaderboard(this.questId, attributeId).pipe(take(1)).subscribe(score => {
      this.gotScores = true;
      this.leaderboardScores.length = 0;
      this.leaderboardScores.push(...score);
      this.addLeaderboardRacers();
    });
  }

  getDoersForPin(pinType: string, pinId: Number, aggregatorIds?: any[]) {
    const userPin = (pinType == 'user' || pinType == 'racer') && this.doers ? this.doers.find((obj) => obj.userId == pinId) : null;
    const teamPin = pinType == 'team' && this.teams ? this.teams.find((team) => team.teamId == pinId) : null;
    
    if (teamPin) {
      return [teamPin];
    } else if (userPin) {
      return [userPin];
    } else if (pinType == 'aggregate') {
      let aggregatedUsers = aggregatorIds ? aggregatorIds.map(id => this.doers.find((obj) => obj.userId == id)) : [];
      return aggregatedUsers;
    } else {
      return null;
    }
  }

  getBooleanShowTeams(pinType: string, pinId: Number) {
    const userPin = (pinType == 'user' || pinType == 'racer') && this.doers ? this.doers.find((obj) => obj.userId == pinId) : null;
    const teamPin = pinType == 'team' && this.teams ? this.teams.find((team) => team.teamId == pinId) : null;

    if (teamPin) {
      return !!teamPin;
    } else if (userPin) {
      return !userPin;
    } else if (pinType == 'aggregate') {
      return false;
    } else {
      return false;
    }
  }

  markerClicked(event) {//: AgmMarker
    if (this.infoRef) {
      this.infoRef.close();
    }
    this.infoRef = event.infoWindow.last
  }
}

// class MilestonesMapData extends MapData {

//   constructor() {
//     super();
//     // this.center = { latitude: -74.0060, longitude: 40.7128 };
//     // this.markers = [];
//   }

// }

// class MilestonesMapView implements MapView {

//   constructor(private mapboxService: MapboxService, private directionsType?: string) {
//   }

//   connect(from: MapLocation, to: MapLocation): Promise<string> {
//     if (!this.directionsType) {
//       return Promise.reject();
//     }
//     return this.mapboxService.getDirections(from, to, DirectionsType[this.directionsType], environment.mapBoxKey).pipe(
//       take(1),
//       map(route => {
//         const id = uuid();
//         this.map.addLayer({
//           id: id,
//           type: 'line',
//           source: {
//             type: 'geojson',
//             data: {
//               type: 'Feature',
//               properties: {},
//               geometry: {
//                 type: 'LineString',
//                 coordinates: route.coordinates.map(location => [location.longitude, location.latitude])
//               }
//             }
//           },
//           layout: {
//             'line-join': 'round',
//             'line-cap': 'round'
//           },
//           paint: {
//             'line-color': '#006a86',
//             'line-width': 5,
//             'line-opacity': 0.67
//           }
//         });
//         return id;
//       })
//     )
//     .toPromise();
//   }

//   center(location: MapLocation): Promise<any> {
//     return Promise.resolve(this.map.panTo({
//       lng: location.longitude,
//       lat: location.latitude
//     }, {
//       animate: true
//     }));
//   }

//   zoom(level: MapZoom): Promise<any> {
//     return Promise.resolve(this.map.zoomTo(level.level, {
//       animate: true
//     }));
//   }
// }
