import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {QuestService} from '../../../../_services/quest.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AppState, questDetailState, teamModalOpenState, teamModalProgressState} from '../../../../_store/app.reducers';
import { QuestTeam } from 'src/app/_interface/team.types';
import { CloseTeamModal, CreateTeam, JoinTeam, LoadTeams, StartFundraiser, StartQuest, TeamJoined } from 'src/app/_interface/store/quest-detail.actions';

@Component({
  selector: 'app-multi-team-quest-starter',
  templateUrl: './multi-team-quest-starter.component.html',
  styleUrls: ['./multi-team-quest-starter.component.scss']
})
export class MultiTeamQuestStarterComponent implements OnInit, OnDestroy {

  @Input() questTitle: string;
  @Input() questId: number;
  @Input() doerId: number;
  @Input() viewerId: number;
  @Input() fundraising: boolean;
  @Input() questCreatorDetails: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuestStart: EventEmitter<boolean> = new EventEmitter<boolean>();

  teams: QuestTeam[] = [];
  teamsInitialized = false;
  teamModalChoice: TeamModalChoice = TeamModalChoice.JoinTeam;
  teamModalOpenSubscription: Subscription;
  teamModalProgressSubscription: Subscription;
  questTeamsSubscription: Subscription;
  joinedTeamSubscription: Subscription;
  selectedTeamId: number;
  progress = false;

  constructor(private questService: QuestService,
              private router: Router,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.teamModalOpenSubscription = this.store.pipe(select(teamModalOpenState)).subscribe(state => {
      if (state.open) {
        this.selectedTeamId = state.selectedId;
        this.joinedTeamSubscription = this.store.pipe(select(questDetailState), map(st => st.joinedTeam)).subscribe(team => {
          if (team) {
            this.store.dispatch(new TeamJoined(null));
            this.router.navigate([team.teamPageUrl]);
          }
        });
        if (this.teamsInitialized) {
          return;
        } else {
          this.store.dispatch(new LoadTeams(this.questId, this.doerId));
        }
      } else {
        if (this.joinedTeamSubscription) {
          this.joinedTeamSubscription.unsubscribe();
        }
        this.teamsInitialized = false;
      }
    });
    this.teamModalProgressSubscription = this.store.pipe(select(teamModalProgressState)).subscribe(state => {
      const updatedProgress = this.fundraising
        ? state.questProgress || state.fundProgress
        : state.questProgress;
      if (this.progress && !updatedProgress) {
        this.onQuestStart.emit(true);
        this.store.dispatch(new CloseTeamModal());
        this.progress = false;
      }
    });
    this.questTeamsSubscription = this.store.pipe(
      select(questDetailState),
      distinctUntilChanged((before, after) => before.selectedQuestTeams.length === after.selectedQuestTeams.length),
      map(state => state.selectedQuestTeams)
    ).subscribe(teams => {
      this.teams.length = 0;
      this.teams.push(...teams);
      this.teamModalChoice = TeamModalChoice.CreateTeam;
      this.teamsInitialized = true;
    });
  }

  ngOnDestroy(): void {
    this.teamModalOpenSubscription.unsubscribe();
    this.teamModalProgressSubscription.unsubscribe();
    this.questTeamsSubscription.unsubscribe();
    if (this.joinedTeamSubscription) {
      this.joinedTeamSubscription.unsubscribe();
    }
  }

  selectOption(choice: string) {
    this.teamModalChoice = TeamModalChoice[choice];
  }


  startQuestInSelectedTeam(teamId: number) {
    if (!teamId || this.progress) {
      return;
    }
    this.progress = true;
    this.store.dispatch(new JoinTeam(this.questId, teamId));
  }

  startQuestInTeam(payload: StartTeamQuestPayload) {
    if (this.progress) {
      return;
    }
    switch (this.teamModalChoice) {
      case TeamModalChoice.CreateTeam:
        this.progress = true;
        this.store.dispatch(new CreateTeam(this.questId, payload.teamName, payload.teamLogoUrl));
        if (this.fundraising) {
          this.store.dispatch(new StartFundraiser(
            this.questId,
            this.viewerId,
            null,
            payload.targetAmount,
            'usd',
            payload.teamName + '’s fundraiser for ' + this.questTitle
          ));
        }
        return;
    }
  }

  startQuestSolo(payload: StartSoloQuestPayload) {
    if (this.progress) {
      return;
    }
    if (this.teamModalChoice === TeamModalChoice.StartSolo) {
      this.progress = true;

      const referrerId = this.fundraising ? null : this.doerId;
      const questMode = this.fundraising ? 'paceYourselfMode' : null;

      this.store.dispatch(new StartQuest(this.questId, referrerId, questMode, payload.campaignName));
      if (this.fundraising) {
        this.store.dispatch(new StartFundraiser(
          this.questId,
          this.viewerId,
          null,
          payload.targetAmount,
          'usd',
          payload.campaignName
        ));
      }
    }
  }

}

interface StartFundraiserPayload {
  campaignName?: string;
  targetAmount?: number;
}

export interface StartTeamQuestPayload extends StartFundraiserPayload {
  teamId?: number;
  teamName: string;
  teamLogoUrl: string;
}

export interface StartSoloQuestPayload extends StartFundraiserPayload {
}

enum TeamModalChoice {
  JoinTeam = 'JoinTeam',
  CreateTeam = 'CreateTeam',
  StartSolo = 'StartSolo'
}
