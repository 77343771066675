import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';
import * as Constants from '../../../../app.constants';
import * as config from '../../../../app.config';
import { Store, State } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-dl-forgot-password',
  templateUrl: './dl-forgot-password.component.html',
  styleUrls: ['./dl-forgot-password.component.scss']
})
export class DlForgotPasswordComponent implements OnInit {
  messages = Constants.VALIDATION_MESSAGES;
  authSubscribtion: Subscription;
  authState: Observable<fromAuth.State>;
  recoveryFormSubmitted = false;
  recoveryForm: UntypedFormGroup;
  isLoading = false;

  constructor(
    private store: Store<AppState>
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.recoveryForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email
      ])
    });
  }

  onRecoveryPass() {
    this.recoveryFormSubmitted = true;
    this.isLoading = true;
    const email = this.recoveryForm.value.email;
    if (this.recoveryForm.valid) {
      this.store.dispatch(new AuthActions.TryRecoveryPass({
        email: email
      }));
    }
  }
  changeAuthTab(value: string) {
    this.store.dispatch(new AuthActions.SelectFormMode(fromAuth.FormMode[value]));
  }

}
