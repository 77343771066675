import {Pipe, PipeTransform} from '@angular/core';
import { QuestDoer } from 'src/app/_interface/quest.types';
import { SortMembersUtils } from 'src/app/_interface/utils/sort-members.utils';

@Pipe({
  name: 'sortMembers'
})
export class SortMembersPipe implements PipeTransform {

  transform(value: QuestDoer[], options: { isListSort?: any, mode: string }): QuestDoer[] {
    if (options.mode !== 'doers') {
      return value;
    }

    return SortMembersUtils.sortDoers(value, options.isListSort);
  }

}
