import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { BirthdayControlService, CalendarRangeInfo } from './birthday-control.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Element } from '@angular/compiler';

@Component({
  selector: 'app-birthday-control',
  templateUrl: './birthday-control.component.html',
  styleUrls: ['./birthday-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthdayControlComponent),
      multi: true
    }
  ],
})
export class BirthdayControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() isRequired: boolean;

  touched = false;

  birthDayForm: UntypedFormGroup;
  birthDayFormSubscription: Subscription;

  eventsSubject: Subject<void> = new Subject<void>();
  eventsSubjectSubscription: Subscription;
  touchEventRegistration = {
    keys: {
      day: false,
      month: false,
      year: false
    },
    count: 0
  };

  constructor(
    private fb: UntypedFormBuilder,
    private birthdayControlService: BirthdayControlService
  ) {
    this.birthDayForm = this.fb.group({
      day: [null, [Validators.required]],
      month: [null, [Validators.required]],
      year: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    // this.eventsSubjectSubscription = this.eventsSubject.asObservable().subscribe(() => {
    //   console.log('CHANGED');
    // });
    this.birthDayFormSubscription = this.birthDayForm.valueChanges.subscribe(() => {
      this.valueChanged();
    });
  }
  ngOnDestroy() {
    if (this.birthDayFormSubscription) {
      this.birthDayFormSubscription.unsubscribe();
    }
  }
  get info (): Observable<CalendarRangeInfo> {
    return this.birthdayControlService.info;
  }

  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => {
    this.touched = true;
  }
  regTouch(val: string) {
    this.closedDropdown();
    this.birthDayForm.get(val).markAsTouched();
    this.validateTouchEvent(val);
  }
  // * Touch event for component will be registered only if will be will be touched all controls
  private validateTouchEvent(val: string) {
    this.touchEventRegistration.keys[val] = true;
    this.touchEventRegistration.count = 0;
    const keysArray = Object.keys(this.touchEventRegistration.keys)
    keysArray.forEach((key) => {
      if (this.touchEventRegistration.keys[key]) {
        this.touchEventRegistration.count++;
      }
    });
    if (keysArray.length === this.touchEventRegistration.count) {
      this.onTouched();
    }
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value: any) {

    if (value === null) {
      const payload = {
        day: '',
        month: '',
        year: ''
      };
      this.birthDayForm.setValue(payload);
    }

    if (value) {
      const payload = {
        day: value.slice(2, 4),
        month: value.slice(0, 2),
        year: value.slice(4, value.length)
      };

      this.birthDayForm.setValue(payload);
    }

    setTimeout(() => {
      this.eventsSubject.next();
    });
  }
  valueChanged() {
    if (this.birthDayForm.valid) {
      const date = this.birthDayForm.value.month + this.birthDayForm.value.day + this.birthDayForm.value.year;
      this.onChange(date);
    }
  }
  prepareValue(count: number): string {
    return count + 1 < 10 ? '0' + (count + 1).toString() : (count + 1).toString();
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }
}
