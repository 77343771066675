import * as DonateActions from './donate.actions';
import { DEFAULT_DIEMLIFE_TIP_PERCENT } from 'src/app/app.constants';

export interface Steps {
  amount: boolean;
  confirm: boolean;
  success: boolean;
}

export interface ChangeDisplay {
  firstName: string;
  lastName: string;
  message: string;
}

export interface FormValue {
  step1?: {
    value: any;
    valid: boolean;
    submitted?: boolean;
  };
  step2?: {
    value: any;
    valid: boolean;
    submitted?: boolean;
  };
  step3?: {
    value: any;
    valid: boolean;
    submitted?: boolean;
  };
}

export interface State {
  brandId: number;
  secondaryBrandId: number;
  amount: number;
  tipPercent: number;
  message: string;
  steps: Steps;
  changeDisplay: ChangeDisplay;
  billing: {
    recurrent: boolean,
    anonymous: boolean
  };
  mailing: boolean;
  successInfo: any;
  brutTotal: any;
  offlineFlag: boolean;
  userAdmin: boolean;
  tippingFlag: boolean;
  monthlyFlag: boolean;
  formDetails: FormValue;
  isLoading: boolean;
  creatorAbsorbFees?: boolean;
  stripeFee?: number;
  platformFee?: number;
  payLater?: boolean;
  tipAmount?: number;
}

export const initialState: State = {
  brandId: null,
  secondaryBrandId: null,
  amount: 0,
  tipPercent: DEFAULT_DIEMLIFE_TIP_PERCENT,
  message: '',
  steps: {
    amount: false,
    confirm: false,
    success: false
  },
  changeDisplay: null,
  billing: {
    recurrent: false,
    anonymous: false,
  },
  mailing: false,
  successInfo: {},
  brutTotal: 0,
  offlineFlag: false,
  userAdmin: false,
  tippingFlag: false,
  monthlyFlag: false,
  formDetails: {
    step1: {
      value: null,
      valid: false,
      submitted: false
    },
    step2: {
      value: null,
      valid: false,
      submitted: false
    },
    step3: {
      value: null,
      valid: false,
      submitted: false
    }
  },
  creatorAbsorbFees: false,
  stripeFee: 0,
  platformFee: 0,
  payLater: false,
  tipAmount: 0,
  isLoading: false
};

export function DonateReducer(state = initialState, action: DonateActions.DonateActions) {
  switch (action.type) {
    case (DonateActions.SET_AMOUNT):
      return {
        ...state,
        amount: action.payload
      };
    case (DonateActions.SET_MESSAGE):
      return {
        ...state,
        message: action.payload
      };
    case (DonateActions.SET_TIP_PERCENT):
      return {
        ...state,
        tipPercent: action.payload
      }
    case (DonateActions.SET_RECURRENT_CHECK):
      const billing = {...state.billing};
      billing.recurrent = action.payload;
      return {
        ...state,
        billing: billing
      };
    case (DonateActions.SET_ANON_CHECK):
      const oldBilling = {...state.billing};
      oldBilling.anonymous = action.payload;
      return {
        ...state,
        billing: oldBilling
      };
    case (DonateActions.SET_MAIL_CHECK):
      return {
        ...state,
        mailing: action.payload
      };
    case (DonateActions.SET_NAVIGATION_STEP):
      return {
        ...state,
        steps: action.payload
      };
    case (DonateActions.SET_CHANGE_DISPLAY):
      return {
        ...state,
        changeDisplay: action.payload
      };
    case (DonateActions.DONATE_SET_SUCCESS_INFO):
      return {
        ...state,
        successInfo: action.payload
      };
    case (DonateActions.SET_BRUT_TOTAL):
      return {
        ...state,
        brutTotal: action.payload
      };
    case (DonateActions.SET_BRAND_ID):
      return {
        ...state,
        brandId: action.payload
      };
    case (DonateActions.SET_SECONDARY_BRAND_ID):
      return {
        ...state,
        secondaryBrandId: action.payload
      };
    case (DonateActions.SET_OFFLINE_FLAG):
      return {
        ...state,
        offlineFlag: action.payload
      };
    case (DonateActions.SET_ADMIN_FLAG):
      return {
        ...state,
        userAdmin: action.payload
      };
    case (DonateActions.SET_TIPPING_FLAG):
      return {
        ...state,
        tippingFlag: action.payload
      }
    case (DonateActions.SET_MONTHLY_FLAG):
      return {
        ...state,
        monthlyFlag: action.payload
      }
    case (DonateActions.SET_FORM_VALUES):
      return {
        ...state,
        formDetails: action.payload        
      }
    case (DonateActions.SET_ABSORB_FEES):
      return {
        ...state,
        creatorAbsorbFees: action.payload    
      }
    case (DonateActions.SET_STRIPE_FEE):
      return {
        ...state,
        stripeFee: action.payload    
      }
    case (DonateActions.SET_PLATFORM_FEE):
      return {
        ...state,
        platformFee: action.payload    
      }
    case (DonateActions.SET_PAY_LATER):
      return {
        ...state,
        payLater: action.payload    
      }
    case (DonateActions.SET_TIP_AMOUNT):
      return {
        ...state,
        tipAmount: action.payload    
      }
    case (DonateActions.SET_LOADING_STATUS):
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state;
  }
}
