import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { HTTPStatus } from 'src/app/interceptors/loader.interceptor';
import tools from './../../../_tools';
import { NotificationsService } from './notifications.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyQuestsSevice } from '../../pages/my-quests/my-quests.service';
import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import * as NotificationsActions from './store/notifications.actions';
import * as fromNotifications from './store/notifications.reducer';
import { NotificationType } from 'src/app/_interface/notification.types';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  activityStatusSubscriber: Subscription;
  activityStatus = false;
  userInfo: fromProfile.State = fromProfile.initialState;
  userInfoState: Observable<fromProfile.State>;
  userInfoSubscriber: Subscription;
  notificationsInfoState: Observable<fromNotifications.State>;
  notificationsInfoSubscriber: Subscription;
  notifications: NotificationType[] = [];
  reachedEnd = false;
  isLoaded = false;
  btnIsLoading = false;
  loadStep = 10;
  pagination = {
    from: 0,
    to: this.loadStep
  };
  usersQuests: { [key: number]: ExploreCardListType } = {};

  constructor(
    private httpStatus: HTTPStatus,
    private notificationsService: NotificationsService,
    private myQuestsService: MyQuestsSevice,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
    this.userInfoState = this.store.select('userInfo');
    this.notificationsInfoState = this.store.select('notifications');

    this.userInfoSubscriber = this.userInfoState.subscribe(
      tools.debounce((userInfo: fromProfile.State) => {
        this.userInfo = userInfo;
        this.notificationsService.getNotifications(userInfo.id, 0, this.loadStep).subscribe((res: NotificationType[]) => {
          this.notifications = res;
          this.isLoaded = true;
        });
      }, 0)
    );
    forkJoin([
      this.myQuestsService.getAllActiveQuestsForUser(),
      this.myQuestsService.getQuestsCompletedForUser()
    ]).subscribe(([activeQuests, completedQuests]) => {
      [...activeQuests, ...completedQuests].forEach(quest => {
        if (!this.usersQuests.hasOwnProperty(quest.id)) {
          this.usersQuests[quest.id] = quest;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.userInfoSubscriber) { this.userInfoSubscriber.unsubscribe(); }
    if (this.notificationsInfoSubscriber) { this.notificationsInfoSubscriber.unsubscribe(); }
    if (this.activityStatusSubscriber) { this.activityStatusSubscriber.unsubscribe(); }
  }

  closeNotifications() {
    this.store.dispatch(new NotificationsActions.HideNotifications());
    this.activeModal.close();
  }

  goToThatPage(item: NotificationType) {
    this.notificationsService.readNotification(item).subscribe(() => {
      const destinationURL = (route) => [route, item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
      ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
      : item.userId)]
      switch (item.type) {
        case 'FRIEND_REQUEST':
          this.router.navigate(['/connections/requests']);
          break;

        case 'PROFILE_BACKED':
          this.router.navigate(['/profile']);
          break;

        //Newsfeed
        case 'COMMENT':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
              queryParams: { postId: item.comment && item.comment.id }
            });
          break;

        case 'COMMENT_LIKED':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'COMMENT_MENTION':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'COMMENT_REPLIED':
          this.router.navigate(destinationURL('/quest-detail-comments'), {
            queryParams: { postId: item.comment && item.comment.id }
          });
          break;

        case 'PHOTO_VIDEO_ADDED':
          this.router.navigate(destinationURL('/quest-detail'), {
            queryParams: { gallery: true }
          });
          break;
          
        case 'QUEST_ACHIEVED':
        case 'QUEST_SAVED':
        case 'QUEST_STARTED':
        case 'QUEST_BACKED':
        case 'EVENT_STARTED':
        case 'FUNDRAISER_STARTED':
        case 'MILESTONE_ACHIEVED':
        case 'FOLLOWED':
        default:
          this.router.navigate(['/quest-detail', item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)]);
          break;
      }
      this.closeNotifications();
    });
  }

  goToThatUser(userId, item) {
    this.notificationsService.readNotification(item).subscribe(() => {
      this.router.navigate(['/profile/' + userId]);
      this.closeNotifications();
    });
  }

  loadMore() {
    if (!this.btnIsLoading) {
      this.btnIsLoading = true;
      this.pagination.from += this.loadStep;
      this.pagination.to += this.loadStep;
      this.notificationsService.getNotifications(this.userInfo.id, this.pagination.from, this.pagination.to)
        .subscribe((data: NotificationType[]) => {
          if (data.length) {
            this.notifications = [...this.notifications, ...data];
          } else {
            this.reachedEnd = true;
          }
          this.btnIsLoading = false;
        });
    }
  }

}
