import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as config from '../../../../app.config';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../../_store/app.reducers';
import * as AuthActions from '../store/auth.actions';
import {HTTPStatus} from '../../../../interceptors/loader.interceptor';
import * as Constants from '../../../../app.constants';
import * as fromAuth from './../../../main/auth/store/auth.reducer';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  private routeSubscriber: Subscription;
  private activityStatusSubscriber: Subscription;
  private token: string;

  public passwordResetForm: UntypedFormGroup;
  public passwordResetFormSubmitted = false;
  public activityStatus = null;
  public messages = Constants.VALIDATION_MESSAGES;
  passwordType = 'password';

  stateSubscriber: Subscription;

  constructor(private route: ActivatedRoute,
              private httpStatus: HTTPStatus,
              private store: Store<fromApp.AppState>,
              private router: Router) {
    this.passwordResetForm = new UntypedFormGroup({
      'newPassword': new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength)
      ]),
      'newPasswordConfirmation': new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength),
        this.passwordsMatchChecker()
      ])
    });
  }

  ngOnInit() {
    this.routeSubscriber = this.route.params.subscribe(params => {
      this.token = String(params.token);
    });
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? 'disabled' : null;
    });

    this.stateSubscriber = this.store.select('auth').subscribe(
      (authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.router.navigate(['/my-quests']);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.routeSubscriber) {
      this.routeSubscriber.unsubscribe();
    }
    if (this.activityStatusSubscriber) {
      this.activityStatusSubscriber.unsubscribe();
    }
    if(this.stateSubscriber) this.stateSubscriber.unsubscribe();
  }

  resetPassword() {
    this.passwordResetFormSubmitted = true;
    if (this.passwordResetForm.valid) {
      this.store.dispatch(new AuthActions.RecoveryPass({
        password: this.passwordResetForm.get('newPassword').value,
        repeatPassword: this.passwordResetForm.get('newPasswordConfirmation').value,
        token: this.token
      }));
    }
  }

  private passwordsMatchChecker(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = this.passwordResetForm
        && control.value
        && this.passwordResetForm.get('newPassword').value
        && this.passwordResetForm.get('newPassword').value !== control.value;
      return invalid ? {'passwordsMatch': true} : null;
    };
  }
  
  showHidePassword() {
    this.passwordType = (this.passwordType === 'password') ? 'text' : 'password';
  }
}
