import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PatchMilestone, PatchNewMilestone } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroupsState } from 'src/app/_interface/dl-milestones.reducer';
import { QuestTask } from 'src/app/_interface/quest.types';
import { AppState } from 'src/app/_store/app.reducers';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { environment } from '../../../../../environments/environment';
import { QuestService } from '../../../../_services/quest.service';
import { QuestPreparedPhoto } from '../../quest/quest.type';
import { DlMilestonesService } from '../service/dl-milestones.service';


@Component({
  selector: 'app-dl-milestone-dialog',
  templateUrl: './dl-milestone-dialog.component.html',
  styleUrls: ['./dl-milestone-dialog.component.scss']
})
export class DlMilestoneDialogComponent implements OnInit, OnDestroy {
  dlMilestonesState: Observable<TaskGroupsState>;
  dlMilestonesSubscription: Subscription;
  milestoneInfo: QuestTask;
  selectedIndex: string | number;
  isLoading: string;
  mode: 'NEW' | 'RELEASED';
  taskIndex: number;
  groupIndex: number;
  isEditMode: boolean;

  environment = environment;

  @Input('questId') questId: number;

  constructor(
    private store: Store<AppState>,
    private dlMilestonesService: DlMilestonesService,
    private questService: QuestService,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private notifier: NotifierService
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    this.dlMilestonesSubscription = this.dlMilestonesState.subscribe((state: TaskGroupsState) => {

      if (!state.selectedMilestoneForPreview) { return false; }

      this.taskIndex = state.selectedMilestoneForPreview.index || 0;
      this.groupIndex = state.selectedMilestoneForPreview.groupIndex || 0;
      this.isEditMode = state.selectedMilestoneForPreview.isEditMode;

      // * detect witch mode should be used for this dialog
      if (state.milestones.length && state.milestones[this.groupIndex]) {
        // tslint:disable-next-line:max-line-length
        this.mode = (state.milestones[this.groupIndex].questTasks.length - 1) < this.taskIndex ? 'NEW' : 'RELEASED';
      } else {
        this.mode = 'NEW';
      }

      if (this.mode === 'RELEASED') {
        this.milestoneInfo = state.milestones[this.groupIndex].questTasks[this.taskIndex];
      } else {
        this.milestoneInfo = state.newMilestoneForPreview;
      }

      this.selectedIndex = this.taskIndex < 10 ? '0' + (this.taskIndex + 1) : (this.taskIndex + 1);
    });
  }
  ngOnDestroy() {
    if (this.dlMilestonesSubscription) {
      this.dlMilestonesSubscription.unsubscribe();
    }
  }
  removePreview() {
    if (this.mode === 'RELEASED' && this.milestoneInfo.id) {
      // * milestone and quest is already created
      this.isLoading = 'REMOVE';
      this.dlMilestonesService.removePreview(this.milestoneInfo.id).subscribe(() => {
        this.removePreviewAction();
      }, () => {
        this.notifier.notify('error', 'Oops, something went wrong, please try again');
        this.isLoading = null;
      });
    } else if (this.mode === 'RELEASED' && !this.milestoneInfo.id) {
      // * new quest with already created milestone
      this.removePreviewAction();
    } else {
      // * new quest with not created milestone
      this.store.dispatch(new PatchNewMilestone({
        imageUrl: null,
        linkUrl: null,
        linkPreview: null,
        linkedQuestId: null,
        linkedQuest: null,
        video: null,
        embeddedVideo: null
      } as QuestTask, this.groupIndex));
      this.activeModal.close();
    }
    this.taskIndex = null;
    this.groupIndex = null;
    this.isEditMode = null;
  }
  private removePreviewAction() {
    this.notifier.notify('success', 'Image removed');
    this.dlMilestonesState.pipe(take(1)).subscribe((state: TaskGroupsState) => {
      this.store.dispatch(new PatchMilestone({
        taskIndex: state.selectedMilestoneForPreview.index,
        groupIndex: state.selectedMilestoneForPreview.groupIndex,
        value: {
          ...this.milestoneInfo,
          imageUrl: null,
          linkUrl: null,
          linkPreview: null,
          linkedQuestId: null,
          linkedQuest: null,
          embeddedVideo: null,
          video: null
        }
      }));
    });
    this.activeModal.close();
    this.isLoading = null;
  }
  prepareQuestVideoId(questVideoUrl: string): SafeResourceUrl | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${res}`) : null;
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  closeModal() {
    this.activeModal.close();
  }

  getOpenLinkText() {
    if (this.milestoneInfo && this.milestoneInfo.linkUrl 
      && this.milestoneInfo.linkUrl.toLowerCase().indexOf('/donate/') > -1
      && ((!environment.production && this.questId === 2241) || (environment.production && this.questId === 1186))) {
      return 'Support Driver';
    }

    return 'Open link';
  }
}
