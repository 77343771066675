import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { SnotifyService } from 'ng-snotify';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MILESTONE_TASK_MAX_LENGTH } from 'src/app/app.constants';
import { QuestService } from 'src/app/_services/quest.service';
import { SnotifyConfigService } from 'src/app/_services/snotify-config.service';
import { AppState } from 'src/app/_store/app.reducers';
import { QuestPreparedPhoto } from '../../quest/quest.type';
import { DlMilestoneDialogComponent } from '../dl-milestone-dialog/dl-milestone-dialog.component';
import * as fromAuth from '../../../main/auth/store/auth.reducer';
import { QuestTask } from 'src/app/_interface/quest.types';
import { DlMilestoneManageModes } from 'src/app/_interface/dl-milestones.typings';
import { RemoveMilestone, SetMilestoneForPreview, UpdateMilestoneTask, UpdateMilestoneTaskCompletion } from 'src/app/_interface/dl-milestones.actions';
import { MilestoneTaskComponent } from '../milestones-list/milestone-task/milestone-task.component';
import { DlMilestonesService } from '../service/dl-milestones.service';

@Component({
  selector: 'app-dl-milestone',
  templateUrl: './dl-milestone.component.html',
  styleUrls: ['./dl-milestone.component.scss']
})
export class DlMilestoneComponent implements OnInit {
  readonly MAX_LENGTH = MILESTONE_TASK_MAX_LENGTH;
  @Input() milestone: QuestTask;
  @Input() allowCheck: boolean;
  @Input() index: number;
  @Input() groupIndex: number;
  @Input() isEditMode: boolean;
  @Input() questId: number;
  @Input() mode: DlMilestoneManageModes;
  @Input() canBeEdit: boolean;
  @Input() showControls: boolean;
  isInvalid = false;
  isLoading: string;
  subject: Subject<string>;
  subjectSubscription: Subscription;
  @Input('isCurrentUserQuestDoer') isCurrentUserQuestDoer: boolean;
  authState: Observable<fromAuth.State>;
  isUserAuthenticated: boolean = false;

  constructor(
    private questService: QuestService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private notifier: NotifierService,
    private milestoneService: DlMilestonesService
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.authState.subscribe(
      (authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.isUserAuthenticated = true;
        } else {
          this.isUserAuthenticated = false;
        }
      });

    // console.log('67', this.milestone, this.milestoneService.createTaskForGroupForm().controls);
  }

  onCheckChange() {
    if (this.isCurrentUserQuestDoer && (!this.allowCheck || this.milestone.isLoading)) {
      this.notifier.notify('warning', 'Please open from My Quests.');
      return;
    }
 
    if (!this.allowCheck || this.isEditMode || this.milestone.isLoading) {
      return false;
    }

    this.milestone.isLoading = true;
    if (this.milestone.linkedQuest && this.milestone.linkedQuestId) {
      this.confirmCheckChangeMilestone(
        this.milestone.isTaskCompleted
          ? `This will restart ${this.milestone.linkedQuest.title}, do you want to proceed?`
          : `This will complete ${this.milestone.linkedQuest.title}, do you want to proceed?`
      );
    } else {
      this.doCheckChangeMilestone();
    }
  }

  doCheckChangeMilestone() {
    this.questService.updateQuestTask(this.milestone.id).subscribe((success) => {
      if (success) {
        this.store.dispatch(new UpdateMilestoneTaskCompletion({ taskIndex: this.index, groupIndex: this.groupIndex, value: !this.milestone.isTaskCompleted }));
      } else {
        this.notifier.notify('warning', 'Issue completing request');
      }
      this.milestone.isLoading = false;
    }, () => {
      this.milestone.isLoading = false;
      this.notifier.notify('error', 'Issue completing request');
    });
  }

  confirmCheckChangeMilestone(message: string) {
    this.snotifyService.confirm(
      message,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        closeOnClick: false,
        timeout: 0,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.doCheckChangeMilestone();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: false,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
              this.milestone.isLoading = false;
            }
          }
        ]
      })
    );
  }

  saveNewTask(event: any) {
    if (this.subject) {
      this.subject.next(event.target.value);
    }
  }

  // onFocusIn() {
  //   this.subject = new Subject<string>();
  //   this.subjectSubscription = this.subject.pipe(
  //     switchMap((value: string) => {
  //       this.isLoading = 'UPDATE_MILESTONE';
  //       return of(value);
  //     }),
  //     debounceTime(1000)
  //   ).subscribe((value: string) => {
  //     if (value.length > 0) {
  //       this.isInvalid = false;
  //       if (this.mode === DlMilestoneManageModes.NEW_QUEST) {
  //         // this.store.dispatch(new UpdateMilestoneTask({taskIndex: this.index, groupIndex:this.groupIndex, task: value}));
  //         this.isLoading = null;
  //       } else {
  //         const payload = {
  //           id: this.milestone.id,
  //           task: value,
  //           video: this.milestone.video
  //         };
  //         this.questService.editMilestone(payload).subscribe(() => {
  //           // this.store.dispatch(new UpdateMilestoneTask({taskIndex: this.index, groupIndex:this.groupIndex, task: value}));
  //           this.isLoading = null;
  //         }, () => {
  //           this.isLoading = null;
  //           this.notifier.notify('error', 'Issue completing request');
  //         });
  //       }
  //     } else {
  //       this.isLoading = null;
  //       this.isInvalid = true;
  //     }
  //   });
  // }

  onFocusOut() {
    this.subject.complete();
    this.subjectSubscription.unsubscribe();
    setTimeout(() => {
      this.subject = null;
    });
  }

  openRemoveModal(removeTemplate) {
    this.modalService.open(removeTemplate, {
      windowClass: 'confirm-modal'
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  removeTask() {
    if (!this.isEditMode) { return false; }

    if (!this.questId) {
      this.store.dispatch(new RemoveMilestone(this.index, this.groupIndex));
      this.closeModal();
    } else {
      const payload = {
        id: this.milestone.id
      };
      this.questService.removeMilestone(payload).subscribe(() => {
        this.store.dispatch(new RemoveMilestone(this.index, this.groupIndex));
        this.closeModal();
      });
    }
  }

  openDialog() {
    this.store.dispatch(new SetMilestoneForPreview({index: this.index, groupIndex: this.groupIndex, isEditMode: this.isEditMode}));
    let modalRef = this.modalService.open(DlMilestoneDialogComponent, {
      scrollable: false,
      windowClass: 'milestone-modal'
    });
    modalRef.componentInstance.questId = this.questId;
  }
  get maxLengthCount(): number {
    return MILESTONE_TASK_MAX_LENGTH - this.milestone.task.length;
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

  editMilestoneTask() {
    let modalRef = this.modalService.open(MilestoneTaskComponent, {
      windowClass: 'no-overflow'
    });

    let taskForm = this.milestoneService.createTaskForGroupForm();
    const _task: any = this.milestone;
    // console.log('233', _task);
    taskForm.patchValue({
      task: _task.task,
      title: _task.title || '',
      activities: _task.activities,
      activitiesIds: _task.activitiesIds,
      addedLink: _task.linkUrl ? _task.linkUrl : (_task.embeddedVideo && _task.embeddedVideo.videoUrl ? _task.embeddedVideo.videoUrl : null),
      imageUrl: _task.imageUrl ? _task.imageUrl : null,
      embeddedVideo: _task.embeddedVideo ? _task.embeddedVideo : null,
      linkUrl: _task.linkUrl ? _task.linkUrl : null,
      linkPreview: _task.linkPreview ? _task.linkPreview : null,
      linkedQuest: null,
      linkedQuestId: null,
      edit: _task.edit ? true : false,
      existingId: _task.id || _task.existingId || null,
      video: _task.video || null,
      required: _task.required
    });
    modalRef.componentInstance.taskForm = taskForm;

    modalRef.componentInstance.isInModal = true;
    modalRef.componentInstance.taskUpdate.subscribe((res) => {
      // console.log('255', res);
      // get task and add to group and run api
      const payload = {
        ...res.milestoneForm,
        id: this.milestone.id,
        // video: this.milestone.video
      };
      this.questService.editMilestone(payload).subscribe(() => {
        let updatedMilestone = {...res.milestoneForm};
        updatedMilestone.id = this.milestone.id;
        this.store.dispatch(new UpdateMilestoneTask({
          taskIndex: this.index, 
          groupIndex:this.groupIndex, 
          task: updatedMilestone
        }));
        
        this.isLoading = null;
        this.modalService.dismissAll();
      }, () => {
        this.isLoading = null;
        this.notifier.notify('error', 'Issue completing request');
      });
    });
  }

}
