import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SnotifyService } from 'ng-snotify';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { QuestService } from '../../../../_services/quest.service';
import { SnotifyConfigService } from '../../../../_services/snotify-config.service';
// import {QuestUserInfo} from '../quest.type';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { saveAs } from 'file-saver';
import { MyQuestsSevice } from 'src/app/components/pages/my-quests/my-quests.service';
import { ProfileService } from 'src/app/components/pages/profile/profile.service';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromAuth from '../../../main/auth/store/auth.reducer';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { ShareEmailComponent } from '../../share-widjet/share-email/share-email.component';
import { ShareWidjetService } from '../../share-widjet/share-widjet.service';
import { environment } from 'src/environments/environment';
import { QuestTask, QuestUserInfo } from 'src/app/_interface/quest.types';
import { StartQuest } from 'src/app/_interface/store/quest-detail.actions';
import { MobileSideNavService } from '../../../../modules/shared-module/components/mobile-side-nav/mobile-side-nav.service';

@Component({
  selector: 'app-quest-header-tools',
  templateUrl: './quest-header-tools.component.html',
  styleUrls: ['./quest-header-tools.component.scss']
})
export class QuestHeaderToolsComponent implements OnInit, OnChanges {
  @ViewChild('optionsDropdown', {static: false}) optionsDropdown: NgbDropdown;
  @ViewChild('shareDropdown', {static: false}) shareDropdown: NgbDropdown;
  @Input() questInfo: QuestDropdownContext;
  @Input() doerInfo: QuestUserInfo;
  @Input() showStartTrigger?: boolean;
  @Input() showSupportTrigger?: boolean;
  @Input() exploreQuestIndex?: number;
  @Input() showCompleteQuest?: boolean;
  authState: Observable<fromAuth.State>;
  profileState: Observable<fromProfile.State>;
  isOpen = false;
  status: string;
  privacyLevel: string;
  url: string;
  @Input() showShareOption?: boolean;
  @Input() showQuestOption?: boolean;
  @Input() isModePaceYourself?: boolean;
  isViewerAdminOrCreator = false;
  loggedInUserId: number = null;
  userInfo: fromProfile.State;
  hasEvent = false;
  isQuestMq = false;
  registrationDownloadProgress = false;
  donorsDownloadProgress = false;

  isCurrentUserAllowedForDownload: boolean = false;

  activityDownloadProgress: boolean = false;

  modalReference: any = null;

  @Input('openSideNavMobile') openSideNavMobile: boolean;

  @ViewChild('sideMenuQuestOptions', {read: ElementRef, static: false}) sideMenuQuestOptions: ElementRef;

  @Output() sideNavOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('editableQuest') editableQuest: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private store: Store<fromApp.AppState>,
    private questService: QuestService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private myQuestsSevice: MyQuestsSevice,
    private shareWidjetService: ShareWidjetService,
    private notifier: NotifierService,
    private profileService: ProfileService,
    private mobileSideNavService: MobileSideNavService
  ) {
    this.authState = this.store.select('auth');
    this.profileState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.userInfo = state;
      this.loggedInUserId = this.userInfo.id;

      this.checkUserPermission();
    });

    if (Boolean(this.questInfo.privacyLevel)) {
      this.privacyLevel = this.questInfo.privacyLevel;
    }
    if (Boolean(this.route.snapshot.data[0])) {
      this.status = this.route.snapshot.data[0].status;
    } else {
      this.status = 'public';
    }
    this.shareWidjetService.getShareUrl(this.questInfo.id).subscribe((res: string) => {
      this.url = res;
    });

    this.questService.getQuestsAdmins(this.questInfo.id).subscribe((questAdmins: any[]) => {
      const questEmailAdmins = questAdmins.map(admin => admin.email)
      this.isViewerAdminOrCreator = questEmailAdmins.indexOf(this.userInfo.email) >= 0 || this.questInfo.createdBy === this.userInfo.id;
      this.checkUserPermission();
    });
    this.hasEvent = this.questInfo.hasEvent;
    this.isQuestMq = this.questInfo.isMegaQuest;
  }

  ngOnChanges(): void {
    if(this.openSideNavMobile) {
      this.mobileSideNavService.openSideNav(this.sideMenuQuestOptions, 'options');
      this.sideNavOpened.emit(true);
      this.openSideNavMobile = false;
    }

    if (this.showShareOption) {
      this.shareDropdown.open()
    }

    if (this.showQuestOption) {
      this.optionsDropdown.open();
    }
  }

  checkUserPermission() {
    this.isCurrentUserAllowedForDownload = (environment.production && this.loggedInUserId === 73) || (!environment.production && this.loggedInUserId === 1386);
  }

  openModal(id: string) {
    setTimeout(() => {
      if (id === 'share-email-modal') {
        this.modalReference = this.modalService.open(ShareEmailComponent);
        this.modalReference.componentInstance.questId = this.questInfo.id;
      }
      this.closeDropdown('shareDropdown');
      // this.closeDropdown('optionsDropdown');
    });
  }
  closeDropdown(dropdownName: string) {
    this.showShareOption = false;
    this.showQuestOption = false;
    if (this.shareDropdown) this.shareDropdown.close()
    if (this.optionsDropdown) this.optionsDropdown.close();
  }

  shareViaFacebook() {
    this.shareWidjetService.shareViaFacebook(this.url);
    this.closeDropdown('shareDropdown');
  }

  copyQuest() {
    this.authState.pipe(take(1)).subscribe((state: fromAuth.State) => {
      if (!state.authenticated) {
        this.notifier.notify('error', 'Please log in');
        return false;
      }
      this.questService.copyQuest(this.questInfo.id).subscribe((res: any) => {
        this.notifier.notify('success', 'Successfully copied Quest');
          this.router.navigate(['/edit', res, 'detail']);
      }, (err: any) => {
        this.notifier.notify('error', 'Oops, something went wrong');
      });
      this.closeDropdown('optionsDropdown');
    });
  }
  doQuest() {
    this.store.dispatch(new StartQuest(this.questInfo.id, this.doerInfo.id, this.questInfo.mode));
    // this.questService.callUpdateForQuest({status: 'doQuest', index: this.exploreQuestIndex});
    this.closeDropdown('optionsDropdown');
  }
  supportQuest() {
    this.questService.callUpdateForQuest({status: 'supportQuest', index: this.exploreQuestIndex});
    this.closeDropdown('optionsDropdown');
  }
  saveQuest() {
    this.authState.pipe(take(1)).subscribe((state: fromAuth.State) => {
      if (!state.authenticated) {
        this.notifier.notify('error', 'Please log in');
        return false;
      }
      this.questService.saveQuest(this.questInfo.id).subscribe((res: any) => {
        if (res.error) {
          this.notifier.notify('error', res.error.message);
        }
        if (res.success) {
          this.questInfo.saved = true;
          this.profileState.pipe(take(1)).subscribe((fromProfileState: fromProfile.State) => {

            this.questService.callUpdateForQuest('saved');

            this.notifier.notify('success', `Successfully saved Quest`);
          });
        }
      });
      this.closeDropdown('optionsDropdown');
    });
  }

  unsaveQuest() {
    this.snotifyService.confirm(
      `Remove saved ${this.questInfo.title} quest?`,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.myQuestsSevice.unsaveQuest(this.questInfo.id).subscribe(() => {
                this.questInfo.saved = false;
                this.notifier.notify('info', `Successfully removed saved Quest`);
                this.snotifyService.remove(toast.id);
              });
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
    this.closeDropdown('optionsDropdown');
  }

  followQuest() {
    this.questService.followQuest(this.questInfo.id, true).subscribe(() => {
      this.questInfo.followed = true;
      this.notifier.notify('success', `You are now following this Quest`);
    }, () => {
      this.notifier.notify('warning', 'Issue completing request');
    });
    this.closeDropdown('optionsDropdown');
  }

  unFollowQuest() {
    this.questService.followQuest(this.questInfo.id, false).subscribe(() => {
      this.questInfo.followed = false;
      this.notifier.notify('info', `You are no longer following this Quest`);
    }, () => {
      this.notifier.notify('warning', 'Issue completing request');
    });
    this.closeDropdown('optionsDropdown');
  }

  cancelQuest() {
    this.snotifyService.confirm(
      `Are you sure you want to remove '${this.questInfo.title}'?`,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.questService.cancelQuest(this.questInfo.id).subscribe(res => {
                this.notifier.notify('success', `Quest removed`);
                this.router.navigate(['/my-quests']);
              });
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
    this.closeDropdown('optionsDropdown');
  }

  copied($event) {
    this.notifier.notify('success', `URL copied`);
    this.closeDropdown('');
  }

  downloadTransactions() {
    this.registrationDownloadProgress = true;
    this.profileService.downloadQuestTransactions(this.questInfo.id).subscribe(data => {
      this.registrationDownloadProgress = false;
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, 'event-participants.xlsx');
    });
  }

  downloadDonors(){
    this.donorsDownloadProgress = true;
    this.profileService.downloadTransactionsCSV(this.questInfo.id).subscribe(data => {
      this.donorsDownloadProgress = false;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'DIEMlife_transactions.csv');
    });
  }

  downloadActivities() {
    this.activityDownloadProgress = true;
    this.profileService.downloadActivityCSV(this.questInfo.id).subscribe(data => {
      this.activityDownloadProgress = false;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'DIEMlife_activities.csv');
    });
  }

  completeQuest() {
    let uncompletedTasks = false;
    this.questInfo.questTasks.forEach((task: QuestTask) => {
      if (!task.isTaskCompleted) {
        uncompletedTasks = true;
      }
    });
    if (uncompletedTasks && this.isModePaceYourself) {
      this.snotifyService.confirm(
        `You have unchecked items, do you still want to complete "${this.questInfo.title}" ?`,
        null,
        this.snotifyConfigService.getConfig('confirm', {
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.confirmMilestonesAutoComplete();
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'No',
              bold: true,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    } else {
      this.snotifyService.confirm(
        `You did it! Want to complete "${this.questInfo.title}" ?`,
        null,
        this.snotifyConfigService.getConfig('confirm', {
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.doCompleteQuest(false);
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'No',
              bold: true,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    }
  }

  confirmMilestonesAutoComplete() {
    this.snotifyService.confirm(
      `Do you want to complete all items for "${this.questInfo.title}"?`,
      null,
      this.snotifyConfigService.getConfig('confirm', {
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.doCompleteQuest(true);
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.doCompleteQuest(false);
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  doCompleteQuest(completeMilestones: boolean): void {
    // this.isProgress = true;
    this.questService.completeQuest(this.questInfo.id, 
      completeMilestones, 
      this.questInfo.geoTriggerEnabled).subscribe(() => {
      // this.isProgress = false;
      this.notifier.notify('success', `Successfully completed Quest`);
      this.router.navigate(['/my-quests', 'completed']);
    });
  }
}

export interface QuestDropdownContext {
  id: number;
  title: string;
  shareUrl: string;
  copyable: boolean;
  followed: boolean;
  saved: boolean;
  active: boolean;
  editable?: boolean;
  starred?: boolean;
  privacyLevel?: string;
  mode?: string;
  createdBy?: number;
  hasEvent?: boolean;
  isQuestFundraiser?: boolean;
  isMegaQuest?: boolean;
  questTasks?: any;
  geoTriggerEnabled?: boolean;
}
