import {Injectable} from '@angular/core';
import {ExploreCardType, ExplorePageType} from './explore-page.types';
import {Quest, QuestActivity, QuestUserInfo} from './quest.types';

@Injectable({
  providedIn: 'root'
})
export class DiemlifeCommonsModelService {

  constructor() {
  }

  public static processQuestExploreData(data: ExplorePageType): ExploreCardType[] {
    const result: ExploreCardType[] = [];
    if (data.quests && data.quests.length) {
      data.quests.map((quest: ExploreCardType) => {
        const questCard = {} as ExploreCardType;
        Object.assign(questCard, quest);

        if (data.completionPercentages && data.completionPercentages.length) {
          questCard.completionPercentage = data.completionPercentages
          .filter(percentage => percentage && percentage.questId === quest.id)
          .map(percentage => Number(percentage.completionPercentage.replace('%', '').trim()))
          .reduce((base, delta) => delta, 0);
        }

        if (data.products && data.products.length) {
          questCard.hasEvent = data.products
          .filter(product => product && product.questId === quest.id)
          .reduce((base, delta) => !!delta, false);
        }

        if (data.followingCounts && data.followingCounts.length) {
          questCard.followingCount = data.followingCounts
          .filter(counter => counter && counter.questId === quest.id)
          .map(counter => counter.count)
          .reduce((base, delta) => base + delta, 0);
        }

        result.push(questCard);
      });
    }
    return result;
  }

  public static buildQuestActivityTracker(activity: QuestActivity, user: QuestUserInfo): QuestActivityTracker {
    return new QuestActivityTracker(activity, user);
  }

  public static isUserQuestAdmin(questPageData: Quest, user: QuestUserInfo): boolean {
    return user
      && questPageData
      && questPageData.quest.admins.filter((email) => user.email === email).length > 0
      && questPageData.user.id === questPageData.quest.user.id;
  }
}

export class QuestActivityTracker {

  constructor(private activity: QuestActivity,
              private user: QuestUserInfo) {
  }

  public hasUserActivity() {
    return !!this.activity && !!this.user && !!this.user.id && this.activity.userId === this.user.id;
  }

  private getQuestActivityStatus(): string {
    return this.activity ? this.activity.status : null;
  }

  private getQuestActivityMode(): string {
    return this.activity ? this.activity.mode : null;
  }

  isStatusInProgress(): boolean {
    return this.getQuestActivityStatus() === 'IN_PROGRESS';
  }

  isStatusComplete(): boolean {
    return this.getQuestActivityStatus() === 'COMPLETE';
  }

  isModeSupportOnly(): boolean {
    return this.getQuestActivityMode() === 'viewOnlyMode';
  }

  isModePaceYourself(): boolean {
    return this.getQuestActivityMode() === 'paceYourselfMode';
  }

  isModeTeam(): boolean {
    return this.getQuestActivityMode() === 'diyMode';
  }

}
