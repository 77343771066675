import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth-guard.service';
import { PasswordResetComponent } from './components/main/auth/password-reset/password-reset.component';
import { AccountComponent } from './components/pages/account/account.component';
import { QuestDetailComponent } from './components/pages/quest-detail/quest-detail.component';
import { QuestManageComponent } from './components/pages/quest-manage/quest-manage.component';
import { QuestDetailCommentsComponent } from './components/pages/quest-detail-comments/quest-detail-comments.component';
import { DeleteUserComponent } from './components/main/auth/delete-user/delete-user.component';
import { PasswordResetFailComponent } from './components/main/auth/password-reset-fail/password-reset-fail.component';

const appRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./components/pages/new-landing/new-landing.module').then(m => m.NewLandingModule)
  },

  {
    path: 'esg',
    loadChildren: () => import('./components/pages/esg-landing/esg-landing.module').then(m => m.EsgLandingModule)
  },
  
  {
    path: 'dei',
    loadChildren: () => import('./components/pages/dei-landing/dei-landing.module').then(m => m.DeiLandingModule)
  },

  { 
    path: 'explore', 
    loadChildren: () => import('./modules/explore/explore.module').then(m => m.ExploreModule)
  },
  
  { 
    path: 'account', 
    loadChildren: () => import('./components/pages/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard] 
  },

  {
    path: 'profile',
    loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },

  { 
    path: 'profile/:userId', 
    loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule),
    pathMatch: 'prefix'
  },
  
  {
    path: 'my-quests',
    loadChildren: () => import('./modules/my-quests/my-quests.module').then(m => m.MyQuestsModule),
    canActivate: [AuthGuard]
  },

  {path: 'create', component: QuestManageComponent, canActivate: [AuthGuard]},

  { path: 'edit/:questId/:editType', component: QuestManageComponent, canActivate: [AuthGuard] },

  // terms
  {
    path: 'legal',
    loadChildren: () => import('./components/pages/legal/legal.module').then(m => m.LegalModule)
  },

  // payments
  {
    path: 'donate/:questId/:userId',
    loadChildren: () => import('./components/pages/donate/donate.module').then(m => m.DonateModule)
  },
  {
    path: 'get-tickets',
    loadChildren: () => import('./modules/ticket-flow/ticket-flow.module').then(m => m.TicketFlowModule)
  },
  // landing page
  { path: 'passwordReset/:token', component: PasswordResetComponent },

  { path: 'passwordResetFail', component: PasswordResetFailComponent },

  { path: 'account-delete', component: DeleteUserComponent },

  { path: 'privacy', redirectTo: '/legal/privacy'},

  // quest details must go last
  {
    path: ':userSlug/:questSlug/:questId/:userId',
    component: QuestDetailComponent
  },
  { path: 'quest-detail-comments/:questId/:userId', component: QuestDetailCommentsComponent },
  { path: 'quest-detail/:questId/:userId', component: QuestDetailComponent },

  {
    path: 'use-cases',
    loadChildren: () => import('./components/pages/use-cases/use-cases.module').then(m => m.UseCasesModule)
  },

  {
    path: 'platform',
    loadChildren: () => import('./components/pages/platform/platform.module').then(m => m.PlatformModule)
  },

  {
    path: 'our-people',
    loadChildren: () => import('./components/pages/our-people/our-people.module').then(m => m.OurPeopleModule)
  },

  {
    path: 'faqs',
    loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule)
  },

  {
    path: 'contact',
    pathMatch: 'full',
    redirectTo: 'home/contact'
  },

  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: 'home/login'
  },

  {
    path: 'signup',
    pathMatch: 'full',
    redirectTo: 'home/signup'
  },

  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
