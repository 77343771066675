import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileSideNavService } from './mobile-side-nav.service';

@Component({
  selector: 'app-mobile-side-nav',
  templateUrl: './mobile-side-nav.component.html',
  styleUrls: ['./mobile-side-nav.component.scss']
})
export class MobileSideNavComponent implements OnInit {
  @Input('title') title: string;
  @Input('showNavFooter') showNavFooter: boolean;
  @Output() clickSubmit: EventEmitter<any> = new EventEmitter();
  @Output() clickReset: EventEmitter<any> = new EventEmitter();

  constructor(private sideNavService: MobileSideNavService) { }

  ngOnInit() {
  }

  closeOptionsWindow() {
    this.sideNavService.closeSideNav();
  }

  applyChanges() {
    this.clickSubmit.emit(true);
  }

  reset() {
    this.clickReset.emit(true);
  }

}
