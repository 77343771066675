import {Component, OnInit, Input, EventEmitter, Output, OnChanges} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { LeaderboardMemberStatus, LeaderboardScore, MemberStatusData, MemberStatusItem } from 'src/app/_interface/leaderboard.types';
import {QuestService} from 'src/app/_services/quest.service';

@Component({
  selector: 'app-leaderboard-score-editor',
  templateUrl: './leaderboard-score-editor.component.html',
  styleUrls: ['./leaderboard-score-editor.component.scss']
})
export class LeaderboardScoreEditorComponent implements OnInit, OnChanges {
  @Output() changeVisibilityForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateMember: EventEmitter<LeaderboardScore> = new EventEmitter<LeaderboardScore>();
  @Input() member: LeaderboardScore;
  @Input() selectedAttributeId: string;
  @Input() questId: number;
  visibleForm = false;
  visibleDropdown = false;
  scoreForm: UntypedFormGroup;
  progress = false;
  statusData: MemberStatusData = {
    list: [
      {
        value: LeaderboardMemberStatus.Started,
        viewText: 'Started'
      },
      {
        value: LeaderboardMemberStatus.Finished,
        viewText: 'Finished'
      },
      {
        value: LeaderboardMemberStatus.DidNotFinish,
        viewText: 'Did Not Finish'
      },
      {
        value: LeaderboardMemberStatus.DidNotStart,
        viewText: 'Did Not Start'
      },
      {
        value: LeaderboardMemberStatus.NoInfo,
        viewText: 'No Info'
      }
    ],
    currentStatus: null
  };

  constructor(private questService: QuestService) {}

  ngOnInit() {
    this.scoreForm = new UntypedFormGroup({
      'score': new UntypedFormControl(this.member.score, [
        Validators.required
      ])
    });
    this.selectStatus(this.member.status);
  }

  ngOnChanges() {
    this.resetForm();
  }

  showForm() {
    this.visibleForm = true;
    this.changeVisibilityForm.emit(this.visibleForm);
  }

  hideForm() {
    this.visibleForm = false;
    this.changeVisibilityForm.emit(this.visibleForm);
    this.resetForm();
  }

  showDropdown() {
    this.visibleDropdown = true;
  }

  toggleDropdown() {
    this.visibleDropdown = !this.visibleDropdown;
  }

  selectStatus(status: LeaderboardMemberStatus) {
    this.statusData.currentStatus = this.statusData.list.find((item: MemberStatusItem) => item.value === status);
    this.hideDropdown();
  }

  hideDropdown() {
    this.visibleDropdown = false;
  }

  onSubmit() {
    this.progress = true;
    const updatedMember = {...this.member};
    updatedMember.score = parseInt(this.scoreForm.get('score').value, 10);
    updatedMember.status = this.statusData.currentStatus.value;
    this.questService.patchLeaderboardScore(
      this.questId,
      this.selectedAttributeId,
      {
        memberId: updatedMember.memberId,
        value: updatedMember.score,
        status: updatedMember.status
      }
    ).subscribe(() => {
      this.updateMember.emit(updatedMember);
      this.hideForm();
      this.progress = false;
    }, error => {
      console.log(error);
      this.progress = false;
    });
  }

  resetForm() {
    if (this.scoreForm && this.member) {
      this.selectStatus(this.member.status);
      this.scoreForm.patchValue({
        score: this.member.score
      });
    }
  }

}
