import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BrandConfig } from 'src/app/_interface/brand.types';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';
import { BrandsLoaded, CreateTeam, CREATE_TEAM, FundraiserStarted, JoinTeam, JOIN_TEAM, LoadBrands, LoadTeams, LOAD_BRANDS, LOAD_TEAMS, QuestStarted, StartFundraiser, StartQuest, START_FUNDRAISER, START_QUEST, TeamCreated, TeamJoined, TeamsLoaded } from 'src/app/_interface/store/quest-detail.actions';
import { QuestTeam } from 'src/app/_interface/team.types';
import { QuestService } from '../../../../_services/quest.service';

@Injectable()
export class QuestTeamModalEffects {

  constructor(private actions: Actions,
              private questService: QuestService,
              private notifier: NotifierService) {
  }

  
  doLoadTeams = createEffect(() => this.actions.pipe(
    ofType<LoadTeams>(LOAD_TEAMS),
    switchMap((action) => this.questService.getQuestTeams(action.questId).pipe(
      map((teams) => teams || []),
      catchError(() => {
        this.notifier.notify('error', 'There was an issue loading your teams');
        return of<QuestTeam[]>([]);
      })
    )),
    switchMap((teams) => [new TeamsLoaded(teams)])
  ));

  
  doLoadBrands = createEffect(() => this.actions.pipe(
    ofType<LoadBrands>(LOAD_BRANDS),
    switchMap((action) => this.questService.getQuestBrands(action.questId).pipe(
      map((brands) => brands || []),
      catchError(() => {
        this.notifier.notify('error', 'There was an issue loading brands');
        return of<BrandConfig[]>([]);
      })
    )),
    switchMap((brands) => [new BrandsLoaded(brands)])
  ));

  
  doCreateNewTeam = createEffect(() => this.actions.pipe(
    ofType<CreateTeam>(CREATE_TEAM),
    switchMap((action) => this.questService.startQuest({
      questMode: 'diyMode',
      questId: action.questId,
      questTeamAction: 'Create',
      questTeamName: action.teamName,
      questTeamLogoUrl: action.teamLogoUrl
    }).pipe(
      map((started) => {
        if (started) {
        this.notifier.notify('success', `New team "${action.teamName}" created`);
        } else {
        this.notifier.notify('warning', `Team "${action.teamName}" not created`);
        }
        return {action, started};
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error creating a team!');
        return of({action, started: false});
      })
    )),
    switchMap((result) => result.started ? this.questService.findTeam(result.action.questId, result.action.teamName).pipe(
      map((team) => team),
      catchError(() => {
        this.notifier.notify('error', 'Error loading team!');
        return of(null);
      })
    ) : of(null)),
    switchMap((team) => [new TeamCreated(team)])
  ));

  
  doJoinTeam = createEffect(() => this.actions.pipe(
    ofType<JoinTeam>(JOIN_TEAM),
    switchMap((action) => this.questService.startQuest({
      questMode: 'diyMode',
      questId: action.questId,
      questTeamAction: 'Join',
      questTeamId: action.teamId
    }).pipe(
      map((started) => {
        if (started) {
        this.notifier.notify('success', 'Successfully joined the team');
        } else {
        this.notifier.notify('warning', 'Could not join the team');
        }
        return {action, started};
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error joining a team!');
        return of({action, started: false});
      })
    )),
    switchMap((result) => result.started && result.action.teamId ? this.questService.getTeam(result.action.teamId).pipe(
      map((team) => team),
      catchError(() => {
        this.notifier.notify('error', 'Error loading team!');
        return of(null);
      })
    ) : of(null)),
    switchMap((team) => [new TeamJoined(team)])
  ));

  
  doStartQuest = createEffect(() => this.actions.pipe(
    ofType<StartQuest>(START_QUEST),
    switchMap((action) => this.questService.startQuest({
      questMode: action.questMode,
      questTeamName: action.fundraiserTitle,
      questTeamAction: action.referrerId ? 'Join' : null,
      referrerId: action.referrerId,
      questId: action.questId
    }).pipe(
      map((started) => {
        if (started) {
        this.notifier.notify('success', 'Quest started!');
        } else {
        this.notifier.notify('warning', 'Quest not started');
        }
        return started;
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error starting Quest!');
        return of(false);
      })
    )),
    switchMap((started) => [new QuestStarted(started)])
  ));

  
  doStartFundraiser = createEffect(() => this.actions.pipe(
    ofType<StartFundraiser>(START_FUNDRAISER),
    switchMap((action) => this.questService.startFundraising(
      action.questId,
      action.userId,
      action.brandUserId,
      action.targetAmount,
      action.currency,
      action.campaignName,
      null,
      null,
      null,
      null,
      action.secondaryBrandUserId
    ).pipe(
      map(link => {
        this.notifier.notify('success', 'Fundraiser started!');
        return link;
      }),
      catchError(() => {
        this.notifier.notify('error', 'Error starting fundraiser!');
        return of<FundraisingLinkType>(null);
      })
    )),
    switchMap((link) => link ? [new FundraiserStarted(link)] : [])
  ));

}
